import React, { useContext } from "react";
import CountUp from "react-countup";
import { Button, Image, Label } from "semantic-ui-react";

import BoardOverlayPlayer from "./BoardOverlayPlayer";
import { config } from "../../../../config";
import AuthContext from "../../../../contexts/AuthContext";
import { createDebugLogger } from "../../../../helpers/debug";
import Enum from "../../../../helpers/enums";
import { firstToUpper } from "../../../../utilities";
import Avatar from "../../../Avatar/Avatar";
import Brackets from "../../../Brackets/Brackets";
import EnableNotificationsPromptButton from "../../../EnableNotificationsPrompt/EnableNotificationsPromptButton";
import Icon from "../../../Icon/Icon";
import Plural from "../../../Plural/Plural";
import InfoPanel from "../../InfoPanel/InfoPanel";

import "./BoardOverlay.scss";

const { log: boardLog } = createDebugLogger(Enum.DebugChannel.BOARDOVERLAY);

const BoardOverlay = ({
  viewState,
  group,
  opponents,
  onContinue,
  currentGame,
  actorMove,
  notifyEnabled,
  onAskNotificationPermission,
  onRemoveNotifications,
  getNextRound,
  handleModalUserManageOpen,
  handleModalAlertOpen,
}) => {
  const { authState } = useContext(AuthContext);

  if (!viewState || viewState.view === Enum.BoardOverlayView.HIDE)
    return <div id="board-overlay" className="hide"></div>;

  const { view, data } = viewState;
  const { tournament, round } = data;

  let boardOverlayView = null,
    buttonText = "Continue";

  const opponentName = () => {
    if (!opponents || !opponents.length) return null;
    return opponents[0].accountUserName;
  };

  const putPlayerFirst = (list) => {
    const nList = list.filter((x) => x.accountId !== authState.account?.id);

    nList.splice(
      0,
      0,
      list.find((x) => x.accountId === authState.account?.id),
    );

    return nList;
  };

  const getResultString = (moves) => {
    let heading = "";
    let result = "";
    const [m1, m2, m3] = moves;

    if (m3) {
      let types = [m1, m2, m3];
      let rCount = types.filter((t) => t === Enum.MoveType.ROCK).length;
      let pCount = types.filter((t) => t === Enum.MoveType.PAPER).length;
      let sCount = types.filter((t) => t === Enum.MoveType.SCISSORS).length;

      if (m1 === m2 && m1 === m3 && m1 !== Enum.MoveType.FORFEIT) {
        result = "";
        heading = "It's a draw!";
      } else if (
        m1 !== Enum.MoveType.FORFEIT &&
        m2 !== Enum.MoveType.FORFEIT &&
        m3 !== Enum.MoveType.FORFEIT &&
        m1 !== m2 &&
        m1 !== m3 &&
        m2 !== m3
      ) {
        result = "";
        heading = "It's a draw!";
      } else if (m1 === m2 && m1 === m3 && m1 === Enum.MoveType.FORFEIT) {
        heading = "It's a draw!";
        result = "Nobody moved before the round ended.";
      } else if (m1 === Enum.MoveType.FORFEIT) {
        heading = "You lost!";
        result = "You didn't make your move in time.";
      } else if (pCount === 0) result = "Rock smashes scissors, ";
      else if (sCount === 0) result = "Paper covers rock, ";
      else if (rCount === 0) result = "Scissors cuts paper, ";
    } else {
      if (
        (m1 === Enum.MoveType.ROCK && m2 === Enum.MoveType.SCISSORS) ||
        (m1 === Enum.MoveType.SCISSORS && m2 === Enum.MoveType.ROCK)
      ) {
        result = `Rock smashes scissors`;
        heading = m1 === Enum.MoveType.ROCK ? "You won!" : "You lost!";
      } else if (
        (m1 === Enum.MoveType.ROCK && m2 === Enum.MoveType.PAPER) ||
        (m1 === Enum.MoveType.PAPER && m2 === Enum.MoveType.ROCK)
      ) {
        result = `Paper covers rock`;
        heading = m1 === Enum.MoveType.PAPER ? "You won!" : "You lost!";
      } else if (
        (m1 === Enum.MoveType.PAPER && m2 === Enum.MoveType.SCISSORS) ||
        (m1 === Enum.MoveType.SCISSORS && m2 === Enum.MoveType.PAPER)
      ) {
        result = `Scissors cuts paper`;
        heading = m1 === Enum.MoveType.SCISSORS ? "You won!" : "You lost!";
      } else if (m1 === m2 && m1 !== Enum.MoveType.FORFEIT) {
        result = "";
        heading = "It's a draw!";
      } else if (m1 === m2 && m1 === Enum.MoveType.FORFEIT) {
        result = "Neither of you moved before the round ended";
        heading = "It's a draw!";
      } else if (m1 === Enum.MoveType.FORFEIT) {
        result = "You didn't make your move in time";
        heading = "You lose!";
      } else if (m2 === Enum.MoveType.FORFEIT) {
        result = "Your opponent didn't move in time";
        heading = "You won!";
      }
    }

    let headingClass = "text-blue";
    if (heading === "You won!") headingClass = "text-green";
    else if (heading === "You lost!") headingClass = "text-red";

    return { heading, result, headingClass };
  };

  if (view === Enum.BoardOverlayView.DECISION_START) {
    /*
    boardOverlayView = (
      <>
        {group.promo && (
          <div className="board-overlay-decision">
            The tournament has begun!
          </div>
        )}
        {!group.promo && (
          <div className="board-overlay-decision">
            {tournament.creatorUserName} has started a new decision.
          </div>
        )}
        <div className="board-overlay-stake board-overlay-heading">
          {tournament.stake}
        </div>
        <div className="dn-promo-body">{parse(group.promoBodyHtml)}</div>
        <div className="board-overlay-prizes">
          {tournament?.prizes?.map((p) => (
            <div key={p.awardedToTopPlayers}>
              <Image
                circular
                src={`/images/prize/${p.id}.jpg`}
                className={`prog-${p.level}`}
              />
            </div>
          ))}
        </div>
        <div className="board-overlay-rules">
          <div className="board-overlay-rules-first-to">
            <DecisionFirstTo tournament={tournament} round={round} />
          </div>
          <div className="board-overlay-rules-time">
            {tournament.gameLengthMinutes} minute time limit per game
          </div>
          Players have {minutesToTimeString(group.promoMoveResponseMinutes)} to
          move once their opponent moves.
          <br />
          Round ends tonight at {round.endTime.format("hh A")}{" "}
          {group.promoTimezone}.
        </div>
      </>
    );
    */
  } else if (view === Enum.BoardOverlayView.ROUND_START) {
    const { match } = data;

    boardLog("Round Start", round.roundNumber, match);

    const nextRound = getNextRound(round.roundNumber);
    const firstRound = round.roundNumber === 1;

    boardLog("In decision? First round?", firstRound);

    const finalMovesDue = round.state === Enum.RoundState.ENDING;

    boardOverlayView = (
      <>
        <div className="board-overlay-round-num board-overlay-heading">
          {round.title}
        </div>
        {finalMovesDue && (
          <div className="board-overlay-round-num board-overlay-subheading">
            {nextRound?.prize && opponentName() && (
              <span>
                It's the final move. Advance to {nextRound?.progwArticle} to win{" "}
                {/* {nextRound?.prize.article} {nextRound?.prize.name}. */}
                the prize.
              </span>
            )}
            {!nextRound?.prize && opponents && (
              <span>It's the final move. Good luck!</span>
            )}
          </div>
        )}

        {!finalMovesDue && (
          <div className="board-overlay-round-num board-overlay-subheading">
            {nextRound?.prize && opponentName() && (
              <span>
                Beat {opponentName()} twice to advance and win{" "}
                {/* {nextRound?.prize.article} {nextRound?.prize.name}. */}
                the prize.
              </span>
            )}
            {!nextRound?.prize && opponents && (
              <span>
                Beat {opponentName()} twice to advance to{" "}
                {nextRound?.progwArticle}!
              </span>
            )}
          </div>
        )}
        <div className="board-overlay-round-outcome">
          {!match && (
            <>
              {tournament.stakeIsPositive && (
                <span>
                  You were knocked out of the {config.strings.decision}.
                </span>
              )}
              {!tournament.stakeIsPositive && (
                <span>You are safe from the penalty!</span>
              )}
            </>
          )}
        </div>
        <Brackets round={round} match={match}></Brackets>
        {round.matchesCount > 1 && (
          <span className="board-overlay-in-progress">
            <span className="board-overlay-in-progress-title">
              <Icon className="fas fa-circle fa-fade"></Icon> In progress
            </span>
            {round.matchesCount - 1} other{" "}
            <Plural
              base="match"
              suffix="es"
              value={round.matchesCount - 1}
            ></Plural>{" "}
            being played right now
          </span>
        )}
      </>
    );

    buttonText = "Make move";
  } else if (view === Enum.BoardOverlayView.MATCH_PROGRESS) {
    const { tournament, match, oldGame } = data;

    const players = putPlayerFirst(oldGame.gameAccounts);
    const nextRound = getNextRound(round.roundNumber);

    const currentScores =
      match && match.matchAccounts && match.matchAccounts.length ?
        players.map(
          (p) =>
            match.matchAccounts.find((x) => x.accountId === p.accountId)?.score,
        )
      : players.map(() => 0);

    const oldScores = players.map(
      (p, i) =>
        currentScores[i] -
        ((
          oldGame?.gameAccounts?.find((x) => x.accountId === p.accountId)
            ?.result === Enum.ResultType.WIN
        ) ?
          1
        : 0),
    );

    const moves = players.map(
      (p) => oldGame?.moves?.find((x) => x.accountId === p.accountId)?.moveType,
    );

    const mySemiFinalResultDecidesTournament =
      round.playersCount === 3 && !match.vsAi && round.playersRemaining === 2;

    const playerData = players.map((p, i) => ({
      accountId: p.accountId,
      accountUserName: p.accountUserName,
      currentScore: currentScores[i],
      oldScore: oldScores[i],
      move: moves[i],
    }));

    const myScore = currentScores ? currentScores[0] : 0;
    const theirScore = currentScores ? currentScores[1] : 0;

    const whoIs1Away = playerData.find(
      (x) => x.currentScore + 1 === tournament.firstToGames,
    );
    const all1Away = !playerData.some(
      (x) => x.currentScore + 1 < tournament.firstToGames,
    );

    let statusSpan = <></>;
    let prizeStr = tournament.stakeIsPositive ? " the prize" : " the penalty";

    const finalMovesDue = round.state === Enum.RoundState.ENDING;
    // finalMovesDue is not used yet, was for rounds with specific end times and final move windows
    if (finalMovesDue) {
      statusSpan = "It's the final move of the round. ";
      if (myScore === theirScore)
        statusSpan += `The winner advances to ${nextRound?.progwArticle}${prizeStr}!`;
      else if (myScore === 1)
        statusSpan += `Win to guarantee your spot in ${nextRound?.progwArticle}!`;
      else
        statusSpan += `Win to make it 1 - 1. A random player will be selected to advance${prizeStr}.`;
    } else if (whoIs1Away) {
      if (all1Away) {
        statusSpan = (
          <>
            Next player to win{" "}
            {group?.isSolo ?
              "is the champion!"
            : round.isFinalRound || mySemiFinalResultDecidesTournament ?
              tournament.stakeIsPositive ?
                `receives the prize` // ${nextRound?.prize.article} ${nextRound?.prize.name}!`
              : "is safe from the penalty"
            : tournament.stakeIsPositive ?
              <>
                advances to
                <br />
                {nextRound?.progwArticle}
              </>
            : "is safe from the penalty"}
          </>
        );
      } else {
        statusSpan = (
          <>
            {whoIs1Away.accountId !== authState.account?.id && (
              <>
                Lose once more and{" "}
                {group?.isSolo ?
                  "RKObot beats you"
                : round.isFinalRound || mySemiFinalResultDecidesTournament ?
                  tournament.stakeIsPositive ?
                    `you miss out on the prize`
                  : "you will receive the penalty"
                : tournament.stakeIsPositive ?
                  "you will be knocked out"
                : "you move one round closer to penalty"}
              </>
            )}

            {whoIs1Away.accountId === authState.account?.id && (
              <>
                You need 1 more win to{" "}
                {group?.isSolo ?
                  "beat RKObot"
                : round.isFinalRound || mySemiFinalResultDecidesTournament ?
                  tournament.stakeIsPositive ?
                    `receive the prize`
                  : "be safe from the penalty"
                : tournament.stakeIsPositive ?
                  <>
                    advance to
                    <br />
                    {nextRound?.progwArticle}
                  </>
                : "be safe from the penalty"}
              </>
            )}
          </>
        );
      }
    } else {
      statusSpan = (
        <>
          {tournament.firstToGames > 1 ?
            `First to win ${tournament.firstToGames} moves `
          : "Winner "}

          {group?.isSolo ?
            " is the champion!"
          : tournament.stakeIsPositive ?
            !round.isFinalRound && !mySemiFinalResultDecidesTournament ?
              <>
                advances to
                <br />
                {nextRound?.progwArticle}!
              </>
            : `receives${prizeStr}!`
          : "is safe from the penalty"}
        </>
      );
    }

    const resultStrings = getResultString(moves);
    const anyForfeit = moves.some((x) => x === Enum.MoveType.FORFEIT);

    boardOverlayView = (
      <>
        {!group?.isSolo && (
          <div className="board-overlay-decision">
            <div className="board-overlay-stake-is">
              {tournament.stakeIsPositive && (
                <span className="text-green">
                  <Icon className="fas fa-fw fa-thumbs-up"></Icon> Prize
                </span>
              )}
              {!tournament.stakeIsPositive && (
                <span className="text-red">
                  <Icon className="fas fa-fw fa-thumbs-down"></Icon>
                  {""}
                  Penalty
                </span>
              )}
            </div>
            <div className="board-overlay-stake">{tournament.stake}</div>
          </div>
        )}
        {tournament.startingPlayers > 2 && (
          <div className="board-overlay-round-num board-overlay-subheading">
            {round.title}
          </div>
        )}
        <div className="board-overlay-current-match board-overlay-heading">
          {resultStrings.heading}
        </div>
        {resultStrings.result && (
          <div className="board-overlay-last-game-result board-overlay-subheading">
            {resultStrings.result}
          </div>
        )}
        <div className="board-overlay-match-summary">
          {playerData.length > 0 && (
            <BoardOverlayPlayer
              player={playerData[0]}
              hideMove={anyForfeit}
              hideScore={tournament.firstToGames === 1}
              handleModalUserManageOpen={handleModalUserManageOpen}
            />
          )}
          {playerData.length > 1 && (
            <>
              <div className="board-overlay-ms-v">vs</div>
              <BoardOverlayPlayer
                player={playerData[1]}
                hideMove={anyForfeit}
                hideScore={tournament.firstToGames === 1}
                handleModalUserManageOpen={handleModalUserManageOpen}
                className="player-right"
              />
            </>
          )}
        </div>
        {/* {!statusSpan && (
          <DecisionFirstTo tournament={tournament} round={round} />
        )} */}
        {!!statusSpan && (
          <div className="board-overlay-status-big">
            {statusSpan}
            {mySemiFinalResultDecidesTournament && (
              <>
                <br />
                <div
                  className="provisional-result"
                  onClick={() => {
                    handleModalAlertOpen(
                      "Result decides tournament",
                      tournament.stakeIsPositive ?
                        config.messages.semiFinalResultDecidesTournament
                          .positive
                      : config.messages.semiFinalResultDecidesTournament
                          .negative,
                    );
                  }}
                >
                  <Label color="orange" size="small">
                    Deciding match{" "}
                    <Icon className="far fa-question-circle"></Icon>
                  </Label>
                </div>
              </>
            )}
          </div>
        )}
        {/* If match max games 1 away, say "you've played X moves this match. Random/you/they win if the next move is drawn" */}
      </>
    );

    buttonText = "Make move";
  } else if (view === Enum.BoardOverlayView.MATCH_END) {
    const { oldGame, oldMatch } = data;

    boardLog("Match End", round, oldGame, oldMatch);

    const players = putPlayerFirst(oldGame.gameAccounts);
    const nextRound = getNextRound(round.roundNumber);

    boardLog("Players", players);

    const currentScores =
      oldMatch && oldMatch.matchAccounts && oldMatch.matchAccounts.length ?
        players.map(
          (p) =>
            oldMatch.matchAccounts.find((x) => x.accountId === p.accountId)
              .score,
        )
      : players.map(() => 0);

    boardLog("Current Scores", currentScores);

    const oldScores = players.map(
      (p, i) =>
        currentScores[i] -
        ((
          oldGame?.gameAccounts?.find((x) => x.accountId === p.accountId)
            .result === Enum.ResultType.WIN
        ) ?
          1
        : 0),
    );

    boardLog("Old Scores", oldScores);

    const moves = players.map(
      (p) => oldGame?.moves?.find((x) => x.accountId === p.accountId)?.moveType,
    );

    boardLog("Moves", moves);

    const playerData = players.map((p, i) => ({
      accountId: p.accountId,
      accountUserName: p.accountUserName,
      currentScore: currentScores[i],
      oldScore: oldScores[i],
      move: moves[i],
    }));

    boardLog("Player Data", playerData);

    const mySemiFinalResultDecidesTournament =
      round.playersCount === 3 &&
      !oldMatch.vsAi &&
      round.playersRemaining === 2;

    let iWon =
      oldMatch.matchAccounts.find((x) => x.accountId === authState.account?.id)
        .result === 1;
    let iAdvanced =
      (tournament.stakeIsPositive && iWon) ||
      (!tournament.stakeIsPositive && !iWon);
    let statusSpan = ""; //iWon ? "You won! " : "You lost! ";

    let heading = iWon ? "You won!" : "You lost!"; //statusSpan;
    let headingClass = iWon ? "text-green" : "text-red";

    const lastGameResult = getResultString(moves).result;

    statusSpan =
      group?.isSolo ?
        iWon ? "You beat RKObot, congratulations!"
        : "You lost against RKObot, better luck next time!"
      : round.isFinalRound || mySemiFinalResultDecidesTournament ?
        tournament.stakeIsPositive ?
          iWon ? "You won the prize, congratulations!"
          : "You missed out on the prize!"
        : iWon ? "You are safe from the penalty!"
        : "You receive the penalty!"
      : tournament.stakeIsPositive ?
        iWon ?
          <>
            You have advanced to
            <br />
            {nextRound?.progwArticle}
          </>
        : "You have been knocked out"
      : iWon ? "You are safe from the penalty, but who will be chosen?"
      : `Unfortunately, you move one round closer to the penalty`;

    boardLog("Status Span", statusSpan);

    const anyForfeit = moves.some((x) => x === Enum.MoveType.FORFEIT);

    boardOverlayView = (
      <>
        {!group?.isSolo && (
          <div className="board-overlay-decision">
            <div className="board-overlay-stake-is">
              {tournament.stakeIsPositive && (
                <span className="text-green">
                  <Icon className="fas fa-fw fa-thumbs-up"></Icon> Prize
                </span>
              )}
              {!tournament.stakeIsPositive && (
                <span className="text-red">
                  <Icon className="fas fa-fw fa-thumbs-down"></Icon>
                  {""}
                  Penalty
                </span>
              )}
            </div>
            <div className="board-overlay-stake">{tournament.stake}</div>
          </div>
        )}
        {tournament.startingPlayers > 2 && (
          <div className="board-overlay-round-num board-overlay-subheading">
            {round.title}
          </div>
        )}
        <div
          className={`board-overlay-current-match board-overlay-heading ${headingClass}`}
        >
          {heading}
        </div>
        <div className="board-overlay-last-game-result board-overlay-subheading">
          {lastGameResult}
        </div>
        <div className="board-overlay-match-summary">
          {playerData.length > 0 && (
            <BoardOverlayPlayer
              player={playerData[0]}
              hideMove={anyForfeit}
              hideScore={tournament.firstToGames === 1}
              handleModalUserManageOpen={handleModalUserManageOpen}
            />
          )}
          {playerData.length > 1 && (
            <>
              <div className="board-overlay-ms-v">vs</div>
              <BoardOverlayPlayer
                player={playerData[1]}
                hideMove={anyForfeit}
                hideScore={tournament.firstToGames === 1}
                className="player-right"
                handleModalUserManageOpen={handleModalUserManageOpen}
              />
            </>
          )}
        </div>

        {oldMatch.randomResult && (
          <div>
            The match was drawn, so a random player was selected as winner
          </div>
        )}
        {!!statusSpan && (
          <div className="board-overlay-status-big">
            {statusSpan}
            {mySemiFinalResultDecidesTournament && (
              <>
                <br />
                <div
                  className="provisional-result"
                  onClick={() => {
                    handleModalAlertOpen(
                      "Result decides tournament",
                      tournament.stakeIsPositive ?
                        config.messages.semiFinalResultDecidesTournament
                          .positive
                      : config.messages.semiFinalResultDecidesTournament
                          .negative,
                    );
                  }}
                >
                  <Label color="orange" size="small">
                    Deciding match{" "}
                    <Icon className="far fa-question-circle"></Icon>
                  </Label>
                </div>
              </>
            )}
          </div>
        )}
        {iAdvanced && round.matchesCount - round.matchesComplete - 1 > 0 && (
          <div className="wait-for-round">
            Wait for {round.title} to finish
            <EnableNotificationsPromptButton />
          </div>
        )}
      </>
    );
  } else if (view === Enum.BoardOverlayView.ROUND_END) {
    // Obsolete
    const { prevMatch, prevRound, newRound } = data;

    boardLog("Round End", prevRound, newRound);

    const inPrev = !!prevMatch;
    const myResult = prevMatch?.matchAccounts?.find(
      (x) => x.accountId === authState.account?.id,
    )?.result;
    const inNext =
      (tournament.stakeIsPositive && myResult === Enum.ResultType.WIN) ||
      (!tournament.stakeIsPositive && myResult === Enum.ResultType.LOSS);
    const nextRound = getNextRound(prevRound.roundNumber);

    boardLog("In prev, in next", inPrev, inNext);

    boardOverlayView = (
      <>
        <div className="board-overlay-round-complete board-overlay-heading">
          {round.title.toUpperCase()} COMPLETE
        </div>
        <div className="board-overlay-players">
          {prevRound && newRound && (
            <CountUp
              start={prevRound.playersCount}
              end={newRound.advancersCount}
              delay={0}
              duration={2}
              suffix=" players"
            />
          )}
        </div>
        <div className="board-overlay-round-outcome">
          {!inPrev && (
            <span>
              You were knocked out of the {config.strings.decision} in an
              earlier round
            </span>
          )}
          {inPrev && (
            <>
              {!inNext && (
                <>
                  {tournament.stakeIsPositive && (
                    <span>
                      You have been knocked out of the {config.strings.decision}
                      !
                    </span>
                  )}
                  {!tournament.stakeIsPositive && (
                    <span>You are safe from the penalty!</span>
                  )}
                </>
              )}
              {inNext && (
                <>
                  {tournament.stakeIsPositive && (
                    <span>
                      You advance to ${nextRound?.progwArticle}.
                      {nextRound?.prize && (
                        <span>
                          {" "}
                          Congratulations, you won the prize!{" "}
                          {/*
                            nextRound?.prize.article
                      }{" "}
                    {nextRound?.prize.name}!*/}
                          <div className="board-overlay-prizes">
                            <Image
                              circular
                              src={`/images/prize/${nextRound?.prize.id}.jpg`}
                              className={`prog-${nextRound?.prize.level}`}
                            />
                          </div>
                        </span>
                      )}
                    </span>
                  )}
                  {!tournament.stakeIsPositive && (
                    <span>You are one round closer to the penalty!</span>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  } else if (view === Enum.BoardOverlayView.DECISION_END) {
    // Obsolete
    boardOverlayView = (
      <>
        <div className="board-overlay-decision-end board-overlay-heading">
          {group.promo ? "TOURNAMENT COMPLETE" : "DECISION RESOLVED"}
        </div>
        <div
          className="board-overlay-chosen"
          onClick={() => handleModalUserManageOpen(tournament.theChosenOneId)}
        >
          <Avatar user={tournament.theChosenOneId} />
          <br />
          {tournament.theChosenOneUserName}
          <br />
          receives the {tournament.stakeIsPositive ? "prize" : "penalty"}
          <br />
          {tournament.stake}
        </div>
      </>
    );
  } else if (view === Enum.BoardOverlayView.DECISION_CANCELLED) {
    const { leader } = data;
    boardOverlayView = (
      <>
        <div className="board-overlay-decision-cancelled board-overlay-heading">
          {config.strings.decision.toUpperCase()} CANCELLED
        </div>
        <div className="board-overlay-chosen">
          {leader} cancelled the {config.strings.decision} early
        </div>
        <div className="">
          Nobody was chosen to receive the{" "}
          {tournament.stakeIsPositive ? "prize" : "penalty"}: {tournament.stake}
        </div>
      </>
    );
  } else if (view === Enum.BoardOverlayView.LOADING) {
    boardOverlayView = <></>;
  } else if (view === Enum.BoardOverlayView.KNOCKED_OUT) {
    boardOverlayView = (
      <>
        {tournament.stakeIsPositive && (
          <>
            <div className="board-overlay-knocked-out board-overlay-heading">
              KNOCKED OUT
            </div>
            <div className="board-overlay-chosen">
              You have been knocked out of the tournament
            </div>
          </>
        )}

        {!tournament.stakeIsPositive && (
          <>
            <div className="board-overlay-knocked-out board-overlay-heading">
              YOU'RE SAFE
            </div>
            <div className="board-overlay-chosen">
              You are safe from suffering the punishment
            </div>
          </>
        )}
      </>
    );
  } else if (view === Enum.BoardOverlayView.WAITING_FOR_ROUND) {
    const { match } = data;

    const nextRound = getNextRound(round.roundNumber);

    boardOverlayView = (
      <>
        {tournament.stakeIsPositive && (
          <>
            <div className="board-overlay-knocked-out board-overlay-heading">
              CONGRATULATIONS!
            </div>
            {match && match.endReason === Enum.EndReason.BYE && (
              <div className="board-overlay-chosen">
                You were randomly selected to receive a bye due to only 3
                players entering the 2nd last round
              </div>
            )}
            <div className="board-overlay-chosen">
              Your match is complete and you're through to{" "}
              {nextRound?.progwArticle}!
              <br />
              {firstToUpper(nextRound?.progwArticle)} begin
              {nextRound?.prog[nextRound?.prog.length - 1] === "s" ?
                ""
              : "s"}{" "}
              {round.nextRoundCalendar}.
            </div>
            <div className="board-overlay-remaining">
              <span>{round.playersRemaining}</span>
              <br />
              players remaining
            </div>
          </>
        )}

        {!tournament.stakeIsPositive && (
          <>
            <div className="board-overlay-knocked-out board-overlay-heading">
              YOU'RE SAFE
            </div>
            <div className="board-overlay-chosen">
              You are safe from suffering the punishment
            </div>
          </>
        )}
      </>
    );
  } else if (view === Enum.BoardOverlayView.TOURNAMENT_WON) {
    boardOverlayView = (
      <>
        {tournament.stakeIsPositive && (
          <>
            <div className="board-overlay-tournament-won board-overlay-heading">
              CONGRATULATIONS!
            </div>
            <div className="board-overlay-chosen">
              You won the tournament and the major prize!
              <br />
              We will be in touch to organise receipt of your prizes
            </div>
          </>
        )}

        {!tournament.stakeIsPositive && (
          <>
            <div className="board-overlay-tournament-won board-overlay-heading">
              YOU'RE SAFE
            </div>
            <div className="board-overlay-chosen">
              You are safe from suffering the punishment
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <div id="board-overlay" className="show">
      <div className="board-overlay-content">
        <div className="board-overlay-scroll-wrap">
          <div className="board-overlay-view">
            {/* <pre>View: {view}</pre>
          <pre>
            Current Game End Time: {JSON.stringify(currentGame?.endTime)}
          </pre>
          <pre>Next Round Begins: {JSON.stringify(round?.nextRoundBegins)}</pre> */}
            {boardOverlayView}

            {currentGame &&
              currentGame.endTime &&
              (view === Enum.BoardOverlayView.DECISION_START ||
                view === Enum.BoardOverlayView.ROUND_START ||
                view === Enum.BoardOverlayView.MATCH_PROGRESS) && (
                <InfoPanel
                  endTime={currentGame.endTime}
                  moved={!!actorMove}
                  notifyEnabled={notifyEnabled}
                  group={group}
                  //hide={
                  //  (!currentGame.moves || currentGame.moves.length === 0)
                  //}
                  onAskNotificationPermission={onAskNotificationPermission}
                  onRemoveNotifications={onRemoveNotifications}
                />
              )}
          </div>
        </div>
        {onContinue && viewState.view !== Enum.BoardOverlayView.LOADING && (
          <div className="action-panel">
            <Button
              icon
              size="big"
              primary
              className="decide-now"
              onClick={onContinue}
            >
              {buttonText}
              <Icon className="fas fa-chevron-right"></Icon>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BoardOverlay;
