import moment from "moment";
import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from "react";
import _ from "underscore";

import Board from "./Board/Board";
import BoardOverlay from "./Board/BoardOverlay/BoardOverlay";
import InfoPanel from "./InfoPanel/InfoPanel";
import { config } from "../../config";
import AuthContext from "../../contexts/AuthContext";
import { createDebugLogger } from "../../helpers/debug";
import Enum from "../../helpers/enums";
import { usePrevious } from "../../hooks";
import { clamp } from "../../utilities";

import "./DecidingInterface.scss";

const { log: decidingLog } = createDebugLogger(Enum.DebugChannel.DECIDING);

const DecidingInterface = ({
  group,
  currentTournament,
  currentRound,
  currentMatch,
  currentGame,
  rpsEventCurrent,
  onMove,
  notifyEnabled,
  onAskNotificationPermission,
  onRemoveNotifications,
  onCloseBoard,
  handleModalUserManageOpen,
  handleModalInstallAppEnableNotificationsOpen,
  handleModalAlertOpen,
}) => {
  const [decidingView, setDecidingView] = useState(Enum.DecidingView.LOADING);
  const [boardOverlayView, setBoardOverlayView] = useState({
    view: Enum.BoardOverlayView.HIDE,
  });
  const [actor, setActor] = useState();
  const [opponents, setOpponents] = useState([]);
  const [actorMove, setActorMove] = useState([]);
  const [opponentMoves, setOpponentMoves] = useState([]);
  const [viewQueue, setViewQueue] = useState([]);
  //const [viewQueueTimer, setViewQueueTimer] = useState(Date.now);
  const [prizeProgMarkers, setPrizeProgMarkers] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [prizeProgCurrent, setPrizeProgCurrent] = useState(0);
  //const [nearlyFinalMoves, setNearlyFinalMoves] = useState(false);
  const [overlayOpened, setOverlayOpened] = useState();

  const { authState } = useContext(AuthContext);

  const rpsEventPrevious = usePrevious(rpsEventCurrent);
  // const viewQueueTimerRef = useRef(null);

  const popFromViewQueue = useCallback(() => {
    let newQueue = [...viewQueue];
    const item = newQueue.shift();
    setViewQueue(newQueue);

    decidingLog("Processing item", item);
    if (!item) {
      setBoardOverlayView({ view: Enum.BoardOverlayView.HIDE });
      if (overlayOpened && (!currentMatch || currentMatch.completed))
        onCloseBoard();
    } else {
      setBoardOverlayView(item);
      setOverlayOpened(true);
    }
  }, [currentMatch, onCloseBoard, overlayOpened, viewQueue]);

  const mapTournament = (t) => {
    return {
      firstToGames: t.firstToGames,
      stake: t.stake,
      stakeIsPositive: t.stakeIsPositive,
      startingPlayers: t.startingPlayers,
      prizes: t.prizes,
    };
  };

  const roundwArticle = (s) => {
    return s ? (s.indexOf("Round") === -1 ? "the " : "") + s : " next round";
  };

  const mapRound = useCallback((r) => {
    const mappedRound = {
      roundNumber: r.roundNumber,
      playersCount: r.playersCount,
      endTime: moment(r.endTime),
      nextRoundBegins: moment(r.nextRoundBegins),
      prevRoundMatches: r.prevRoundMatches,
      matchesCount: r.matchesCount,
      matchesComplete: r.matchesComplete,
      playersRemaining: r.playersRemaining,
      state: r.state,
      title: r.title,
      titlewArticle: roundwArticle(r.title),
      isFinalRound: r.isFinalRound,
    };

    return mappedRound;
  }, []);

  const deepCopy = (i) => JSON.parse(JSON.stringify(i));

  const pushToViewQueue = useCallback(
    (items) => {
      decidingLog("Pushing item to queue", items);

      setViewQueue([...viewQueue, ...items]);
    },
    [viewQueue],
  );

  const onPageLoad = useCallback(() => {
    /*
      - If Round 1, Game 1, and I haven't moved
        show TOURN_START, then ROUND_START

      - If >= Game 2 and I haven't moved
        show MATCH PROGRESS

      - If Match finished but new round hasn't started
        show MATCH PROGRESS
        show ROUND END

      - If >= Round 2, Game 1, and I haven't moved
        show MATCH PROGRESS for last game of previous round;
        show ROUND END for previous round;
        ROUND START for current round
      */

    decidingLog(
      "Page loaded. Original data:",
      currentTournament,
      currentRound,
      currentMatch,
      currentGame,
    );

    const views = [];

    if (currentTournament && currentRound) {
      if (currentMatch) {
        const iMoved = currentGame?.moves?.some(
          (x) => x.accountId === authState?.account?.id,
        );
        // const theyMoved = currentGame?.moves?.some(
        //   (x) => x.accountId !== authState?.account?.id
        // );

        if (
          currentRound.roundNumber > 1 &&
          currentMatch.games.length === 1 &&
          currentGame &&
          !iMoved
        ) {
          //show MATCH PROGRESS for last game of previous round;
          //show ROUND END for previous round;
          //ROUND START for current round
          const vData0 = {};

          vData0.tournament = mapTournament(currentTournament);
          vData0.tournament.tournamentAccountsLookup = deepCopy(
            currentTournament.tournamentAccountsLookup,
          );
          vData0.tournament.creatorUserName = currentTournament.creatorUserName;
          vData0.tournament.stakeIsPositive = currentTournament.stakeIsPositive;
          vData0.tournament.stake = currentTournament.stake;
          vData0.tournament.gameLengthMinutes =
            currentTournament.gameLengthMinutes;

          vData0.round = mapRound(currentRound);
          vData0.match = deepCopy(currentMatch);

          views.push({
            view: Enum.BoardOverlayView.ROUND_START,
            data: vData0,
          });
        }

        // If Round 1, Game 1, and I haven't moved
        // show DECISION_START then ROUND_START
        if (
          currentRound.roundNumber === 1 &&
          currentMatch.games.length === 1 &&
          currentGame &&
          !iMoved
        ) {
          const vData1 = {};

          vData1.tournament = mapTournament(currentTournament);
          vData1.tournament.tournamentAccountsLookup = deepCopy(
            currentTournament.tournamentAccountsLookup,
          );
          vData1.tournament.creatorUserName = currentTournament.creatorUserName;
          vData1.tournament.stakeIsPositive = currentTournament.stakeIsPositive;
          vData1.tournament.stake = currentTournament.stake;
          vData1.tournament.gameLengthMinutes =
            currentTournament.gameLengthMinutes;

          vData1.round = mapRound(currentRound);
          vData1.match = deepCopy(currentMatch);

          views.push({
            view: Enum.BoardOverlayView.DECISION_START,
            data: vData1,
          });

          views.push({
            view: Enum.BoardOverlayView.ROUND_START,
            data: vData1,
          });
        }

        // If >= Game 2 in an incomplete match and I haven't moved
        // show MATCH_PROGRESS for current match
        if (
          currentMatch.games.length > 1 &&
          !currentMatch.completed &&
          !iMoved
        ) {
          // match progress
          const vData2 = {
            tournament: mapTournament(currentTournament),
            round: mapRound(currentRound),
            match: deepCopy(currentMatch),
            oldGame: deepCopy(currentMatch.games[1]),
            newGame: deepCopy(currentGame),
          };

          vData2.round.isFinalRound = currentRound.isFinalRound;

          views.push({
            view: Enum.BoardOverlayView.MATCH_PROGRESS,
            data: vData2,
          });
        }
      }
    }

    if (!views.length) views.push({ view: Enum.BoardOverlayView.HIDE });

    pushToViewQueue(views);
  }, [
    authState?.account?.id,
    currentGame,
    currentMatch,
    currentRound,
    currentTournament,
    mapRound,
    pushToViewQueue,
  ]);

  const onTournamentStarted = useCallback(() => {
    /*
    decidingLog(
      "Tournament started. Original data:",
      currentTournament,
      currentRound,
      currentMatch
    );

    const vData = {};

    vData.tournament = mapTournament(currentTournament);
    vData.tournament.tournamentAccountsLookup = deepCopy(
      currentTournament.tournamentAccountsLookup
    );
    vData.tournament.creatorUserName = currentTournament.creatorUserName;
    vData.tournament.stakeIsPositive = currentTournament.stakeIsPositive;
    vData.tournament.stake = currentTournament.stake;
    vData.tournament.gameLengthMinutes = currentTournament.gameLengthMinutes;

    vData.round = mapRound(currentRound);
    vData.match = deepCopy(currentMatch);

    decidingLog("Tournament started. View data:", vData);

    const views = [
      {
        view: Enum.BoardOverlayView.DECISION_START,
        data: vData,
      },
    ];

    if (currentMatch && !currentMatch.completed)
      views.push({
        view: Enum.BoardOverlayView.ROUND_START,
        data: vData,
      });

    pushToViewQueue(views);
    */
  }, []);

  const onGameStarted = useCallback(
    (rpsEventPrev, rpsEventCurrent) => {
      decidingLog("Game started", rpsEventPrev, rpsEventCurrent);

      if (!rpsEventPrev || !rpsEventCurrent) return;

      const vData = {
        tournament: mapTournament(currentTournament),
        round: mapRound(currentRound),
        match: deepCopy(currentMatch),
        oldGame: deepCopy(rpsEventPrev.subject),
        newGame: deepCopy(rpsEventCurrent.subject),
      };

      vData.round.isFinalRound = currentRound.isFinalRound;

      pushToViewQueue([
        { view: Enum.BoardOverlayView.MATCH_PROGRESS, data: vData },
      ]);
    },
    [currentMatch, currentRound, currentTournament, mapRound, pushToViewQueue],
  );

  const onMatchFinished = useCallback(
    (rpsEventPrev, rpsEventCurrent) => {
      decidingLog("Match finished", rpsEventPrev, rpsEventCurrent);

      if (!rpsEventPrev || !rpsEventCurrent) return;

      const vData = {
        tournament: mapTournament(currentTournament),
        round: mapRound(currentRound),
        oldGame: deepCopy(rpsEventPrev.subject),
        oldMatch: deepCopy(rpsEventCurrent.subject),
      };

      vData.oldMatch.vsAi = vData.oldMatch.matchAccounts.some(
        (ma) => ma.accountId === config.aiAccountMember.accountId,
      );

      pushToViewQueue([{ view: Enum.BoardOverlayView.MATCH_END, data: vData }]);
    },
    [currentRound, currentTournament, mapRound, pushToViewQueue],
  );

  const onRoundFinished = useCallback(() => {
    decidingLog("Round finished", rpsEventCurrent?.subject);

    if (!rpsEventCurrent || !currentTournament || !currentTournament.myMatch)
      return;

    // const vData = {
    //   tournament: mapTournament(currentTournament),
    //   prevRound: mapRound(currentRound),
    //   prevMatch: deepCopy(currentMatch),
    //   newRound: deepCopy(rpsEventCurrent.subject),
    // };

    /*
    pushToViewQueue([
      {
        view: Enum.BoardOverlayView.ROUND_END,
        data: vData,
      },
    ]);
    */
  }, [currentTournament, rpsEventCurrent]);

  const onRoundStarted = useCallback(() => {
    if (!currentTournament || !currentTournament.myMatch) return;
    /*
      decidingLog("Round started", rpsEventCurrent?.subject);

      const vData = {};

      vData.tournament = mapTournament(currentTournament);
      vData.tournament.tournamentAccountsLookup = deepCopy(
        currentTournament.tournamentAccountsLookup
      );
      vData.tournament.stakeIsPositive = currentTournament.stakeIsPositive;

      vData.round = mapRound(rpsEventCurrent?.subject);
      vData.match = deepCopy(rpsEventCurrent?.subject?.myMatch);

      pushToViewQueue([
        {
          view: Enum.BoardOverlayView.ROUND_START,
          data: vData,
        },
      ]);
      */
  }, [currentTournament]);

  const onTournamentFinished = useCallback(() => {
    /*decidingLog("Tournament finished");
    const vData = { tournament: deepCopy(rpsEventCurrent?.subject) };
    pushToViewQueue([
      { view: Enum.BoardOverlayView.DECISION_END, data: vData },
    ]);*/
  }, []);

  const onTournamentClosed = useCallback(
    () => {
      decidingLog("Tournament closed");
      /*
    const data = {
      tournament: currentTournament,
      leader: rpsEventCurrent?.actor?.accountUserName,
    };
    pushToViewQueue([{ view: Enum.BoardOverlayView.DECISION_CANCELLED, data }]);
    */
    },
    [
      /*currentTournament, pushToViewQueue, rpsEventCurrent*/
    ],
  );

  const handleBoardOverlayContinue = () => {
    //onRpsEventContinue();

    if (boardOverlayView.view === Enum.BoardOverlayView.MATCH_END) {
      onCloseBoard();
      return;
    }

    popFromViewQueue();
    //clearTimeout(viewQueueTimerRef.current);
    //setViewQueueTimer(Date.now);
  };

  const handleMove = (moveType) => {
    onMove(moveType, () => {
      setActorMove({ moveType });
    });
  };

  /*
  const _testPushOverlay = () => {
    pushToViewQueue([
      {
        view: Enum.BoardOverlayView.TEST,
        data: { tournament: currentTournament },
      },
      {
        view: Enum.BoardOverlayView.DECISION_END,
        data: { tournament: currentTournament },
      },
      {
        view: Enum.BoardOverlayView.TEST,
        data: { tournament: currentTournament },
      },
    ]);
  };

  const _testOpponentMove = (move) => {
    Api.makeMove(
      currentGame.id,
      opponents[0].accountId,
      currentTournament.id,
      move
    );
  };
*/
  useEffect(() => {
    if (currentMatch) {
      setActor(
        currentMatch.matchAccounts.find(
          (ma) => ma.accountId === authState?.account?.id,
        ),
      );
      setOpponents(
        currentMatch.matchAccounts.filter(
          (ma) => ma.accountId !== authState?.account?.id,
        ),
      );
    } else {
      setActor(null);
      setOpponents([]);
    }
  }, [currentMatch, currentGame, authState?.account]);

  useEffect(() => {
    if (!currentGame || !authState || !authState.account) setActorMove(null);
    else
      setActorMove(
        currentGame.moves.find((m) => m.accountId === authState?.account?.id),
      );
  }, [currentGame, authState]);

  useEffect(() => {
    if (!opponents || !currentGame) setOpponentMoves([]);
    else
      setOpponentMoves(
        opponents.map((o) =>
          currentGame.moves.find((m) => m.accountId === o.accountId),
        ),
      );
  }, [opponents, currentGame]);

  useEffect(() => {
    decidingLog("DETERMINING DECIDING VIEW");
    if (boardOverlayView === Enum.BoardOverlayView.LOADING) return;

    if (!currentTournament || !authState || !authState.account || !currentRound)
      setDecidingView(Enum.DecidingView.NOT_IN_DECISION);
    else
      setDecidingView(
        (
          _.contains(
            currentTournament.tournamentAccountsLookup,
            authState?.account?.id,
          )
        ) ?
          currentMatch ?
            currentMatch.completed ?
              currentMatch.iAdvanced ?
                Enum.DecidingView.STILL_IN
              : Enum.DecidingView.KNOCKED_OUT
            : Enum.DecidingView.ACTIVE_MATCH
          : Enum.DecidingView.KNOCKED_OUT
        : Enum.DecidingView.NOT_IN_DECISION,
      );
  }, [
    currentTournament,
    authState,
    currentMatch,
    currentRound,
    decidingView,
    boardOverlayView,
  ]);

  useEffect(() => {
    decidingLog("Deciding view changed to:", decidingView);
  }, [decidingView]);

  useEffect(() => {
    if (
      rpsEventCurrent &&
      (!rpsEventPrevious ||
        rpsEventPrevious.typeSlug !== rpsEventCurrent.typeSlug)
    ) {
      switch (rpsEventCurrent.typeSlug) {
        case "page-load":
          onPageLoad();
          break;
        case "tournament-started":
          onTournamentStarted();
          break;
        case "game-started":
          onGameStarted(rpsEventPrevious, rpsEventCurrent);
          break;
        case "match-finished":
          onMatchFinished(rpsEventPrevious, rpsEventCurrent);
          break;
        case "round-started":
          onRoundStarted(rpsEventCurrent);
          break;
        case "round-finished":
          onRoundFinished();
          break;
        case "tournament-finished":
          onTournamentFinished();
          break;
        case "tournament-closed":
          onTournamentClosed();
          break;
        default:
          return;
      }
    }
  }, [
    onGameStarted,
    onMatchFinished,
    onPageLoad,
    onRoundFinished,
    onRoundStarted,
    onTournamentClosed,
    onTournamentFinished,
    onTournamentStarted,
    rpsEventCurrent,
    rpsEventPrevious,
  ]);

  const playersToBracket = (p) => {
    if (p <= 2) return "Final";
    //else if (p <= 4) return "Semi Finals";
    //else if (p <= 8) return "Quarter Finals";
    else return "Top " + p;
  };

  const getPrize = useCallback(
    (p1, p2) => {
      if (!currentTournament || !currentTournament.prizes) return null;

      for (let i = currentTournament.prizes.length - 1; i >= 0; i--) {
        if (
          currentTournament.prizes[i].awardedToTopPlayers >= p1 &&
          currentTournament.prizes[i].awardedToTopPlayers < p2
        )
          return currentTournament.prizes[i];
      }

      return null;
    },
    [currentTournament],
  );

  const getNextRound = useCallback(
    (roundNumber) => {
      let prizeProgCurr = prizeProgMarkers.findIndex(
        (x) => x.roundNumber === roundNumber,
      );
      if (prizeProgCurr + 1 < prizeProgMarkers.length)
        return prizeProgMarkers[prizeProgCurr + 1];
      return null;
    },
    [prizeProgMarkers],
  );

  useEffect(() => {
    if (!currentTournament) return;
    if (!currentTournament.rounds) return;
    if (!Array.isArray(currentTournament.rounds)) return;
    if (!currentRound) return;

    // reverse map
    let pMarkers = currentTournament.rounds.map((r, index, array) => {
      let item = array[array.length - 1 - index];
      return {
        prog: playersToBracket(item.playersCount),
        players: item.playersCount,
        roundNumber: index + 1,
        state: item.state,
      };
    });

    let roundCount = currentTournament.rounds.length;
    let currentMarker = 0;

    for (let i = 0; i < pMarkers.length; i++) {
      if (pMarkers[i].state === Enum.RoundState.PLANNED) {
        currentMarker = i - 1;
        break;
      }

      currentMarker = i;
    }

    setPrizeProgCurrent(currentMarker);

    pMarkers.push({
      prog: playersToBracket(1),
      players: 1,
      roundNumber: roundCount++,
    });

    pMarkers = pMarkers.map((pm, i) => {
      return {
        title: pm.prog.startsWith("Top") ? `Round ${i + 1}` : "",
        prog: pm.prog,
        progwArticle: roundwArticle(
          pm.prog.startsWith("Top") ? `Round ${i + 1}` : pm.prog,
        ),
        players: pm.players,
        prize: getPrize(pm.players, i > 0 ? pMarkers[i - 1].players : 1e9),
        percent: clamp((100 / (pMarkers.length - 1)) * i, 1, 99.8),
        roundNumber: pm.roundNumber,
      };
    });

    decidingLog("PRIZE PROG MARKERS SET", pMarkers);
    setPrizeProgMarkers(pMarkers);
  }, [getPrize, currentTournament, currentRound]);

  // useEffect(() => {
  //   if (!currentGame || !currentGame.endTime) return;

  //   let hourFromNow = moment().add(1, "hours");
  //   setNearlyFinalMoves(hourFromNow.isAfter(currentGame.endTime));
  // }, [currentGame]);

  useEffect(() => {
    if (
      viewQueue &&
      viewQueue.length > 0 &&
      boardOverlayView?.view === Enum.BoardOverlayView.HIDE
    ) {
      decidingLog("Nothing active, setting view to first item", viewQueue);
      popFromViewQueue();
    }
  }, [boardOverlayView?.view, popFromViewQueue, viewQueue]);

  useEffect(() => {
    if (
      decidingView !== Enum.DecidingView.ACTIVE_MATCH &&
      decidingView !== Enum.DecidingView.LOADING &&
      boardOverlayView?.view === Enum.BoardOverlayView.HIDE //&&
      //overlayOpened &&
      //(!currentMatch || currentMatch.completed)
    ) {
      onCloseBoard();
    }
  }, [
    boardOverlayView?.view,
    currentMatch,
    decidingView,
    onCloseBoard,
    overlayOpened,
  ]);

  const moveHistory = useMemo(() => {
    const reversed = [...(currentMatch?.moves || [])].reverse();

    return reversed.reduce((acc, move) => {
      if (!acc[move.accountId]) acc[move.accountId] = [];

      if (acc[move.accountId].length < 3) acc[move.accountId].push(move);

      return acc;
    }, {});
  }, [currentMatch?.moves]);

  return (
    <div
      className={`deciding-interface ${
        (
          boardOverlayView.view !== Enum.BoardOverlayView.HIDE ||
          decidingView !== Enum.DecidingView.ACTIVE_MATCH
        ) ?
          "di-overlay-visible"
        : ""
      }`}
    >
      <div className="deciding-game-zone">
        {decidingView === Enum.DecidingView.ACTIVE_MATCH &&
          boardOverlayView.view === Enum.BoardOverlayView.HIDE && (
            <>
              <Board
                group={group}
                tournament={currentTournament}
                round={currentRound}
                match={currentMatch}
                game={currentGame}
                moveHistory={moveHistory}
                actor={actor}
                opponents={opponents}
                actorMove={actorMove}
                opponentMoves={opponentMoves}
                rpsEventCurrent={rpsEventCurrent}
                onMove={handleMove}
                getNextRound={getNextRound}
                handleModalUserManageOpen={handleModalUserManageOpen}
              />
              <InfoPanel
                group={group}
                endTime={currentGame?.endTime}
                moved={!!actorMove}
                notifyEnabled={notifyEnabled}
                currentTournament={currentTournament}
                hide={
                  !currentGame //||
                  //(
                  //(!currentGame.moves || currentGame.moves.length === 0)
                  //&& !nearlyFinalMoves)
                }
                onAskNotificationPermission={onAskNotificationPermission}
                onRemoveNotifications={onRemoveNotifications}
              />
            </>
          )}

        {/* {boardOverlayView.view === Enum.BoardOverlayView.HIDE && (
          <>
            {decidingView === Enum.DecidingView.KNOCKED_OUT && (

              <BoardOverlay
                viewState={{
                  view: Enum.BoardOverlayView.KNOCKED_OUT,
                  data: { tournament: currentTournament, round: currentRound },
                }}
                group={group}
                notifyEnabled={notifyEnabled}
                onAskNotificationPermission={onAskNotificationPermission}
                onRemoveNotifications={onRemoveNotifications}
                nearlyFinalMoves={nearlyFinalMoves}
              />
            )}

            {decidingView === Enum.DecidingView.STILL_IN && (
              <BoardOverlay
                viewState={{
                  view: currentRound.isFinalRound
                    ? Enum.BoardOverlayView.TOURNAMENT_WON
                    : Enum.BoardOverlayView.WAITING_FOR_ROUND,
                  data: {
                    tournament: currentTournament,
                    round: currentRound,
                    match: currentMatch,
                  },
                }}
                group={group}
                notifyEnabled={notifyEnabled}
                onAskNotificationPermission={onAskNotificationPermission}
                onRemoveNotifications={onRemoveNotifications}
                nearlyFinalMoves={nearlyFinalMoves}
                getNextRound={getNextRound}
              />
            )}

            {decidingView === Enum.DecidingView.NOT_IN_DECISION && (
              <div className="deciding-status-message">
                <Icon className="fal fa-user-times"></Icon>
                <Header as="h3">
                  You are not playing in this{" "}
                  {group.promo ? "tournament" : "game"}.
                </Header>
              </div>
            )}
          </>
        )} */}

        <BoardOverlay
          viewState={boardOverlayView}
          group={group}
          opponents={opponents}
          onContinue={handleBoardOverlayContinue}
          currentGame={currentGame}
          actorMove={actorMove}
          notifyEnabled={notifyEnabled}
          onAskNotificationPermission={onAskNotificationPermission}
          onRemoveNotifications={onRemoveNotifications}
          getNextRound={getNextRound}
          handleModalUserManageOpen={handleModalUserManageOpen}
          handleModalInstallAppEnableNotificationsOpen={
            handleModalInstallAppEnableNotificationsOpen
          }
          handleModalAlertOpen={handleModalAlertOpen}
          //nearlyFinalMoves={nearlyFinalMoves}
        />
      </div>
    </div>
  );
};

export default DecidingInterface;
