import React from "react";

import GroupMembersHeading from "../GroupMembers/GroupMembersHeading";
import GroupMembersList from "../GroupMembers/GroupMembersList";
import Plural from "../Plural/Plural";

import "../GroupMembers/GroupMembers.scss";

const DecisionResultsMembers = ({
  group,
  currentTournament,
  chosen,
  notChosen,
  handleModalUserManageOpen,
}) => {
  const handleMemberClick = (id, member) => {
    handleModalUserManageOpen(member);
  };

  return (
    <div className="group-members">
      {group && (
        <div className="group-members-list">
          {/* <ScrollWrap> */}
          {chosen.length > 1 && (
            <div className="group-members-chosen-list">
              <GroupMembersHeading>
                <>
                  {chosen.length}{" "}
                  <Plural base="player" value={chosen.length}></Plural> chosen
                </>
              </GroupMembersHeading>

              <GroupMembersList
                chosenOnes={true}
                list={chosen}
                group={group}
                currentTournament={currentTournament}
                onMemberClick={handleMemberClick}
                hideParticipating={true}
              />
            </div>
          )}

          <GroupMembersHeading>
            <>
              {notChosen.length ? notChosen.length : "No"}{" "}
              <Plural base="player" value={notChosen.length}></Plural>
            </>
          </GroupMembersHeading>

          <GroupMembersList
            list={notChosen}
            group={group}
            currentTournament={currentTournament}
            onMemberClick={handleMemberClick}
            chosenOnes={false}
            hideParticipating={true}
            size={"small"}
          />
          {/* </ScrollWrap> */}
        </div>
      )}
    </div>
  );
};

export default DecisionResultsMembers;
