import React from "react";

import "./QrSvg.scss";

function QrSvg() {
  return (
    <svg
      width="200"
      height="200"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      className="qr-svg"
    >
      <rect
        x="0"
        y="0"
        width="200"
        height="200"
        className="qr-svg__background"
      />
      <rect x="0" y="0" width="8" height="8" />
      <rect x="8" y="0" width="8" height="8" />
      <rect x="16" y="0" width="8" height="8" />
      <rect x="24" y="0" width="8" height="8" />
      <rect x="32" y="0" width="8" height="8" />
      <rect x="40" y="0" width="8" height="8" />
      <rect x="48" y="0" width="8" height="8" />
      <rect x="64" y="0" width="8" height="8" />
      <rect x="112" y="0" width="8" height="8" />
      <rect x="128" y="0" width="8" height="8" />
      <rect x="144" y="0" width="8" height="8" />
      <rect x="152" y="0" width="8" height="8" />
      <rect x="160" y="0" width="8" height="8" />
      <rect x="168" y="0" width="8" height="8" />
      <rect x="176" y="0" width="8" height="8" />
      <rect x="184" y="0" width="8" height="8" />
      <rect x="192" y="0" width="8" height="8" />
      <rect x="0" y="8" width="8" height="8" />
      <rect x="48" y="8" width="8" height="8" />
      <rect x="64" y="8" width="8" height="8" />
      <rect x="96" y="8" width="8" height="8" />
      <rect x="112" y="8" width="8" height="8" />
      <rect x="128" y="8" width="8" height="8" />
      <rect x="144" y="8" width="8" height="8" />
      <rect x="192" y="8" width="8" height="8" />
      <rect x="0" y="16" width="8" height="8" />
      <rect x="16" y="16" width="8" height="8" />
      <rect x="24" y="16" width="8" height="8" />
      <rect x="32" y="16" width="8" height="8" />
      <rect x="48" y="16" width="8" height="8" />
      <rect x="104" y="16" width="8" height="8" />
      <rect x="128" y="16" width="8" height="8" />
      <rect x="144" y="16" width="8" height="8" />
      <rect x="160" y="16" width="8" height="8" />
      <rect x="168" y="16" width="8" height="8" />
      <rect x="176" y="16" width="8" height="8" />
      <rect x="192" y="16" width="8" height="8" />
      <rect x="0" y="24" width="8" height="8" />
      <rect x="16" y="24" width="8" height="8" />
      <rect x="24" y="24" width="8" height="8" />
      <rect x="32" y="24" width="8" height="8" />
      <rect x="48" y="24" width="8" height="8" />
      <rect x="64" y="24" width="8" height="8" />
      <rect x="72" y="24" width="8" height="8" />
      <rect x="80" y="24" width="8" height="8" />
      <rect x="88" y="24" width="8" height="8" />
      <rect x="96" y="24" width="8" height="8" />
      <rect x="104" y="24" width="8" height="8" />
      <rect x="112" y="24" width="8" height="8" />
      <rect x="120" y="24" width="8" height="8" />
      <rect x="144" y="24" width="8" height="8" />
      <rect x="160" y="24" width="8" height="8" />
      <rect x="168" y="24" width="8" height="8" />
      <rect x="176" y="24" width="8" height="8" />
      <rect x="192" y="24" width="8" height="8" />
      <rect x="0" y="32" width="8" height="8" />
      <rect x="16" y="32" width="8" height="8" />
      <rect x="24" y="32" width="8" height="8" />
      <rect x="32" y="32" width="8" height="8" />
      <rect x="48" y="32" width="8" height="8" />
      <rect x="80" y="32" width="8" height="8" />
      <rect x="88" y="32" width="8" height="8" />
      <rect x="120" y="32" width="8" height="8" />
      <rect x="128" y="32" width="8" height="8" />
      <rect x="144" y="32" width="8" height="8" />
      <rect x="160" y="32" width="8" height="8" />
      <rect x="168" y="32" width="8" height="8" />
      <rect x="176" y="32" width="8" height="8" />
      <rect x="192" y="32" width="8" height="8" />
      <rect x="0" y="40" width="8" height="8" />
      <rect x="48" y="40" width="8" height="8" />
      <rect x="80" y="40" width="8" height="8" />
      <rect x="88" y="40" width="8" height="8" />
      <rect x="96" y="40" width="8" height="8" />
      <rect x="104" y="40" width="8" height="8" />
      <rect x="128" y="40" width="8" height="8" />
      <rect x="144" y="40" width="8" height="8" />
      <rect x="192" y="40" width="8" height="8" />
      <rect x="0" y="48" width="8" height="8" />
      <rect x="8" y="48" width="8" height="8" />
      <rect x="16" y="48" width="8" height="8" />
      <rect x="24" y="48" width="8" height="8" />
      <rect x="32" y="48" width="8" height="8" />
      <rect x="40" y="48" width="8" height="8" />
      <rect x="48" y="48" width="8" height="8" />
      <rect x="64" y="48" width="8" height="8" />
      <rect x="80" y="48" width="8" height="8" />
      <rect x="96" y="48" width="8" height="8" />
      <rect x="112" y="48" width="8" height="8" />
      <rect x="128" y="48" width="8" height="8" />
      <rect x="144" y="48" width="8" height="8" />
      <rect x="152" y="48" width="8" height="8" />
      <rect x="160" y="48" width="8" height="8" />
      <rect x="168" y="48" width="8" height="8" />
      <rect x="176" y="48" width="8" height="8" />
      <rect x="184" y="48" width="8" height="8" />
      <rect x="192" y="48" width="8" height="8" />
      <rect x="64" y="56" width="8" height="8" />
      <rect x="72" y="56" width="8" height="8" />
      <rect x="96" y="56" width="8" height="8" />
      <rect x="112" y="56" width="8" height="8" />
      <rect x="120" y="56" width="8" height="8" />
      <rect x="128" y="56" width="8" height="8" />
      <rect x="0" y="64" width="8" height="8" />
      <rect x="16" y="64" width="8" height="8" />
      <rect x="24" y="64" width="8" height="8" />
      <rect x="40" y="64" width="8" height="8" />
      <rect x="48" y="64" width="8" height="8" />
      <rect x="56" y="64" width="8" height="8" />
      <rect x="80" y="64" width="8" height="8" />
      <rect x="104" y="64" width="8" height="8" />
      <rect x="120" y="64" width="8" height="8" />
      <rect x="128" y="64" width="8" height="8" />
      <rect x="144" y="64" width="8" height="8" />
      <rect x="168" y="64" width="8" height="8" />
      <rect x="184" y="64" width="8" height="8" />
      <rect x="192" y="64" width="8" height="8" />
      <rect x="0" y="72" width="8" height="8" />
      <rect x="24" y="72" width="8" height="8" />
      <rect x="80" y="72" width="8" height="8" />
      <rect x="88" y="72" width="8" height="8" />
      <rect x="96" y="72" width="8" height="8" />
      <rect x="104" y="72" width="8" height="8" />
      <rect x="152" y="72" width="8" height="8" />
      <rect x="184" y="72" width="8" height="8" />
      <rect x="8" y="80" width="8" height="8" />
      <rect x="32" y="80" width="8" height="8" />
      <rect x="48" y="80" width="8" height="8" />
      <rect x="56" y="80" width="8" height="8" />
      <rect x="72" y="80" width="8" height="8" />
      <rect x="104" y="80" width="8" height="8" />
      <rect x="128" y="80" width="8" height="8" />
      <rect x="144" y="80" width="8" height="8" />
      <rect x="152" y="80" width="8" height="8" />
      <rect x="160" y="80" width="8" height="8" />
      <rect x="0" y="88" width="8" height="8" />
      <rect x="8" y="88" width="8" height="8" />
      <rect x="16" y="88" width="8" height="8" />
      <rect x="56" y="88" width="8" height="8" />
      <rect x="64" y="88" width="8" height="8" />
      <rect x="72" y="88" width="8" height="8" />
      <rect x="120" y="88" width="8" height="8" />
      <rect x="144" y="88" width="8" height="8" />
      <rect x="152" y="88" width="8" height="8" />
      <rect x="168" y="88" width="8" height="8" />
      <rect x="176" y="88" width="8" height="8" />
      <rect x="0" y="96" width="8" height="8" />
      <rect x="8" y="96" width="8" height="8" />
      <rect x="24" y="96" width="8" height="8" />
      <rect x="40" y="96" width="8" height="8" />
      <rect x="48" y="96" width="8" height="8" />
      <rect x="56" y="96" width="8" height="8" />
      <rect x="64" y="96" width="8" height="8" />
      <rect x="80" y="96" width="8" height="8" />
      <rect x="88" y="96" width="8" height="8" />
      <rect x="96" y="96" width="8" height="8" />
      <rect x="112" y="96" width="8" height="8" />
      <rect x="136" y="96" width="8" height="8" />
      <rect x="144" y="96" width="8" height="8" />
      <rect x="160" y="96" width="8" height="8" />
      <rect x="176" y="96" width="8" height="8" />
      <rect x="184" y="96" width="8" height="8" />
      <rect x="192" y="96" width="8" height="8" />
      <rect x="8" y="104" width="8" height="8" />
      <rect x="40" y="104" width="8" height="8" />
      <rect x="56" y="104" width="8" height="8" />
      <rect x="64" y="104" width="8" height="8" />
      <rect x="88" y="104" width="8" height="8" />
      <rect x="96" y="104" width="8" height="8" />
      <rect x="112" y="104" width="8" height="8" />
      <rect x="120" y="104" width="8" height="8" />
      <rect x="128" y="104" width="8" height="8" />
      <rect x="136" y="104" width="8" height="8" />
      <rect x="144" y="104" width="8" height="8" />
      <rect x="152" y="104" width="8" height="8" />
      <rect x="160" y="104" width="8" height="8" />
      <rect x="192" y="104" width="8" height="8" />
      <rect x="8" y="112" width="8" height="8" />
      <rect x="32" y="112" width="8" height="8" />
      <rect x="48" y="112" width="8" height="8" />
      <rect x="56" y="112" width="8" height="8" />
      <rect x="64" y="112" width="8" height="8" />
      <rect x="72" y="112" width="8" height="8" />
      <rect x="88" y="112" width="8" height="8" />
      <rect x="104" y="112" width="8" height="8" />
      <rect x="136" y="112" width="8" height="8" />
      <rect x="152" y="112" width="8" height="8" />
      <rect x="160" y="112" width="8" height="8" />
      <rect x="176" y="112" width="8" height="8" />
      <rect x="184" y="112" width="8" height="8" />
      <rect x="0" y="120" width="8" height="8" />
      <rect x="16" y="120" width="8" height="8" />
      <rect x="56" y="120" width="8" height="8" />
      <rect x="64" y="120" width="8" height="8" />
      <rect x="80" y="120" width="8" height="8" />
      <rect x="88" y="120" width="8" height="8" />
      <rect x="112" y="120" width="8" height="8" />
      <rect x="144" y="120" width="8" height="8" />
      <rect x="168" y="120" width="8" height="8" />
      <rect x="192" y="120" width="8" height="8" />
      <rect x="16" y="128" width="8" height="8" />
      <rect x="24" y="128" width="8" height="8" />
      <rect x="32" y="128" width="8" height="8" />
      <rect x="48" y="128" width="8" height="8" />
      <rect x="64" y="128" width="8" height="8" />
      <rect x="96" y="128" width="8" height="8" />
      <rect x="112" y="128" width="8" height="8" />
      <rect x="120" y="128" width="8" height="8" />
      <rect x="128" y="128" width="8" height="8" />
      <rect x="136" y="128" width="8" height="8" />
      <rect x="144" y="128" width="8" height="8" />
      <rect x="152" y="128" width="8" height="8" />
      <rect x="160" y="128" width="8" height="8" />
      <rect x="168" y="128" width="8" height="8" />
      <rect x="176" y="128" width="8" height="8" />
      <rect x="184" y="128" width="8" height="8" />
      <rect x="192" y="128" width="8" height="8" />
      <rect x="64" y="136" width="8" height="8" />
      <rect x="72" y="136" width="8" height="8" />
      <rect x="80" y="136" width="8" height="8" />
      <rect x="112" y="136" width="8" height="8" />
      <rect x="128" y="136" width="8" height="8" />
      <rect x="160" y="136" width="8" height="8" />
      <rect x="192" y="136" width="8" height="8" />
      <rect x="0" y="144" width="8" height="8" />
      <rect x="8" y="144" width="8" height="8" />
      <rect x="16" y="144" width="8" height="8" />
      <rect x="24" y="144" width="8" height="8" />
      <rect x="32" y="144" width="8" height="8" />
      <rect x="40" y="144" width="8" height="8" />
      <rect x="48" y="144" width="8" height="8" />
      <rect x="64" y="144" width="8" height="8" />
      <rect x="72" y="144" width="8" height="8" />
      <rect x="80" y="144" width="8" height="8" />
      <rect x="104" y="144" width="8" height="8" />
      <rect x="112" y="144" width="8" height="8" />
      <rect x="128" y="144" width="8" height="8" />
      <rect x="144" y="144" width="8" height="8" />
      <rect x="160" y="144" width="8" height="8" />
      <rect x="168" y="144" width="8" height="8" />
      <rect x="176" y="144" width="8" height="8" />
      <rect x="184" y="144" width="8" height="8" />
      <rect x="192" y="144" width="8" height="8" />
      <rect x="0" y="152" width="8" height="8" />
      <rect x="48" y="152" width="8" height="8" />
      <rect x="64" y="152" width="8" height="8" />
      <rect x="88" y="152" width="8" height="8" />
      <rect x="104" y="152" width="8" height="8" />
      <rect x="112" y="152" width="8" height="8" />
      <rect x="120" y="152" width="8" height="8" />
      <rect x="128" y="152" width="8" height="8" />
      <rect x="160" y="152" width="8" height="8" />
      <rect x="192" y="152" width="8" height="8" />
      <rect x="0" y="160" width="8" height="8" />
      <rect x="16" y="160" width="8" height="8" />
      <rect x="24" y="160" width="8" height="8" />
      <rect x="32" y="160" width="8" height="8" />
      <rect x="48" y="160" width="8" height="8" />
      <rect x="80" y="160" width="8" height="8" />
      <rect x="104" y="160" width="8" height="8" />
      <rect x="112" y="160" width="8" height="8" />
      <rect x="128" y="160" width="8" height="8" />
      <rect x="136" y="160" width="8" height="8" />
      <rect x="144" y="160" width="8" height="8" />
      <rect x="152" y="160" width="8" height="8" />
      <rect x="160" y="160" width="8" height="8" />
      <rect x="0" y="168" width="8" height="8" />
      <rect x="16" y="168" width="8" height="8" />
      <rect x="24" y="168" width="8" height="8" />
      <rect x="32" y="168" width="8" height="8" />
      <rect x="48" y="168" width="8" height="8" />
      <rect x="64" y="168" width="8" height="8" />
      <rect x="72" y="168" width="8" height="8" />
      <rect x="136" y="168" width="8" height="8" />
      <rect x="144" y="168" width="8" height="8" />
      <rect x="152" y="168" width="8" height="8" />
      <rect x="160" y="168" width="8" height="8" />
      <rect x="176" y="168" width="8" height="8" />
      <rect x="184" y="168" width="8" height="8" />
      <rect x="192" y="168" width="8" height="8" />
      <rect x="0" y="176" width="8" height="8" />
      <rect x="16" y="176" width="8" height="8" />
      <rect x="24" y="176" width="8" height="8" />
      <rect x="32" y="176" width="8" height="8" />
      <rect x="48" y="176" width="8" height="8" />
      <rect x="64" y="176" width="8" height="8" />
      <rect x="72" y="176" width="8" height="8" />
      <rect x="80" y="176" width="8" height="8" />
      <rect x="112" y="176" width="8" height="8" />
      <rect x="120" y="176" width="8" height="8" />
      <rect x="128" y="176" width="8" height="8" />
      <rect x="136" y="176" width="8" height="8" />
      <rect x="144" y="176" width="8" height="8" />
      <rect x="152" y="176" width="8" height="8" />
      <rect x="160" y="176" width="8" height="8" />
      <rect x="168" y="176" width="8" height="8" />
      <rect x="176" y="176" width="8" height="8" />
      <rect x="184" y="176" width="8" height="8" />
      <rect x="0" y="184" width="8" height="8" />
      <rect x="48" y="184" width="8" height="8" />
      <rect x="88" y="184" width="8" height="8" />
      <rect x="96" y="184" width="8" height="8" />
      <rect x="104" y="184" width="8" height="8" />
      <rect x="112" y="184" width="8" height="8" />
      <rect x="136" y="184" width="8" height="8" />
      <rect x="144" y="184" width="8" height="8" />
      <rect x="160" y="184" width="8" height="8" />
      <rect x="176" y="184" width="8" height="8" />
      <rect x="0" y="192" width="8" height="8" />
      <rect x="8" y="192" width="8" height="8" />
      <rect x="16" y="192" width="8" height="8" />
      <rect x="24" y="192" width="8" height="8" />
      <rect x="32" y="192" width="8" height="8" />
      <rect x="40" y="192" width="8" height="8" />
      <rect x="48" y="192" width="8" height="8" />
      <rect x="64" y="192" width="8" height="8" />
      <rect x="72" y="192" width="8" height="8" />
      <rect x="80" y="192" width="8" height="8" />
      <rect x="88" y="192" width="8" height="8" />
      <rect x="136" y="192" width="8" height="8" />
      <rect x="152" y="192" width="8" height="8" />
      <rect x="160" y="192" width="8" height="8" />
      <rect x="168" y="192" width="8" height="8" />
      <rect x="176" y="192" width="8" height="8" />
      <rect x="184" y="192" width="8" height="8" />
      <rect x="192" y="192" width="8" height="8" />
    </svg>
  );
}

export default QrSvg;
