import moment from "moment-timezone";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button } from "semantic-ui-react";

import Avatar from "../../../../../components/Avatar/Avatar";
import Timer from "../../../../../components/DecidingInterface/InfoPanel/Timer/Timer";
import Icon from "../../../../../components/Icon/Icon";
import ModalStakeInfo from "../../../../../components/Modal/ModalStakeInfo/ModalStakeInfo";
import Plural from "../../../../../components/Plural/Plural";
import TextButton from "../../../../../components/TextButton/TextButton";
import AuthContext from "../../../../../contexts/AuthContext";
import { humanReadableRepeatStr } from "../../../../../utilities";

function RepeatingViewDetail({
  group,
  viewingRepeatingGame,
  handleConfigClick,
  handleModalDecisionParticipantsOpen,
  handleLeaveTournamentViewing,
  handleJoinTournamentViewing,
}) {
  const { authState } = useContext(AuthContext);
  const [repeatStr, setRepeatStr] = useState("");

  const [modalStakeInfoOpen, setModalStakeInfoOpen] = useState(false);
  const [modalStakeInfoKey, setModalStakeInfoKey] = useState(new Date());

  const handleModalStakeInfoOpen = () => {
    setModalStakeInfoOpen(true);
    setModalStakeInfoKey(new Date());
  };

  const handleModalStakeInfoClose = () => {
    setModalStakeInfoOpen(false);
  };

  const amCreator = useMemo(() => {
    return authState?.account?.id === viewingRepeatingGame.creatorId;
  }, [authState, viewingRepeatingGame]);

  useEffect(() => {
    const tzNameLong = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzName = moment.tz(tzNameLong).zoneAbbr();

    setRepeatStr(
      humanReadableRepeatStr(viewingRepeatingGame, true, false, tzName),
    );
  }, [viewingRepeatingGame]);

  return (
    <div className="repeating-config-wrapper">
      <div className="decision-info">
        <h5 className="game-owner">
          <Avatar user={viewingRepeatingGame.creatorId}></Avatar>
          {amCreator ?
            "Your"
          : viewingRepeatingGame.creatorUserName + "'s"}{" "}
          repeating game
        </h5>

        <h1 className="ellipsis-overflow" onClick={handleModalStakeInfoOpen}>
          {viewingRepeatingGame.stake}
        </h1>

        <h5>
          Next game{" "}
          <span className="player-count">
            Choosing {viewingRepeatingGame.winnersToDraw}{" "}
            <Plural
              value={viewingRepeatingGame.winnersToDraw}
              base="player"
            ></Plural>{" "}
            {repeatStr}
          </span>
        </h5>

        <div className="timer-wrapper timer-repeating">
          <Timer
            endTime={viewingRepeatingGame.startTime}
            labelled={true}
            large={true}
          ></Timer>
        </div>

        <TextButton onClick={handleModalDecisionParticipantsOpen}>
          <Icon className="far fa-users"></Icon>
          &nbsp; {viewingRepeatingGame.participants}{" "}
          <Plural
            value={viewingRepeatingGame.participants}
            base="player"
          ></Plural>{" "}
          joined
        </TextButton>

        {viewingRepeatingGame.amParticipating === true && !amCreator && (
          <div className="decision-joined waiting-players">
            Joined repeating game, wait for next result
          </div>
        )}

        {authState?.account?.id === viewingRepeatingGame.creatorId &&
          viewingRepeatingGame.participants <= 1 && (
            <div className="decision-joined waiting-players">
              Repeating game is waiting for players
            </div>
          )}
      </div>

      <div className="action-panel">
        {!amCreator && (
          <>
            {viewingRepeatingGame.amParticipating && (
              <Button icon onClick={handleLeaveTournamentViewing}>
                <Icon className="far fa-user-minus"></Icon>
                &nbsp; Withdraw
              </Button>
            )}
            {!viewingRepeatingGame.amParticipating && (
              <>
                <Button
                  icon
                  size="big"
                  primary
                  onClick={handleJoinTournamentViewing}
                >
                  <Icon className="far fa-user-plus"></Icon>
                  &nbsp; Join game
                </Button>
              </>
            )}
          </>
        )}
        {(amCreator || group.amBoss) && (
          <Button icon onClick={handleConfigClick}>
            <Icon className="far fa-cog"></Icon>
            &nbsp; Edit game
          </Button>
        )}
      </div>

      {viewingRepeatingGame && (
        <ModalStakeInfo
          key={modalStakeInfoKey}
          open={modalStakeInfoOpen}
          close={handleModalStakeInfoClose}
          tournament={viewingRepeatingGame}
        ></ModalStakeInfo>
      )}
    </div>
  );
}

export default RepeatingViewDetail;
