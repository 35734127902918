import JsFileDownloader from "js-file-downloader";

import { rpsApi } from "./api-config";
import { config } from "../config";

class _Api {
  /* HTTP Verbs */
  static async _get(url) {
    return await rpsApi
      .get(url)
      .then(async (response) => {
        return response?.data;
      })
      .catch(function () {});
  }

  static async _post(url, payload) {
    return await rpsApi
      .post(url, payload)
      .then(async (response) => {
        return response;
      })
      .catch(function () {});
  }

  static async _put(url, payload) {
    return await rpsApi
      .put(url, payload)
      .then(async (response) => {
        return response;
      })
      .catch(function () {});
  }

  static async _delete(url, payload) {
    return await rpsApi
      .delete(url, payload)
      .then(async (response) => {
        return response;
      })
      .catch(function () {});
  }

  static async _download(url, filename) {
    const headers = [];

    const token = localStorage.getItem("token");

    if (token)
      headers.push({ name: "Authorization", value: `Bearer ${token}` });

    new JsFileDownloader({
      url: `${config.domains.getDomain("api")}${url}`,
      headers,
      autoStart: true,
      nameCallback: function (name) {
        return filename || name;
      },
    }).catch(function () {});
  }

  /* API endpoints */

  // Auth
  async authStatus(tzOffset, tzName) {
    return _Api._get(`/auth/status/?tzOffset=${tzOffset}&tzName=${tzName}`);
  }

  // Admin
  async getLogFiles() {
    return _Api._get("/admin/logs");
  }

  async getLogFile(name) {
    return _Api._get(`/admin/logs/${name}`);
  }

  // Account

  async getProviderData() {
    return _Api._get("/account");
  }

  async confirmIdToken(token, tzOffset, msftAccessToken) {
    return _Api._post("/account/login", { token, tzOffset, msftAccessToken });
  }

  async changeUserName(userName) {
    return _Api._put("/account/username", { userName });
  }

  async updateUser(user) {
    return _Api._put("/account", user);
  }

  async sendTokenToServer(token) {
    return _Api._post("/account/cloudmessaging", { token });
  }

  async deleteTokenFromServer(token) {
    return _Api._post("/account/cloudmessaging", {
      token: token,
      delete: true,
    });
  }

  async logout() {
    return _Api._post("/account/logout");
  }

  // FAQs
  async getFaqs() {
    return _Api._get("/faq");
  }

  // Group
  async makeGroupPublic(groupId) {
    return _Api._put(`/group/${groupId}`, { makePublic: true });
  }

  async changeGroupName(groupId, name) {
    return _Api._put(`/group/${groupId}`, { name });
  }

  async getMoreGroups(moreUrl) {
    return _Api._get(moreUrl);
  }

  async joinGroup(inviteCode) {
    return _Api._post("/invite", { inviteCode });
  }

  async challengeAccount(challengeAccountId, challengeCheck) {
    return _Api._post("/group", { challengeAccountId, challengeCheck });
  }

  // GroupAccount
  async groupAccountSeenAllRepeating(groupId, accountId) {
    return _Api._put(`/groupaccount/${groupId}`, {
      accountId,
      seenAllRepeating: true,
    });
  }

  // Notifications
  async getAccountNotifications(callback) {
    return _Api._get("/notification").then((response) => {
      if (callback) callback(response);
    });
  }

  async getNotificationConfig(callback) {
    return _Api._get("/notification/getconfig").then((response) => {
      if (callback) callback(response);
    });
  }

  async clearAccountNotifications(callback) {
    return _Api._put("/notification", { clear: true }).then((response) => {
      if (callback) callback(response);
    });
  }

  async markAllNotificationsSeen(callback) {
    return _Api._put("/notification", { markSeen: true }).then((response) => {
      if (callback) callback(response);
    });
  }

  // Tournament
  async getTournament(id, callback) {
    return _Api._get(`/tournament/${id}`).then((response) => {
      if (callback) callback(response);
    });
  }

  async prepareTournament(tournament, callback) {
    return _Api._post("/tournament", tournament).then((response) => {
      if (callback) callback(response);
    });
  }

  async startTournamentEarly(tournamentId) {
    return _Api._put(`/tournament/${tournamentId}`, { forceStart: true });
  }

  async saveDraftTournament(tournamentId, config) {
    return _Api._put(`/tournament/${tournamentId}`, config);
  }

  async endTournamentEarly(tournamentId) {
    return _Api._put(`/tournament/${tournamentId}`, { forceClose: true });
  }

  async rescheduleDecision(tournamentId, prepareLengthMinutes, startTime) {
    return _Api._put(`/tournament/${tournamentId}`, {
      prepareLengthMinutes,
      startTime,
    });
  }

  async rescheduleRepeatingDecision(tournamentId, params, callback) {
    return _Api._put(`/tournament/${tournamentId}`, params).then((response) => {
      if (callback) callback(response);
    });
  }

  // Tournament accounts
  async getTournamentAccounts(tournamentId) {
    return _Api._get(`/tournamentaccount/?tournamentId=${tournamentId}`);
  }

  async rsvpTournament(
    tournamentId,
    targetId,
    participating,
    instantRpsMoveSet
  ) {
    return _Api._post(
      `/tournamentaccount/${tournamentId}`,
      targetId
        ? { accountId: targetId, participating, instantRpsMoveSet }
        : { participating, instantRpsMoveSet }
    );
  }

  async leaveTournament(tournamentId, targetId) {
    let accParam = targetId ? "?accountId=" + targetId : "";
    return _Api._delete(`/tournamentaccount/${tournamentId}${accParam}`, {});
  }

  // Invites
  async invitePromo(groupSlug, emails) {
    console.log("test");
    return _Api._post(`/invite/${groupSlug}`, { emails });
  }

  // GroupAccount
  async downloadAdminGroupAccounts(groupId) {
    _Api._download(
      `/groupaccount/${groupId}/?adminView=true&download=true`,
      "group-members.csv"
    );
  }

  // Moves
  async makeMove(gameId, accountId, tournamentId, moveType) {
    return _Api._post("/move", { gameId, accountId, tournamentId, moveType });
  }

  // A01
  // async a01ScheduleCheck(id) {
  //   return _Api._post(`/tournament/a01schedulecheck`, { id });
  // }

  // async a01ScheduleStart(id) {
  //   return _Api._post(`/tournament/a01schedule`, { id });
  // }

  // async a01ScheduleRoundExpiry(id) {
  //   return _Api._post(`/tournament/a01roundexpiry`, { id });
  // }

  // async a01HandleResetTournament(id, toSignUps, config) {
  //   return _Api._post(`/tournament/a01resettournament`, {
  //     id,
  //     toSignUps,
  //     ...config,
  //   });
  // }

  // async a01HandleSkipToFinalMovesDue(id) {
  //   return _Api._post(`/tournament/a01finalmoves`, { id });
  // }

  // async a01HandleEndRound(id) {
  //   return _Api._post(`/tournament/a01endround`, { id });
  // }

  // async a01HandleStartRound(id) {
  //   return _Api._post(`/tournament/a01startround`, { id });
  // }

  // async a01PutMeInFinal(id) {
  //   return _Api._post(`/tournament/a01putmeinfinal`, { id });
  // }
}

_Api.Instance = new _Api();

export const Api = _Api.Instance;
