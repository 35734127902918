import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import { Menu } from "semantic-ui-react";

import { config } from "../../../config";
import Enum from "../../../helpers/enums";
import Icon from "../../Icon/Icon";
import MaxPlus from "../../MaxPlus/MaxPlus";
import TimeAgo from "../../TimeAgo/TimeAgo";

const GroupListItem = ({ group, selected }) => {
  //const [started, setStarted] = useState(false);
  const [gameModeText, setGameModeText] = useState("Random");
  const [gameModeClass, setGameModeClass] = useState("text-blue");

  useEffect(() => {
    if (group === "solo") return;

    if (group?.activeDecisionGameMode === Enum.TournamentGameMode.RANDOM) {
      setGameModeText("Random");
      setGameModeClass("text-blue");
    } else if (
      group?.activeDecisionGameMode === Enum.TournamentGameMode.INSTANTRPS
    ) {
      setGameModeText("Instant RPS");
    } else if (
      group?.activeDecisionGameMode === Enum.TournamentGameMode.TOURNAMENTRPS
    ) {
      setGameModeText("Rock paper scissors");
      setGameModeClass(
        group?.activeDecisionStakeIsPositive ? "text-green" : "text-red",
      );
    }
  }, [group?.activeDecisionGameMode, group?.activeDecisionStakeIsPositive]);

  if (group === "solo")
    return (
      <Menu.Item
        as={Link}
        to={`/g/solo`}
        active={selected}
        className="group-list-item group-list-item-solo"
      >
        <div className="group-details">
          <span className="group-list-item-name">Play solo</span>
          <span className={`group-list-item-players`}>
            <Icon className="fas fa-chevron-right"></Icon>
          </span>
        </div>
        <div className="group-details">
          <span className="group-list-item-decision">
            Test your skills versus RKObot!
          </span>
          <span className="group-countdown"></span>
        </div>
      </Menu.Item>
    );

  return (
    <>
      {group.creating && (
        <Menu.Item
          as={Link}
          to={`/g/new`}
          active={selected}
          className="group-list-item group-list-item-creating"
        >
          <span className="group-list-item-name">New group</span>
          <span className="group-list-item-players">
            Setting up {config.strings.decision}...
          </span>
        </Menu.Item>
      )}
      {!group.creating && (
        <Menu.Item
          as={Link}
          to={`/g/${group.slug}`}
          active={selected}
          className="group-list-item"
        >
          {/* {group.promo && (
            <img src="/images/rps-logo-circle.png" alt="RPS logo"></img>
          )} */}
          <div className="group-details">
            <span className="group-list-item-name">
              {group.name || "Group " + group.slug}
            </span>
            <span className={`group-list-item-players`}>
              <Icon className="fas fa-user-group"></Icon>
              <MaxPlus
                maximum={99}
                value={group.playerCount}
                className="group-list-item-players-value"
              ></MaxPlus>
            </span>
          </div>
          <div className="group-details">
            <span className="group-list-item-decision">
              {!group.activeDecisionStake && !!group.lastFinishedTournament && (
                <>
                  Last game:{" "}
                  <TimeAgo time={group.lastFinishedTournament}></TimeAgo>
                </>
              )}
              {!group.activeDecisionStake && !group.lastFinishedTournament && (
                <>Start new game&hellip;</>
              )}
              {group.activeDecisionStake && (
                <>
                  <b className={gameModeClass}>{gameModeText}:</b>{" "}
                  {group.activeDecisionStake}
                </>
              )}
            </span>
            <span className="group-countdown">
              {group.activeDecisionStake && (
                <>
                  {/* {group.playerCount === group.rsvpCount &&
                    group.playerCount > 1 && (
                      <Icon className="fas fa-user-check text-green"></Icon>
                    )} */}
                  <Countdown
                    key={group.activeDecisionStartTime}
                    date={group.activeDecisionStartTime}
                    renderer={({ days, hours, minutes, seconds, completed }) =>
                      completed ?
                        <>
                          <span className="text-green">
                            <Icon className="far fa-fw fa-spin fa-circle-notch"></Icon>
                            &nbsp;&nbsp;Playing
                          </span>
                        </>
                      : <>
                          {days > 0 && <>{days}d </>}
                          {hours > 0 && <>{hours}h </>}
                          {minutes > 0 && <>{minutes}m </>}
                          {seconds}s
                        </>
                    }
                  />
                </>
              )}
            </span>
          </div>
        </Menu.Item>
      )}
    </>
  );
};

export default GroupListItem;
