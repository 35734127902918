import React from "react";
import { Modal, TransitionablePortal } from "semantic-ui-react";

import { config } from "../../../config";
import RkoModal from "../RkoModal/RkoModal";

import "./ModalRules.scss";

// const ConfigurableValue = ({ trigger, text }) => (
//   <Popup
//     trigger={<span className="configurable-value">{trigger}</span>}
//     position="top center"
//   >
//     {text || "Configurable when setting up the tournament"}
//   </Popup>
// );

const ModalRules = ({ open, close, rules }) => {
  if (
    rules === undefined ||
    rules === null ||
    rules.firstToGames === undefined ||
    rules.firstToGames === null ||
    rules.firstToGames === 0
  ) {
    rules = {
      firstToGames: config.validation.tournamentRps.firstToGames.default,
      gameLengthMinutes:
        config.validation.tournamentRps.gameLengthMinutes.default,
      stakeIsPositive: true,
      matchMaxGames: config.validation.tournamentRps.matchMaxGames.default,
    };
  }

  const handleClose = () => {
    document.body.classList.remove("modal-fade-in");
    close();
  };

  return (
    <TransitionablePortal
      open={open}
      onOpen={() =>
        setTimeout(() => {
          document.body.classList.add("modal-fade-in");
          document.body.classList.add("modal-half-height");
        }, 0)
      }
      transition={{ animation: "fade down", duration: 300 }}
    >
      <RkoModal
        open={true}
        onClose={handleClose}
        className="modal-rules modal-half-height"
      >
        <Modal.Header>Rock Paper Scissors rules</Modal.Header>
        <Modal.Content scrolling>
          {rules && (
            <Modal.Description>
              <h5>Playing for a prize</h5>

              <ul>
                <li>
                  Push notifications will be sent for important events in the
                  game
                </li>
                <li>
                  Participants are randomly matched with another tournament
                  participant
                </li>
                <li>
                  If an odd number of players are in a round, the leftover
                  player competes against an AI opponent which cannot progress
                </li>
                <li>
                  Matches involve a series of moves, with the match winner being
                  the first to achieve a set number of wins, ranging from 1 to
                  10
                </li>
                <li>
                  Moves have a time limit; if only one player moves, they win
                  that move by default
                </li>
                <li>
                  In case of a tie, the move is replayed until a winner is
                  decided
                </li>
                <li>
                  If neither player moves, the match is cancelled with the
                  following outcomes:
                  <ul>
                    <li>If more moves won: You win the match</li>
                    <li>
                      If equal number of moves won: Winner is chosen randomly
                    </li>
                    <li>
                      If it's the first move and no one plays, both are
                      disqualified
                    </li>
                  </ul>
                </li>
                <li>
                  Winning a match advances you to the next round against another
                  winner, losing a match means you are out of the prize running
                </li>
                <li>
                  The next round begins immediately after the last match of the
                  previous round ends
                </li>
              </ul>

              <h5>Playing for a penalty</h5>
              <ul>
                <li>
                  Push notifications will be sent for important events in the
                  game
                </li>
                <li>
                  Participants are randomly matched with another tournament
                  participant
                </li>
                <li>
                  If an odd number of players are in a round, the leftover
                  player competes against an AI opponent which cannot progress
                </li>
                <li>
                  Matches involve a series of moves, with the match winner being
                  the first to achieve a set number of wins, ranging from 1 to
                  10
                </li>
                <li>
                  Moves have a time limit; if only one player moves, they win
                  that move by default
                </li>
                <li>
                  In case of a tie, the move is replayed until a winner is
                  decided
                </li>
                <li>
                  If neither player moves, the match is cancelled with the
                  following outcomes:
                  <ul>
                    <li>If more moves won: You win the match</li>
                    <li>
                      If equal number of moves won: Winner is chosen randomly
                    </li>
                    <li>
                      If it's the first move and no one plays, both forfeit and
                      a random player advances
                    </li>
                  </ul>
                </li>
                <li>
                  Losing a match moves you to the next round against another
                  loser, winning means you are safe from the penalty and don't
                  play further matches
                </li>
                <li>
                  The next round begins immediately after the last match of the
                  previous round ends
                </li>
              </ul>
            </Modal.Description>
          )}
        </Modal.Content>
      </RkoModal>
    </TransitionablePortal>
  );
};

export default ModalRules;
