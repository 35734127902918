import React, { useState } from "react";

import { Button, Form, Header } from "semantic-ui-react";

import "../GroupPage/GroupPage";

//import EnableNotificationsPrompt from "../../components/EnableNotificationsPrompt/EnableNotificationsPrompt";
import Icon from "../../components/Icon/Icon";
//import ModalUnblockNotifications from "../../components/Modal/ModalUnblockNotifications/ModalUnblockNotifications";
import withAuth from "../../components/withAuth";
import { config } from "../../config";
import { rpsApi } from "../../helpers/api-config";

import "./CreatePage.scss";

const CreatePage = ({ history }) => {
  const [groupName, setGroupName] = useState("");
  const [createLoading, setCreateLoading] = useState(false);

  // const [unblockNotificationsOpen, setUnblockNotificationsOpen] =
  //   useState(false);
  // const [unblockNotificationsKey, setUnblockNotificationsKey] = useState(
  //   new Date(),
  // );

  // const handleModalUnblockNotificationsOpen = () => {
  //   setUnblockNotificationsKey(new Date());
  //   setUnblockNotificationsOpen(true);
  // };

  // const handleModalUnblockNotificationsClose = () => {
  //   setUnblockNotificationsOpen(false);
  // };

  const handleGroupCreate = () => {
    if (createLoading) return;

    setCreateLoading(true);

    let config = {
      name: groupName,
    };

    rpsApi
      .post("/group", config)
      .then(
        (response) => {
          if (response.status === 201)
            history.push(`/g/${response.data}?created`);
          else {
            alert(response.data);
            console.log("Error creating group.", response);
          }
        },
        () => {
          console.log("Create error");
        },
      )
      .catch((response) => {
        alert(response);
        console.log("Error creating group.", response);
      });
  };

  return (
    <div className="page page-create-group" id="page-logged-in">
      {/* <pre>Slug:{slug}</pre> */}
      {/* <UserNav currentGroup={currentGroup} groups={groups} /> */}
      <div id="page-content">
        <div className="subpage subpage-group" id="subpage-create">
          {/* {account && <UserNav />} */}
          {/* N */}
          <div id="subpage-content">
            <div className="inner-content">
              <div className="create-header">
                <div className="create-header-inner">
                  Create a group of friends and play for a
                  prize&nbsp;or&nbsp;penalty!
                </div>
              </div>

              <Form className="group-creation tournament-config text-center">
                <Header as="h3">Group name</Header>
                <div className={``}>
                  <Form.Field className="tournament-config-stake">
                    <div className="stake-wrapper">
                      <input
                        id="tournament-config-stake-input"
                        type="text"
                        name="groupName"
                        value={groupName}
                        style={{ borderRadius: 0 }}
                        onChange={(ev) => {
                          setGroupName(
                            ev.target.value.substring(
                              0,
                              config.validation.maxLength.groupName,
                            ),
                          );
                        }}
                        placeholder="Enter group name"
                        autoComplete="off"
                      />
                    </div>
                  </Form.Field>
                </div>
              </Form>

              <div className="action-panel">
                <Button
                  icon
                  size="big"
                  primary
                  onClick={handleGroupCreate}
                  disabled={createLoading || !groupName}
                  className="decide-now"
                >
                  <span>Create group</span>
                  <Icon className="far fa-chevron-right"></Icon>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuth(CreatePage);
