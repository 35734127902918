import React, { useEffect, useState } from "react";
import { Accordion, Grid, Header } from "semantic-ui-react";

import { Api } from "../../helpers/api";

import "./FaqPage.scss";

const FaqPage = () => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const result = await Api.getFaqs();
      setFaqs(result);
    }

    fetchData();
  }, []);

  return (
    <div className="subpage subpage-legal subpage-faq">
      <div id="subpage-content">
        <div className="inner-content">
          <div className="legal-container">
            <ul>
              <li>
                You will get an email or a notification when the Rock Paper
                Scissors (RPS) tournament begins. In this tournament, you'll be
                randomly matched with another participant.
              </li>
              <li>
                Your <b>match</b> will be a series of <b>moves</b>. The first
                player to reach the required number of wins from these{" "}
                <b>moves</b> will be declared the winner of the <b>match</b>.
              </li>
              <li>
                Each <b>move</b> has a time limit for you to decide and play
                either 'rock', 'paper', or 'scissors'. If you're the only one to
                make a <b>move</b> in this time, you'll win by default!
              </li>
              <li>
                If neither you nor your opponent make a <b>move</b> within the
                time limit, the <b>match</b> will be considered cancelled.
                Here's what will happen next:
                <ul>
                  <li>
                    If you've won more <b>moves</b> than your opponent in the{" "}
                    <b>match</b>, you win the <b>match</b>.
                  </li>
                  <li>
                    If you're playing for a <b className="text-green">prize</b>{" "}
                    and it's the first <b>move</b> of the <b>match</b>, both you
                    and your opponent will be disqualified from the tournament.
                  </li>
                  <li>
                    If you're playing for a <b className="text-red">penalty</b>{" "}
                    and it's the first <b>move</b> of the <b>match</b>, the 2
                    players are considered to have forfeited as they never
                    played. A random player will be chosen to move forward to
                    the next round.
                  </li>
                  <li>
                    If you have both won an equal number of moves, the{" "}
                    <b>match</b> winner will be chosen randomly.
                  </li>
                </ul>
              </li>
              {/* <li>
                  If neither player reaches the required number of wins after a
                  set number of <b>moves</b>, the <b>match</b> will be decided
                  based on who has won the most <b>RPS games</b>. If there's a
                  tie, the <b>match</b> winner will be selected randomly.
                </li> */}
              <li>
                If you're playing for a <b className="text-green">prize</b>,
                winning the <b>match</b> advances you to the next <b>round</b>{" "}
                where you will play the winner of another <b>match</b>. This
                process continues until the final where the winner of the prize
                is determined! If you lose, you are knocked out of the running
                for the prize. Better luck next time!
              </li>
              <li>
                If you're playing for a <b className="text-red">penalty</b>,
                losing the <b>match</b> moves you to the next <b>round</b> where
                you will play the loser of another <b>match</b>. If you win, you
                are safe from the penalty and don't play any further matches.
                This process continues until the final where the penalty
                recipient is determined!
              </li>
            </ul>
            {false &&
              faqs &&
              faqs.faqCategories &&
              faqs.faqCategories.length && (
                <Grid columns={1}>
                  <Grid.Row>
                    {faqs.faqCategories.map((fcc, idx) => (
                      <Grid.Column key={idx}>
                        {fcc &&
                          fcc.length &&
                          fcc.map((fc) => (
                            <React.Fragment key={fc.id}>
                              <Header as="h3">{fc.title}</Header>
                              <Accordion
                                styled
                                fluid
                                panels={fc.faqItems}
                              ></Accordion>
                            </React.Fragment>
                          ))}
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </Grid>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqPage;
