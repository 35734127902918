import React, { useMemo } from "react";

import Icon from "../../Icon/Icon";

import "./GroupMember.scss";

const GroupMemberProviders = ({ member }) => {
  const providers = useMemo(() => {
    if (!member.accountProviders) return {};

    var provs = member.accountProviders.split(",");

    return {
      facebook: provs.indexOf("facebook.com") > -1,
      twitter: provs.indexOf("twitter.com") > -1,
      google: provs.indexOf("google.com") > -1,
      snapchat: provs.indexOf("snapchat.com") > -1,
      microsoft: provs.indexOf("microsoft.com") > -1,
      apple: provs.indexOf("apple.com") > -1,
    };
  }, [member]);

  const hasProviders = useMemo(() => {
    return (
      providers.facebook ||
      providers.twitter ||
      providers.google ||
      providers.snapchat ||
      providers.microsoft ||
      providers.apple
    );
  }, [providers]);

  return (
    <>
      {hasProviders && (
        <div className="group-member-providers">
          <span className="authed-with">Authenticated with</span>
          {providers.facebook && <Icon className="fab fa-facebook"></Icon>}
          {providers.twitter && <Icon className="fab fa-twitter"></Icon>}
          {providers.google && <Icon className="fab fa-google"></Icon>}
          {providers.microsoft && <Icon className="fab fa-microsoft"></Icon>}
          {providers.snapchat && <Icon className="fab fa-snapchat"></Icon>}
          {providers.apple && <Icon className="fab fa-apple"></Icon>}
        </div>
      )}
    </>
  );
};

export default GroupMemberProviders;
