import React, { useContext, useEffect, useState } from "react";
import { Modal, TransitionablePortal } from "semantic-ui-react";

import AuthContext from "../../../contexts/AuthContext";
import { Api } from "../../../helpers/api";
import { humanReadableRepeatStr } from "../../../utilities";
import Avatar from "../../Avatar/Avatar";
import GroupMembers from "../../GroupMembers/GroupMembers";
import Plural from "../../Plural/Plural";
import RkoModal from "../RkoModal/RkoModal";

import "./ModalDecisionParticipants.scss";

const ModalDecisionParticipants = ({ group, tournament, open, close }) => {
  const [filteredTournament, setFilteredTournament] = useState(tournament);

  const { authState } = useContext(AuthContext);

  useEffect(() => {
    let isMounted = true;

    if (tournament) {
      Api.getTournamentAccounts(tournament.id).then((response) => {
        if (!isMounted) return;

        setFilteredTournament({
          ...tournament,
          tournamentAccountsLookup:
            response.length ?
              response
                .filter((x) => x.participating === true)
                .map((x) => x.accountId)
            : [],
        });
      });
    }

    return () => {
      isMounted = false;
    };
  }, [tournament]);

  const handleClose = () => {
    document.body.classList.remove("modal-fade-in");
    close();
  };

  return (
    <TransitionablePortal
      open={open}
      onOpen={() =>
        setTimeout(() => document.body.classList.add("modal-fade-in"), 0)
      }
      transition={{ animation: "fade down", duration: 300 }}
    >
      <RkoModal
        size="tiny"
        centered={true}
        open={true}
        onClose={handleClose}
        className="modal-decision-participants modal-half-height"
      >
        <Modal.Content scrolling>
          {filteredTournament?.tournamentAccountsLookup?.length > 0 && (
            <>
              <h5 className="game-owner">
                <Avatar user={filteredTournament.creatorId}></Avatar>
                {filteredTournament.creatorId === authState?.account?.id ?
                  "Your"
                : filteredTournament.creatorUserName + "'s"}{" "}
                repeating game
              </h5>
              <h1 className="ellipsis-overflow">{filteredTournament.stake}</h1>
              <span className="winners-to-draw">
                Choosing {filteredTournament.winnersToDraw}{" "}
                <Plural
                  value={filteredTournament.winnersToDraw}
                  base="player"
                ></Plural>{" "}
                {humanReadableRepeatStr(filteredTournament, true)}
              </span>
              <GroupMembers
                group={group}
                currentTournament={filteredTournament}
                participating={true}
                hideParticipating={true}
              />
            </>
          )}
        </Modal.Content>
      </RkoModal>
    </TransitionablePortal>
  );
};

export default ModalDecisionParticipants;
