import React from "react";
import { isTablet } from "react-device-detect";
import { Modal, TransitionablePortal } from "semantic-ui-react";

import Icon from "../../Icon/Icon";
import RkoModal from "../RkoModal/RkoModal";

import "./ModalIosAddToHomeScreen.scss";

const ModalIosAddToHomeScreen = ({ open, close }) => {
  const handleClose = () => {
    document.body.classList.remove("modal-fade-in");
    close();
  };

  return (
    <TransitionablePortal
      open={open}
      onOpen={() =>
        setTimeout(() => {
          document.body.classList.add("modal-fade-in");
        }, 0)
      }
      transition={{ animation: "fade down", duration: 300 }}
    >
      <RkoModal
        open={true}
        onClose={handleClose}
        className={`modal-iosaddtohomescreen ${isTablet ? "is-ipad" : ""}`}
      >
        <Modal.Content>
          <Modal.Description>
            {isTablet && (
              <>
                <p className="text-right">
                  <span className="arrow">
                    <Icon className="far fa-arrow-up-right"></Icon>
                  </span>
                </p>
                <p className="text-center">
                  Tap the <Icon className="far fa-arrow-up-from-square"></Icon>{" "}
                  icon in the menu above, then
                  <br />
                  scroll down and tap{" "}
                  <Icon className="far fa-plus-square"></Icon>Add to Home
                  Screen.
                </p>
              </>
            )}
            {!isTablet && (
              <p className="text-center">
                Tap the <Icon className="far fa-arrow-up-from-square"></Icon>{" "}
                icon below, then scroll down and
                <br />
                tap <Icon className="far fa-plus-square"></Icon>Add to Home
                Screen.
                <br />
                <span className="arrow">
                  <Icon className="far fa-arrow-down"></Icon>
                </span>
              </p>
            )}
          </Modal.Description>
        </Modal.Content>
      </RkoModal>
    </TransitionablePortal>
  );
};

export default ModalIosAddToHomeScreen;
