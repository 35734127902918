import { Tween, Easing } from "@tweenjs/tween.js";
import moment from "moment";

import Icon from "./components/Icon/Icon";
import Enum from "./helpers/enums";

export function isPowerOfTwo(number) {
  return (number & (number - 1)) === 0;
}

export function gameNoun(tournament) {
  return tournament &&
    tournament.gameMode === Enum.TournamentGameMode.TOURNAMENTRPS
    ? "game"
    : "game";
}

export function firstToUpper(s) {
  if (!s) return s;

  return s[0].toUpperCase() + s.substring(1, s.length);
}

export function minutesToTimeString(m, short, noPlural) {
  const units = short ? ["min", "hr"] : [" minute", " hour"];

  if (!m || isNaN(m)) return `0 ${units[0]}${noPlural ? "" : "s"}`;

  let hours = Math.floor(m / 60);
  let minutes = Math.floor(m % 60);

  if (minutes === 0)
    return `${hours} ${units[1]}${noPlural || hours === 1 ? "" : "s"}`;

  if (m < 60) return `${m} ${units[0]}${noPlural || m === 1 ? "" : "s"}`;

  return `${hours} ${units[1]}${
    noPlural || hours === 1 ? "" : "s"
  } and ${minutes} ${units[0]}${noPlural || minutes === 1 ? "" : "s"}`;
}

export function toHtmlDateTimeFormat(m) {
  return moment(m).format("YYYY-MM-DDTHH:mm");
}

export function fillOptions(min, max, step, pad, textSuffix) {
  const array = [];
  step = step || 1;
  pad = pad || 1;
  for (let i = min * step; i <= max * step; i += step) {
    array.push({
      text: String(i).padStart(pad, "0") + (textSuffix ? " " + textSuffix : ""),
      value: i,
    });
  }
  return array;
}

export function clamp(val, min, max) {
  return val > max ? max : val < min ? min : val;
}

export function randomFrom(source, num) {
  return source.sort(() => 0.5 - Math.random()).slice(0, num);
}

export function distance(from, to) {
  return Math.hypot(to.x - from.x, to.y - from.y);
}

export function appendEmoji(message, emoji) {
  let whitespace =
    message.length === 0 || message.substr(-1) === " " ? "" : " ";
  return `${message}${whitespace}${emoji} `;
}

export function firstUpper(string) {
  return string[0].toUpperCase() + string.substr(1);
}

export function tweenToTargetPosition(
  object,
  easing = Easing.Quadratic.Out,
  time = 500,
  onComplete = null,
  delay = 0
) {
  object.tweenPosition = { x: object.x, y: object.y };

  if (object.positionTween) object.positionTween.stop();

  object.positionTween = new Tween(object.tweenPosition)
    .to({ x: object.targetX, y: object.targetY }, time)
    .easing(easing)
    .onComplete(() => {
      if (onComplete) onComplete();
    });

  if (delay) object.positionTween.delay(delay);

  object.positionTween.start();
}

export function tweenToTargetScale(
  object,
  easing = Easing.Quadratic.Out,
  time = 500,
  onComplete = null,
  delay = 0
) {
  object.tweenScale = { xy: object.scale.x };

  if (object.scaleTween) object.scaleTween.stop();

  object.scaleTween = new Tween(object.tweenScale)
    .to(object.targetScale, time)
    .easing(easing)
    .onStart(() => {
      object.isScaleTweening = true;
    })
    .onComplete(() => {
      object.isScaleTweening = false;
      if (onComplete) onComplete();
    });

  if (delay) object.scaleTween.delay(delay);

  object.scaleTween.start();
}

export function tweenToTargetAlpha(
  object,
  easing = Easing.Quadratic.Out,
  time = 500,
  onComplete = null,
  delay = 0
) {
  object.tweenAlpha = { alpha: object.alpha };

  if (object.alphaTween) object.alphaTween.stop();

  object.alphaTween = new Tween(object.tweenAlpha)
    .to({ alpha: object.targetAlpha }, time)
    .easing(easing)
    .onStart(() => {
      object.isAlphaTweening = true;
    })
    .onComplete(() => {
      object.isAlphaTweening = false;
      if (onComplete) onComplete();
    });

  if (delay) object.alphaTween.delay(delay);

  object.alphaTween.start();
}

// Debounce function
export function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

// Human readable string for a repeating tournament based on its properties
const repeatDaysOfWeekStr = (tournament) => {
  let str = "";

  if (tournament.repeatEvery === 1) {
    if (tournament.repeatDaysOfWeek === 127)
      // every
      return "day" + (tournament.repeatEvery === 1 ? "" : "s");
    if (tournament.repeatDaysOfWeek === 62)
      // weekdays
      return "weekday";
    if (tournament.repeatDaysOfWeek === 65)
      // weekends
      return "Sat and Sun";
  } else {
    str = tournament.repeatEvery + " weeks on ";
  }

  if (tournament.repeatDaysOfWeek === 65)
    // weekends
    return str + "Sat and Sun";

  let dow = repeatDaysOfWeek(tournament);
  if (!dow) return "";

  str += dow
    .map((d) => {
      switch (d) {
        case Enum.RepeatDayOfWeek.SUNDAY:
          return "Sun";
        case Enum.RepeatDayOfWeek.MONDAY:
          return "Mon";
        case Enum.RepeatDayOfWeek.TUESDAY:
          return "Tue";
        case Enum.RepeatDayOfWeek.WEDNESDAY:
          return "Wed";
        case Enum.RepeatDayOfWeek.THURSDAY:
          return "Thu";
        case Enum.RepeatDayOfWeek.FRIDAY:
          return "Fri";
        case Enum.RepeatDayOfWeek.SATURDAY:
          return "Sat";
        default:
          return "Unknown";
      }
    })
    .join(", ");

  return str;
};

const repeatDaysOfWeek = (tournament) => {
  let dow = [];

  let keys = Object.keys(Enum.RepeatDayOfWeek);

  for (let i = 0; i < keys.length; i++) {
    if (!Object.prototype.hasOwnProperty.call(Enum.RepeatDayOfWeek, keys[i])) continue;
    if (
      (tournament.repeatDaysOfWeek & Enum.RepeatDayOfWeek[keys[i]]) ===
      Enum.RepeatDayOfWeek[keys[i]]
    )
      dow.push(Enum.RepeatDayOfWeek[keys[i]]);
  }

  return dow;
};

const repeatDayOfMonth = (tournament) => {
  let d = tournament.repeatDayOfMonth;
  return (
    `${d}` +
    (d === 1 || d === 21 || d === 31
      ? "st"
      : d === 2 || d === 22
      ? "nd"
      : d === 3 || d === 23
      ? "rd"
      : "th")
  );
};

const repeatPeriodStr = (tournament) => {
  switch (tournament.repeatPeriod) {
    case Enum.RepeatPeriod.DAILY:
      return (
        (tournament.repeatEvery === 1 ? "" : tournament.repeatEvery) +
        " day" +
        (tournament.repeatEvery === 1 ? "" : "s")
      );
    case Enum.RepeatPeriod.WEEKLY:
      return repeatDaysOfWeekStr(tournament);
    case Enum.RepeatPeriod.MONTHLY:
      return (
        (tournament.repeatEvery === 1 ? "" : tournament.repeatEvery) +
        " month" +
        (tournament.repeatEvery === 1 ? "" : "s") +
        ` on the ${repeatDayOfMonth(tournament)}`
      );
    default:
      return "Unknown";
  }
};

export const humanReadableRepeatStr = (
  tournament,
  lowercase,
  adjustForTz,
  myTz
) => {
  if (lowercase === undefined) lowercase = false;
  if (adjustForTz === undefined) adjustForTz = true;

  const originalTime = moment.parseZone(tournament.startTime);

  return (
    <>
      {lowercase ? "e" : "E"}very {repeatPeriodStr(tournament)} at{" "}
      {adjustForTz && (
        <nobr>{moment(tournament.startTime).format("h:mm a")}</nobr>
      )}
      {!adjustForTz && (
        <nobr>
          {originalTime.format("h:mm a") +
            (myTz !== tournament.timeZoneStartedIn
              ? " " + tournament.timeZoneStartedIn
              : "")}
        </nobr>
      )}
    </>
  );
};

export const reverseFirstX = (arr, x) => {
  if (!Array.isArray(arr) || !Number.isInteger(x) || x < 0) {
    throw new Error("Invalid input");
  }

  if (x === 0 || arr.length === 0) {
    return [];
  }

  const sliceToReverse = arr.slice(0, x);
  return sliceToReverse.reverse();
};

export const commaSeparateWithNMore = (arr, n, onlySelf) => {
  if (!arr) return "";

  const length = arr.length;

  if (length === 0) {
    return "";
  } else if (length === 1) {
    return arr[0] === "You" ? onlySelf : arr[0];
  } else if (length === 2) {
    return arr.join(" and ");
  } else if (length <= n) {
    return arr.slice(0, length - 1).join(", ") + " and " + arr[length - 1];
  } else {
    const more = length - n;
    return (
      arr.slice(0, n).join(", ") + `, and ${more} other${more === 1 ? "" : "s"}`
    );
  }
};

export const calculateRoundsForMaxPlayers = (players) => {
  let logBase2 = Math.log2(players);
  let rounds = Math.ceil(logBase2);
  return rounds;
};

export const getTournamentStatusStuff = (
  group,
  currentTournament,
  currentRound,
  account
) => {
  //console.log("getTournamentStatusStuff", group, currentTournament);
  if (!currentTournament || !group)
    return {
      tournamentStatusIcon: null,
      tournamentStatusText: null,
    };

  let bossOrCreator =
    currentTournament.creatorId === account.id || group.amBoss;

  if (currentTournament.state === Enum.TournamentState.PREPARE) {
    if (currentTournament.participants === currentTournament.startingPlayers) {
      return {
        tournamentStatusIcon: null,
        tournamentStatusText: bossOrCreator
          ? "Ready to start"
          : "Wait for start",
      };
    } else {
      if (
        ((currentTournament.winnersToDraw === 1 &&
          currentTournament.participants >= 2) ||
          (currentTournament.winnersToDraw > 1 &&
            currentTournament.participants >=
              currentTournament.winnersToDraw)) &&
        group.playerCount === currentTournament.tournamentAccountsLookup.length
      ) {
        return {
          tournamentStatusIcon: null,
          tournamentStatusText: bossOrCreator
            ? "Ready to start"
            : "Wait for start",
        };
      }

      if (
        ((currentTournament.winnersToDraw === 1 &&
          currentTournament.participants < 2) ||
          (currentTournament.winnersToDraw > 1 &&
            currentTournament.participants <
              currentTournament.winnersToDraw)) &&
        group.playerCount > 1 &&
        group.playerCount === currentTournament.tournamentAccountsLookup.length
      ) {
        return {
          tournamentStatusIcon: null,
          tournamentStatusText: bossOrCreator ? "Not enough players" : null,
        };
      }
    }

    return {
      tournamentStatusIcon: (
        <Icon key="joining" className="far fa-spin fa-spinner-third"></Icon>
      ),
      tournamentStatusText: "Players joining",
    };
  }

  if (currentTournament.state === Enum.TournamentState.ACTIVE && currentRound) {
    return {
      tournamentStatusIcon: null,
      tournamentStatusText: (
        <>
          <b>
            {currentTournament.startingPlayers === 2
              ? "Playing"
              : "Playing " + currentRound.title}
          </b>
        </>
      ),
    };
  }
};

export const GSLUG_PARAM = ":slug";
export const GSLUG_REGEX = "(solo|[a-zA-Z0-9]{6,32})";
