import React, { useContext, useEffect, useMemo, useState } from "react";

import BracketAdvancer from "./BracketAdvancer";
import BracketHistory from "./BracketHistory";
import { config } from "../../config";
import AuthContext from "../../contexts/AuthContext";
import Enum from "../../helpers/enums";
import Avatar from "../Avatar/Avatar";

const BracketMatch = ({ tournament, round, match, handleUserClick }) => {
  const { authState } = useContext(AuthContext);

  const [me, setMe] = useState();
  const [opponents, setOpponents] = useState();
  const [advancer, setAdvancer] = useState(null);

  const [myHistory, setMyHistory] = useState();
  const [opponentHistory, setOpponentHistory] = useState();

  const playersToBracketJsx = (round) => {
    if (round.playersCount <= 2) return <>Final</>;
    else if (round.roundNumber === 1) return <>Round 1</>;
    // else if (round.playersCount <= 4)
    //   return (
    //     <>
    //       Semi
    //       <br />
    //       Finals
    //     </>
    //   );
    // else if (round.playersCount <= 8)
    //   return (
    //     <>
    //       Quarter
    //       <br />
    //       Finals
    //     </>
    //   );
    else return <>Round {round.roundNumber}</>;
  };

  useEffect(() => {
    if (!match || !match.matchAccounts || !authState.account) return;

    setMe(
      match.matchAccounts.find((x) => x.accountId === authState.account?.id),
    );
    setOpponents(
      match.matchAccounts.filter((x) => x.accountId !== authState.account?.id),
    );
  }, [match, authState.account]);

  useEffect(() => {
    if (!match || !match.historicalMatchAccounts || !authState.account) return;

    const myHistory = match.historicalMatchAccounts.find(
      (x) => x.accountId === authState.account?.id,
    );
    console.log(match.historicalMatchAccounts);
    console.log("myHistory", myHistory);
    setMyHistory(
      match.historicalMatchAccounts.filter(
        (x) => x.matchId === myHistory?.matchId,
      ),
    );
    console.log(
      match.historicalMatchAccounts.filter(
        (x) => x.matchId === myHistory?.matchId,
      ),
    );
    setOpponentHistory(
      match.historicalMatchAccounts.filter(
        (x) => x.matchId !== myHistory?.matchId,
      ),
    );
  }, [match, authState.account]);

  useEffect(() => {
    const advanceResult =
      tournament.stakeIsPositive ? Enum.ResultType.WIN : Enum.ResultType.LOSS;

    if (me && me.result === advanceResult) setAdvancer(me);
    else if (
      opponents &&
      opponents.length >= 1 &&
      opponents[0].result === advanceResult
    )
      setAdvancer(opponents[0]);
    else setAdvancer(null);
  }, [me, opponents, tournament.stakeIsPositive]);

  const nextRoundTitle = useMemo(() => {
    if (!round) return null;

    if (match?.nextRoundTitle) {
      return match?.nextRoundTitle?.replace("Final", "\nFinal");
    }

    if (round.isFinalRound) {
      return tournament.stakeIsPositive ? <>Prize</> : <>Penalty</>;
    } else {
      return playersToBracketJsx({
        roundNumber: round.roundNumber + 1,
        playersCount: Math.ceil(round.playersCount / 2),
      });
    }
  }, [round, tournament?.stakeIsPositive, match?.nextRoundTitle]);

  const currentRoundTitle = useMemo(() => {
    return match?.roundTitle?.replace(" Final", " \nFinal");
  }, [match?.roundTitle]);

  const prevRoundTitle = useMemo(() => {
    return match?.prevRoundTitle?.replace(" Final", "\nFinal");
  }, [match?.prevRoundTitle]);

  return (
    <div className="br-rounds">
      {/* <pre>{JSON.stringify(round, '', 2)}</pre> */}
      <div
        className={`br-round br-round-next ${
          round.isFinalRound ? "br-round-next-final" : ""
        }  ${advancer ? "br-advancer-known" : "br-advancer-unknown"} ${
          tournament.stakeIsPositive ?
            "br-round-next-positive"
          : "br-round-next-negative"
        } ${
          advancer && advancer.accountId === config.aiAccountMember.accountId ?
            "br-advancer-ai"
          : ""
        }`}
      >
        <div className="br-round-title">
          <span>{nextRoundTitle}</span>
        </div>
        <BracketAdvancer
          tournament={tournament}
          player={advancer}
          handleUserClick={handleUserClick}
        ></BracketAdvancer>
      </div>

      <div className="br-round br-round-curr">
        <div className="br-round-title">
          <span>{currentRoundTitle}</span>
        </div>
        <div
          className={`br-match br-match-my ${advancer ? "br-resolved" : ""}`}
        >
          <div
            className={`br-player br-player-left ${
              advancer && advancer === me ? "br-player-adv" : ""
            } ${advancer && advancer !== me ? "br-player-ko" : ""} ${
              tournament.stakeIsPositive ? "br-player-positive" : (
                "br-player-negative"
              )
            } br-player-me`}
          >
            {me && (
              <div onClick={() => handleUserClick(me.accountId)}>
                <Avatar user={me.accountId}></Avatar>
                <span className="br-username ellipsis-overflow">
                  {/* {me.accountUserName} */}You
                </span>
              </div>
            )}
          </div>
          <div className="br-match-prog">vs</div>
          <div
            className={`br-player br-player-right ${
              advancer && advancer === opponents[0] ? "br-player-adv" : ""
            } ${advancer && advancer !== opponents[0] ? "br-player-ko" : ""} ${
              tournament.stakeIsPositive ? "br-player-positive" : (
                "br-player-negative"
              )
            } ${
              (
                advancer &&
                advancer === opponents[0] &&
                advancer.accountId === config.aiAccountMember.accountId
              ) ?
                "br-player-ai"
              : ""
            }`}
          >
            {opponents && (
              <div onClick={() => handleUserClick(opponents[0].accountId)}>
                <Avatar user={opponents[0].accountId}></Avatar>
                <span className="br-username ellipsis-overflow">
                  {opponents[0].accountUserName}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {tournament && match?.historicalMatchAccounts && (
        <div className="br-round br-round-prev">
          <div className="br-round-title">
            <span>{prevRoundTitle}</span>
          </div>
          {myHistory?.length === 2 && (
            <>
              <BracketHistory
                tournament={tournament}
                round={round}
                matchAccounts={myHistory}
                side="left"
                handleUserClick={handleUserClick}
              ></BracketHistory>
            </>
          )}
          {opponentHistory?.length === 2 && (
            <>
              <BracketHistory
                tournament={tournament}
                round={round}
                matchAccounts={opponentHistory}
                side="right"
                handleUserClick={handleUserClick}
              ></BracketHistory>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default BracketMatch;
