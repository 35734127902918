import React, { useContext } from "react";
import { Modal } from "semantic-ui-react";

import ModalMountContext from "../../../contexts/ModalMountContext";

const RkoModal = ({ children, ...props }) => {
  const modalMountRef = useContext(ModalMountContext);

  return (
    <Modal
      {...props}
      mountNode={
        modalMountRef && modalMountRef.current ?
          modalMountRef.current
        : undefined
      }
    >
      {children}
    </Modal>
  );
};

export default RkoModal;
