import React, { useEffect, useState } from "react";
import { Modal, TransitionablePortal } from "semantic-ui-react";

import "./ModalStakeSuggested.scss";
import { config } from "../../../config";
import Icon from "../../Icon/Icon";
import RkoModal from "../RkoModal/RkoModal";

const ModalStakeSuggested = ({ onClickStake, open, close }) => {
  const [randomStakes, setRandomStakes] = useState([]);

  const handleClose = () => {
    document.body.classList.remove("modal-fade-in");
    close();
  };

  const getRandomStakes = (stakeList, count) => {
    const shuffled = stakeList.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  useEffect(() => {
    setRandomStakes(getRandomStakes(config.stakes, config.stakeSuggestedCount));
  }, []);

  return (
    <TransitionablePortal
      open={open}
      onOpen={() =>
        setTimeout(() => document.body.classList.add("modal-fade-in"), 0)
      }
      transition={{ animation: "fade down", duration: 300 }}
    >
      <RkoModal
        size="mini"
        centered={false}
        open={true}
        onClose={handleClose}
        className="modal-stake-suggested"
      >
        <Modal.Content>
          <Modal.Description>
            <p>Need some ideas? Try one of these!</p>
            {randomStakes.map((stake, index) => (
              <div
                key={index}
                className="stake-suggested"
                onClick={() => {
                  onClickStake(stake.stake);
                }}
              >
                {stake.stake}
                <Icon className="fas fa-chevron-right"></Icon>
              </div>
            ))}
          </Modal.Description>
        </Modal.Content>
      </RkoModal>
    </TransitionablePortal>
  );
};

export default ModalStakeSuggested;
