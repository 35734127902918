import React from "react";

import "./Nameplate.scss";
import Avatar from "../../../Avatar/Avatar";

const Nameplate = ({
  player,
  score,
  isOpponent,
  isThirdPlayer,
  hideScore,
  handleModalUserManageOpen,
}) => {
  return (
    <div
      className={`component-nameplate ${
        isOpponent ? "is-opponent" : "is-player"
      }
      ${isThirdPlayer ? "is-third" : ""}`}
    >
      <div
        className="nameplate-avatar"
        onClick={() => handleModalUserManageOpen(player.accountId)}
      >
        <Avatar user={player.accountId} />
      </div>
      <div
        className="nameplate-panel"
        onClick={() => handleModalUserManageOpen(player.accountId)}
      >
        {isOpponent ? player.accountUserName : "You"}
      </div>
      {!hideScore && <div className="nameplate-score">{score}</div>}
    </div>
  );
};

export default Nameplate;
