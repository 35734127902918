import moment from "moment";
import React, { useEffect, useState } from "react";
import { Loader, Menu } from "semantic-ui-react";

import GroupListItem from "./GroupListItem/GroupListItem";

import "./GroupList.scss";

const GroupList = ({
  currentGroup,
  groups,
  groupsLoaded,
  //onLoadMoreGroups,
}) => {
  const [processedGroups, setProcessedGroups] = useState([]);

  useEffect(() => {
    if (!groups || !groups.data || !groups.data.length) return;

    const processedGroups = groups.data
      .filter((g) => !g.promo)
      .map((group) => {
        return {
          ...group,
          lastFinishedTournamentMoment: moment(group.lastFinishedTournament),
        };
      });

    setProcessedGroups(processedGroups);
  }, [groups]);

  // const onLoadMore = async () => {
  //   if (!groupsLoaded || !groups || !groups._more || !groups._more.more)
  //     return false;

  //   return onLoadMoreGroups();
  // };

  //const scrollRef = useInfiniteScroll(onLoadMore);

  return (
    // <div className="group-list" ref={scrollRef}>
    <div className="group-list">
      {!groupsLoaded && <Loader active content="Loading..."></Loader>}
      {groupsLoaded && (
        <div className="group-list-items">
          <Menu vertical>
            {(!processedGroups || !processedGroups.length) && (
              <>
                <div className="no-groups">
                  <h1 className="game-mode-heading-color">Friend groups</h1>
                  You're not a member of any groups yet.
                  <br />
                  <br />
                  Start a new group to set up a game.
                </div>
              </>
            )}
            {processedGroups &&
              processedGroups.map((group) => {
                return (
                  <GroupListItem
                    key={group.slug}
                    group={group}
                    selected={group.slug === currentGroup}
                  />
                );
              })}
            {currentGroup === "new" && (
              <GroupListItem group={{ creating: true }} selected={true} />
            )}
          </Menu>
        </div>
      )}
    </div>
  );
};

export default GroupList;
