import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { Button, Label } from "semantic-ui-react";

import DecisionHistoryItem from "./DecisionHistoryItem";
import DecisionResults from "./DecisionResults";
import "./DecisionHistory.scss";
import { Api } from "../../helpers/api";
import { createDebugLogger } from "../../helpers/debug";
import Enum from "../../helpers/enums";
import Icon from "../Icon/Icon";

const { log: groupLog } = createDebugLogger(Enum.DebugChannel.GROUP);

const DecisionHistory = ({
  slug,
  group,
  tournaments,
  historyTournamentId,
  onLoadMore,
  history,
  onDecisionReplay,
  loadingDecisionHistory,
  handleModalAlertOpen,
}) => {
  const [openId, setOpenId] = useState(historyTournamentId);
  const [historyTournament, setHistoryTournament] = useState(null);
  const [historyTab, setHistoryTab] = useState(Enum.HistoryTab.ALL);
  const [tournamentDetailLoading, setTournamentDetailLoading] = useState(false);

  const onOpen = (id) => {
    //if (openId === id) setOpenId(null);
    //else setOpenId(id);
    groupLog("[Decision History] onOpen", historyTournamentId);
    history.push(`/g/${slug}/history/${id}`);
  };

  useEffect(() => {
    if (!openId) setHistoryTournament(null);
    else {
      if (!group) return;

      groupLog("[Decision History] loading data", openId);
      setTournamentDetailLoading(true);

      Api.getTournament(openId, (response) => {
        let startTimeString = moment(response.startTime).format(
          "D MMM yyyy h:mm a",
        );

        let newTas = response.tournamentAccounts.map((ta) => {
          let ga = group.groupAccounts.find(
            (ga) => ga.accountId === ta.accountId,
          );

          return ga ?
              { ...ga, participating: ta.participating }
            : { ...ta, notMember: true };
        });

        setHistoryTournament({
          ...response,
          startTimeString,
          tournamentAccounts: newTas,
        });

        setTournamentDetailLoading(false);
      });
    }
  }, [openId]);

  useEffect(() => {
    groupLog("[Decision History] setting openid", historyTournamentId);
    setOpenId(historyTournamentId);
  }, [historyTournamentId]);

  const handleTabChange = (tab) => {
    groupLog("[Decision History] tab change", historyTournamentId);
    setHistoryTab(tab);
    onLoadMore(tab, true);
  };

  const filteredTournaments = useMemo(() => {
    if (!tournaments || !tournaments.data) return null;

    let filtered = [...tournaments.data];

    filtered = filtered.filter(
      (x) =>
        x.state !== Enum.TournamentState.PREPARE &&
        x.state !== Enum.TournamentState.ACTIVE,
    );

    return filtered;
  }, [tournaments]);

  return (
    <>
      {!historyTournament && !tournamentDetailLoading && (
        <div
          className={`component-decision-history ${
            historyTournament ? "decision-history-viewing-detail" : ""
          }`}
        >
          <div className="group-tabs">
            <div
              className={`group-tab ${
                historyTab === Enum.HistoryTab.ALL ? "active" : ""
              }`}
              onClick={() => handleTabChange(Enum.HistoryTab.ALL)}
            >
              <div className="group-tab-title">
                <Icon className="fas fa-list"></Icon> All
                {tournaments?.allCount > 0 && (
                  <Label color="blue">{tournaments.allCount}</Label>
                )}
              </div>
            </div>
            <div
              className={`group-tab ${
                historyTab === Enum.HistoryTab.QUICK ? "active" : ""
              }`}
              onClick={() => handleTabChange(Enum.HistoryTab.QUICK)}
            >
              <div className="group-tab-title">
                <Icon className="fas fa-forward-fast"></Icon> Games
                {tournaments?.quickCount > 0 && (
                  <Label color="blue">{tournaments.quickCount}</Label>
                )}
              </div>
            </div>
            <div
              className={`group-tab ${
                historyTab === Enum.HistoryTab.REPEATING ? "active" : ""
              }`}
              onClick={() => handleTabChange(Enum.HistoryTab.REPEATING)}
            >
              <div className="group-tab-title">
                <Icon className="fas fa-calendar-days"></Icon> Repeating
                {tournaments?.repeatingCount > 0 && (
                  <Label color="blue">{tournaments.repeatingCount}</Label>
                )}
              </div>
            </div>
          </div>
          <div className={`decision-history-list-wrap `}>
            <div className="decision-history-list">
              {loadingDecisionHistory && (
                <>
                  <div className="no-decisions">
                    <Icon className="fas fa-spinner-third fa-spin"></Icon>
                  </div>
                </>
              )}
              {!loadingDecisionHistory && (
                <>
                  {(!tournaments ||
                    !tournaments.data ||
                    !tournaments.data.length ||
                    !filteredTournaments ||
                    !filteredTournaments.length) && (
                    <div className="no-decisions">
                      No past{" "}
                      {historyTab === Enum.HistoryTab.REPEATING && "repeating"}{" "}
                      games
                    </div>
                  )}
                  {tournaments?.data &&
                    filteredTournaments?.length &&
                    filteredTournaments.map((tournament) => {
                      return (
                        <DecisionHistoryItem
                          key={tournament.id + "_" + tournament.state}
                          tournament={tournament}
                          open={tournament.id === openId}
                          onOpen={onOpen}
                        />
                      );
                    })}
                  {tournaments?.data?.length > 0 &&
                    (!tournaments?._more?.more ||
                      tournaments?._more?.total <=
                        tournaments?._more?.perPage) && (
                      <div className="no-more-tournaments">
                        No more past games
                      </div>
                    )}
                </>
              )}
            </div>
            {tournaments?._more?.more && tournaments?._more.total > 10 && (
              <div className="action-panel">
                <Button
                  primary
                  onClick={() => onLoadMore(historyTab, false)}
                  className="view-more"
                >
                  <span>View more</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      {(historyTournament || tournamentDetailLoading) && (
        <DecisionResults
          tournament={historyTournament}
          group={group}
          onDecisionReplay={onDecisionReplay}
          loading={tournamentDetailLoading}
          handleModalAlertOpen={handleModalAlertOpen}
        />
      )}
    </>
  );
};

export default DecisionHistory;
