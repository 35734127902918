import React from "react";
import { Modal, TransitionablePortal } from "semantic-ui-react";

import RkoModal from "../RkoModal/RkoModal";

import "./ModalGroupAlert.scss";

const ModalGroupAlert = ({ header, content, open, close }) => {
  const handleClose = () => {
    document.body.classList.remove("modal-fade-in");
    close();
  };

  return (
    <TransitionablePortal
      open={open}
      onOpen={() =>
        setTimeout(() => document.body.classList.add("modal-fade-in"), 0)
      }
      transition={{ animation: "fade down", duration: 300 }}
    >
      <RkoModal
        size="mini"
        centered={false}
        open={true}
        onClose={handleClose}
        className="modal-group-alert"
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>{content}</p>
          </Modal.Description>
        </Modal.Content>
      </RkoModal>
    </TransitionablePortal>
  );
};

export default ModalGroupAlert;
