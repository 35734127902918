import * as PIXI from "pixi.js";

class BoardSide extends PIXI.Container {
  constructor(board, isOpponent, is3KO, is3rd) {
    super();

    //const colors = [0xf0e4e9, 0xf4f3ed, 0xf1f5f3, 0xe8eef2, 0xf2eef5];

    this.x = is3KO && is3rd ? board.appWidth / 2 : 0;
    this.y = isOpponent ? 0 : board.appHeight / 2;
    let width = is3KO && is3rd ? board.appWidth / 2 : board.appWidth;
    //let color = is3KO && is3rd ? 0xe6e6e6 : isOpponent ? 0xdadada : 0xeeeeee;

    const colorChoice = isOpponent ? 0xdadada : 0xeeeeee;
    //colors[userId % 5];
    //const patternChoice = (Math.floor(userId / 5) % 4) + 1;

    let boardGfx = new PIXI.Graphics();
    boardGfx.beginFill(colorChoice); // Example color: Pastel Blue
    boardGfx.drawRect(0, 0, width, board.appHeight / 2);
    boardGfx.endFill();
    this.addChild(boardGfx);

    //let patternTexture = PIXI.Texture.from(`/images/board/pattern${patternChoice}.png`);
    //let patternSprite = new PIXI.Sprite(patternTexture);

    // Set the position (for example, aligning the center of the image to the center of the board)
    //patternSprite.anchor.set(0.5);
    //patternSprite.x = width / 2;
    //patternSprite.y = (board.appHeight / 2) / 2;
    //patternSprite.blendMode = PIXI.BLEND_MODES.MULTIPLY;
    //patternTexture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;


    // Create a container with the desired dimensions
    let container = new PIXI.Container();
    container.width = width;
    container.height = board.appHeight / 2;

    // Create a mask for the container
    let mask = new PIXI.Graphics();
    mask.beginFill(0xFFFFFF);
    mask.drawRect(0, 0, width, board.appHeight / 2);
    mask.endFill();

    container.addChild(mask);        // Add mask to the container
    container.mask = mask;          // Set the mask on the container
    //container.addChild(patternSprite);  // Add the sprite to the container

    this.addChild(container);  // Add the container to the board


    board.app.stage.addChild(this);

    return this;
  }

  newGame() {}
}

export default BoardSide;
