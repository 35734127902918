import React, { useContext, useState } from "react";

import AuthContext from "../../contexts/AuthContext";
import Icon from "../Icon/Icon";
import ModalInstallAppEnableNotifications from "../Modal/ModalInstallAppEnableNotifications/ModalInstallAppEnableNotifications";
import TextButton from "../TextButton/TextButton";

function EnableNotificationsPromptButton({
  promptJsx,
  enabledJsx,
  iconOnly,
  hideWhenEnabled,
}) {
  const { authState } = useContext(AuthContext);

  const [
    modalInstallAppEnableNotificationsOpen,
    setModalInstallAppEnableNotificationsOpen,
  ] = useState(false);
  const [
    modalInstallAppEnableNotificationsKey,
    setModalInstallAppEnableNotificationsKey,
  ] = useState(new Date());

  const handleModalInstallAppEnableNotificationsOpen = () => {
    setModalInstallAppEnableNotificationsOpen(true);
    setModalInstallAppEnableNotificationsKey(new Date());
  };

  const handleModalInstallAppEnableNotificationsClose = () => {
    setModalInstallAppEnableNotificationsOpen(false);
  };

  return (
    <div
      className={`enable-notifications-prompt-btn ${
        iconOnly ? "icon-only" : ""
      }`}
    >
      {!(authState?.notifyEnabled && hideWhenEnabled) && (
        <>
          {iconOnly && (
            <TextButton onClick={handleModalInstallAppEnableNotificationsOpen}>
              <Icon className="far fa-bell"></Icon>
            </TextButton>
          )}
          {!iconOnly && (
            <>
              {authState?.notifyEnabled ?
                <>
                  {enabledJsx || (
                    <>
                      <Icon className="far fa-bell"></Icon> Your notifications
                      are enabled
                      <br />
                      so we'll notify you when it does
                    </>
                  )}
                </>
              : <>
                  <TextButton
                    onClick={handleModalInstallAppEnableNotificationsOpen}
                  >
                    {promptJsx || (
                      <>
                        <Icon className="far fa-bell"></Icon> Want to be
                        notified when it does?
                        <br />
                        Tap here to set up
                      </>
                    )}
                  </TextButton>
                </>
              }
            </>
          )}
        </>
      )}

      <ModalInstallAppEnableNotifications
        key={
          "install-app-enable-notifications-" +
          modalInstallAppEnableNotificationsKey
        }
        open={modalInstallAppEnableNotificationsOpen}
        close={handleModalInstallAppEnableNotificationsClose}
      />
    </div>
  );
}

export default EnableNotificationsPromptButton;
