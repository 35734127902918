import React, { useEffect, useMemo, useState } from "react";

import { Button, Label } from "semantic-ui-react";

import GroupMembersAvatarStack from "../../../components/GroupMembers/GroupMembersAvatarStack/GroupMembersAvatarStack";
import Icon from "../../../components/Icon/Icon";
import ModalStakeInfo from "../../../components/Modal/ModalStakeInfo/ModalStakeInfo";
import Plural from "../../../components/Plural/Plural";
import Enum from "../../../helpers/enums";

function GroupUpper({
  group,
  groupTab,
  onUserSlideoutOpen,
  userSlideOutOpen,
  onTournamentSlideoutOpen,
  handleGroupHistoryClick,
  handleModalGroupNameOpen,
  handleTabChange,
  currentTournament,
  displayNewMemberTag,
}) {
  const [modalStakeInfoOpen, setModalStakeInfoOpen] = useState(false);
  const [modalStakeInfoKey, setModalStakeInfoKey] = useState(new Date());

  const [majorPrize, setMajorPrize] = useState(null);
  const [otherPrizesCount, setOtherPrizesCount] = useState(null);

  useEffect(() => {
    setMajorPrize(
      group?.promoData?.prizes?.length >= 1 ?
        group?.promoData?.prizes[0]
      : null,
    );
    console.log(group?.promoData?.prizes);
    setOtherPrizesCount(
      group?.promoData?.prizes?.reduce(
        (sum, item) => sum + item.awardedToTopPlayers,
        0,
      ) - 1,
    );
  }, [group]);

  const handleModalStakeInfoOpen = () => {
    setModalStakeInfoOpen(true);
    setModalStakeInfoKey(new Date());
  };

  const handleModalStakeInfoClose = () => {
    setModalStakeInfoOpen(false);
  };

  const showTournamentSlideoutButton = useMemo(() => {
    return (
      currentTournament?.gameMode === Enum.TournamentGameMode.TOURNAMENTRPS &&
      currentTournament?.state === Enum.TournamentState.ACTIVE &&
      currentTournament?.startingPlayers > 2
    );
  }, [
    currentTournament?.gameMode,
    currentTournament?.startingPlayers,
    currentTournament?.state,
  ]);

  return (
    <div className="group-upper">
      <div className="group-upper-slideout-buttons">
        {showTournamentSlideoutButton && (
          <div
            className="tournament-prizes-button"
            onClick={onTournamentSlideoutOpen}
          >
            <div className="tournament-prizes-button-container">
              <div className="nav-chevron">
                <Icon className="far fa-chevron-left"></Icon>
              </div>
              <div className="tournament-prizes-button-content">
                <Icon className="far fa-code-fork fa-rotate-90"></Icon>
              </div>
            </div>
            {/* <div className="tournament-prizes-button-label">Tournament</div> */}
          </div>
        )}
        {!group.promo && (
          <div className="group-dynamic-members">
            <GroupMembersAvatarStack
              group={group}
              onClick={onUserSlideoutOpen}
              userSlideOutOpen={userSlideOutOpen}
              displayNewMemberTag={
                displayNewMemberTag && !showTournamentSlideoutButton
              }
            />
          </div>
        )}
      </div>

      {group.promo && (
        <div
          className={`group-current-tournament group-current-tournament-promo ${
            !currentTournament ? "no-active-tournament" : ""
          }`}
        >
          {group?.promoData?.prizes && (
            <>
              {majorPrize !== null && (
                <>
                  <h3>Major prize</h3>

                  <h1
                    onClick={handleModalStakeInfoOpen}
                    className={`ellipsis-overflow game-mode-heading-color`}
                  >
                    <span>{majorPrize.name}</span>
                  </h1>
                </>
              )}

              {otherPrizesCount !== null && otherPrizesCount > 0 && (
                <span className="winners-to-draw">
                  and {otherPrizesCount} other{" "}
                  <Plural base="prize" value={otherPrizesCount} /> up for grabs
                </span>
              )}
            </>
          )}

          {!group?.promoData?.prizes && (
            <>
              <h3>Test your skill</h3>

              <h1
                onClick={handleModalStakeInfoOpen}
                className={`ellipsis-overflow game-mode-heading-color`}
              >
                <span>Be the RPS champion</span>
              </h1>

              <span className="winners-to-draw">&nbsp;</span>
            </>
          )}
        </div>
      )}

      {!group.promo && (
        <div
          className={`group-current-tournament ${
            !currentTournament ? "no-active-tournament" : ""
          }`}
        >
          {!currentTournament && (
            <>
              <h5>No active game</h5>
              <span className="winners-to-draw">
                <br />
                Set up new game
              </span>
            </>
          )}
          {currentTournament && (
            <>
              <h3>
                {currentTournament.gameMode ===
                  Enum.TournamentGameMode.TOURNAMENTRPS &&
                  "Playing rock paper scissors for"}
                {currentTournament.gameMode ===
                  Enum.TournamentGameMode.RANDOM &&
                  "Playing random choice game for"}
              </h3>

              <h1
                onClick={handleModalStakeInfoOpen}
                className={`ellipsis-overflow ${
                  (
                    currentTournament.gameMode ===
                    Enum.TournamentGameMode.TOURNAMENTRPS
                  ) ?
                    currentTournament.stakeIsPositive ?
                      "text-green"
                    : "text-red"
                  : "text-blue"
                }`}
              >
                {currentTournament.stake}
              </h1>

              {currentTournament.gameMode !==
                Enum.TournamentGameMode.RANDOM && (
                <span className="winners-to-draw">
                  {currentTournament.stakeIsPositive && (
                    <span className="text-green">
                      <Icon className="fas fa-fw fa-thumbs-up"></Icon> Prize
                    </span>
                  )}
                  {!currentTournament.stakeIsPositive && (
                    <span className="text-red">
                      <Icon className="fas fa-fw fa-thumbs-down"></Icon>
                      {""}
                      Penalty
                    </span>
                  )}
                </span>
              )}

              <span className="winners-to-draw">
                {currentTournament.gameMode ===
                  Enum.TournamentGameMode.RANDOM && (
                  <>
                    Choosing {currentTournament.winnersToDraw}{" "}
                    <Plural
                      value={currentTournament.winnersToDraw}
                      base="player"
                    ></Plural>
                  </>
                )}
              </span>
            </>
          )}
        </div>
      )}

      {!group.promo && (
        <div className="group-nav-buttons">
          <div className="group-nav-button">
            <Button
              basic
              size="tiny"
              circular
              onClick={() => handleGroupHistoryClick(false)}
              className={`group-options-btn only-icon`}
            >
              <Icon className="fas fa-fw fa-clock-rotate-left"></Icon>
            </Button>
            <div className="button-description">Games</div>
          </div>
          <div className="group-nav-button">
            <Button
              basic
              size="tiny"
              circular
              onClick={handleModalGroupNameOpen}
              className="group-options-btn only-icon"
            >
              <Icon className="fas fa-fw fa-cog"></Icon>
            </Button>
            <div className="button-description">Settings</div>
          </div>
        </div>
      )}

      {!group.promo && (
        <div className="group-tabs">
          <div
            className={`group-tab ${
              groupTab === Enum.GroupTab.QUICK ? "active" : ""
            }`}
            onClick={() => handleTabChange(Enum.GroupTab.QUICK)}
          >
            <div className="group-tab-heading">
              <Icon className="fas fa-forward-fast"></Icon> Game
            </div>
          </div>
          <div
            className={`group-tab ${
              groupTab === Enum.GroupTab.REPEATING ? "active" : ""
            }`}
            onClick={() => handleTabChange(Enum.GroupTab.REPEATING)}
          >
            <div className="group-tab-heading">
              <Icon className="fas fa-calendar-days"></Icon> Repeating
              {(group.unseenRepeatingCount === 0 ||
                groupTab === Enum.GroupTab.REPEATING) &&
                group.repeatingTournaments?.length > 0 && (
                  <Label color="blue">
                    {group.repeatingTournaments.length}
                  </Label>
                )}
              {group.unseenRepeatingCount > 0 &&
                groupTab === Enum.GroupTab.QUICK && (
                  <Label color="red">New!</Label>
                )}
            </div>
          </div>
        </div>
      )}

      {currentTournament && (
        <ModalStakeInfo
          key={modalStakeInfoKey}
          open={modalStakeInfoOpen}
          close={handleModalStakeInfoClose}
          tournament={currentTournament}
        ></ModalStakeInfo>
      )}
    </div>
  );
}

export default GroupUpper;
