import React, { useEffect, useState } from "react";

import { Image } from "semantic-ui-react";

import { rpsApi } from "../../helpers/api-config";
import Icon from "../Icon/Icon";

const Avatar = ({ user, square, size, onClick }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const defaultImageSrc = `/images/anonymous.jpg`;

  useEffect(() => {
    let isMounted = true;

    const img = new window.Image();
    img.src = `${rpsApi.defaults.baseURL}/account/avatar/${user}.jpg`;
    img.onload = () => {
      if (isMounted) setImageLoaded(true);
    };

    return () => {
      isMounted = false;
    };
  }, [user]);

  return (
    <div className="rps-avatar-wrapper">
      <Image
        className={`rps-avatar ${square ? "rps-avatar-square" : ""}`}
        avatar
        src={
          imageLoaded ?
            `${rpsApi.defaults.baseURL}/account/avatar/${user}.jpg`
          : defaultImageSrc
        }
        size={size}
        onClick={onClick}
      />
      {!imageLoaded && (
        <div className="rps-avatar-loader">
          <Icon className="far fa-fw fa-spin fa-spinner-third"></Icon>
        </div>
      )}
    </div>
  );
};

export default Avatar;
