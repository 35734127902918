export const joyrideTours = {
  groupQuickSetupTour: [
    {
      target: "#tournament-config-stake-input",
      content:
        "Enter what you're playing for here. This can be anything from making breakfast to doing the dishes for a week.",
      disableBeacon: true,
      disableScrolling: true,
      disableScrollParentFix: true,
      floaterProps: {
        placement: "auto",
      }
    },
    {
      target: ".suggestions-btn-wrapper",
      content: "If you need help, tap here to see some suggestions.",
      disableBeacon: true,
      disableScrolling: true,
      disableScrollParentFix: true,
    },
    {
      target: ".component-tournament-rules .tr-value",
      content: "Select how long players will have to join the game, before it starts automatically. You can always start the game early before this time is up.",
      disableBeacon: true,
      disableScrollParentFix: true,
    },
    {
      target: "#joyride-tour-random-btn",
      content: "Tap here to start a game that chooses a player at random.",
      disableBeacon: true,
      disableScrolling: true,
      disableScrollParentFix: true,
    },
    {
      target: "#joyride-tour-rps-btn",
      content:
        "Tap here to set up a game of rock paper scissors. More than 2 players joining will result in a tournament.",
      disableBeacon: true,
      disableScrolling: true,
      disableScrollParentFix: true,
    },
  ],
  groupRepeatingSetupTour: [
    {
      target: ".stake-input",
      content:
        "Enter what you're regularly playing for here. This can be anything from who makes breakfast each morning to who does the dishes each week.",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: ".repeating-config",
      content: "Select how often and when you want the game to repeat. Users only have to join once, then the game will include them automatically each time.",
      disableBeacon: true,
      disableScrollParentFix : true,
        },
    {
      target: "#joyride-tour-repeating-btn",
      content: "Tap here to finish setting up your repeating game by choosing the number of players to select randomly each time.",
      disableBeacon: true,
      disableScrolling: true,
      disableScrollParentFix : true,
    }
  ],
  groupRpsCurrentlyPlayingTour: [
    {
      target: ".match-avatar-right",
      content: "You've been paired up with another player. You can see more about this member by tapping their name or picture.",
      disableBeacon: true,
      disableScrolling: true,
      disableScrollParentFix: true,
    },
    {
      target: ".component-timer",
      content: "This is how long you and your opponent have to move, make sure you don't run out of time!",
      disableBeacon: true,
    },
    {
      target: ".decide-now",
      content: "Tap here to make your move or view your existing move.",
      disableBeacon: true,
      disableScrolling: true,
      disableScrollParentFix : true,
    },
  ],
  groupAwaitingPlayersTour: [
    {
      target: "span.player-count",
      content: "This is how many players have joined the game so far.",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: ".group-members-avatar-stack",
      content: "Tap here to see all group members. You can also see who's joined or declined to join the game.",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: ".component-timer",
      content: "This is how long members have to join the game before it starts.",
      disableBeacon: true,
      disableScrolling: true,
      disableScrollParentFix : true,
    },
    {
      target: ".decide-now",
      content: "Tap here to start the game early with the current players.",
      disableBeacon: true,
      disableScrolling: true,
      disableScrollParentFix: true,
    }
  ]
};
