import React, { useEffect, useState } from "react";

import "./RandomGameDrawTransition.scss";

function RandomGameDrawTransition({
  initialCount = 3,
  secondSpeed = 1000,
  onCountdownEnd,
}) {
  const [count, setCount] = useState(initialCount);

  useEffect(() => {
    if (count === 0) {
      onCountdownEnd();
      return; // Stop further execution
    }

    const timerId = setTimeout(() => setCount(count - 1), secondSpeed);

    return () => clearTimeout(timerId);
  }, [count, onCountdownEnd, secondSpeed]);

  return (
    <div className="random-game-draw-transition">
      <div className="number">{count}</div>
    </div>
  );
}

export default RandomGameDrawTransition;
