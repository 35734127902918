import React from "react";

import "./TextButton.scss";

const TextButton = ({
  className,
  flush,
  inverted,
  onClick,
  disabled,
  children,
  style
}) => {
  return (
    <span
      className={`text-button ${className ? className : ""}
        ${flush ? "flush" : ""}
        ${inverted ? "inverted" : ""}
        ${disabled ? "disabled" : ""}`}
      onClick={(e) => {
        !disabled && onClick(e);
      }}
      style={style}
    >
      {children}
    </span>
  );
};

export default TextButton;
