import React, { useContext } from "react";

import GroupMemberBadge from "./GroupMemberBadge";
import AuthContext from "../../../../contexts/AuthContext";
import Enum from "../../../../helpers/enums";

import "./GroupMemberBadges.scss";

const GroupMemberBadges = ({
  member,
  onClick,
  hideParticipating,
  hideYou,
  currentTournament,
}) => {
  const { authState } = useContext(AuthContext);

  if (!member) return <span>&nbsp;</span>;

  return (
    <span className="group-member-badges" onClick={onClick}>
      {member.isBoss && <GroupMemberBadge type="boss" />}
      {/* {currentTournament?.creatorId === member.accountId && (
        <GroupMemberBadge type="creator" />
      )} */}

      {!hideYou &&
        !member.isBoss &&
        authState.account?.id === member.accountId && (
          <GroupMemberBadge type="you" />
        )}
      {/* {member.repeatingGameCount > 0 && <GroupMemberBadge type="repeating" />} */}

      {!hideParticipating && (
        <>
          {currentTournament?.state === Enum.TournamentState.PREPARE && (
            <>
              {member.isParticipating !== true &&
                member.isParticipating !== false && (
                  <GroupMemberBadge type="waitingrsvp" />
                )}
              {member.isParticipating === true && (
                <GroupMemberBadge type="participant" />
              )}
              {member.isParticipating === false && (
                <GroupMemberBadge type="nonparticipant" />
              )}
            </>
          )}
          {currentTournament?.state !== Enum.TournamentState.PREPARE &&
            member.isParticipating && (
              <>
                {member.isCurrentlyPlaying && (
                  <>
                    {member.matchComplete && (
                      <GroupMemberBadge
                        type="advanced"
                        currentTournament={currentTournament}
                      />
                    )}
                    {!member.matchComplete && (
                      <>
                        {member.hasMoved && <GroupMemberBadge type="moved" />}
                        {!member.hasMoved && (
                          <GroupMemberBadge type="waitingtomove" />
                        )}
                      </>
                    )}
                  </>
                )}
                {!member.isCurrentlyPlaying && (
                  <GroupMemberBadge
                    type="knockedout"
                    currentTournament={currentTournament}
                  />
                )}
              </>
            )}
        </>
      )}

      {/* {!member.isBoss && member.isAssistant && (
        <GroupMemberBadge type="assistant" />
      )}

      {member.chosenLastTournament === true && (
        <GroupMemberBadge type="wonLast" />
      )}

      {member.chosenLastTournament === false && (
        <GroupMemberBadge type="lostLast" />
      )}

      {member.isCurrentlyPlaying && !member.hasMoved && (
        <GroupMemberBadge type="waiting" />
      )}

      {member.isCurrentlyPlaying && member.hasMoved && (
        <GroupMemberBadge type="moved" />
      )} */}

      {member.notMember === true && <GroupMemberBadge type="notMember" />}
    </span>
  );
};

export default GroupMemberBadges;
