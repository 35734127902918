import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import Enum from "./helpers/enums";
import { debounce } from "./utilities";

export const useStore = (store) => {
  const [current, setCurrent] = useState(store.state);

  useEffect(() => {
    const destroy = store.listen(setCurrent);
    return () => destroy();
  }, [store]);

  return current;
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useInfiniteScroll = (onLoadMore) => {
  const scrollRef = useRef(null);
  const loading = useRef(false);
  const disabled = useRef(false);

  const handleScroll = debounce(async () => {
    if (loading.current || disabled.current || !scrollRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

    if (scrollHeight - (scrollTop + clientHeight) <= 50) {
      loading.current = true;
      const result = await onLoadMore();
      loading.current = false;
      if (result === false) {
        disabled.current = true;
      }
    }
  }, 200);

  useEffect(() => {
    const scrollRefCopy = scrollRef.current;

    if (scrollRefCopy) {
      scrollRefCopy.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollRefCopy) {
        scrollRefCopy.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return scrollRef;
};

// CURRENTLY UNUSED AS NON-HOOK COMPONENTS NEED THIS... SEE utilities.js
export const useRepeatHumanString = (tournament) => {
  const [repeatHumanString, setRepeatHumanString] = useState("");

  const time = useMemo(() => {
    if (!tournament?.startTime) return "";
    return moment(tournament.startTime).format("h:mm a");
  }, [tournament.startTime]);

  useEffect(() => {
    const repeatDaysOfWeekStr = () => {
      let str = "";

      if (tournament.repeatEvery === 1) {
        if (tournament.repeatDaysOfWeek === 127)
          // every
          return "day" + (tournament.repeatEvery === 1 ? "" : "s");
        if (tournament.repeatDaysOfWeek === 62)
          // weekdays
          return "weekday";
        if (tournament.repeatDaysOfWeek === 65)
          // weekends
          return "Sat and Sun";
      } else {
        str = tournament.repeatEvery + " weeks on ";
      }

      if (tournament.repeatDaysOfWeek === 65)
        // weekends
        return str + "Sat and Sun";

      let dow = repeatDaysOfWeek();
      if (!dow) return "";

      str += dow
        .map((d) => {
          switch (d) {
            case Enum.RepeatDayOfWeek.SUNDAY:
              return "Sun";
            case Enum.RepeatDayOfWeek.MONDAY:
              return "Mon";
            case Enum.RepeatDayOfWeek.TUESDAY:
              return "Tue";
            case Enum.RepeatDayOfWeek.WEDNESDAY:
              return "Wed";
            case Enum.RepeatDayOfWeek.THURSDAY:
              return "Thu";
            case Enum.RepeatDayOfWeek.FRIDAY:
              return "Fri";
            case Enum.RepeatDayOfWeek.SATURDAY:
              return "Sat";
            default:
              return "Unknown";
          }
        })
        .join(", ");

      return str;
    };

    const repeatDaysOfWeek = () => {
      let dow = [];

      let keys = Object.keys(Enum.RepeatDayOfWeek);

      for (let i = 0; i < keys.length; i++) {
        if (!Object.prototype.hasOwnProperty.call(Enum.RepeatDayOfWeek, keys[i])) continue;
        if (
          (tournament.repeatDaysOfWeek & Enum.RepeatDayOfWeek[keys[i]]) ===
          Enum.RepeatDayOfWeek[keys[i]]
        )
          dow.push(Enum.RepeatDayOfWeek[keys[i]]);
      }

      return dow;
    };

    const repeatDayOfMonth = () => {
      let d = tournament.repeatDayOfMonth;
      return (
        `${d}` +
        (d === 1 || d === 21 || d === 31
          ? "st"
          : d === 2 || d === 22
          ? "nd"
          : d === 3 || d === 23
          ? "rd"
          : "th")
      );
    };

    const repeatPeriodStr = () => {
      switch (tournament.repeatPeriod) {
        case Enum.RepeatPeriod.DAILY:
          return (
            (tournament.repeatEvery === 1 ? "" : tournament.repeatEvery) +
            " day" +
            (tournament.repeatEvery === 1 ? "" : "s")
          );
        case Enum.RepeatPeriod.WEEKLY:
          return repeatDaysOfWeekStr();
        case Enum.RepeatPeriod.MONTHLY:
          return (
            (tournament.repeatEvery === 1 ? "" : tournament.repeatEvery) +
            " month" +
            (tournament.repeatEvery === 1 ? "" : "s") +
            ` on the ${repeatDayOfMonth()}`
          );
        default:
          return "Unknown";
      }
    };

    setRepeatHumanString(`Every ${repeatPeriodStr()} at ${time}`);
  }, [time, tournament]);

  return repeatHumanString;
};
