import React, { useEffect, useState } from "react";
import { Button, Form, Modal, TransitionablePortal } from "semantic-ui-react";

import "./ModalDecisionOptions.scss";
import _ from "underscore";

import { config } from "../../../config";
import Enum from "../../../helpers/enums";
import { usePrevious } from "../../../hooks";
import NumberDropdown from "../../Form/NumberDropdown/NumberDropdown";
import ToggleSwitch from "../../Form/ToggleSwitch/ToggleSwitch";
import Icon from "../../Icon/Icon";
import TextButton from "../../TextButton/TextButton";
import RkoModal from "../RkoModal/RkoModal";

const ModalDecisionOptions = ({
  group,
  tournament,
  onTournamentPrepare,
  open,
  close,
  handleModalAlertOpen,
  handleModalRulesOpen,
}) => {
  const [winnersToDraw, setWinnersToDraw] = useState(tournament.winnersToDraw);
  const [firstToGames, setFirstToGames] = useState(tournament.firstToGames);
  const [matchMaxGames, setMatchMaxGames] = useState(tournament.matchMaxGames);
  const [gameLengthMinutes, setGameLengthMinutes] = useState(
    tournament.gameLengthMinutes,
  );
  const [startingPlayers, setStartingPlayers] = useState(
    config.validation.tournamentRps.maxPlayers.default,
  );
  // eslint-disable-next-line no-unused-vars
  const [isRps, setIsRps] = useState(
    (tournament.gameMode === Enum.TournamentGameMode.INSTANTRPS ||
      tournament.gameMode === Enum.TournamentGameMode.TOURNAMENTRPS) &&
      config.rps.enabled,
  );
  // eslint-disable-next-line no-unused-vars
  const [isRpsTournament, setIsRpsTournament] = useState(true);
  const [stakeIsPositive, setStakeIsPositive] = useState(
    tournament.stakeIsPositive,
  );
  // eslint-disable-next-line no-unused-vars
  const [maxRoundsRequired, setMaxRoundsRequired] = useState(1);
  const [advancedTournamentOptionsOpen, setAdvancedTournamentOptionsOpen] =
    useState(false);

  const [sipWarning, setSipWarning] = useState(false);

  const prepareLengthMinutesOptions = _.flatten([
    [2, 5, 10, 30].map((m) => {
      return {
        key: m,
        text: m + " minutes",
        value: m,
      };
    }),
    [60, 120, 240, 720].map((m) => {
      return {
        key: m,
        text: m / 60 + " hour" + (m / 60 === 1 ? "" : "s"),
        value: m,
      };
    }),
  ]);

  const prepareLengthMinutesText = prepareLengthMinutesOptions.reduce(
    (obj, item) => {
      const text = item.text
        .replace(/minutes?/g, "min")
        .replace(/hours?/g, "hr");
      obj[item.key] = text;
      return obj;
    },
  );

  const prevIsRpsTournament = usePrevious(isRpsTournament);
  const prevFirstToGames = usePrevious(firstToGames);

  useEffect(() => {
    if (firstToGames === prevFirstToGames) return;

    if (matchMaxGames < firstToGames) setMatchMaxGames(firstToGames);
  }, [firstToGames, matchMaxGames, prevFirstToGames]);

  useEffect(() => {
    if (isRpsTournament === prevIsRpsTournament) return;

    let { min, max } =
      config.validation[isRpsTournament ? "tournamentRps" : "instantRps"]
        .maxPlayers;
    if (startingPlayers < min) setStartingPlayers(min);
    if (startingPlayers > max) setStartingPlayers(max);
  }, [isRpsTournament, prevIsRpsTournament, startingPlayers]);

  const handleClose = () => {
    document.body.classList.remove("modal-fade-in");
    close();
  };

  const isFormValid = () => {
    if (isRps) {
      return (
        stakeIsPositive !== null &&
        startingPlayers >= config.validation.tournamentRps.maxPlayers.min &&
        startingPlayers <= config.validation.tournamentRps.maxPlayers.max &&
        firstToGames >= config.validation.tournamentRps.firstToGames.min &&
        firstToGames <= config.validation.tournamentRps.firstToGames.max &&
        matchMaxGames >= config.validation.tournamentRps.matchMaxGames.min &&
        matchMaxGames <= config.validation.tournamentRps.matchMaxGames.max &&
        prepareLengthMinutesOptions.find(
          (o) => o.value === gameLengthMinutes,
        ) !== undefined
      );
    } else {
      return (
        winnersToDraw >= config.validation.winnersToDraw.min &&
        winnersToDraw <= config.validation.winnersToDraw.max
      );
    }
  };

  const handleSave = () => {
    onTournamentPrepare({
      winnersToDraw,
      startingPlayers,
      gameMode:
        isRps ?
          isRpsTournament ? Enum.TournamentGameMode.TOURNAMENTRPS
          : Enum.TournamentGameMode.INSTANTRPS
        : Enum.TournamentGameMode.RANDOM,
      stakeIsPositive,
      firstToGames,
      matchMaxGames,
      gameLengthMinutes,
    });
    handleClose();
  };

  const handleStakeIsPositiveToggle = (value) => {
    setStakeIsPositive(value);
  };

  const onBtnWrapClick = () => {
    if (!isFormValid()) {
      setSipWarning(true);
      handleModalAlertOpen("Unable to play", "Please select prize or penalty.");
    }
  };

  return (
    <TransitionablePortal
      open={open}
      onOpen={() =>
        setTimeout(() => {
          document.body.classList.add("modal-fade-in");
          document.body.classList.add("modal-half-height");
        }, 0)
      }
      transition={{ animation: "fade down", duration: 300 }}
    >
      <RkoModal
        open={true}
        onClose={handleClose}
        className="modal-decision-options modal-half-height"
      >
        <Modal.Content scrolling>
          <Modal.Description>
            <Form className="tournament-config no-margin">
              {isRps && (
                <>
                  <Form.Field className="decision-stake-is-positive">
                    <div className="">
                      <label
                        className={`sip-label ${
                          sipWarning ? "sip-warning" : ""
                        }`}
                      >
                        Playing for prize or penalty?
                      </label>
                      <ToggleSwitch
                        leftText={
                          <span>
                            Penalty<Icon className="fas fa-thumbs-down"></Icon>
                          </span>
                        }
                        rightText={
                          <span>
                            <Icon className="fas fa-thumbs-up"></Icon>Prize
                          </span>
                        }
                        leftColor="#d01919"
                        rightColor="#16ab39"
                        value={stakeIsPositive}
                        onToggle={handleStakeIsPositiveToggle}
                      />
                      {(stakeIsPositive === true ||
                        stakeIsPositive === false) && (
                        <div
                          className={`decision-form-description ${
                            isRps ? "sip-negative" : "sip-positive"
                          }`}
                        >
                          {stakeIsPositive === true ?
                            "Winners move to the next round when playing a tournament"
                          : "Losers move to the next round when playing a tournament"
                          }
                        </div>
                      )}
                    </div>
                  </Form.Field>

                  {isRpsTournament && (
                    <>
                      {!advancedTournamentOptionsOpen && (
                        <>
                          <Form.Field
                            className="advanced-option-summary-field"
                            onClick={() =>
                              setAdvancedTournamentOptionsOpen(true)
                            }
                          >
                            <div className="">
                              {/* <label>Your game</label> */}
                              <div className="decision-form-description advanced-option-summary">
                                <div>
                                  <Icon className="far fa-trophy"></Icon> First
                                  to {firstToGames}{" "}
                                  {stakeIsPositive === false ? "safe" : "wins"}
                                  <Icon className="far fa-clock"></Icon>
                                  {
                                    prepareLengthMinutesText[gameLengthMinutes]
                                  }{" "}
                                  moves
                                  {/* <Icon className="far fa-users"></Icon> Max{" "}
                                  {startingPlayers} players */}
                                  <TextButton
                                    onClick={() =>
                                      setAdvancedTournamentOptionsOpen(true)
                                    }
                                    color="blue"
                                  >
                                    Edit&nbsp;
                                    <Icon className="fas fa-chevron-down"></Icon>
                                  </TextButton>
                                </div>
                              </div>
                              {/* <div className="">
                                <TextButton
                                  onClick={() =>
                                    setAdvancedTournamentOptionsOpen(true)
                                  }
                                  color="blue"
                                >
                                  <Icon className="fas fa-chevron-down"></Icon>
                                  &nbsp; Edit advanced options
                                </TextButton>
                              </div> */}
                            </div>
                          </Form.Field>
                        </>
                      )}
                      {advancedTournamentOptionsOpen && (
                        <>
                          <Form.Field className="">
                            <div className="">
                              <label>Time limit</label>
                              <div className="time-limit-dropdown">
                                <select
                                  value={gameLengthMinutes}
                                  onChange={(e) => {
                                    setGameLengthMinutes(
                                      parseInt(e.target.value, 10),
                                    );
                                  }}
                                >
                                  {prepareLengthMinutesOptions.map((o) => (
                                    <option key={o.value} value={o.value}>
                                      {o.text}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="decision-form-description">
                                How long each player has to move
                              </div>
                            </div>
                          </Form.Field>
                          <Form.Field className="">
                            <div className="">
                              <label>Moves to win</label>
                              <div className="">
                                <NumberDropdown
                                  min={
                                    config.validation.tournamentRps.firstToGames
                                      .min
                                  }
                                  max={
                                    config.validation.tournamentRps.firstToGames
                                      .max
                                  }
                                  startValue={firstToGames}
                                  onChange={setFirstToGames}
                                />
                              </div>
                              <div className="decision-form-description">
                                First player to win <b>{firstToGames}</b>{" "}
                                {firstToGames === 1 ? "move" : "moves"}
                                {stakeIsPositive && " advances to next round"}
                                {!stakeIsPositive &&
                                  " is safe and loser moves one round closer to penalty"}
                              </div>
                            </div>
                          </Form.Field>
                          {/* <Form.Field className="">
                            <div className="">
                              <label>Maximum RPS games</label>
                              <div className="">
                                <NumberDropdown
                                  min={Math.max(
                                    config.validation.tournamentRps
                                      .matchMaxGames.min,
                                    firstToGames
                                  )}
                                  max={
                                    config.validation.tournamentRps
                                      .matchMaxGames.max
                                  }
                                  startValue={matchMaxGames}
                                  onChange={setMatchMaxGames}
                                />
                              </div>
                              <div className="decision-form-description">
                                If <b>{matchMaxGames}</b> RPS{" "}
                                <Plural
                                  value={matchMaxGames}
                                  base="game is"
                                  plural="games are"
                                />{" "}
                                played without a winner, a random winner is
                                chosen.
                              </div>
                            </div>
                          </Form.Field> */}
                          <Form.Field className="">
                            <div className="">
                              <label>Maximum players</label>
                              <div className="">
                                <NumberDropdown
                                  min={
                                    config.validation.tournamentRps.maxPlayers
                                      .min
                                  }
                                  max={
                                    config.validation.tournamentRps.maxPlayers
                                      .max
                                  }
                                  startValue={startingPlayers}
                                  onChange={setStartingPlayers}
                                />
                              </div>
                              <div className="decision-form-description">
                                Maximum amount of players allowed to join
                              </div>
                              <div className="">
                                <br />
                                <TextButton onClick={handleModalRulesOpen}>
                                  Show tournament rules
                                </TextButton>
                                <br />
                                <br />
                                <TextButton
                                  onClick={() =>
                                    setAdvancedTournamentOptionsOpen(false)
                                  }
                                >
                                  <Icon className="fas fa-chevron-up"></Icon>
                                  &nbsp; Hide
                                </TextButton>
                              </div>
                            </div>
                          </Form.Field>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {!isRps && (
                <>
                  <Form.Field className="">
                    <div className="">
                      <label>Players to choose randomly?</label>
                      <div className="">
                        <NumberDropdown
                          min={config.validation.winnersToDraw.min}
                          max={config.validation.winnersToDraw.max}
                          startValue={winnersToDraw}
                          onChange={setWinnersToDraw}
                        />
                      </div>
                      {winnersToDraw > group.playerCount && (
                        <div className="decision-form-description text-orange">
                          You will need at least {winnersToDraw} players to play
                          this game
                        </div>
                      )}
                    </div>
                  </Form.Field>
                </>
              )}
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <div className="action-panel-btn-wrap" onClick={onBtnWrapClick}>
            <Button
              size="big"
              icon
              primary
              onClick={handleSave}
              disabled={!isFormValid()}
              style={{ marginBottom: "15px" }}
            >
              {group.amBoss &&
                (group.finishedGameCount === 0 ||
                  group.groupAccounts.length === 1) && <>Invite new players</>}
              {(!group.amBoss ||
                (group.finishedGameCount >= 1 &&
                  group.groupAccounts.length > 1)) && (
                <>Send invitations</>
              )}{" "}
              <Icon className="far fa-chevron-right"></Icon>
            </Button>
          </div>
        </Modal.Actions>
      </RkoModal>
    </TransitionablePortal>
  );
};

export default ModalDecisionOptions;
