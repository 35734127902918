import React, { useContext, useMemo, useState } from "react";

import { Button, Dimmer, Form, Loader } from "semantic-ui-react";

import Avatar from "../../../components/Avatar/Avatar";
import ConfirmBox from "../../../components/ConfirmBox/ConfirmBox";
import NumberDropdown from "../../../components/Form/NumberDropdown/NumberDropdown";
import Icon from "../../../components/Icon/Icon";
import { config } from "../../../config";
import AuthContext from "../../../contexts/AuthContext";
import { Api } from "../../../helpers/api";
import Enum from "../../../helpers/enums";

function GroupSolo({
  group,
  currentTournament,
  handleResumeSolo,
  handleForfeit,
}) {
  const { authState } = useContext(AuthContext);

  const [firstToGames, setFirstToGames] = useState(
    config.validation.soloVsBot.firstToGames.default,
  );
  const [confirmForfeitOpen, setConfirmForfeitOpen] = useState(false);

  const myMatchAccount = useMemo(() => {
    if (!currentTournament || !currentTournament.myMatch) return null;

    return currentTournament.myMatch.matchAccounts?.find(
      (ma) => ma.accountId === authState?.account?.id,
    );
  }, [currentTournament, authState]);

  const opponentMatchAccount = useMemo(() => {
    if (!currentTournament || !currentTournament.myMatch) return null;

    return currentTournament.myMatch.matchAccounts?.find(
      (ma) => ma.accountId !== authState?.account?.id,
    );
  }, [currentTournament, authState]);

  const onForfeit = () => {
    setConfirmForfeitOpen(true);
  };

  const onForfeitConfirm = () => {
    handleForfeit(currentTournament?.id, authState?.account?.id);
    setConfirmForfeitOpen(false);
  };

  const onForfeitCancel = () => {
    setConfirmForfeitOpen(false);
  };

  const handleResumeGame = () => {
    handleResumeSolo();
  };

  const handleStartGame = () => {
    Api.prepareTournament(
      {
        groupId: group.id,
        soloGame: true,
        gameMode: Enum.TournamentGameMode.TOURNAMENTRPS, // allows the post to work but doesn't actually set the mode
        startMode: Enum.TournamentStartMode.QUICK, // same as comment above
        stake: "",
        firstToGames: firstToGames,
      },
      () => {},
    );
  };

  return (
    <div className="subpage subpage-group-solo page-theme-solo">
      {!group && (
        <Dimmer inverted active>
          <Loader inverted active content="Loading..." />
        </Dimmer>
      )}

      {group && (
        <div id="subpage-content">
          <div className="inner-content">
            <>
              <div className="solo-upper">
                <div className="solo-header">
                  <h1 className="game-mode-heading-color">Play solo</h1>
                </div>

                {group.soloStats && (
                  <div className="solo-stats">
                    <h4>Your stats</h4>
                    <div className="solo-stats-items">
                      <div>
                        <div className="solo-stats-name">Wins</div>
                        <div className="solo-stats-value">
                          {group.soloStats.wins}
                        </div>
                      </div>
                      <div>
                        <div className="solo-stats-name">Losses</div>
                        <div className="solo-stats-value">
                          {group.soloStats.losses}
                        </div>
                      </div>
                    </div>
                    <div className="solo-stats-items">
                      <div>
                        <div className="solo-stats-name">Streak</div>
                        <div className="solo-stats-value">
                          {group.soloStats.winStreak}
                        </div>
                      </div>
                      <div>
                        <div className="solo-stats-name">Best streak</div>
                        <div className="solo-stats-value">
                          {group.soloStats.maxWinStreak}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {currentTournament && (
                  <div className="solo-match-status">
                    <span className="text-green">
                      <Icon className="far fa-fw fa-spin fa-circle-notch"></Icon>
                    </span>
                    &nbsp;&nbsp;Game in progress
                  </div>
                )}
                {!currentTournament && (
                  <div className="solo-match-status">
                    Test your rock paper scissors skill!
                  </div>
                )}

                <div className="solo-vs">
                  <div className="solo-vs-avatar">
                    <Avatar user={authState?.account.id} />
                    <div className="solo-vs-name">You</div>
                  </div>
                  {myMatchAccount && (
                    <div className="solo-vs-score">{myMatchAccount.score}</div>
                  )}
                  <div className="solo-vs-vs">vs</div>
                  {opponentMatchAccount && (
                    <div className="solo-vs-score">
                      {opponentMatchAccount.score}
                    </div>
                  )}
                  <div className="solo-vs-avatar">
                    <Avatar user={config.aiAccountMember.accountId} />
                    <div className="solo-vs-name">RKObot</div>
                  </div>
                </div>

                {currentTournament && (
                  <>
                    <div className="solo-copy">
                      Resume your active game against RKObot, a
                      computer-controlled player who will try its best to defeat
                      you!
                    </div>
                  </>
                )}
                {!currentTournament && (
                  <>
                    <div className="solo-copy">
                      Play a quick game against RKObot, a computer-controlled
                      player who will try its best to defeat you!
                    </div>

                    <div className="solo-options">
                      <Form>
                        <Form.Field className="">
                          <div className="">
                            <label>How many moves to win?</label>
                            <div className="">
                              <NumberDropdown
                                min={
                                  config.validation.soloVsBot.firstToGames.min
                                }
                                max={
                                  config.validation.soloVsBot.firstToGames.max
                                }
                                startValue={firstToGames}
                                onChange={setFirstToGames}
                              />
                            </div>
                            <div className="decision-form-description">
                              First player to win <b>{firstToGames}</b>{" "}
                              {firstToGames === 1 ? "move" : "moves"} wins!
                            </div>
                          </div>
                        </Form.Field>
                      </Form>
                    </div>
                  </>
                )}
              </div>
              <div className="action-panel">
                {currentTournament && (
                  <>
                    <Button
                      icon
                      size="big"
                      primary
                      onClick={handleResumeGame}
                      className="decide-now"
                    >
                      <span>Resume game</span>
                      <Icon className="far fa-chevron-right"></Icon>
                    </Button>
                    <Button icon onClick={onForfeit} className="decide-now">
                      <span>Forfeit game</span>
                      <Icon className="far fa-chevron-right"></Icon>
                    </Button>
                  </>
                )}
                {!currentTournament && (
                  <>
                    <Button
                      icon
                      size="big"
                      primary
                      onClick={handleStartGame}
                      className="decide-now"
                    >
                      <span>Start game</span>
                      <Icon className="far fa-chevron-right"></Icon>
                    </Button>
                  </>
                )}
              </div>

              <ConfirmBox
                content={`Forfeit your match against RKObot? This will be marked as a loss and clear any win streaks.`}
                cancelButton="Cancel"
                confirmButton="Forfeit"
                open={confirmForfeitOpen}
                onCancel={onForfeitCancel}
                onConfirm={onForfeitConfirm}
              ></ConfirmBox>
            </>
            {/*soloView === Enum.SoloView.TOURNAMENTBOARD && (
            <>
              <DecidingInterface
                group={group}
                currentTournament={currentTournament}
                currentRound={currentRound}
                currentMatch={currentMatch}
                currentGame={currentGame}
                rpsEventCurrent={rpsEventCurrent}
                onMove={handleMoveClick}
                onRpsEventContinue={handleRpsEventContinue}
                onCloseBoard={handleCloseBoard}
                handleModalUserManageOpen={handleModalUserManageOpen}
                handleModalInstallAppEnableNotificationsOpen={
                  handleModalInstallAppEnableNotificationsOpen
                }
                handleModalAlertOpen={handleModalAlertOpen}
              />
            </>
              )*/}
          </div>
        </div>
      )}
    </div>
  );
}

export default GroupSolo;
