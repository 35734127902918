import queryString from "query-string";
import React, { useState, useEffect } from "react";

import { Button } from "semantic-ui-react";

import GroupList from "../../components/GroupList/GroupList";
import Icon from "../../components/Icon/Icon";
import ModalGroupAlert from "../../components/Modal/ModalGroupAlert/ModalGroupAlert";
import withAuth from "../../components/withAuth";

import "./GroupsPage.scss";

const GroupsPage = ({
  history,
  location,
  groups,
  groupsLoaded,
  onLoadMoreGroups,
}) => {
  const [header, setHeader] = useState("");
  const [content, setContent] = useState("");
  const [modalGroupAlertOpen, setModalGroupAlertOpen] = useState(false);
  const [modalGroupAlertKey, setModalGroupAlertKey] = useState(new Date());

  const handleModalGroupAlertClose = () => {
    setModalGroupAlertOpen(false);
  };

  useEffect(() => {
    const handleModalGroupAlertOpen = (action, name) => {
      let header, content;
      let decodedName = decodeURIComponent(name);

      switch (action) {
        case "closed":
          header = "Group closed";
          content = `The group "${decodedName}" has been closed.`;
          break;
        case "kicked":
          header = "Removed from group";
          content = `You have been removed from the group "${decodedName}".`;
          break;
        case "left":
          header = "Left group";
          content = `You have left the group "${decodedName}".`;
          break;
        case "joinError":
          header = "Error joining group";
          content = `${decodedName}`;
          break;
        default:
          return;
      }

      setModalGroupAlertKey(new Date());
      setModalGroupAlertOpen(true);
      setHeader(header);
      setContent(content);
    };

    var qs = queryString.parse(location.search);

    if (qs.closed !== undefined) handleModalGroupAlertOpen("closed", qs.closed);
    else if (qs.kicked !== undefined)
      handleModalGroupAlertOpen("kicked", qs.kicked);
    else if (qs.left !== undefined) handleModalGroupAlertOpen("left", qs.left);
    else if (qs.joinError !== undefined)
      handleModalGroupAlertOpen("joinError", qs.joinError);
  }, [location.search]);

  return (
    <>
      <div className="subpage subpage-groups page-theme-friends">
        <div id="subpage-content">
          <div className="inner-content">
            <div className="groups-container">
              <GroupList
                groups={groups}
                groupsLoaded={groupsLoaded}
                onLoadMoreGroups={onLoadMoreGroups}
              ></GroupList>
              <div className="action-panel">
                <Button
                  icon
                  size="big"
                  primary
                  onClick={() => history.push("/g/new")}
                >
                  <span>
                    <Icon className="fas fa-plus"></Icon> New group
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <ModalGroupAlert
          key={"group-alert-" + modalGroupAlertKey}
          open={modalGroupAlertOpen}
          close={handleModalGroupAlertClose}
          header={header}
          content={content}
        />
      </div>
    </>
  );
};

export default withAuth(GroupsPage);
