import React, { useContext, useEffect, useMemo, useState } from "react";

import { Button, Form, Popup } from "semantic-ui-react";

import Avatar from "../../components/Avatar/Avatar";
import EnableNotificationsPromptButton from "../../components/EnableNotificationsPrompt/EnableNotificationsPromptButton";
import Icon from "../../components/Icon/Icon";
import withAuth from "../../components/withAuth";
import AuthContext from "../../contexts/AuthContext";
import { Api } from "../../helpers/api";
import { rpsApi } from "../../helpers/api-config";
import { usePrevious } from "../../hooks";

import "./SettingsPage.scss";

const SettingsPage = ({ onChangeUserName, onChangeTagline }) => {
  const { authState } = useContext(AuthContext);

  const [userNameCheckTimer, setUserNameCheckTimer] = useState(null);
  const [userName, setUserName] = useState(authState?.account?.userName);
  const [userNameAvailable, setUserNameAvailable] = useState(true);
  const [userNameCheckLoading, setUserNameCheckLoading] = useState(false);
  const [userNameStatus, setUserNameStatus] = useState("");
  const [userNameStatusPopup, setUserNameStatusPopup] = useState(false);
  const [userNameChanged, setUserNameChanged] = useState(false);

  const [tagline, setTagline] = useState(authState?.account?.tagline || "");
  const [taglineChanged, setTaglineChanged] = useState(false);

  const [providerData, setProviderData] = useState(null);

  const prevUserName = usePrevious(userName);

  const currentTagline = useMemo(() => {
    return authState?.account?.tagline || "";
  }, [authState?.account?.tagline]);

  const handleUserNameChange = (value) => {
    if (userNameCheckTimer) clearInterval(userNameCheckTimer);

    setUserNameAvailable(false);
    setUserNameCheckLoading(true);
    setUserNameStatus("");
    setUserName(value);
  };

  const handleTaglineChange = (value) => {
    // trim to 50 characters
    if (value.length > 50) value = value.substring(0, 50);
    setTagline(value);
  };

  const getUserNameCheckIcon = () => {
    const iconData =
      userNameCheckLoading ? { className: "fas fa-spinner-third fa-spin" }
      : userNameAvailable ? { className: "fas fa-check", color: "green" }
      : { className: "fas fa-times", color: "red" };

    return (
      <Icon
        key={`username-check-icon-${iconData.className}`}
        embedded
        {...iconData}
      />
    );
  };

  const handleSave = () => {
    if (
      !authState ||
      !authState.account ||
      (userName === authState?.account?.userName && tagline === currentTagline)
    ) {
      return;
    }

    if (userName !== authState?.account?.userName)
      onChangeUserName(userName, () => {
        setUserNameChanged(true);
      });

    if (tagline !== currentTagline)
      onChangeTagline(tagline, () => {
        setTaglineChanged(true);
      });
  };

  useEffect(() => {
    Api.getProviderData().then((result) => {
      setProviderData(result);
    });
  }, []);

  useEffect(() => {
    if (!prevUserName || !userName || prevUserName === userName) return;

    if (userName.length < 3 || userName.length > 16) {
      setUserNameAvailable(false);
      setUserNameCheckLoading(false);
      setUserNameStatus(
        `Username must be ${
          userName.length < 3 ? "at least 3" : "no more than 16"
        } characters.`,
      );
    } else {
      setUserNameCheckTimer(
        setTimeout(() => {
          rpsApi.post(`/account/username`, { userName }).then((result) => {
            setUserNameAvailable(result.data.available);
            setUserNameCheckLoading(false);
            setUserNameStatus(
              result.data.available ?
                "" //This username is available."
              : result.data.error || "An error occurred, please try again",
            );
          });
        }, 700),
      );
    }
  }, [prevUserName, userName]);

  useEffect(() => {
    setUserNameStatusPopup(!!userNameStatus);
  }, [userNameStatus]);

  useEffect(() => {
    return () => {
      if (userNameCheckTimer) clearTimeout(userNameCheckTimer);
    };
  }, [userNameCheckTimer]);

  const ProviderIcon = ({ provider }) => {
    switch (provider) {
      case "Apple":
        return (
          <a title="Apple" className="sso-icon sso-icon-apple">
            <i></i>
          </a>
        );
      case "Google":
        return (
          <a title="Google" className="sso-icon sso-icon-google">
            <i>
              <b></b>
              <b></b>
              <b></b>
              <b></b>
            </i>
          </a>
        );
      case "Microsoft":
        return (
          <a title="Microsoft" className="sso-icon sso-icon-microsoft">
            <i>
              <b></b>
              <b></b>
              <b></b>
              <b></b>
            </i>
          </a>
        );
      case "Facebook":
        return (
          <a title="Facebook" className="sso-icon sso-icon-facebook">
            <i></i>
          </a>
        );
      case "Amazon":
        return (
          <a title="Amazon" className="sso-icon sso-icon-amazon">
            <i></i>
          </a>
        );
      case "GitHub":
        return (
          <a title="GitHub" className="sso-icon sso-icon-github">
            <i></i>
          </a>
        );
      case "LinkedIn":
        return (
          <a title="LinkedIn" className="sso-icon sso-icon-linkedin">
            <i></i>
          </a>
        );
      case "Proton":
        return (
          <a title="Proton (SimpleLogin)" className="sso-icon sso-icon-proton">
            <i></i>
          </a>
        );
      case "Slack":
        return (
          <a title="Slack" className="sso-icon sso-icon-slack">
            <i>
              <b></b>
              <b></b>
              <b></b>
              <b></b>
            </i>
          </a>
        );
      case "Twitter":
        return (
          <a title="X (Twitter)" className="sso-icon sso-icon-twitter">
            <i></i>
          </a>
        );
      case "Yahoo":
        return (
          <a title="Yahoo" className="sso-icon sso-icon-yahoo">
            <i></i>
          </a>
        );
      case "Zoho":
        return (
          <a title="Zoho" className="sso-icon sso-icon-zoho">
            <i></i>
          </a>
        );
      default:
        return <Icon embedded className="fal fa-envelope" />;
    }
  };

  return (
    <div className="subpage subpage-settings">
      <div id="subpage-content">
        <div className="inner-content">
          <div className="settings-container">
            <h3>Account details</h3>

            <div className="account-details-list">
              <div className="provider-icon">
                {providerData?.provider && (
                  <ProviderIcon provider={providerData.provider} />
                )}
              </div>
              {(!providerData || providerData.provider) && (
                <div className="adl-item">
                  <h5>Provider</h5>
                  <p>{providerData?.provider}</p>
                </div>
              )}
              {(!providerData || providerData.name) && (
                <div className="adl-item">
                  <h5>Name</h5>
                  <p>{providerData?.name}</p>
                </div>
              )}
              {(!providerData || providerData.email) && (
                <div className="adl-item">
                  <h5>Email</h5>
                  <p>{providerData?.email}</p>
                </div>
              )}
              <div className="adl-item">
                <h5>Picture</h5>
                <Avatar square={true} user={authState?.account?.id} />
              </div>
            </div>
            <div className="settings-notifications">
              <EnableNotificationsPromptButton
                promptJsx={
                  <>
                    <Icon className="far fa-bell"></Icon> Tap to set up game
                    update
                    <br />
                    and result notifications
                  </>
                }
                enabledJsx={
                  <>
                    <Icon className="far fa-bell"></Icon> Your notifications are
                    enabled
                  </>
                }
              />
            </div>
            <h3>Update account</h3>
            <Form>
              <Form.Field>
                <label>Username</label>
                <Popup
                  content={userNameStatus}
                  position="bottom left"
                  open={!userNameCheckLoading && userNameStatusPopup}
                  size="small"
                  trigger={
                    <Form.Input
                      fluid
                      icon={getUserNameCheckIcon()}
                      iconPosition="left"
                      placeholder="Username"
                      type="text"
                      name="userName"
                      value={userName}
                      onChange={(e) => {
                        let value = e.target.value;
                        handleUserNameChange(value);
                      }}
                    />
                  }
                />
              </Form.Field>

              <Form.Field>
                <label>Tagline</label>
                <Form.Input
                  fluid
                  icon={<Icon embedded className={"far fa-message-lines"} />}
                  iconPosition="left"
                  placeholder="Tagline"
                  type="text"
                  name="tagline"
                  value={tagline}
                  onChange={(e) => {
                    let value = e.target.value;
                    handleTaglineChange(value);
                  }}
                />
              </Form.Field>

              <div className="settings-action">
                <Button
                  primary
                  icon={<Icon embedded className="fas fa-chevron-right" />}
                  labelPosition="right"
                  content="Save"
                  onClick={handleSave}
                  disabled={
                    !userNameAvailable ||
                    (tagline === currentTagline &&
                      userName === authState?.account?.userName)
                  }
                />
                {userNameChanged && (
                  <div className="settings-saved">
                    <Icon embedded className="fas fa-check" color="green" />
                    <span>Username saved</span>
                  </div>
                )}

                {taglineChanged && (
                  <div className="settings-saved">
                    <Icon embedded className="fas fa-check" color="green" />
                    <span>Tagline saved</span>
                  </div>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuth(SettingsPage);
