import React, { useContext, useEffect, useState } from "react";

import { Button } from "semantic-ui-react";

import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import Icon from "../../components/Icon/Icon";
import NotificationsList from "../../components/NotificationsList/NotificationsList";
import withAuth from "../../components/withAuth";
import AuthContext from "../../contexts/AuthContext";

import "./NotificationsPage.scss";

const NotificationsPage = ({
  loadAccountNotifications,
  clearAccountNotifications,
  notificationsLoaded,
}) => {
  const { authState } = useContext(AuthContext);

  const [confirmClearAll, setConfirmClearAll] = useState(false);

  const handleClearAllConfirm = () => {
    setConfirmClearAll(false);
    clearAccountNotifications();
  };

  const handleClearAllCancel = () => {
    setConfirmClearAll(false);
  };

  const handleClearAll = () => {
    setConfirmClearAll(true);
  };

  useEffect(() => {
    loadAccountNotifications();
  }, []);

  return (
    <>
      <div className="subpage subpage-notifications">
        <div id="subpage-content">
          <div className="inner-content">
            <div className="notifications-container">
              <NotificationsList
                notificationsLoaded={notificationsLoaded}
                notifications={authState?.account?.notifications}
              ></NotificationsList>
              <div className="action-panel">
                <Button
                  icon
                  onClick={handleClearAll}
                  disabled={
                    !notificationsLoaded ||
                    !authState ||
                    !authState.account ||
                    !authState.account.notifications ||
                    authState.account.notifications.length === 0
                  }
                >
                  <span>
                    <Icon className="fas fa-trash"></Icon> Clear all
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmBox
        open={confirmClearAll}
        content={
          <div className="content">
            Are you sure you want to clear all your notifications? This cannot
            be undone.
          </div>
        }
        cancelButton="Cancel"
        confirmButton="Confirm"
        onConfirm={handleClearAllConfirm}
        onCancel={handleClearAllCancel}
      />
    </>
  );
};

export default withAuth(NotificationsPage);
