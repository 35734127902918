import React from "react";

const AuthContext = React.createContext({
  authState: {
    isLoading: true,
    isAuthenticated: false,
    account: null,
  },
});

export default AuthContext;
