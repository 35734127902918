import moment from "moment";

import Match from "./Match";
import { config } from "../config";
import Enum from "../helpers/enums";

class Round {
  // static functions as state is immutable
  static playersToBracket(round) {
    if (round.playersCount <= 2) return "Final";
    else if (round.roundNumber === 1) return "Round 1";
    //else if (round.playersCount <= 4) return "Semi Finals";
    //else if (round.playersCount <= 8) return "Quarter Finals";
    else return "Round " + round.roundNumber;
  }

  static fromRoundStart(startRound) {
    const newRound = new Round();

    newRound.matchAccountList = startRound.matchAccountList;
    newRound.id = startRound.id;
    newRound.state = Enum.RoundState.ACTIVE;
    newRound.isFinalRound = startRound.matchesCount === 1;
    newRound.endTime = moment(startRound.endTime);
    newRound.nextRoundBegins = moment(startRound.nextRoundBegins);
    newRound.matchesComplete = 0;
    newRound.matchesCount = startRound.matchesCount;
    newRound.advancersCount = 0;
    newRound.leaversCount = 0;
    newRound.playersCount = startRound.playersCount;
    newRound.playersRemaining = startRound.playersCount;
    newRound.advancers = [];
    newRound.leavers = [];
    newRound.matches = startRound.matches;
    newRound.roundNumber = startRound.roundNumber;
    newRound.prevRoundMatches = startRound.prevRoundMatches;
    newRound.title = this.playersToBracket(newRound);
    newRound.titleSingle = newRound.title.endsWith("s")
      ? newRound.title.slice(0, -1)
      : newRound.title;

    newRound.nextRoundCalendar = newRound.nextRoundBegins
      ? moment(newRound.nextRoundBegins).calendar(
          null,
          config.formatting.nextRoundBegins
        )
      : null;

    return newRound;
  }

  static fromTournamentInit(round, matchAccounts) {
    if (!round) return null;

    var newRound = new Round();

    newRound.matchAccountList = matchAccounts;
    newRound.advancersCount = round.advancersCount;
    newRound.state = round.state;
    newRound.id = round.id;
    newRound.isFinalRound = round.isFinalRound;
    newRound.endTime = moment(round.endTime);
    newRound.nextRoundBegins = moment(round.nextRoundBegins);
    newRound.matchesComplete = round.matchesComplete;
    newRound.matchesCount = round.matchesCount;
    newRound.playersCount = round.playersCount;
    newRound.playersRemaining = round.playersRemaining;
    newRound.roundNumber = round.roundNumber;
    newRound.matches = round.matches;
    newRound.prevRoundMatches = round.prevRoundMatches;
    newRound.title = this.playersToBracket(newRound);
    newRound.titleSingle = newRound.title.endsWith("s")
      ? newRound.title.slice(0, -1)
      : newRound.title;

    newRound.nextRoundCalendar = newRound.nextRoundBegins
      ? moment(newRound.nextRoundBegins).calendar(
          null,
          config.formatting.nextRoundBegins
        )
      : null;

    return newRound;
  }

  static patchRoundFinishedEvent(prevRound, data) {
    const patchedRound = Object.assign(new Round(), {
      ...prevRound,
      ...data,
      state: Enum.RoundState.COMPLETE,
    });
    return patchedRound;
  }

  static patchRoundProgressedEvent(prevRound, data) {
    const patchedRound = Object.assign(new Round(), { ...prevRound });

    patchedRound.playersRemaining = data.playersRemaining;
    patchedRound.matchesComplete++;

    if (data.matchProgress && patchedRound.matches) {
      let matchIdx = patchedRound.matches.findIndex(
        (m) => m.id === data.matchProgress.id
      );

      if (matchIdx === -1) return patchedRound;

      if (data.matchProgress.scores) {
        patchedRound.matches[matchIdx] = Match.patchMatchScoreUpdateEvent(
          patchedRound.matches[matchIdx],
          {
            matchAccountScores: data.matchProgress.scores,
          }
        );
      } else {
        patchedRound.matches[matchIdx] = Match.patchMatchFinishedEvent(
          patchedRound.matches[matchIdx],
          { ...data.matchProgress }
        );
      }
    }

    // TODO for small sized round score updates
    // if (data.matchProgress) {
    //   for (var i = 0; i < data.matchProgress)
    //   let matchIdx = patchedRound.matches.findIndex((m) => m.id === data.id);
    //   if (matchIdx === -1) return patchedRound;

    //   const patchFn = data.matchProgress
    //     ? Match.patchMatchScoreUpdateEvent
    //     : Match.patchMatchFinishedEvent;

    //   patchedRound.matches[matchIdx] = patchFn(
    //     patchedRound.matches[matchIdx],
    //     data
    //   );
    // }

    return patchedRound;
  }
}

export default Round;
