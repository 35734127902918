import React from "react";

import "./MoveHistory.scss";
import Enum from "../../../../helpers/enums";
import Plural from "../../../Plural/Plural";

const MoveHistory = ({ moves, isOpponent }) => {
  const altText = (moveType) => {
    switch (moveType) {
      case Enum.MoveType.FORFEIT:
        return "Forfeit";
      case Enum.MoveType.ROCK:
        return "Rock";
      case Enum.MoveType.PAPER:
        return "Paper";
      case Enum.MoveType.SCISSORS:
        return "Scissors";
      default:
        return "";
    }
  };

  return (
    <div
      className={`component-move-history ${
        isOpponent ? "is-opponent" : "is-player"
      }`}
    >
      {moves && (
        <>
          {moves.map((move) => (
            <div key={move.id} className={`move`}>
              {move.moveType !== Enum.MoveType.FORFEIT && (
                <img
                  src={`/images/game/${move.moveType}${
                    move.result !== Enum.ResultType.DRAW && !isOpponent ?
                      "-" + move.result
                    : ""
                  }.png`}
                  alt={altText(move.moveType)}
                />
              )}
              {move.moveType === Enum.MoveType.FORFEIT && (
                <img
                  src={`/images/game/forfeit.png`}
                  alt={altText(move.moveType)}
                />
              )}
            </div>
          ))}
          <div className="title">
            Last {moves.length > 1 ? moves.length : ""}{" "}
            <Plural value={moves.length} base="move"></Plural>
          </div>
        </>
      )}
    </div>
  );
};

export default MoveHistory;
