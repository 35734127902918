import React, { useEffect, useState } from "react";
import {
  BrowserView,
  MobileView,
  isChrome,
  isFirefox,
  isEdge,
  isChromium,
  isSafari,
} from "react-device-detect";
import { Button, Image, Modal, TransitionablePortal } from "semantic-ui-react";

import Icon from "../../Icon/Icon";

import "./ModalUnblockNotifications.scss";
import RkoModal from "../RkoModal/RkoModal";

const ModalUnblockNotifications = ({ open, close }) => {
  const [installedApp, setInstalledApp] = useState(false);

  const handleClose = () => {
    document.body.classList.remove("modal-fade-in");
    close();
  };

  useEffect(() => {
    if (
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone
    ) {
      setInstalledApp(true);
    }
  }, []);

  return (
    <TransitionablePortal
      open={open}
      onOpen={() =>
        setTimeout(() => document.body.classList.add("modal-fade-in"), 0)
      }
      transition={{ animation: "fade down", duration: 300 }}
    >
      <RkoModal
        size="small"
        centered={false}
        open={true}
        onClose={handleClose}
        className="modal-unblock-notifications modal-half-height"
      >
        <Modal.Header>How to unblock notifications</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <MobileView>
              {installedApp && (
                <>
                  {(isEdge || isChromium || isChrome) && (
                    <>
                      <ol>
                        <li>Open your phone's settings</li>
                        <li>Go to the Apps section</li>
                        <li>
                          Tap "randomKO" in the list, you may need to tap "See
                          all apps" if the list is truncated
                        </li>
                        <li>Tap "Permissions"</li>
                        <li>
                          If "Notifications" is in the "Not allowed" section,
                          tap it
                        </li>
                        <li>
                          Turn on the slider for "All randomKO notifications" or
                          similar
                        </li>
                        <li>
                          Ensure no other settings in here are toggled off
                        </li>
                        <li>Return to the randomKO app and try again</li>
                      </ol>
                    </>
                  )}

                  {isSafari && (
                    <>
                      <ol>
                        <li>Go to your phone's settings</li>
                        <li>Tap "Notifications"</li>
                        <li>Scroll down the list of apps and tap "randomKO"</li>
                        <li>Ensure "Allow Notifications" is toggled on</li>
                      </ol>
                    </>
                  )}

                  {isFirefox && (
                    <>
                      <ol>
                        <li>
                          Open randomKO in the full Firefox browser for further
                          instructions on how to unblock notifications
                        </li>
                        <li>
                          Once notifications have been unblocked and enabled
                          there, you will be able to continue using the
                          installed version
                        </li>
                        <li>
                          If you can't find a notifications option to unblock,
                          you may just need to tap "Enable notifications" again.
                        </li>
                      </ol>
                    </>
                  )}
                </>
              )}
              {!installedApp && (
                <>
                  {(isEdge || isChromium || isChrome) && (
                    <>
                      <ol>
                        <li>
                          Tap the settings icon to the left of the address bar
                          at the top of your screen.{" "}
                          <Image
                            src={`/images/notifications/chrome-mobile.png`}
                          ></Image>{" "}
                        </li>
                        <li>
                          Tap the Permissions option.{" "}
                          <Image
                            src={`/images/notifications/chrome-mobile-2.png`}
                          ></Image>
                        </li>
                        <li>
                          Tap the Reset Permissions button.{" "}
                          <Image
                            src={`/images/notifications/chrome-mobile-3.png`}
                          ></Image>
                        </li>
                        <li>
                          Refresh the page then tap "Enable notifications"
                          again.
                        </li>
                        <li>
                          <b>
                            If the Permissions option or Notifications option is
                            not present, and you have previously uninstalled the
                            installed version of the randomKO app, you may need
                            to re-install the app from the main menu in order to
                            enable notifications again.
                          </b>
                        </li>
                      </ol>
                    </>
                  )}
                  {isFirefox && (
                    <>
                      <ol>
                        <li>
                          Tap the lock icon to the left of the address bar at
                          the bottom of your screen.{" "}
                          <Image
                            src={`/images/notifications/firefox-mobile.png`}
                          ></Image>{" "}
                        </li>
                        <li>
                          Tap the Blocked text next to the Notifications line
                          item to switch it to Allowed.{" "}
                          <Image
                            src={`/images/notifications/firefox-mobile-2.png`}
                          ></Image>
                        </li>
                        <li>
                          <b>If there is no Notifications item here:</b> You may
                          just need to tap "Enable notifications" again.
                        </li>
                        <li>
                          Tap Continue below. The page may refresh. Tap the
                          Enable Notifications button if it appears again. If it
                          doesn't, notifications have been successfully enabled
                          already.
                        </li>
                      </ol>
                    </>
                  )}
                  {!isEdge && !isChromium && !isChrome && !isFirefox && (
                    <>
                      <ol>
                        <li>Open your web browser's settings.</li>
                        <li>
                          Tap an option related to Permissions, Security or
                          Privacy.
                        </li>
                        <li>
                          Remove any blocks on the "Notifications" permission.
                        </li>
                        <li>Refresh the page and try again.</li>
                        <li>
                          For more information, please do a web search for how
                          to unblock notifications permissions for your
                          particular device.
                        </li>
                      </ol>
                    </>
                  )}
                </>
              )}
            </MobileView>
            <BrowserView>
              {(isEdge || isChromium || isChrome) && (
                <>
                  <ol>
                    <li>
                      Click the settings icon to the left of the address bar.{" "}
                      <Image
                        src={`/images/notifications/chrome-toolbar.png`}
                      ></Image>{" "}
                    </li>
                    <li>
                      Click the Reset Permissions button.{" "}
                      <Image
                        src={`/images/notifications/chrome-toolbar-2.png`}
                      ></Image>
                    </li>
                    <li>
                      Back on the site, click "Enable notifications" in the red
                      box.
                    </li>
                  </ol>
                </>
              )}
              {isFirefox && (
                <>
                  <ol>
                    <li>
                      Click the permissions icon to the left of the address bar.{" "}
                      <Image
                        src={`/images/notifications/firefox-toolbar.png`}
                      ></Image>
                    </li>
                    <li>
                      Click the 'X' next to the word "Blocked" on the "Send
                      notifications" listing.{" "}
                      <Image
                        src={`/images/notifications/firefox-toolbar-2.png`}
                      ></Image>
                    </li>
                    <li>
                      Click Continue below, then click "Enable notifications" in
                      the red box.
                    </li>
                  </ol>
                </>
              )}
              {!isEdge && !isChromium && !isChrome && !isFirefox && (
                <>
                  <ol>
                    <li>Open your web browser's settings.</li>
                    <li>
                      Select an option related to Permissions, Security or
                      Privacy.
                    </li>
                    <li>
                      Remove any blocks on the "Notifications" permission.
                    </li>
                    <li>
                      For more information, please do a web search for how to
                      unblock notifications permissions for your particular
                      browser.
                    </li>
                  </ol>
                </>
              )}
            </BrowserView>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            icon={<Icon embedded className="fas fa-chevron-right" />}
            labelPosition="right"
            content={"Continue"}
            onClick={handleClose}
          ></Button>
        </Modal.Actions>
      </RkoModal>
    </TransitionablePortal>
  );
};

export default ModalUnblockNotifications;
