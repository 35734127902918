import React from "react";

import { fillOptions } from "../../../utilities";

import "./NumberDropdown.scss";

const NumberDropdown = ({ min, max, startValue, onChange }) => {
  const options = fillOptions(min, max);

  const handleChange = (data) => {
    var numValue = parseInt(data, 10);
    onChange(numValue);
  };

  return (
    <div className="number-dropdown">
      <div className="select-wrap">
        <select
          value={startValue}
          onChange={(e) => handleChange(e.target.value)}
        >
          {options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.text}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default NumberDropdown;
