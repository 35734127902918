import { LogLevel } from "@microsoft/signalr";

export const DEBUG = {
  _ENABLED: true,

  deciding: true,
  rpshub: true,
  eventqueue: true,
  boardoverlay: true,
  n10n: false,
  app: false,
  group: false,
  touch: false,
  auth: false,

  hubLogLevel: LogLevel.None
};

export const debugLog = (channel, ...args) => {
  if (DEBUG._ENABLED && DEBUG[channel]) {
    console.log(`[${channel}]`, ...args);
  }
};

export const debugGroup = (channel, ...args) => {
  if (DEBUG._ENABLED && DEBUG[channel]) {
    console.group(`[${channel}]`, ...args);
  }
};

export const debugGroupEnd = (channel) => {
  if (DEBUG._ENABLED && DEBUG[channel]) {
    console.groupEnd();
  }
};

export const createDebugLogger = (channel) => {
  return {
    log: (...args) => debugLog(channel, ...args),
    logGroup: (...args) => debugGroup(channel, ...args),
    logGroupEnd: () => debugGroupEnd(channel),
  };
};
