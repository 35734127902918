import React from "react";

import RepeatingViewConfigure from "./RepeatingViewConfigure";
import RepeatingViewDefault from "./RepeatingViewDefault";
import RepeatingViewDetail from "./RepeatingViewDetail";
import Enum from "../../../../../helpers/enums";

function GroupLowerRepeating({
  groupView,
  group,
  tournamentPreparing,
  viewingRepeatingId,
  viewingRepeatingGame,
  newRepeatingDecisionValid,
  handleJoinTournamentViewing,
  handleLeaveTournamentViewing,
  handleViewRepeatingTournament,
  handleNewRepeatingGame,
  handleConfigClick,
  handleConfigCancel,
  handleConfigChange,
  handleModalDecisionParticipantsOpen,
  handleViewRepeatingTournamentCancel,
  handlePrepareClick,
  handleCancelClick,
  handleModalInstallAppEnableNotificationsOpen,
  handleModalAlertOpen,
  handleJoyrideTourOpen,
}) {
  return (
    <div className="group-lower">
      {groupView === Enum.GroupView.DEFAULT && (
        <RepeatingViewDefault
          group={group}
          handleViewRepeatingTournament={handleViewRepeatingTournament}
          handleNewRepeatingGame={handleNewRepeatingGame}
        />
      )}

      {groupView === Enum.GroupView.VIEWREPEATING &&
        viewingRepeatingId &&
        viewingRepeatingGame && (
          <RepeatingViewDetail
            group={group}
            viewingRepeatingGame={viewingRepeatingGame}
            handleConfigClick={handleConfigClick}
            handleModalDecisionParticipantsOpen={
              handleModalDecisionParticipantsOpen
            }
            handleJoinTournamentViewing={handleJoinTournamentViewing}
            handleLeaveTournamentViewing={handleLeaveTournamentViewing}
            handleViewRepeatingTournamentCancel={
              handleViewRepeatingTournamentCancel
            }
            handleModalInstallAppEnableNotificationsOpen={
              handleModalInstallAppEnableNotificationsOpen
            }
          />
        )}

      {groupView === Enum.GroupView.CONFIGURE && (
        <RepeatingViewConfigure
          group={group}
          viewingRepeatingId={viewingRepeatingId}
          tournamentPreparing={tournamentPreparing}
          handleConfigCancel={handleConfigCancel}
          handleConfigChange={handleConfigChange}
          handlePrepareClick={handlePrepareClick}
          handleCancelClick={handleCancelClick}
          newRepeatingDecisionValid={newRepeatingDecisionValid}
          handleModalAlertOpen={handleModalAlertOpen}
          handleJoyrideTourOpen={handleJoyrideTourOpen}
        />
      )}
    </div>
  );
}

export default GroupLowerRepeating;
