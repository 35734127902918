const Enum = {
  DebugChannel: {
    APP: 'app',
    DECIDING: 'deciding',
    RPSHUB: 'rpshub',
    EVENTQUEUE: 'eventqueue',
    BOARDOVERLAY: 'boardoverlay',
    N10N: 'n10n',
    GROUP: 'group',
    TOUCH: 'touch',
    AUTH: 'auth',
  },
  MoveType: {
    FORFEIT: 0,
    ROCK: 1,
    PAPER: 2,
    SCISSORS: 3,
    HIDDEN: 4,
  },

  GroupRole: {
    PLAYER: 3,
  },

  ResultType: {
    DRAW: 0,
    WIN: 1,
    LOSS: 2,
  },

  GroupView: {
    DEFAULT: 0,
    HISTORY: 1,
    CONFIGURE: 2,
    VIEWREPEATING: 3,
    TOURNAMENTBOARD: 4
  },

  SoloView: {
    DEFAULT: 0,
    TOURNAMENTBOARD: 1
  },

  GroupTab: {
    QUICK: 0,
    REPEATING: 1
  },

  HistoryTab: {
    ALL: null,
    QUICK: 0,
    REPEATING: 1,
  },

  DecidingView: {
    LOADING: -1,
    NOT_IN_DECISION: 0,
    KNOCKED_OUT: 1,
    STILL_IN: 2,
    ACTIVE_MATCH: 3,
  },

  BoardOverlayView: {
    LOADING: -1,
    HIDE: 0,
    DECISION_START: 1,
    ROUND_START: 2,
    MATCH_PROGRESS: 3,
    ROUND_END: 4,
    DECISION_END: 5,
    DECISION_CANCELLED: 6,
    KNOCKED_OUT: 7,
    WAITING_FOR_ROUND: 8,
    MATCH_END: 9,
    TOURNAMENT_WON: 10
  },

  TournamentState: {
    REPEATING: 0,
    PREPARE: 1,
    ACTIVE: 2,
    COMPLETE: 3,
    CANCELLED: 4
  },

  RepeatPeriod: {
    DAILY: 1,
    WEEKLY: 2,
    MONTHLY: 3
  },

  RepeatDayOfWeek: {
    SUNDAY: 1,
    MONDAY: 2,
    TUESDAY: 4,
    WEDNESDAY: 8,
    THURSDAY: 16,
    FRIDAY: 32,
    SATURDAY: 64,
  },

  TournamentGameMode: {
    RANDOM: 0,
    INSTANTRPS: 1,
    TOURNAMENTRPS: 2
  },

  TournamentStartMode: {
    QUICK: 0,
    REPEATING: 1
  },

  PromoState: {
    ENTRIES_OPEN: 0,
    ENTRIES_CLOSED: 1,
    PROMO_STARTING: 2,
    PROMO_STARTED: 3,
    PROMO_ENDING: 4,
    PROMO_ENDED: 5,
  },

  RoundState: {
    PLANNED: 0,
    ACTIVE: 1,
    ENDING: 2,
    COMPLETE: 3
  },

  EndReason: {
    GAME_EXPIRED: 2,
    COULDNT_START: 9,
    BYE: 10,
    SKIPPED_FINAL_DUE_TO_RKOBOT: 11,
    SKIPPED_FINAL_DUE_TO_FORFEITS: 12
  },

  LoginResult: {
    NEW_ACCOUNT: 1,
    EXISTING_ACCOUNT: 2
  },

  GroupListMatchPosition: {
    NO_MATCH: 0,
    TOP: 1,
    BOTTOM: 2,
  }
};

export default Enum;
