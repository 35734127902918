import React, { useContext } from "react";

import AuthContext from "../contexts/AuthContext";

const withAuth = (AuthComponent, adminOnly = false) => {
  return function authWrap(props) {
    const { authState } = useContext(AuthContext);
    const { bypassAuthCheck } = props;

    if (bypassAuthCheck) {
      return <AuthComponent {...props} />;
    }

    return authState?.account && (!adminOnly || authState.isRkoAdmin) ?
        <AuthComponent {...props} />
      : null;
  };
};

export default withAuth;
