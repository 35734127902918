import moment from "moment";
import React, { useEffect, useState } from "react";
import { Header } from "semantic-ui-react";
import _ from "underscore";

const TournamentRules = ({ tournament, onConfigChange, startTimeValidity }) => {
  const [startHours, setStartHours] = useState(9);
  const [startMinutes, setStartMinutes] = useState(0);
  const [startAmPm, setStartAmPm] = useState("am");

  const currentDate = new Date().toISOString().slice(0, 10);
  const [startDateObj, setStartDateObj] = useState(currentDate);

  const hoursOptions = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((h) => {
    return {
      key: h,
      text: h,
      value: h,
    };
  });
  const minutesOptions = _.range(60).map((h) => {
    return {
      key: h,
      text: h < 10 ? "0" + h : h,
      value: h,
    };
  });
  const amPmOptions = ["am", "pm"].map((h) => {
    return {
      key: h,
      text: h,
      value: h,
    };
  });

  const prepareLengthMinutesOptions = _.flatten([
    [2, 5, 15].map((m) => {
      return {
        key: m,
        text: m + " minutes",
        value: m,
      };
    }),
    [60, 120, 360, 1440].map((m) => {
      return {
        key: m,
        text: m / 60 + " hour" + (m / 60 === 1 ? "" : "s"),
        value: m,
      };
    }),
  ]);

  useEffect(() => {
    let h = tournament.startTime.hour();

    setStartDateObj(tournament.startTime.format("YYYY-MM-DD"));
    setStartMinutes(tournament.startTime.minute());
    setStartHours(h === 0 ? 12 : h > 12 ? h - 12 : h);
    setStartAmPm(h < 12 ? "am" : "pm");
  }, [tournament.startTime]);

  const handleHoursChange = (val) => {
    val = parseInt(val);

    setStartHours(val);

    let h =
      startAmPm === "am" ? (val === 12 ? 0 : val) : val === 12 ? val : val + 12;

    let newTime = new moment(tournament.startTime);
    newTime.hours(h);
    onConfigChange({ startTime: newTime });
  };

  const handleMinutesChange = (val) => {
    val = parseInt(val);

    setStartMinutes(val);
    let newTime = new moment(tournament.startTime);
    newTime.minutes(val);
    onConfigChange({
      startTime: newTime,
    });
  };

  const handleAmPmChange = (val) => {
    setStartAmPm(val);
    onConfigChange({
      startTime: new moment(tournament.startTime).hours(
        val === "am"
          ? startHours === 12
            ? 0
            : startHours
          : startHours === 12
          ? 12
          : startHours + 12
      ),
    });
  };

  const dateRegex = new RegExp(/^\d{4}-\d{2}-\d{2}$/);
  const handleDateChange = (val) => {
    if (!val.match(dateRegex)) return;

    setStartDateObj(val);

    let h =
      startAmPm === "am"
        ? startHours === 12
          ? 0
          : startHours
        : startHours === 12
        ? startHours
        : startHours + 12;

    onConfigChange({
      startTime: new moment(val).hours(h).minutes(startMinutes),
    });
  };

  return (
    <div className="component-tournament-rules">
          <Header as="h3">
            Time for players to join?
            {/* <HelpNode
              content={
                "Choose how long players will have to join the decision before it begins. You can reschedule this later."
              }
            ></HelpNode> */}
          </Header>
          <div className="tr-value">
            {/* <Dropdown
                  scrolling
                  inline
                  options={prepareLengthMinutesOptions}
                  value={tournament.prepareLengthMinutes}
                  onChange={(_, value) =>
                    onConfigChange({
                      prepareLengthMinutes: parseInt(value.value, 10),
                    })
                  }
                /> */}
            <div className="big-select-wrap">
              <select
                value={
                  tournament.prepareLengthMinutes === -1
                    ? "custom"
                    : tournament.prepareLengthMinutes
                }
                onChange={(e) =>
                  onConfigChange({
                    prepareLengthMinutes:
                      e.target.value === "custom"
                        ? -1
                        : parseInt(e.target.value, 10),
                  })
                }
              >
                {prepareLengthMinutesOptions.map((o) => (
                  <option key={o.value} value={o.value}>
                    {o.text}
                  </option>
                ))}
                <option key="custom" value="custom">
                  Choose date and time
                </option>
              </select>
            </div>
          </div>
          {tournament.prepareLengthMinutes === -1 && (
            <div style={{width: '250px', margin: '0 auto'}}>
              <div className="tr-value">
                <input
                  type="date"
                  className="tournament-rules-custom-date"
                  value={startDateObj}
                  onChange={(ev) => {
                    handleDateChange(ev.target.value);
                  }}
                />
              </div>
              <div className="tr-time">
                <select
                  className=""
                  value={startHours}
                  onChange={(ev) => {
                    handleHoursChange(ev.target.value);
                  }}
                >
                  {hoursOptions.map((x) => (
                    <option key={x.value} value={x.value}>
                      {x.text}
                    </option>
                  ))}
                </select>
                <b>:</b>
                <select
                  className=""
                  value={startMinutes}
                  onChange={(ev) => {
                    handleMinutesChange(ev.target.value);
                  }}
                >
                  {minutesOptions.map((x) => (
                    <option key={x.value} value={x.value}>
                      {x.text}
                    </option>
                  ))}
                </select>
                <select
                  className=""
                  value={startAmPm}
                  onChange={(ev) => {
                    handleAmPmChange(ev.target.value);
                  }}
                >
                  {amPmOptions.map((x) => (
                    <option key={x.value} value={x.value}>
                      {x.text}
                    </option>
                  ))}
                </select>
              </div>
              {startTimeValidity !== 0 && (
                <div className="time-validation small text-red">
                  {startTimeValidity === -1
                    ? "Start time must be in the future."
                    : "Start time must be within 2 months."}
                </div>
              )}
            </div>
          )}
          {/* <Button
                basic
                onClick={() => {
                  onConfigChange({
                    startMode: Enum.TournamentStartMode.DATETIME,
                    startTime: tournament.startTime
                      ? tournament.startTime
                      : moment().add(1, "hour"),
                  });
                }}
              >
                <Icon className="fal fa-calendar"></Icon> Select a time and date
                instead
              </Button> */}
        {/* <Grid.Column>
          <Header as="h5">
            Wins to<br />{tournament.stakeIsPositive ? "move forward" : "be safe"}
          </Header>
          <div className="tr-value">
            <Dropdown
              scrolling
              inline
              options={firstToGamesOptions}
              value={firstToGames}
              onChange={(_, value) =>
                setFirstToGames(parseInt(value.value, 10))
              }
            />
          </div>
        </Grid.Column>
        <Grid.Column>
          <Header as="h5">Time to make move before forfeiting</Header>
          <div className="tr-value">
            <Duration
              totalMinutes={gameLengthMinutes}
              hourLabel="h"
              minuteLabel="m"
              suffix=" "
              onSave={(value) => setGameLengthMinutes(value)}
            />
          </div>
        </Grid.Column>
        <Grid.Column>
          <Header as="h5">Maximum moves before tie-break</Header>
          <div className="tr-value">
            <Dropdown
              scrolling
              inline
              options={matchMaxGamesOptions}
              value={matchMaxGames}
              onChange={(_, value) =>
                setMatchMaxGames(parseInt(value.value, 10))
              }
            />
          </div>
        </Grid.Column> */}
    </div>
  );
};

export default TournamentRules;
