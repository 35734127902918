import React, { useContext } from "react";
import { RWebShare } from "react-web-share";

import AuthContext from "../../contexts/AuthContext";
import Enum from "../../helpers/enums";

const ShareButton = ({ group, currentTournament, children }) => {
  const { authState } = useContext(AuthContext);

  const data =
    group ?
      {
        text:
          `You've been invited to join ${authState?.account?.userName}'s randomKO group: ${group?.name}. ` +
          (currentTournament ?
            `\r\n\r\nPlaying${
              (
                currentTournament.gameMode ===
                Enum.TournamentGameMode.TOURNAMENTRPS
              ) ?
                " rock paper scissors "
              : " "
            }for: ${currentTournament.stake}. `
          : "") +
          "\r\n\r\n",
        url: `https://${window.location.host}/i/${group?.inviteCode}`,
        title: "randomKO invitation",
      }
    : {
        text: `Join me on randomKO!`,
        url: `https://${window.location.host.replace(/\/$/, "")}`,
        title: "randomKO",
      };

  return (
    <RWebShare data={data} defaultExpanded={true}>
      {children}
    </RWebShare>
  );
};

export default ShareButton;
