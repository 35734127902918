import axios from "axios";

import { config } from "../config";

const validStatusCodes = [200, 201, 204];

const rpsApi = axios.create({
  baseURL: config.domains.getDomain("api"),
  headers: { Accept: "application/json", "Content-Type": "application/json" },
  validateStatus: function (status) {
    return validStatusCodes.indexOf(status) !== -1;
  },
  withCredentials: true,
});

/*rpsApi.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (token) config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);*/

rpsApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const error = err.response;
    if (!error) return;

    // If the response was a 401
    if (error.status === 401) {
      // Redirect to login
      //localStorage.removeItem("token");
      //window.location.href = `/login/?return=${encodeURIComponent(currentPath)}`
    } else if (error.status >= 500) {
      console.error("randomKO API response error.");
      console.error(err);
    }

    return error;
  }
);

function apiCancellationToken() {
  return axios.CancelToken.source();
}

export { rpsApi, apiCancellationToken };
