import React from "react";

import { Button } from "semantic-ui-react";

import Icon from "../../../../../components/Icon/Icon";
import JoyrideButton from "../../../../../components/JoyrideButton/JoyrideButton";
import TournamentConfig from "../../../../../components/TournamentConfig/TournamentConfig";
import Enum from "../../../../../helpers/enums";

const GroupLowerNoTournament = ({
  group,
  startTimeValidity,
  handleConfigChange,
  tournamentPreparing,
  handleModalDecisionOptionsOpen,
  handleModalAlertOpen,
  handleJoyrideTourOpen,
}) => {
  const onBtnWrapClick = () => {
    if (
      tournamentPreparing ||
      !group?.myDraftTournament?.stake ||
      startTimeValidity !== 0
    ) {
      handleModalAlertOpen(
        "Unable to play",
        "Please enter what you're playing for.",
        true,
      );
    }
  };

  return (
    <>
      {group?.promo && (
        <div className="group-lower">
          This promotion hasn't started yet, please return later.
        </div>
      )}
      {!group?.promo && (
        <div className="group-lower">
          <TournamentConfig
            tournament={group.myDraftTournament}
            onConfigChange={handleConfigChange}
            startTimeValidity={startTimeValidity}
          />

          <JoyrideButton onClick={handleJoyrideTourOpen}></JoyrideButton>

          <div className="action-panel">
            <div
              className="action-panel-btn-wrap"
              onClick={onBtnWrapClick}
              id="joyride-tour-random-btn"
            >
              <Button
                icon
                size="big"
                primary
                onClick={() =>
                  handleModalDecisionOptionsOpen(Enum.TournamentGameMode.RANDOM)
                }
                disabled={
                  tournamentPreparing ||
                  !group?.myDraftTournament?.stake ||
                  startTimeValidity !== 0
                }
              >
                <Icon className="far fa-shuffle"></Icon>
                Choose randomly
                <Icon className="far fa-chevron-right"></Icon>
              </Button>
            </div>
            <div
              className="action-panel-btn-wrap"
              onClick={onBtnWrapClick}
              id="joyride-tour-rps-btn"
            >
              <Button
                icon
                size="big"
                primary
                onClick={() =>
                  handleModalDecisionOptionsOpen(
                    Enum.TournamentGameMode.TOURNAMENTRPS,
                  )
                }
                disabled={
                  tournamentPreparing ||
                  !group?.myDraftTournament?.stake ||
                  startTimeValidity !== 0
                }
              >
                <Icon className="far fa-hand-scissors"></Icon>
                Play rock paper scissors
                <Icon className="far fa-chevron-right"></Icon>
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GroupLowerNoTournament;
