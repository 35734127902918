import React, { useContext } from "react";

import { config } from "../../config";
import AuthContext from "../../contexts/AuthContext";
import Avatar from "../Avatar/Avatar";
import HelpNode from "../HelpNode/HelpNode";

const BracketAdvancer = ({ tournament, player, handleUserClick }) => {
  const { authState } = useContext(AuthContext);

  return (
    <>
      <div
        className={`br-advancer ${
          player ? "br-advancer-known" : "br-advancer-unknown"
        } ${
          tournament.stakeIsPositive ?
            "br-advancer-positive"
          : "br-advancer-negative"
        } ${
          player && player.accountId === config.aiAccountMember.accountId ?
            "br-advancer-ai"
          : ""
        }
    `}
      >
        <div
          onClick={() => {
            if (player) handleUserClick(player.accountId);
          }}
        >
          <Avatar
            user={
              player && player.accountId !== config.aiAccountMember.accountId ?
                player.accountId
              : -1
            }
          ></Avatar>
          {player && player.accountId === config.aiAccountMember.accountId && (
            <span className="br-username">
              None
              <HelpNode
                content="AI users do not progress through rounds."
                position="top center"
                offset={[0, 0]}
              />
            </span>
          )}
          {!player ||
            (player.accountId !== config.aiAccountMember.accountId && (
              <span className="br-username">
                {player ?
                  player.accountId === authState.account.id ?
                    "You"
                  : player.accountUserName
                : tournament?.stakeIsPositive ?
                  "Winner"
                : "Loser"}
              </span>
            ))}
        </div>
      </div>
    </>
  );
};

export default BracketAdvancer;
