import Icon from "../Icon/Icon";
import "./JoyrideButton.scss";

const JoyrideButton = ({ onClick }) => {
  return (
    <div
      className="joyride-btn"
      onClick={onClick}
    >
      <Icon className="far fa-question-circle"></Icon>
    </div>
  );
};

export default JoyrideButton;
