import React from "react";

import { Label } from "semantic-ui-react";

import Icon from "../../../Icon/Icon";

const GroupMemberBadge = ({ type, currentTournament }) => {
  const BADGE_DATA = {
    you: ["fas fa-user member-is-you is-label"],
    boss: ["fas fa-crown member-is-boss is-label"],
    creator: ["fas fa-star member-is-creator is-label"],
    assistant: ["fas fa-star member-is-assistant", "Assistant"],
    wonLast: ["fas fa-thumbs-up member-decision-won", "Won last game"],
    lostLast: ["fas fa-thumbs-down member-decision-lost", "Lost last game"],
    waiting: ["far fa-clock member-is-turn", "Waiting for this player to move"],
    //moved: ["fas fa-check member-is-turn", "This player has made their move"],
    participant: [
      "fas fa-check member-is-participating",
      "Playing in this game",
    ],
    nonparticipant: [
      "fas fa-times member-not-participating",
      "Not playing in this game",
    ],
    waitingrsvp: [
      "fas fa-times member-waiting-rsvp",
      "Waiting for this player to RSVP",
    ],
    advanced: ["fas fa-check member-still-in", "Advanced"],
    moved: ["fas fa-check member-still-in", "Moved"],
    waitingtomove: ["fas fa-check member-still-in", "Awaiting move"],
    knockedout: ["fas fa-times member-knocked-out", "Knocked out"],
    repeating: [
      "fas fa-calendar-days member-repeating",
      "This player has made their move",
    ],
    notMember: [
      "fas fa-user-slash member-not-member",
      "This player is not a member of this group",
    ],
  };

  const classes = BADGE_DATA[type][0];

  if (!classes) return <span></span>;

  if (type === "boss")
    return (
      <Label horizontal color="orange">
        Admin
      </Label>
    );
  else if (type === "you")
    return (
      <Label horizontal color="blue">
        You
      </Label>
    );
  else if (type === "creator") return null;
  // (
  //   <Label horizontal color="blue">
  //     Game Creator
  //   </Label>
  // );
  else if (type === "participant")
    return <span className="text-label text-green">Joined</span>;
  else if (type === "nonparticipant")
    return <span className="text-label text-red">Declined</span>;
  else if (type === "waitingrsvp")
    return <span className="text-label text-blue">Waiting&hellip;</span>;
  else if (type === "moved")
    return (
      <span className="text-label text-green">
        <Icon className="far fa-check"></Icon>Moved
      </span>
    );
  else if (type === "waitingtomove")
    return (
      <span className="text-label text-orange">
        <Icon className="far fa-spin fa-circle-notch"></Icon>Awaiting
        move&hellip;
      </span>
    );
  else if (type === "advanced")
    return currentTournament?.stakeIsPositive ?
        <span className="text-label text-green">
          Advanced<Icon className="far fa-chevrons-right"></Icon>
        </span>
      : <span className="text-label text-red">
          Still in<Icon className="far fa-chevrons-right"></Icon>
        </span>;
  else if (type === "knockedout")
    return currentTournament?.stakeIsPositive ?
        <span className="text-label text-red">
          Knocked out<Icon className="far fa-times"></Icon>
        </span>
      : <span className="text-label text-green">
          Safe<Icon className="far fa-check"></Icon>
        </span>;

  return (
    <span>
      <Icon className={classes} />
    </span>
  );
};

export default GroupMemberBadge;
