import "./Brackets.scss";

import React from "react";

import BracketMatch from "./BracketMatch";

function Brackets({ tournament, round, match, handleUserClick }) {
  if (!tournament || !round || !match) return null;

  return (
    <div
      id="brackets"
      className={`${
        round.roundNumber === 1
          ? "br-first-round"
          : round.isFinalRound
          ? "br-last-round"
          : ""
      }`}
    >
      <div className="br-rows">
        <>
          <BracketMatch
            tournament={tournament}
            round={round}
            match={match}
            handleUserClick={handleUserClick}
          ></BracketMatch>
        </>
      </div>
    </div>
  );
}

export default Brackets;
