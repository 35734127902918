import moment from "moment";
import React, { useMemo, useState, useContext, useEffect } from "react";
import Moment from "react-moment";
import {
  Button,
  Form,
  Modal,
  TransitionablePortal,
  Label,
} from "semantic-ui-react";
import _ from "underscore";

import { config } from "../../../config";
import AuthContext from "../../../contexts/AuthContext";
import { Api } from "../../../helpers/api";
import Enum from "../../../helpers/enums";
import ConfirmBox from "../../ConfirmBox/ConfirmBox";
import Icon from "../../Icon/Icon";
import TextButton from "../../TextButton/TextButton";
import RkoModal from "../RkoModal/RkoModal";

import "./ModalGroupOptions.scss";

const ModalGroupOptions = ({
  group,
  tournament,
  open,
  close,
  onSaveNameSuccess,
  onLeaveGroup,
  onDeleteGroup,
  onModalAlertOpen,
  callback,
  onCancelClick,
}) => {
  const [name, setName] = useState(group?.name);
  const [leaveConfirmOpen, setLeaveConfirmOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const { authState } = useContext(AuthContext);

  const handleClose = () => {
    document.body.classList.remove("modal-fade-in");
    close();
  };

  const handleSaveName = () => {
    Api.changeGroupName(group.id, name)
      .then(() => {
        if (onSaveNameSuccess) onSaveNameSuccess(name);
        handleClose();
      })
      .catch(() => {});
  };

  const handleDelete = () => {
    setDeleteConfirmOpen(false);

    onDeleteGroup(() => {
      handleClose();
    });
  };

  const onLeaveClick = () => {
    if (
      tournament?.creatorId === authState?.account?.id &&
      tournament?.state === Enum.TournamentState.PREPARE
    ) {
      onModalAlertOpen(
        "Cannot leave",
        "You cannot leave while setting up a game"
      );
      return;
    }

    setLeaveConfirmOpen(true);
  };

  const isValid = () => {
    return (
      group &&
      name &&
      name !== group.name &&
      name.length >= 1 &&
      name.length <= config.validation.maxLength.groupName
    );
  };

  const groupBoss = useMemo(() => {
    return group?.groupAccounts.find((m) => m.isBoss);
  }, [group]);

  const currentDate = new moment(tournament?.startTime);
  const [startHours, setStartHours] = useState(
    currentDate.hours() > 12 ? currentDate.hours() - 12 : currentDate.hours()
  );
  const [startMinutes, setStartMinutes] = useState(currentDate.minutes());
  const [startAmPm, setStartAmPm] = useState(
    currentDate.hours() >= 12 ? "pm" : "am"
  );

  const [startDateObj, setStartDateObj] = useState(
    currentDate.format("YYYY-MM-DD")
  );
  const [startTimeValidity, setStartTimeValidity] = useState(0); // -1 = past, 0 = valid, 1 = too far in future

  const hoursOptions = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((h) => {
    return {
      key: h,
      text: h,
      value: h,
    };
  });
  const minutesOptions = _.range(60).map((h) => {
    return {
      key: h,
      text: h < 10 ? "0" + h : h,
      value: h,
    };
  });
  const amPmOptions = ["am", "pm"].map((h) => {
    return {
      key: h,
      text: h,
      value: h,
    };
  });

  const prepareLengthMinutesOptions = _.flatten([
    [2, 5, 15].map((m) => {
      return {
        key: m,
        text: m + " minutes",
        value: m,
      };
    }),
    [60, 120, 360, 1440].map((m) => {
      return {
        key: m,
        text: m / 60 + " hour" + (m / 60 === 1 ? "" : "s"),
        value: m,
      };
    }),
  ]);

  const [prepareLengthMinutes, setPrepareLengthMinutes] = useState(
    tournament?.prepareLengthMinutes
  );

  const [startTime, setStartTime] = useState(tournament?.startTime);

  useEffect(() => {
    let startTimeValidity = 0;
    let startTimeMoment = moment(startTime);

    if (startTimeMoment) {
      // start time must be in the future but no more than 2 months in the future
      if (startTimeMoment.isBefore(moment())) {
        startTimeValidity = -1;
      } else if (startTimeMoment.isAfter(moment().add(2, "months"))) {
        startTimeValidity = 1;
      }
    }

    setStartTimeValidity(startTimeValidity);
  }, [startTime]);

  const handleSave = () => {
    if (startTimeValidity !== 0) return;

    Api.rescheduleDecision(tournament.id, prepareLengthMinutes, startTime)
      .then(() => {
        handleClose();
        if (callback) callback();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleHoursChange = (val) => {
    val = parseInt(val);

    setStartHours(val);

    let h =
      startAmPm === "am" ? (val === 12 ? 0 : val) : val === 12 ? val : val + 12;

    let newTime = new moment(tournament.startTime);
    newTime.hours(h);
    setStartTime(newTime);
  };

  const handleMinutesChange = (val) => {
    val = parseInt(val);

    setStartMinutes(val);
    let newTime = new moment(tournament.startTime);
    newTime.minutes(val);
    setStartTime(newTime);
  };

  const handleAmPmChange = (val) => {
    setStartAmPm(val);
    setStartTime(
      new moment(tournament.startTime).hours(
        val === "am"
          ? startHours === 12
            ? 0
            : startHours
          : startHours === 12
          ? 12
          : startHours + 12
      )
    );
  };

  const dateRegex = new RegExp(/^\d{4}-\d{2}-\d{2}$/);
  const handleDateChange = (val) => {
    if (!val.match(dateRegex)) return;

    setStartDateObj(val);

    let h =
      startAmPm === "am"
        ? startHours === 12
          ? 0
          : startHours
        : startHours === 12
        ? startHours
        : startHours + 12;

    setStartTime(new moment(val).hours(h).minutes(startMinutes));
  };

  const amCreator = useMemo(() => {
    if (!tournament || !authState || !authState.account) return false;
    return tournament.creatorId === authState.account.id;
  }, [tournament, authState]);

  const tournamentActive = useMemo(() => {
    return tournament?.state === Enum.TournamentState.ACTIVE;
  }, [tournament]);

  return (
    <TransitionablePortal
      open={open}
      onOpen={() =>
        setTimeout(() => document.body.classList.add("modal-fade-in"), 0)
      }
      transition={{ animation: "fade down", duration: 300 }}
    >
      <RkoModal
        size="small"
        open={true}
        onClose={handleClose}
        className="modal-group-name"
      >
        <Modal.Content>
          <Modal.Description>
            {group && (
              <>
                <div className="group-name-title ellipsis-overflow">
                  {group.name}
                </div>

                <div className="group-boss">
                  {groupBoss?.accountUserName}{" "}
                  <Label color="orange">Admin</Label>
                </div>

                {groupBoss && (
                  <div className="group-created">
                    Created{" "}
                    <Moment
                      fromNow
                      date={groupBoss.joinedTime}
                      withTitle
                      titleFormat="D MMM YYYY HH:mm:ss"
                    />
                  </div>
                )}

                <Form className="no-margin">
                  {!tournament && group.amBoss && (
                    <>
                      <Form.Field className="input-rename-group">
                        <label>Rename group</label>
                        <div className="ui action input">
                          <div className="">
                            <input
                              type="text"
                              label="Group name"
                              name="name"
                              onChange={(e) =>
                                setName(
                                  e.target.value.substring(
                                    0,
                                    config.validation.maxLength.groupName
                                  )
                                )
                              }
                              value={name}
                              placeholder="Enter group name"
                            />
                          </div>
                          <Button
                            primary
                            onClick={handleSaveName}
                            disabled={!isValid()}
                          >
                            Save
                          </Button>
                        </div>
                      </Form.Field>
                    </>
                  )}

                  {!tournament && group.amBoss && group.canDelete && (
                    <div className="leave-btn-wrap">
                      <TextButton
                        onClick={() => setDeleteConfirmOpen(true)}
                        color="red"
                        basic
                        circular
                        className="leave-btn"
                      >
                        <Icon className="far fa-fw fa-trash"></Icon>
                        <span>Delete group</span>
                      </TextButton>
                    </div>
                  )}
                </Form>
              </>
            )}

            {tournament && (amCreator || group?.amBoss) && (
              <>
                <div className="tournament-manage-controls tournament-manage-options">
                  <div className="tournament-manage-title">
                    <span>Playing&nbsp;for:</span>
                    <h3 className="ellipsis-overflow">
                      {tournament.gameMode !==
                        Enum.TournamentGameMode.RANDOM && (
                        <span className="stake-positive">
                          {tournament.stakeIsPositive && (
                            <span className="text-green">
                              <Icon className="fas fa-fw fa-thumbs-up"></Icon>
                            </span>
                          )}
                          {!tournament.stakeIsPositive && (
                            <span className="text-red">
                              <Icon className="fas fa-fw fa-thumbs-down"></Icon>
                            </span>
                          )}
                        </span>
                      )}{" "}
                      {tournament.stake}
                    </h3>
                  </div>
                  {!tournamentActive && (
                    <Form className="tournament-config no-margin">
                      <Form.Field className="decision-reschedule-field">
                        <label>Time for players to join?</label>
                        <div className="component-tournament-rules">
                          <div className="tr-value">
                            <div className="big-select-wrap">
                              <select
                                value={
                                  prepareLengthMinutes === -1
                                    ? "custom"
                                    : prepareLengthMinutes
                                }
                                onChange={(e) =>
                                  setPrepareLengthMinutes(
                                    e.target.value === "custom"
                                      ? -1
                                      : parseInt(e.target.value, 10)
                                  )
                                }
                              >
                                {prepareLengthMinutesOptions.map((o) => (
                                  <option key={o.value} value={o.value}>
                                    {o.text}
                                  </option>
                                ))}
                                <option key="custom" value="custom">
                                  Custom
                                </option>
                              </select>
                            </div>
                            {prepareLengthMinutes !== -1 && (
                              <Button
                                icon={
                                  <Icon
                                    embedded
                                    className="fas fa-chevron-right"
                                  />
                                }
                                labelPosition="right"
                                content={"Reschedule"}
                                primary
                                disabled={startTimeValidity !== 0}
                                onClick={(e) => handleSave(e)}
                              ></Button>
                            )}
                          </div>
                          {prepareLengthMinutes === -1 && (
                            <>
                              <div className="tr-value">
                                <input
                                  type="date"
                                  className="tournament-rules-custom-date"
                                  value={startDateObj}
                                  onChange={(ev) => {
                                    handleDateChange(ev.target.value);
                                  }}
                                />
                              </div>
                              <div className="tr-time">
                                <select
                                  className=""
                                  value={startHours}
                                  onChange={(ev) => {
                                    handleHoursChange(ev.target.value);
                                  }}
                                >
                                  {hoursOptions.map((x) => (
                                    <option key={x.value} value={x.value}>
                                      {x.text}
                                    </option>
                                  ))}
                                </select>
                                <b>:</b>
                                <select
                                  className=""
                                  value={startMinutes}
                                  onChange={(ev) => {
                                    handleMinutesChange(ev.target.value);
                                  }}
                                >
                                  {minutesOptions.map((x) => (
                                    <option key={x.value} value={x.value}>
                                      {x.text}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  className=""
                                  value={startAmPm}
                                  onChange={(ev) => {
                                    handleAmPmChange(ev.target.value);
                                  }}
                                >
                                  {amPmOptions.map((x) => (
                                    <option key={x.value} value={x.value}>
                                      {x.text}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {startTimeValidity !== 0 && (
                                <div className="time-validation small text-red">
                                  {startTimeValidity === -1
                                    ? "Start time must be in the future."
                                    : "Start time must be within 2 months."}
                                </div>
                              )}

                              <Button
                                icon={
                                  <Icon
                                    embedded
                                    className="fas fa-chevron-right"
                                  />
                                }
                                labelPosition="right"
                                content={"Reschedule"}
                                primary
                                disabled={startTimeValidity !== 0}
                                onClick={(e) => handleSave(e)}
                              ></Button>
                            </>
                          )}
                        </div>
                      </Form.Field>
                    </Form>
                  )}
                </div>
                {tournament &&
                  (amCreator || group?.amBoss) &&
                  (tournament?.state === Enum.TournamentState.ACTIVE ||
                    tournament?.state === Enum.TournamentState.PREPARE) && (
                    <div className="leave-btn-wrap">
                      <TextButton
                        onClick={(e) => onCancelClick(e)}
                        color="red"
                        basic
                        circular
                        className="leave-btn"
                      >
                        <Icon embedded className="fas fa-fw fa-times" />
                        <span>Cancel game</span>
                      </TextButton>
                    </div>
                  )}
              </>
            )}

            {group && !group.amBoss && (
              <div className="leave-btn-wrap">
                <TextButton
                  onClick={onLeaveClick}
                  color="red"
                  basic
                  circular
                  className="leave-btn"
                >
                  <Icon className="far fa-fw fa-sign-out"></Icon>
                  <span>Leave group</span>
                </TextButton>
              </div>
            )}
          </Modal.Description>
        </Modal.Content>

        <ConfirmBox
          open={leaveConfirmOpen}
          content="Are you sure you want to leave the group? You may not be able to join it again."
          confirmButton="Leave group"
          onConfirm={() => {
            onLeaveGroup(() => {
              setLeaveConfirmOpen(false);
            });
          }}
          onCancel={() => setLeaveConfirmOpen(false)}
        ></ConfirmBox>

        <ConfirmBox
          open={deleteConfirmOpen}
          content="Are you sure you want to delete this group? You cannot undo this action."
          confirmButton="Delete group"
          onConfirm={handleDelete}
          onCancel={() => setDeleteConfirmOpen(false)}
        ></ConfirmBox>
      </RkoModal>
    </TransitionablePortal>
  );
};

export default ModalGroupOptions;
