import React from "react";

import CountUp from "react-countup";

import BoardOverlayMove from "./BoardOverlayMove";
import Avatar from "../../../Avatar/Avatar";

const BoardOverlayPlayer = ({
  player,
  hideMove,
  className,
  hideScore,
  handleModalUserManageOpen,
}) => {
  const { accountId, accountUserName, currentScore, oldScore, move } = player;

  return (
    <div className={`board-overlay-ms-player ${className ? className : ""}`}>
      <Avatar
        user={accountId}
        onClick={() => handleModalUserManageOpen(accountId)}
      />
      {!hideMove && (
        <BoardOverlayMove
          move={move}
          onClick={() => handleModalUserManageOpen(accountId)}
        />
      )}
      <div
        className="board-overlay-ms-player-name"
        onClick={() => handleModalUserManageOpen(accountId)}
      >
        {accountUserName}
      </div>
      {oldScore !== undefined && currentScore !== undefined && !hideScore && (
        <div className="board-overlay-ms-player-score">
          <CountUp start={oldScore} end={currentScore} delay={0} duration={0} />
        </div>
      )}
    </div>
  );
};

export default BoardOverlayPlayer;
