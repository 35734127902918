import moment from "moment";
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Button, Header } from "semantic-ui-react";
import _ from "underscore";

import StakeInput from "./StakeInput/StakeInput";
import TournamentRules from "./TournamentRules/TournamentRules";
import Enum from "../../helpers/enums";
import { clamp, isPowerOfTwo } from "../../utilities";

import "./TournamentConfig.scss";

const TournamentConfig = ({
  tournament,
  onConfigChange,
  isRepeating,
  isEditing,
  startTimeValidity,
  children,
}) => {
  const formRef = useRef(null);
  const scrollTimeoutRef = useRef(null);

  const quickOptions = [
    {
      key: "daily",
      text: "Daily",
      value: "daily",
    },
    {
      key: "weekday",
      text: "Every weekday (Mon to Fri)",
      value: "weekday",
    },
    {
      key: "weekly",
      text:
        "Every " +
        (isEditing && !!tournament
          ? moment(tournament.startTime)
          : moment()
        ).format("dddd"),
      value: "weekly",
    },
    {
      key: "monthly",
      text:
        "Every month on the " +
        (isEditing && !!tournament
          ? moment(tournament.startTime)
          : moment()
        ).format("Do"),
      value: "monthly",
    },
    {
      key: "custom",
      text: "Set custom schedule",
      value: "custom",
    },
  ];

  const hoursOptions = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((h) => {
    return {
      key: h,
      text: h,
      value: h,
    };
  });
  const minutesOptions = _.range(60).map((h) => {
    return {
      key: h,
      text: h < 10 ? "0" + h : h,
      value: h,
    };
  });
  const amPmOptions = ["am", "pm"].map((h) => {
    return {
      key: h,
      text: h,
      value: h,
    };
  });
  const domOptions = _.range(1, 32).map((d) => {
    return {
      key: d,
      text:
        d +
        (d === 1 || d === 21 || d === 31
          ? "st"
          : d === 2 || d === 22
            ? "nd"
            : d === 3 || d === 23
              ? "rd"
              : "th"),
      value: d,
    };
  });

  const [startHours, setStartHours] = useState(9);
  const [startMinutes, setStartMinutes] = useState(0);
  const [startAmPm, setStartAmPm] = useState("am");
  const [quickOption, setQuickOption] = useState(
    isEditing && !!tournament
      ? tournament.repeatEvery === 1
        ? tournament.repeatPeriod === Enum.RepeatPeriod.DAILY
          ? "daily"
          : tournament.repeatPeriod === Enum.RepeatPeriod.WEEKLY &&
            tournament.repeatDaysOfWeek === 62
            ? "weekday"
            : tournament.repeatPeriod === Enum.RepeatPeriod.WEEKLY &&
              isPowerOfTwo(tournament.repeatDaysOfWeek)
              ? "weekly"
              : tournament.repeatPeriod === Enum.RepeatPeriod.MONTHLY
                ? "monthly"
                : "custom"
        : "custom"
      : "daily"
  );

  useEffect(() => {
    return () => {
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let day = (
      isEditing && !!tournament ? moment(tournament.startTime) : moment()
    )
      .format("dddd")
      .toUpperCase();
    let dow = Enum.RepeatDayOfWeek[day];

    switch (quickOption) {
      case "weekday":
        onConfigChange({
          repeatEvery: 1,
          repeatPeriod: Enum.RepeatPeriod.WEEKLY,
          repeatDayOfMonth: null,
          repeatDaysOfWeek: 62,
        });
        break;
      case "weekly":
        onConfigChange({
          repeatEvery: 1,
          repeatPeriod: Enum.RepeatPeriod.WEEKLY,
          repeatDayOfMonth: null,
          repeatDaysOfWeek: dow,
        });
        break;
      case "monthly":
        onConfigChange({
          repeatEvery: 1,
          repeatPeriod: Enum.RepeatPeriod.MONTHLY,
          repeatDayOfMonth: (isEditing && !!tournament
            ? moment(tournament.startTime)
            : moment()
          ).date(),
          repeatDaysOfWeek: null,
        });
        break;
      case "custom":
        /*onConfigChange({
          repeatEvery: 1,
          repeatPeriod: Enum.RepeatPeriod.DAILY,
          repeatDayOfMonth: moment().date(),
          repeatDaysOfWeek: dow,
        });*/
        break;
      case "daily":
      default:
        onConfigChange({
          repeatEvery: 1,
          repeatPeriod: Enum.RepeatPeriod.DAILY,
          repeatDayOfMonth: null,
          repeatDaysOfWeek: null,
        });
        break;
    }
  }, [quickOption, onConfigChange]);

  useEffect(() => {
    let h = tournament.startTime.hour();

    setStartMinutes(tournament.startTime.minute());
    setStartHours(h === 0 ? 12 : h > 12 ? h - 12 : h);
    setStartAmPm(h < 12 ? "am" : "pm");
  }, [tournament.startTime]);

  const handleOnConfigChange = (obj) => {
    if (scrollTimeoutRef.current) clearTimeout(scrollTimeoutRef.current);

    if (obj?.prepareLengthMinutes === -1 && formRef.current) {
      scrollTimeoutRef.current = setTimeout(() => {
        formRef.current.scrollTop = formRef.current.scrollHeight;
      }, 100);
    }

    onConfigChange(obj);
  };

  const handleHoursChange = (val) => {
    val = parseInt(val);

    setStartHours(val);

    let h =
      startAmPm === "am" ? (val === 12 ? 0 : val) : val === 12 ? val : val + 12;

    let newTime = new moment(tournament.startTime);
    newTime.hours(h);
    onConfigChange({ startTime: newTime });
  };

  const handleMinutesChange = (val) => {
    val = parseInt(val);

    setStartMinutes(val);
    let newTime = new moment(tournament.startTime);
    newTime.minutes(val);
    onConfigChange({
      startTime: newTime,
    });
  };

  const handleAmPmChange = (val) => {
    setStartAmPm(val);
    onConfigChange({
      startTime: new moment(tournament.startTime).hours(
        val === "am"
          ? startHours === 12
            ? 0
            : startHours
          : startHours === 12
            ? 12
            : startHours + 12
      ),
    });
  };

  const periodOptions = useMemo(() => {
    return [
      {
        key: Enum.RepeatPeriod.DAILY,
        text: "day" + (tournament.repeatEvery === 1 ? "" : "s"),
        value: Enum.RepeatPeriod.DAILY,
      },
      {
        key: Enum.RepeatPeriod.WEEKLY,
        text: "week" + (tournament.repeatEvery === 1 ? "" : "s"),
        value: Enum.RepeatPeriod.WEEKLY,
      },
      {
        key: Enum.RepeatPeriod.MONTHLY,
        text: "month" + (tournament.repeatEvery === 1 ? "" : "s"),
        value: Enum.RepeatPeriod.MONTHLY,
      },
    ];
  }, [tournament.repeatEvery]);

  const toggleDay = useCallback(
    (day) => {
      let newDays = tournament.repeatDaysOfWeek;

      if ((tournament.repeatDaysOfWeek & day) === day) newDays &= ~day;
      else newDays |= day;

      onConfigChange({ repeatDaysOfWeek: newDays });
    },
    [onConfigChange, tournament.repeatDaysOfWeek]
  );

  // const handleInvite = () => {
  //   onInvite({ stake: tournament.stake });
  // };

  const handleEveryChange = (ev) => {
    const rawValue = parseInt(ev.target.value, 10);
    const clampedValue = clamp(rawValue, 1, 30);

    onConfigChange({
      repeatEvery: clampedValue,
    });
  };

  return (
    <form className="ui form tournament-config text-center" ref={formRef}>
      {children && <>{children}</>}
      {isEditing && (
        <Header as="h5" className="header-editing-repeating">
          <br />
        </Header>
      )}
      {!isEditing && (
        <>
          <Header as="h3">Playing for?</Header>

          <StakeInput
            stake={tournament.stake}
            //stakeIsPositive={newStakeIsPositive}
            //showSaveButton={showSaveButton}
            onConfigChange={onConfigChange}
          //onRevertStake={handleRevertStake}
          //onSaveStake={handleSaveStake}
          />
        </>
      )}

      {!isRepeating && (
        <TournamentRules
          tournament={tournament}
          onConfigChange={handleOnConfigChange}
          startTimeValidity={startTimeValidity}
        />
      )}

      {isRepeating && (
        <div className="repeating-config">
          <Header as="h3">How often?</Header>

          <div className="repeating-quick big-select-wrap">
            <select
              className=""
              value={quickOption}
              onChange={(ev) => {
                setQuickOption(ev.target.value);
              }}
            >
              {quickOptions.map((x) => (
                <option key={x.value} value={x.value}>
                  {x.text}
                </option>
              ))}
            </select>
          </div>
          <div className="repeating-config-padding">
            {quickOption === "custom" && (
              <div className="repeating-custom">
                <div className="repeating-every-period">
                  <span>Every</span>
                  <input
                    type="number"
                    step="1"
                    min={1}
                    max={30}
                    name="stake"
                    value={tournament.repeatEvery}
                    onChange={handleEveryChange}
                    autoComplete="off"
                  />
                  <select
                    className=""
                    value={tournament.repeatPeriod}
                    onChange={(ev) => {
                      onConfigChange({
                        repeatPeriod: parseInt(ev.target.value),
                      });
                    }}
                  >
                    {periodOptions.map((x) => (
                      <option key={x.value} value={x.value}>
                        {x.text}
                      </option>
                    ))}
                  </select>
                </div>
                {tournament.repeatPeriod === Enum.RepeatPeriod.WEEKLY && (
                  <div className="repeating-days">
                    <span>on</span>
                    {Object.keys(Enum.RepeatDayOfWeek).map((d) => {
                      let dVal = Enum.RepeatDayOfWeek[d];

                      return (
                        <Button
                          circular
                          size="tiny"
                          key={d}
                          onClick={() => toggleDay(dVal)}
                          primary={
                            (tournament.repeatDaysOfWeek & dVal) === dVal
                          }
                          basic={(tournament.repeatDaysOfWeek & dVal) !== dVal}
                        >
                          {d[0]}
                        </Button>
                      );
                    })}
                  </div>
                )}
                {tournament.repeatPeriod === Enum.RepeatPeriod.MONTHLY && (
                  <div className="repeating-day-of-month">
                    <span>on the</span>

                    <select
                      className=""
                      value={tournament.repeatDayOfMonth}
                      onChange={(ev) => {
                        onConfigChange({
                          repeatDayOfMonth: parseInt(ev.target.value),
                        });
                      }}
                    >
                      {domOptions.map((x) => (
                        <option key={x.value} value={x.value}>
                          {x.text}
                        </option>
                      ))}
                    </select>
                    {tournament.repeatDayOfMonth === 31 && (
                      <span className="dom-warning">
                        or last day of month for
                        <br />
                        Feb, Apr, Jun, Sep and Nov.
                      </span>
                    )}
                    {(tournament.repeatDayOfMonth === 30 ||
                      tournament.repeatDayOfMonth === 29) && (
                        <span className="dom-warning">
                          or last day of month for Feb.
                        </span>
                      )}
                  </div>
                )}
              </div>
            )}
            <div className="tr-time">
              <select
                className=""
                value={startHours}
                onChange={(ev) => {
                  handleHoursChange(ev.target.value);
                }}
              >
                {hoursOptions.map((x) => (
                  <option key={x.value} value={x.value}>
                    {x.text}
                  </option>
                ))}
              </select>
              <b>:</b>
              <select
                className=""
                value={startMinutes}
                onChange={(ev) => {
                  handleMinutesChange(ev.target.value);
                }}
              >
                {minutesOptions.map((x) => (
                  <option key={x.value} value={x.value}>
                    {x.text}
                  </option>
                ))}
              </select>
              <select
                className=""
                value={startAmPm}
                onChange={(ev) => {
                  handleAmPmChange(ev.target.value);
                }}
              >
                {amPmOptions.map((x) => (
                  <option key={x.value} value={x.value}>
                    {x.text}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}

      {/* <div className="advanced-options">
        <div className="advanced-option-button">
          <Button basic onClick={onDecisionOptions}>
            <Icon className="far fa-cog"></Icon> Options
          </Button>
          <span>
            {tournament.winnersToDraw}{" "}
            <Plural base="player" value={tournament.winnersToDraw}></Plural>{" "}
            will be chosen.
          </span>
        </div>
      </div> */}
    </form>
  );
};

export default TournamentConfig;
