import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Menu } from "semantic-ui-react";

import NotificationContext from "../../../contexts/NotificationContext";
import Avatar from "../../Avatar/Avatar";

import Icon from "../../Icon/Icon";
import TimeAgo from "../../TimeAgo/TimeAgo";

const NotificationsListItem = ({ notification }) => {
  const notificationContext = useContext(NotificationContext);
  const history = useHistory();

  const [notificationConfig, setNotificationConfig] = useState(null);
  const [generatedTitle, setGeneratedTitle] = useState(null);
  const [generatedBody, setGeneratedBody] = useState(null);
  const [generatedLink, setGeneratedLink] = useState(null);

  useEffect(() => {
    setNotificationConfig(notificationContext[notification.type]);
  }, [notification, notificationContext]);

  useEffect(() => {
    const fillTemplate = (template, values, raw) => {
      if (!template) return null;
      if (!values) return template;

      // Find all placeholders in the template and replace them with corresponding values
      const output = template.split(/({[^}]+})/g).map((part, index) => {
        if (part.startsWith("{") && part.endsWith("}")) {
          // Extract the key and optional type from the placeholder
          const [key, type] = part.slice(1, -1).split(":");

          if (Object.prototype.hasOwnProperty.call(values, key)) {
            let val = values[key];
            // Check if the value is a datetime string by matching it with the regex
            if (type === "date") {
              // If it's a valid date, format it
              return (
                <span key={index} className="datetime">
                  {moment(val).format("D MMM YYYY h:mm:ss a")}
                </span>
              );
            } else if (type === "username") {
              return (
                <span key={index} className="username">
                  {val}
                </span>
              );
            } else if (type === "avatar") {
              return (
                <span key={index} className="avatar">
                  <Avatar user={val} size="mini" />
                </span>
              );
            }

            // Wrap the value in a span tag
            return raw ? val : <span key={index}>{val}</span>;
          }
        }

        // If it's not a placeholder or if it's a key not in values, return the part as is
        return part;
      });

      // Return the array of JSX elements
      return output;
    };

    if (!notificationConfig || !notificationConfig.general) return;

    if (notificationConfig.general.title)
      setGeneratedTitle(
        fillTemplate(notificationConfig.general.title, notification.dataObject),
      );

    if (notificationConfig.general.body)
      setGeneratedBody(
        fillTemplate(notificationConfig.general.body, notification.dataObject),
      );

    if (notificationConfig.general.link)
      setGeneratedLink(
        fillTemplate(
          notificationConfig.general.link,
          notification.dataObject,
          true,
        )?.join(""),
      );
  }, [notification, notificationConfig]);

  const handleNotificationClick = () => {
    console.log("notif clicky", generatedLink);
    history.push(generatedLink || "/groups");
  };

  return (
    <Menu.Item
      key={notification.id}
      className="notifications-list-item"
      onClick={handleNotificationClick}
    >
      {[110, 510].indexOf(notificationConfig?.type) > -1 && (
        <div
          className={`notification-icon ${
            (
              notificationConfig?.type === 510 &&
              notification.dataObject.TournamentStakeIsPositive === false
            ) ?
              "penalty"
            : ""
          }`}
        >
          {notificationConfig?.type === 510 && (
            <Icon
              className={`far fa-fw fa-thumbs-${
                notification.dataObject.TournamentStakeIsPositive === true ?
                  "up"
                : "down"
              }`}
            ></Icon>
          )}
          {notificationConfig?.type === 110 && (
            <Icon className="far fa-fw fa-shuffle"></Icon>
          )}
        </div>
      )}
      {/* {notificationConfig?.type === 160 && (
        <div className="notification-icon">
          <Icon className="far fa-fw fa-check"></Icon>
        </div>
      )} */}
      <div className="notification-content">
        <h5>{generatedTitle}</h5>
        <div className="notification-body">
          <div>{generatedBody}</div>
        </div>
        <div className="notification-timeago">
          <TimeAgo time={notification?.siteDisplayTime} />
        </div>
      </div>
    </Menu.Item>
  );
};

export default NotificationsListItem;
