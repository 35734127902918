import firebase from "firebase/app";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";

import { Dimmer, Loader } from "semantic-ui-react";

import { config } from "../config";
import AuthContext from "../contexts/AuthContext";

import { Api } from "../helpers/api";
import { createDebugLogger } from "../helpers/debug";
import Enum from "../helpers/enums";

const { log: authLog } = createDebugLogger(Enum.DebugChannel.AUTH);
const { log: n10nLog } = createDebugLogger(Enum.DebugChannel.N10N);

function AuthProvider({ children }) {
  const [authState, setAuthState] = useState({
    isLoading: true,
    isAuthenticated: false,
    account: null,
    notifyEnabled: null,
    notifyRequested: false,
    notifyRequesting: false,
    notifyPermission: null,
    notifyLoading: false,
    notifyDenied: false,
    currentToken: null,
    n10nDebugMsg: null,
  });

  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    let tzGuess = moment.tz.guess();
    let tz = tzGuess ? moment.tz.zone(tzGuess) : null;
    let tzOffset = tz ? -tz.utcOffset(moment.now()) : null;

    const tzNameLong = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzName = moment.tz(tzNameLong).zoneAbbr();

    Api.authStatus(tzOffset, tzName).then((response) => {
      authLog("authStatus", response);

      let account,
        isLoading = false,
        isAuthenticated = response?.authenticated === true,
        isRkoAdmin = response?.isRkoAdmin === true;

      if (isAuthenticated) account = response.account;

      setAuthState((prevState) => {
        const newAuthState = {
          ...prevState,
          isLoading,
          isAuthenticated,
          account,
        };

        if (!isRkoAdmin) return newAuthState;

        return {
          ...newAuthState,
          isRkoAdmin,
        };
      });
    });

    return () => {
      setIsMounted(false);
    };
  }, []);

  const handleReceiveAccountNotification = (notification) => {
    setAuthState((prevState) => {
      const maxNotifications = 50;
      const oldNotifications = prevState.account?.notifications?.data || [];

      return {
        ...prevState,
        account: {
          ...prevState.account,
          unseenNotifications:
            window.location.pathname === "/notifications" ?
              0
            : prevState.account.unseenNotifications + 1,
          notifications: {
            ...prevState.account.notifications,
            data: [
              notification,
              ...oldNotifications.slice(0, maxNotifications - 1),
            ],
          },
        },
      };
    });
  };

  const handleChangeUserName = (userName) => {
    setAuthState((prevState) => {
      return {
        ...prevState,
        account: {
          ...prevState.account,
          userName,
        },
      };
    });
  };

  const handleChangeTagline = (tagline) => {
    setAuthState((prevState) => {
      return {
        ...prevState,
        account: {
          ...prevState.account,
          tagline,
        },
      };
    });
  };

  const setAccountNotifications = (notifications) => {
    setAuthState((prevState) => {
      return {
        ...prevState,
        account: {
          ...prevState.account,
          notifications,
          unseenNotifications: 0,
        },
      };
    });
  };

  const clearAccountNotifications = () => {
    setAuthState((prevState) => {
      return {
        ...prevState,
        account: {
          ...prevState.account,
          notifications: [],
          unseenNotifications: 0,
        },
      };
    });
  };

  const setN10nDebugMsg = (msg) => {
    setAuthState((prevState) => {
      return {
        ...prevState,
        n10nDebugMsg: msg,
      };
    });
  };

  const handleAskNotificationPermission = (option) => {
    n10nLog("Func: handleAskNotificationPermission", "option", option);
    initializePushNotifications();
  };

  const checkNotificationPermissions = () => {
    n10nLog("Func: checkNotificationPermissions");
    if (!("Notification" in window)) {
      authLog("notifications not supported");
      return;
    }

    if (Notification.permission === "granted") initializePushNotifications();
  };

  const delay = (ms) => {
    n10nLog("Func: delay", "ms", ms);

    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  const areNotificationsSupported = () => {
    n10nLog("Func: areNotificationsSupported");

    if (!("Notification" in window)) {
      n10nLog("notifications not supported");
      setN10nDebugMsg("E01: Notifications are not supported in this browser.");
      return false;
    }
    return true;
  };

  const requestNotificationPermission = async () => {
    if (!isMounted) return null;
    n10nLog("Func: async requestNotificationPermission");

    let permission = await Notification.requestPermission();

    n10nLog(
      "Func: async requestNotificationPermission",
      "permission",
      permission,
    );
    setAuthState((prevState) => {
      return {
        ...prevState,
        notifyRequesting: false,
        notifyPermission: permission,
      };
    });

    return permission === "granted";
  };

  const getCurrentTokenWithRetry = async (
    messaging,
    retries = config.firebase.retries.max,
  ) => {
    if (!isMounted) return null;
    n10nLog(
      "Func: async getCurrentTokenWithRetry",
      "messaging",
      messaging,
      "retries",
      retries,
    );

    try {
      const currentToken = await messaging.getToken({
        vapidKey: config.firebase.vapidKey,
      });
      if (currentToken) return currentToken;
      throw new Error("No current token returned from firebase.getToken.");
    } catch (error) {
      if (retries > 0) {
        await delay(config.firebase.retries.delay);
        return getCurrentTokenWithRetry(messaging, retries - 1);
      } else {
        throw error;
      }
    }
  };

  const initializePushNotifications = async () => {
    if (!isMounted) return null;
    n10nLog("Func: async initializePushNotifications");

    if (!areNotificationsSupported()) return;

    let enabled = JSON.parse(localStorage.getItem("notifications") || "false");

    if (enabled && Notification.permission === "granted") {
      await handleEnabledNotifications();
    } else {
      await handleNewNotificationRequest();
    }
  };

  const handleEnabledNotifications = async () => {
    if (!isMounted) return null;
    n10nLog("Func: async handleEnabledNotifications");

    if (Notification.permission !== "granted") return;

    n10nLog("Get token.");

    try {
      const messaging = firebase.messaging();
      const currentToken = await getCurrentTokenWithRetry(messaging);

      if (currentToken) {
        handleCurrentToken(currentToken);
      }
    } catch (err) {
      handleErrorGettingToken(err);
    }
  };

  const handleNewNotificationRequest = async () => {
    if (!isMounted) return null;
    n10nLog("Func: async handleNewNotificationRequest");

    setAuthState((prevState) => {
      return {
        ...prevState,
        notifyRequested: true,
        notifyRequesting: true,
        notifyRetrying: prevState.notifyDenied,
      };
    });

    const permissionGranted = await requestNotificationPermission();
    if (!permissionGranted) {
      handlePermissionDenied();
      return;
    }

    try {
      handleNewTokenAfterPermission();
    } catch (err) {
      handleErrorGettingToken(err);
    }
  };

  const handleCurrentToken = (currentToken) => {
    n10nLog("Func: handleCurrentToken", "currentToken", currentToken);

    Api.sendTokenToServer(currentToken);
    // Update state with current token and notification enabled
    setAuthState((prevState) => ({
      ...prevState,
      notifyEnabled: true,
      notifyRequesting: false,
      notifyLoading: false,
      currentToken,
    }));
  };

  const handleErrorGettingToken = (error) => {
    n10nLog("Func: handleErrorGettingToken", "error", error);

    setAuthState((prevState) => ({
      ...prevState,
      notifyEnabled: false,
      notifyLoading: false,
      currentToken: null,
      n10nDebugMsg:
        "E06: Error getting token from firebase. " +
        JSON.stringify(error, null, 2),
    }));
  };

  const handlePermissionDenied = () => {
    n10nLog("Func: handlePermissionDenied");

    setAuthState((prevState) => ({
      ...prevState,
      notifyEnabled: false,
      notifyDenied: true,
      notifyLoading: false,
      n10nDebugMsg: "E08: Permission denied.",
    }));

    setTimeout(() => {
      setAuthState((prevState) => {
        return {
          ...prevState,
          notifyRetrying: false,
        };
      });
    }, 2000);
  };

  const handleNewTokenAfterPermission = async () => {
    if (!isMounted) return null;
    n10nLog("Func: async handleNewTokenAfterPermission");

    setAuthState((prevState) => ({
      ...prevState,
      notifyLoading: true,
    }));

    const messaging = firebase.messaging();

    try {
      const currentToken = await getCurrentTokenWithRetry(messaging);

      if (currentToken) {
        localStorage.setItem("notifications", "true");
        handleCurrentToken(currentToken);
      } else {
        setN10nDebugMsg(
          "E05: No Instance ID token available. Request permission to generate one.",
        );
      }
    } catch (err) {
      handleErrorGettingToken(err);
    } finally {
      setAuthState((prevState) => ({
        ...prevState,
        notifyLoading: false,
      }));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        handleReceiveAccountNotification,
        handleChangeUserName,
        handleChangeTagline,
        setAccountNotifications,
        clearAccountNotifications,
        handleAskNotificationPermission,
        checkNotificationPermissions,
      }}
    >
      {authState.isLoading ?
        <Dimmer inverted active>
          <Loader inverted active content="" />
        </Dimmer>
      : children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
