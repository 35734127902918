import * as PIXI from "pixi.js";

import { config } from "../../../config";
import { tweenToTargetAlpha } from "../../../utilities";


class MoveSlot extends PIXI.Container {
  constructor(board, isOpponent, hidden, actorMoved, is3KO, is3rd) {
    super();

    const height = 80;
    const half = height / 2;
    const width = is3KO ? 120 : 80;
    const color = is3KO && is3rd ? 0xeeeeee : isOpponent ? 0xe9e9e9 : 0xdadada;

    this.center = is3KO ? (width / 2) * (is3rd ? 1 : -1) : 0;

    this.isOpponent = isOpponent;
    this.is3KO = is3KO;
    this.is3rd = is3rd;
    this.x = board.appWidth / 2;
    this.y = board.sideY(isOpponent, board.appHeight / 2 - half);

    var slotGfx = new PIXI.Graphics();
    slotGfx.beginFill(color);
    slotGfx.drawRect(this.center - width / 2, -half, width, height);
    slotGfx.endFill();

    this.addChild(slotGfx);
    board.app.stage.addChild(this);

    if (!isOpponent) {
      this.createHelperSpr();
      this.createHelperText();
    }

    if (hidden) this.showHiddenMove();
    //else if (actorMoved) this.showWaiting();

    if (!isOpponent && !actorMoved) {
      const dragStyle = new PIXI.TextStyle(config.board.textConfig.dragHere);
      this.dragHereText = new PIXI.Text("Drag move here", dragStyle);
      this.dragHereText.roundPixels = true;
      this.dragHereText.anchor.set(0.5);
      this.dragHereText.alpha = 1;
      this.dragHereText.x = this.center;
      this.dragHereText.y = 50;
      this.addChild(this.dragHereText);
    }

    return this;
  }

  newGame() {}

  createHelperSpr() {
    const slotHelperTex = PIXI.Texture.from("/images/game/slot.png");
    this.slotHelperSpr = new PIXI.Sprite(slotHelperTex);

    this.slotHelperSpr.anchor.set(0.5);
    this.slotHelperSpr.scale.set(0.1);
    this.slotHelperSpr.alpha = this.slotHelperSpr.targetAlpha = 0;
    this.slotHelperSpr.x = 0;
    this.slotHelperSpr.y = 0;
    this.addChild(this.slotHelperSpr);
  }

  createHelperText() {
    const style = new PIXI.TextStyle({
      fontFamily: "Lato",
      fontSize: 20,
      fontWeight: "bold",
      fill: "#000000",
      align: "center",
      dropShadow: false,
      dropShadowColor: "#999999",
      dropShadowBlur: 5,
      dropShadowAngle: 0,
      dropShadowDistance: 0,
    });

    this.slotHelperText = new PIXI.Text("Drag your move to the circle", style);
    this.slotHelperText.roundPixels = true;
    this.slotHelperText.anchor.set(0.5);
    this.slotHelperText.alpha = 0;
    this.slotHelperText.x = 0;
    this.slotHelperText.y = 70;

    this.addChild(this.slotHelperText);
  }

  tick() {
    if (this.isOpponent) return;

    if (this.slotHelperSpr.tweenAlpha) {
      this.slotHelperSpr.alpha = this.slotHelperSpr.tweenAlpha.alpha;
    }

  }

  hideDragHere() {
    this.removeChild(this.dragHereText);
  }

  showHiddenMove() {
    const liStyle = new PIXI.TextStyle(config.board.textConfig.hiddenMove);
    this.hiddenMoveText = new PIXI.Text("?", liStyle);
    this.hiddenMoveText.roundPixels = true;
    this.hiddenMoveText.anchor.set(0.5);
    this.hiddenMoveText.alpha = 1;
    this.hiddenMoveText.x = this.center;
    this.hiddenMoveText.y = -3;

    this.addChild(this.hiddenMoveText);
  }

  hideHiddenMove() {
    this.removeChild(this.hiddenMoveText);
  }

  showForfeitMove() {
    const slotForfeitTex = PIXI.Texture.from("/images/game/forfeit.png");
    this.slotForfeitSpr = new PIXI.Sprite(slotForfeitTex);

    this.slotForfeitSpr.anchor.set(0.5);
    this.slotForfeitSpr.scale.set(0.2);
    this.slotForfeitSpr.alpha = 0.5;

    this.slotForfeitSpr.x = this.center;
    this.slotForfeitSpr.y = 0;

    this.addChild(this.slotForfeitSpr);
  }

  hideForfeitMove() {
    this.removeChild(this.slotForfeitSpr);
  }

  showWaiting() {
    /*
    const slotWaitingTex = PIXI.Texture.from("/images/game/waiting.png");
    this.slotWaitingSpr = new PIXI.Sprite(slotWaitingTex);

    this.slotWaitingSpr.anchor.set(0.5);
    this.slotWaitingSpr.scale.set(0.2);
    this.slotWaitingSpr.alpha = 0.5;

    this.slotWaitingSpr.x = this.center;
    this.slotWaitingSpr.y = 0;

    this.addChild(this.slotWaitingSpr);
    */
  }

  hideWaiting() {
    this.removeChild(this.slotWaitingSpr);
  }


  showHelper() {
    this.slotHelperSpr.targetAlpha = 1;
    tweenToTargetAlpha(this.slotHelperSpr);
  }

  hideHelper() {
    this.slotHelperText.alpha = 0;
    this.slotHelperSpr.targetAlpha = 0;
    tweenToTargetAlpha(this.slotHelperSpr);
  }

  isHelperInRange(is) {
    this.slotHelperSpr.tint = is ? 0x00ffff : 0xffffff;
  }

  toggleHelperText(show) {
    if (this.slotHelperSpr.alphaTween) this.slotHelperSpr.alphaTween.stop();

    this.slotHelperSpr.tweenAlpha = { alpha: show ? 1 : 0 };
    this.slotHelperSpr.alpha = show ? 1 : 0;
    this.slotHelperText.alpha = show ? 1 : 0;
  }

  onMoveLockedIn() {
    this.hideDragHere();
    this.hideHelper();
  }

}

export default MoveSlot;
