import React, { useState } from "react";

import DecisionsRepeatingItem from "./DecisionsRepeatingItem";

import "./DecisionsRepeating.scss";

const DecisionsRepeating = ({
  group,
  onViewTournament,
}) => {
  const [openId, setOpenId] = useState();

  const handleOpen = (id) => {
    setOpenId((prevState) => (prevState === id ? null : id));
  };

  const handleViewTournament = (e, tournamentId) => {
    e.stopPropagation();
    onViewTournament(tournamentId);
  };

  return (
    <div className="component-decisions-repeating">
      <div>
        {(!group ||
          !group.repeatingTournaments ||
          !group.repeatingTournaments.length) && (
          <div className="new-group-instructions">Set up repeating game<br />to play at regular times</div>
        )}
        {group?.repeatingTournaments &&
          group.repeatingTournaments.map((tournament) => (
            <DecisionsRepeatingItem
              key={tournament.id}
              group={group}
              tournament={tournament}
              open={tournament.id === openId}
              onOpen={handleOpen}
              onViewTournament={handleViewTournament}
            />
          ))}
      </div>
    </div>
  );
};

export default DecisionsRepeating;
