import React from "react";
import { Button, Modal, TransitionablePortal } from "semantic-ui-react";

import Icon from "../../Icon/Icon";
import RkoModal from "../RkoModal/RkoModal";

import "./ModalLogout.scss";

const ModalLogout = ({ open, close, onLogout }) => {
  const handleClose = () => {
    document.body.classList.remove("modal-fade-in");
    close();
  };

  return (
    <TransitionablePortal
      open={open}
      onOpen={() =>
        setTimeout(() => document.body.classList.add("modal-fade-in"), 0)
      }
      transition={{ animation: "fade down", duration: 300 }}
    >
      <RkoModal
        size="mini"
        centered={false}
        open={true}
        onClose={handleClose}
        className="modal-logout"
      >
        <Modal.Content>
          <Modal.Description>
            <p>Are you sure you want to sign out?</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            icon={<Icon embedded className="fas fa-chevron-right" />}
            labelPosition="right"
            content={"Sign out"}
            onClick={onLogout}
          ></Button>
        </Modal.Actions>
      </RkoModal>
    </TransitionablePortal>
  );
};

export default ModalLogout;
