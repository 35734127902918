import React from "react";
import { Grid, Header } from "semantic-ui-react";

import Enum from "../../helpers/enums";
import TournamentRulesSummary from "../TournamentRulesSummary/TournamentRulesSummary";

const GroupMembersHeading = ({
  currentTournament,
  tournamentStatusText,
  tournamentStatusIcon,
  children,
}) => {
  return (
    <Grid
      columns="equal"
      className={`group-members-list-heading ${
        tournamentStatusText ? "has-status" : ""
      }
      ${
        currentTournament?.state === Enum.TournamentState.ACTIVE ?
          "has-rules"
        : ""
      }
      `}
    >
      <Grid.Column textAlign="left">
        <Header as="h5">{children}</Header>
        {!!tournamentStatusText && (
          <div className="group-members-list-heading-tournament-status">
            {tournamentStatusIcon}
            {tournamentStatusText}
          </div>
        )}

        {currentTournament?.state === Enum.TournamentState.ACTIVE && (
          <TournamentRulesSummary
            firstToGames={currentTournament.firstToGames}
            stakeIsPositive={currentTournament.stakeIsPositive}
            gameLengthMinutes={currentTournament.gameLengthMinutes}
          ></TournamentRulesSummary>
        )}
      </Grid.Column>
    </Grid>
  );
};

export default GroupMembersHeading;
