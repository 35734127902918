import React from "react";

import { Button } from "semantic-ui-react";

import DecisionsRepeating from "../../../../../components/DecisionsRepeating/DecisionsRepeating";
import Icon from "../../../../../components/Icon/Icon";

function RepeatingViewDefault({
  group,
  handleViewRepeatingTournament,
  handleNewRepeatingGame,
}) {
  return (
    <>
      <div className="repeating-decisions">
        <DecisionsRepeating
          group={group}
          onViewTournament={handleViewRepeatingTournament}
        ></DecisionsRepeating>
      </div>

      <div className="action-panel">
        <Button icon size="big" primary onClick={handleNewRepeatingGame}>
          Set up repeating game
          <Icon className="far fa-chevron-right"></Icon>
        </Button>
      </div>
    </>
  );
}

export default RepeatingViewDefault;
