import React from "react";

const Plural = ({ value, base, plural, suffix }) => {
  if (!base) return null;
  if (!suffix) suffix = `${base[base.length - 1] === "s" ? "e" : ""}s`;
  if (!plural) plural = base + suffix;

  return <span>{value === 1 ? base : plural}</span>;
};

export default Plural;
