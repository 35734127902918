import React from "react";
import { Button, Form, Modal, TransitionablePortal } from "semantic-ui-react";

import InputCopy from "../../Form/InputCopy/InputCopy";
import Icon from "../../Icon/Icon";
import ShareButton from "../../ShareButtons/ShareButton";
import RkoModal from "../RkoModal/RkoModal";

import "./ModalInvite.scss";

const ModalInvite = ({ group, tournament, open, close }) => {
  const handleClose = () => {
    document.body.classList.remove("modal-fade-in");
    close();
  };

  return (
    <TransitionablePortal
      open={open}
      onOpen={() =>
        setTimeout(() => document.body.classList.add("modal-fade-in"), 0)
      }
      transition={{ animation: "fade down", duration: 300 }}
    >
      <RkoModal
        size="tiny"
        centered={false}
        open={true}
        onClose={handleClose}
        className="modal-invite"
      >
        <Modal.Content>
          <Modal.Description>
            Invite others to join by sharing the link
            <Form>
              <Form.Field>
                <input
                  type="text"
                  className="input"
                  readOnly={true}
                  value={`${window.location.origin}/i/${group?.inviteCode}`}
                />
              </Form.Field>
            </Form>
            <div className="group-member-button-group">
              <InputCopy
                value={`${window.location.origin}/i/${group?.inviteCode}`}
                buttonText="Copy"
                size="large"
                hideInput={true}
              />
              <ShareButton group={group} currentTournament={tournament}>
                <Button primary size="large" onClick={handleClose}>
                  <Icon className="far fa-share"></Icon> Share
                </Button>
              </ShareButton>
            </div>
          </Modal.Description>
        </Modal.Content>
      </RkoModal>
    </TransitionablePortal>
  );
};

export default ModalInvite;
