import moment from "moment";
import React, { useState, useEffect } from "react";

function TimeAgo({ time }) {
  const calculateTimeAgo = () => {
    const now = moment();
    const providedTime = moment(time);

    // Check if the provided time is in the future
    if (providedTime.isAfter(now)) {
      return "just now"; // Or any text you prefer for future times
    } else {
      return providedTime.fromNow();
    }
  };

  const [timeAgo, setTimeAgo] = useState(calculateTimeAgo());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeAgo(calculateTimeAgo());
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [time]);

  return <span>{timeAgo}</span>;
}

export default TimeAgo;
