import React, { useState } from "react";

import Icon from "../Icon/Icon";

import "./WebViewBrowserWarning.scss";

function WebViewBrowserWarning({ platform }) {
  const [dismissed, setDismissed] = useState(false);

  const onDismissClick = () => {
    setDismissed(true);
  };

  return !dismissed && !!platform ?
      <div id="web-view-browser-warning" className={platform}>
        <div>
          <span onClick={onDismissClick} className="ip-dismiss">
            <Icon className="far fa-times"></Icon>
          </span>
          {platform === "Android" && (
            <>
              <div className="ip-text">
                RKO works best in your full browser. Tap the{" "}
                <Icon className="far fa-ellipsis-v"></Icon> menu at the
                top-right
                <br />
                then tap <Icon className="far fa-sign-in"></Icon>
                &nbsp;&nbsp;Open&nbsp;in&nbsp;browser
              </div>
              <span className="ip-arrow">
                <Icon className="far fa-arrow-up"></Icon>
              </span>
            </>
          )}
          {platform === "iOS" && (
            <>
              <div className="ip-text">
                RKO works best in your full browser. Tap the{" "}
                <Icon className="far fa-arrow-up-from-square"></Icon> menu at
                the bottom-right
                <br />
                then tap <Icon className="far fa-sign-in"></Icon>
                &nbsp;&nbsp;Open&nbsp;in&nbsp;Safari
              </div>
              <span className="ip-arrow">
                <Icon className="far fa-arrow-down"></Icon>
              </span>
            </>
          )}
        </div>
      </div>
    : null;
}

export default WebViewBrowserWarning;
