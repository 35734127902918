import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { Link, Route, Switch, useLocation } from "react-router-dom";

import { Container, Dropdown, Menu } from "semantic-ui-react";

import Icon from "../../components/Icon/Icon";
import MaxPlus from "../../components/MaxPlus/MaxPlus";
import ModalInstallAppEnableNotifications from "../../components/Modal/ModalInstallAppEnableNotifications/ModalInstallAppEnableNotifications";
import ShareButton from "../../components/ShareButtons/ShareButton";
import AuthContext from "../../contexts/AuthContext";
import { useInstallPrompt } from "../../contexts/InstallPromptContext";
import { rpsApi } from "../../helpers/api-config";
import { GSLUG_PARAM, GSLUG_REGEX } from "../../utilities";
import LoggedInMaster from "../LoggedInMaster/LoggedInMaster";

import "./TopNavMaster.scss";

const TopNavMaster = (props) => {
  const { authState } = React.useContext(AuthContext);

  const [currentGroupName, setCurrentGroupName] = useState("");
  const [currentPageTheme, setCurrentPageTheme] =
    useState("page-theme-default");

  const {
    hub,
    groups,
    groupsLoaded,
    currentGroup,
    currentGroupMembers,
    registerHubStateListener,
    clearHubStateListeners,
    onGroupClosed,
    onGroupSubscribed,
    onLogout,
    setCurrentGroup,
    onDecisionParticipating,
    clearUnseenRepeatingForGroup,
    history,
    onLoadMoreGroups,
    onChangeUserName,
    onChangeTagline,
    notificationsLoaded,
    loadAccountNotifications,
    clearAccountNotifications,
    onModalRulesOpen,
  } = props;

  const reHistoryDetail = new RegExp("/history/([0-9])+/?$");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [
    modalInstallAppEnableNotificationsOpen,
    setModalInstallAppEnableNotificationsOpen,
  ] = useState(false);
  const [
    modalInstallAppEnableNotificationsKey,
    setModalInstallAppEnableNotificationsKey,
  ] = useState(new Date());
  const dropdownRef = useRef(null);

  const {
    isStandalone,
    deferredPrompt,
    onInstallClick,
    showFreshInstallModal,
  } = useInstallPrompt();

  const currentLocation = useLocation();
  var qs = queryString.parse(window.location.search);

  const handleGroupSubscribed = (group) => {
    setCurrentGroupName(
      group?.name ? group.name
      : group?.slug ? `Group ${group.slug}`
      : null,
    );
    if (onGroupSubscribed) onGroupSubscribed(group);
  };

  const handleDropdownOpen = () => {
    setDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  const onGames = () => {
    history.push("/games");
  };

  // const onNewGroup = () => {
  //   history.push("/g/new");
  // };

  const onSettings = () => {
    history.push("/settings");
  };

  const onAbout = () => {
    history.push("/about");
  };

  const onSolo = () => {
    history.push("/g/solo");
  };

  const handleModalInstallAppEnableNotificationsOpen = () => {
    setModalInstallAppEnableNotificationsKey(new Date());
    setModalInstallAppEnableNotificationsOpen(true);
  };

  const handleModalInstallAppEnableNotificationsClose = () => {
    setModalInstallAppEnableNotificationsOpen(false);
  };

  useEffect(() => {
    if (currentLocation.pathname.indexOf("/g/solo") === 0)
      setCurrentPageTheme("page-theme-solo");
    else if (currentLocation.pathname.indexOf("/g/rkotourn") === 0)
      setCurrentPageTheme("page-theme-tournament");
    else if (
      currentLocation.pathname.indexOf("/groups") === 0 ||
      currentLocation.pathname.indexOf("/g/") === 0
    )
      setCurrentPageTheme("page-theme-friends");
    else setCurrentPageTheme("page-theme-default");
  }, [currentLocation.pathname]);

  useEffect(() => {
    if (showFreshInstallModal) {
      handleModalInstallAppEnableNotificationsOpen();
    }
  }, [showFreshInstallModal]);

  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.classList.toggle("open", dropdownOpen);
    }
  }, [dropdownOpen]);

  return (
    <>
      <div id="top-nav" className={`${currentPageTheme}`}>
        <Container>
          {currentLocation.pathname === "/games" && (
            <Menu.Item className={`random-logo`}>
              <span>random</span>KO <span className="beta">beta</span>
            </Menu.Item>
          )}

          {currentLocation.pathname.match(/\/groups*/) && (
            <>
              <Menu.Item as={Link} to="/games">
                <Icon className="fas fa-circle-arrow-left"></Icon>
              </Menu.Item>
              <Menu.Item as={Link} to="/games">
                <span>Friend groups</span>
              </Menu.Item>
            </>
          )}

          {currentLocation.pathname === "/g/new" && (
            <>
              <Menu.Item as={Link} to="/groups">
                <Icon className="fas fa-circle-arrow-left"></Icon>
              </Menu.Item>
              <Menu.Item as={Link} to="/groups">
                <span>New group</span>
              </Menu.Item>
            </>
          )}

          {currentLocation.pathname === "/g/solo" && (
            <>
              <Menu.Item as={Link} to="/games">
                <Icon className="fas fa-circle-arrow-left"></Icon>
              </Menu.Item>
              <Menu.Item as={Link} to="/games">
                <span>Play solo</span>
              </Menu.Item>
            </>
          )}

          {currentLocation.pathname === "/g/rkotourn" && (
            <>
              <Menu.Item as={Link} to="/games">
                <Icon className="fas fa-circle-arrow-left"></Icon>
              </Menu.Item>
              <Menu.Item as={Link} to="/games">
                <span>RKO Tournament</span>
              </Menu.Item>
            </>
          )}

          {currentLocation.pathname.indexOf("play") > -1 && (
            <>
              <Menu.Item
                as={Link}
                to={currentLocation.pathname.split("/play")[0]}
              >
                <Icon className="fas fa-circle-arrow-left"></Icon>
              </Menu.Item>
              <Menu.Item
                as={Link}
                to={currentLocation.pathname.split("/play")[0]}
              >
                <span>Your match</span>
              </Menu.Item>
            </>
          )}

          {currentLocation.pathname.indexOf("history") > -1 && (
            <>
              {!reHistoryDetail.test(currentLocation.pathname) && (
                <>
                  <Menu.Item
                    as={Link}
                    to={currentLocation.pathname.split("/history")[0]}
                  >
                    <Icon className="fas fa-circle-arrow-left"></Icon>
                  </Menu.Item>
                  <Menu.Item
                    as={Link}
                    to={currentLocation.pathname.split("/history")[0]}
                  >
                    <span>Past games</span>
                  </Menu.Item>
                </>
              )}
              {reHistoryDetail.test(currentLocation.pathname) && (
                <>
                  <Menu.Item
                    as={Link}
                    to={
                      currentLocation.pathname.split("/history")[0] +
                      (qs.direct === "true" ? "" : "/history")
                    }
                  >
                    <Icon className="fas fa-circle-arrow-left"></Icon>
                  </Menu.Item>
                  <Menu.Item
                    as={Link}
                    to={
                      currentLocation.pathname.split("/history")[0] +
                      (qs.direct === "true" ? "" : "/history")
                    }
                  >
                    <span>Game results</span>
                  </Menu.Item>
                </>
              )}
            </>
          )}

          {currentLocation.pathname.indexOf("cancelled") > -1 && (
            <>
              <Menu.Item
                as={Link}
                to={currentLocation.pathname.split("/cancelled")[0]}
              >
                <Icon className="fas fa-circle-arrow-left"></Icon>
              </Menu.Item>
              <Menu.Item
                as={Link}
                to={currentLocation.pathname.split("/cancelled")[0]}
              >
                <span>Game cancelled</span>
              </Menu.Item>
            </>
          )}

          {currentLocation.pathname.indexOf("results") > -1 && (
            <>
              <Menu.Item
                as={Link}
                to={currentLocation.pathname.split("/results")[0]}
              >
                <Icon className="fas fa-circle-arrow-left"></Icon>
              </Menu.Item>
              <Menu.Item
                as={Link}
                to={currentLocation.pathname.split("/results")[0]}
              >
                <span>Game played</span>
              </Menu.Item>
            </>
          )}

          {currentLocation.pathname.indexOf("settings") > -1 && (
            <>
              <Menu.Item onClick={() => window.history.back()}>
                <Icon className="fas fa-circle-arrow-left"></Icon>
              </Menu.Item>
              <Menu.Item onClick={() => window.history.back()}>
                <span>Settings</span>
              </Menu.Item>
            </>
          )}

          {currentLocation.pathname.indexOf("notifications") > -1 && (
            <>
              <Menu.Item onClick={() => window.history.back()}>
                <Icon className="fas fa-circle-arrow-left"></Icon>
              </Menu.Item>
              <Menu.Item onClick={() => window.history.back()}>
                <span>Notifications</span>
              </Menu.Item>
            </>
          )}

          {currentLocation.pathname.indexOf("help") > -1 && (
            <>
              <Menu.Item onClick={() => window.history.back()}>
                <Icon className="fas fa-circle-arrow-left"></Icon>
              </Menu.Item>
              <Menu.Item onClick={() => window.history.back()}>
                <span>Help</span>
              </Menu.Item>
            </>
          )}

          {currentLocation.pathname.indexOf("about") > -1 && (
            <>
              <Menu.Item onClick={() => window.history.back()}>
                <Icon className="fas fa-circle-arrow-left"></Icon>
              </Menu.Item>
              <Menu.Item onClick={() => window.history.back()}>
                <span>About</span>
              </Menu.Item>
            </>
          )}

          {currentLocation.pathname.indexOf("/g/") > -1 &&
            currentLocation.pathname !== "/games" &&
            currentLocation.pathname !== "/g/new" &&
            currentLocation.pathname !== "/g/solo" &&
            currentLocation.pathname !== "/g/rkotourn" &&
            currentLocation.pathname.indexOf("play") === -1 &&
            currentLocation.pathname.indexOf("history") === -1 &&
            currentLocation.pathname.indexOf("cancelled") === -1 &&
            currentLocation.pathname.indexOf("results") === -1 &&
            currentLocation.pathname.indexOf("notifications") === -1 &&
            currentLocation.pathname.indexOf("settings") === -1 &&
            currentLocation.pathname !== "/about" &&
            currentLocation.pathname !== "/help" && (
              <>
                <Menu.Item as={Link} to="/groups">
                  <Icon className="fas fa-circle-arrow-left"></Icon>
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to="/groups"
                  className={`group-name-menu ${
                    currentGroupMembers ? "" : "group-name-menu-no-members"
                  }`}
                >
                  {!currentGroupName && (
                    <>
                      <span className="group-name-menu-name">&nbsp;</span>
                      <span className="group-name-menu-members">&nbsp;</span>
                    </>
                  )}
                  {currentGroupName && (
                    <span className="group-name-menu-name">
                      {currentGroupName}
                    </span>
                  )}
                  {currentGroupMembers && (
                    <span className="group-name-menu-members">
                      {currentGroupMembers}
                    </span>
                  )}
                </Menu.Item>
              </>
            )}

          {(!authState || !authState.isLoading) && (
            <Menu.Menu position="right">
              <Menu.Item as={Link} to={"/notifications"}>
                <div className="notification-bell">
                  <Icon className="far fa-bell"></Icon>
                  {authState?.account?.unseenNotifications > 0 && (
                    <div className="notification-bell-badge">
                      <MaxPlus
                        value={authState?.account?.unseenNotifications}
                        maximum={50}
                      />
                    </div>
                  )}
                </div>
              </Menu.Item>
              <Dropdown
                id="dropdown-account"
                className={`${dropdownOpen ? "open" : ""}`}
                direction="left"
                item
                trigger={
                  <div className="dropdown-account-trigger">
                    {dropdownOpen && (
                      <Icon className="far fa-fw fa-times"></Icon>
                    )}
                    {!dropdownOpen && (
                      <Icon className="far fa-fw fa-bars"></Icon>
                    )}
                  </div>
                }
                open={dropdownOpen}
                onOpen={handleDropdownOpen}
                onClose={handleDropdownClose}
              >
                <Dropdown.Menu className="user-dropdown">
                  <Dropdown.Item
                    className="user-dropdown-item ellipsis-overflow"
                    onClick={onSettings}
                    image={{
                      avatar: true,
                      src: `${rpsApi.defaults.baseURL}/account/avatar/${authState?.account?.id}.jpg`,
                    }}
                    text={authState?.account?.userName}
                  />
                  <Dropdown.Item onClick={onLogout}>
                    <Icon className="far fa-sign-out fa-fw"></Icon> Sign out
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  {!isStandalone && !!deferredPrompt && (
                    <Dropdown.Item onClick={onInstallClick}>
                      <Icon className="far fa-plus-circle fa-fw"></Icon> Add to
                      home screen
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item onClick={onGames}>
                    <Icon className="far fa-dice fa-fw"></Icon> Games
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onSettings}>
                    <Icon className="far fa-gear fa-fw"></Icon> Settings
                  </Dropdown.Item>
                  <Dropdown.Item onClick={onAbout}>
                    <Icon className="far fa-question-circle fa-fw"></Icon> About
                  </Dropdown.Item>
                  <Dropdown.Item href="/terms" target="_self">
                    <Icon className="far fa-file-contract fa-fw"></Icon> Terms
                  </Dropdown.Item>
                  <Dropdown.Item href="/privacy" target="_self">
                    <Icon className="far fa-user-shield fa-fw"></Icon> Privacy
                  </Dropdown.Item>
                  <ShareButton>
                    <Dropdown.Item onClick={() => {}}>
                      <Icon className="far fa-share-from-square fa-fw"></Icon>{" "}
                      Share
                    </Dropdown.Item>
                  </ShareButton>
                  <Dropdown.Item onClick={onSolo}>
                    <Icon className="far fa-user fa-fw"></Icon> Play solo
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          )}
        </Container>
      </div>
      <div id="main" className={`${currentPageTheme}`}>
        {/* <UpdatePromptButton /> */}
        <Switch>
          <Route
            exact
            path={[
              `/g/${GSLUG_PARAM}${GSLUG_REGEX}/:history(history)?/:id([0-9]+)?`,
              `/g/${GSLUG_PARAM}${GSLUG_REGEX}/:cancelled(cancelled)?`,
              `/g/${GSLUG_PARAM}${GSLUG_REGEX}/:results(results)?`,
              `/g/${GSLUG_PARAM}${GSLUG_REGEX}/:play(play)?`,
              "/g/new",
              "/g/solo",
              "/groups",
              "/notifications",
              "/settings",
              "/help",
              "/about",
              "/games",
            ]}
            //path="/g/:slug(promo)"
            render={(props) => (
              <LoggedInMaster
                hub={hub}
                registerHubStateListener={registerHubStateListener}
                clearHubStateListeners={clearHubStateListeners}
                slug={props.match.params.slug}
                pathIsCancelled={props.match.params.cancelled}
                pathIsResults={props.match.params.results}
                pathIsHistory={props.match.params.history}
                pathIsPlay={props.match.params.play}
                pathTournamentId={props.match.params.id}
                groups={groups}
                groupsLoaded={groupsLoaded}
                onLoadMoreGroups={onLoadMoreGroups}
                currentGroup={currentGroup}
                onGroupClosed={onGroupClosed}
                onGroupSubscribed={handleGroupSubscribed}
                onModalRulesOpen={onModalRulesOpen}
                setCurrentGroup={setCurrentGroup}
                onDecisionParticipating={onDecisionParticipating}
                clearUnseenRepeatingForGroup={clearUnseenRepeatingForGroup}
                onChangeUserName={onChangeUserName}
                onChangeTagline={onChangeTagline}
                notificationsLoaded={notificationsLoaded}
                loadAccountNotifications={loadAccountNotifications}
                clearAccountNotifications={clearAccountNotifications}
                {...props}
              />
            )}
          />
        </Switch>
      </div>

      <ModalInstallAppEnableNotifications
        key={
          "install-app-enable-notifications-" +
          modalInstallAppEnableNotificationsKey
        }
        open={modalInstallAppEnableNotificationsOpen}
        close={handleModalInstallAppEnableNotificationsClose}
      />
    </>
  );
};

export default TopNavMaster;
