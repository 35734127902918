import React from "react";
import { useHistory } from "react-router-dom";
import { Loader, Menu } from "semantic-ui-react";

import NotificationsListItem from "./NotificationsListItem/NotificationsListItem";

import "./NotificationsList.scss";
import EnableNotificationsPromptButton from "../EnableNotificationsPrompt/EnableNotificationsPromptButton";
import Icon from "../Icon/Icon";
import TextButton from "../TextButton/TextButton";

const NotificationsList = ({ notificationsLoaded, notifications }) => {
  const history = useHistory();

  const onReturnToGroups = () => {
    history.push("/groups");
  };

  return (
    <div className="notifications-list">
      <EnableNotificationsPromptButton
        promptJsx={
          <>
            <Icon className="far fa-bell"></Icon> Want these notifications sent
            to your device?
            <br />
            Tap here to set up
          </>
        }
        enabledJsx={
          <>
            <Icon className="far fa-bell"></Icon> New notifications will be sent
            to your device
          </>
        }
      />
      {!notificationsLoaded && <Loader active content="Loading..."></Loader>}
      {notificationsLoaded && (
        <div className="notifications-list-items">
          <Menu vertical>
            {(!notifications ||
              !notifications.data ||
              !notifications.data.length) && (
              <div className="no-notifications">
                <div className="no-notifications-copy">
                  You have no notifications.
                </div>
                <TextButton onClick={onReturnToGroups}>
                  Return to groups list
                </TextButton>
              </div>
            )}
            {notifications?.data &&
              notifications.data.map((notification) => {
                return (
                  <NotificationsListItem
                    key={notification.id}
                    notification={notification}
                  />
                );
              })}
          </Menu>
        </div>
      )}
    </div>
  );
};

export default NotificationsList;
