import React, { useMemo, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button, Input } from "semantic-ui-react";

import Icon from "../../Icon/Icon";

const InputCopy = ({ value, className, hideInput, buttonText, size }) => {
  const [copied, setCopied] = useState(false);

  let copy = useMemo(
    () => (
      <CopyToClipboard
        text={value}
        onCopy={() => {
          if (!copied)
            setTimeout(() => {
              setCopied(false);
            }, 3000);

          setCopied(true);
        }}
      >
        <Button primary size={size || "medium"} className={className}>
          {!copied && (
            <span>
              <Icon className="far fa-copy" /> {buttonText || "Copy link"}
            </span>
          )}

          {copied && (
            <span>
              <Icon className="far fa-check" /> Copied
            </span>
          )}
        </Button>
      </CopyToClipboard>
    ),
    [value, size, className, copied, buttonText],
  );

  return (
    <>
      {hideInput && copy}
      {!hideInput && (
        <Input type="text" defaultValue={value} action>
          <input />
          {copy}
        </Input>
      )}
    </>
  );
};

export default InputCopy;
