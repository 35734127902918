import React from "react";
import { List } from "semantic-ui-react";

import GroupMemberBadges from "./GroupMemberBadges/GroupMemberBadges";
import { config } from "../../../config";
import Enum from "../../../helpers/enums";
import Avatar from "../../Avatar/Avatar";

import "./GroupMember.scss";

const GroupMember = ({
  id,
  member,
  onMemberClick,
  order,
  useNumbering,
  deleted,
  hideParticipating,
  currentTournament,
  showVsAi,
}) => {
  const handleMemberClick = (id, member) => {
    onMemberClick(id, member);
  };

  return (
    <>
      <List.Item
        className={`group-member ${
          member.notMember ? "group-member-not-member" : ""
        } ${
          !!member.listMatchPosition || member.aiOpponent ?
            (
              member.listMatchPosition === Enum.GroupListMatchPosition.TOP ||
              member.aiOpponent
            ) ?
              "group-member-match-top"
            : "group-member-match-bottom"
          : "group-member-match-none"
        }`}
      >
        {!deleted && (
          <div>
            <Avatar
              user={member.accountId}
              onClick={() => handleMemberClick(id, member)}
            />
            <List.Content>
              <span>
                {useNumbering && <b className="order">{order + 1}. </b>}
                <span onClick={() => handleMemberClick(id, member)}>
                  {member.accountUserName}
                </span>
                <GroupMemberBadges
                  onClick={() => handleMemberClick(id, member)}
                  member={member}
                  hideParticipating={hideParticipating}
                  currentTournament={currentTournament}
                />
              </span>
            </List.Content>
          </div>
        )}
        {deleted && (
          <div className="group-member-deleted">
            <Avatar user={-1} />
            <List.Content>
              <span>
                {useNumbering && <b className="order">{order + 1}. </b>}
                <span>Deleted user</span>
              </span>
            </List.Content>
          </div>
        )}
      </List.Item>
      {showVsAi && member.aiOpponent && (
        <List.Item className="group-member group-member-match-bottom">
          <div>
            <Avatar
              user={config.aiAccountMember.accountId}
              onClick={() =>
                handleMemberClick(
                  config.aiAccountMember.accountId,
                  config.aiAccountMember,
                )
              }
            />
            <List.Content>
              <span>
                <span
                  onClick={() =>
                    handleMemberClick(
                      config.aiAccountMember.accountId,
                      config.aiAccountMember,
                    )
                  }
                >
                  {config.aiAccountMember.accountUserName}
                </span>
              </span>
            </List.Content>
          </div>
        </List.Item>
      )}
    </>
  );
};

export default GroupMember;
