import React, { createContext, useContext, useState, useEffect } from "react";

import { isIOS } from "react-device-detect";

const InstallPromptContext = createContext();

export function useInstallPrompt() {
  return useContext(InstallPromptContext);
}

export function InstallPromptProvider({ children }) {
  const [deferredPrompt, setDeferredPrompt] = useState(
    window.deferredInstallPromptEvent || null,
  );

  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [isStandalone, setIsStandalone] = useState(false);
  const [hasSeenPrompt, setHasSeenPrompt] = useState(false);
  const [showFreshInstallModal, setShowFreshInstallModal] = useState(false);

  useEffect(() => {
    const _isStandalone =
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone;
    const _hasSeenPrompt = localStorage.getItem("installPromptSeen") === "true";
    const _freshInstallModalSeen = checkFreshInstallModalSeen();
    setIsStandalone(_isStandalone);
    setHasSeenPrompt(_hasSeenPrompt);

    if (_isStandalone && _hasSeenPrompt) {
      setIsAppInstalled(true);
    }

    if (!_freshInstallModalSeen && _isStandalone) {
      setShowFreshInstallModal(true);
      localStorage.setItem("freshInstallModalSeen", "true");
    }

    const handleDeferredPrompt = (e) => {
      setDeferredPrompt(e);
    };

    // Update state if global event changes after component mount
    window.addEventListener("deferredInstallPromptSet", (e) => {
      handleDeferredPrompt(e.detail);
    });

    return () => {
      window.removeEventListener(
        "deferredInstallPromptSet",
        handleDeferredPrompt,
      );
    };
  }, []);

  const checkFreshInstallModalSeen = () => {
    const seenData = localStorage.getItem("freshInstallModalSeen");
    return !!seenData;
  };

  const onInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          localStorage.setItem("installPromptSeen", "true");
        }
        setDeferredPrompt(null);
        setHasSeenPrompt(true);
      });
    }
  };

  const showIosInstall =
    !(
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone
    ) &&
    (isIOS ||
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform));

  const value = {
    deferredPrompt,
    isAppInstalled,
    isStandalone,
    hasSeenPrompt,
    showIosInstall,
    onInstallClick,
    showFreshInstallModal,
  };

  return (
    <InstallPromptContext.Provider value={value}>
      {children}
    </InstallPromptContext.Provider>
  );
}
