import React, { useContext, useMemo, useState } from "react";
import { Button, Modal, TransitionablePortal } from "semantic-ui-react";

import AuthContext from "../../../contexts/AuthContext";
import { useInstallPrompt } from "../../../contexts/InstallPromptContext";
import EnableNotificationsPrompt from "../../EnableNotificationsPrompt/EnableNotificationsPrompt";
import Icon from "../../Icon/Icon";
import TextButton from "../../TextButton/TextButton";
import ModalUnblockNotifications from "../ModalUnblockNotifications/ModalUnblockNotifications";
import RkoModal from "../RkoModal/RkoModal";

import "./ModalInstallAppEnableNotifications.scss";

const ModalInstallAppEnableNotifications = ({ open, close }) => {
  const { authState } = useContext(AuthContext);
  const { showIosInstall, isAppInstalled, deferredPrompt, onInstallClick } =
    useInstallPrompt();

  const [appInstallPromptDismissed, setAppInstallPromptDismissed] =
    useState(false);
  const [notificationsClicked, setNotificationsClicked] = useState(false);
  const [unblockNotificationsOpen, setUnblockNotificationsOpen] =
    useState(false);
  const [unblockNotificationsKey, setUnblockNotificationsKey] = useState(
    new Date(),
  );

  const handleModalUnblockNotificationsOpen = () => {
    setUnblockNotificationsKey(new Date());
    setUnblockNotificationsOpen(true);
  };

  const handleModalUnblockNotificationsClose = () => {
    setUnblockNotificationsOpen(false);
  };

  const handleClose = () => {
    document.body.classList.remove("modal-fade-in");
    close();
  };

  const showAppInstall = useMemo(() => {
    console.log("deferredPrompt", deferredPrompt);
    console.log("isAppInstalled", isAppInstalled);
    return !!deferredPrompt && !isAppInstalled;
  }, [deferredPrompt, isAppInstalled]);

  return (
    <>
      <TransitionablePortal
        open={open}
        onOpen={() =>
          setTimeout(() => document.body.classList.add("modal-fade-in"), 0)
        }
        transition={{ animation: "fade down", duration: 300 }}
      >
        <RkoModal
          size="small"
          centered={false}
          open={true}
          onClose={handleClose}
          className="modal-install-app-enable-notifications"
        >
          <Modal.Content>
            <Modal.Description>
              {showAppInstall && !appInstallPromptDismissed && (
                <>
                  <p className="iaen-intro">
                    Install the randomKO app
                    <br />
                    to receive important game updates
                  </p>

                  <div className="join-wrapper">
                    <Button
                      onClick={onInstallClick}
                      size="large"
                      color="blue"
                      className="shiny"
                    >
                      <span>
                        <Icon className="far fa-plus-circle fa-fw"></Icon>
                        <span className="device-desktop-hide">
                          Add to home screen
                        </span>
                        <span className="device-desktop-show-inline">
                          Install randomKO
                        </span>
                      </span>
                    </Button>
                  </div>
                  <div className="text-buttons">
                    <TextButton
                      onClick={() => setAppInstallPromptDismissed(true)}
                    >
                      No thanks
                    </TextButton>
                  </div>
                </>
              )}

              {(showIosInstall ||
                !showAppInstall ||
                appInstallPromptDismissed) && (
                <>
                  {isAppInstalled && (
                    <p className="iaen-intro">Thanks for installing randomKO</p>
                  )}
                  {showIosInstall && (
                    <p className="iaen-intro">
                      Add randomKO to your home screen
                      <br />
                      Once complete, you can enable notifications
                    </p>
                  )}

                  {!showIosInstall && (
                    <p className="iaen-intro">
                      Enable notifications to receive important
                      <br />
                      game updates and results
                    </p>
                  )}

                  <EnableNotificationsPrompt
                    onModalUnblockNotificationsOpen={
                      handleModalUnblockNotificationsOpen
                    }
                    notificationsClicked={notificationsClicked}
                    setNotificationsClicked={setNotificationsClicked}
                  ></EnableNotificationsPrompt>

                  <div className="text-buttons">
                    <TextButton onClick={handleClose}>
                      {authState?.notifyEnabled ?
                        <>
                          Continue{" "}
                          <Icon className="far fa-chevron-right"></Icon>
                        </>
                      : <>No thanks</>}
                    </TextButton>
                  </div>
                </>
              )}
            </Modal.Description>
          </Modal.Content>
        </RkoModal>
      </TransitionablePortal>

      <ModalUnblockNotifications
        key={"unblock-notifications-" + unblockNotificationsKey}
        open={unblockNotificationsOpen}
        close={handleModalUnblockNotificationsClose}
      />
    </>
  );
};

export default ModalInstallAppEnableNotifications;
