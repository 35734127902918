import React from "react";
import { Popup } from "semantic-ui-react";

import "./HelpNode.scss";

const HelpNode = ({
  trigger,
  iconClass,
  content,
  size,
  offset,
  position,
  inverted,
}) => {
  return (
    <div
      className={`help-node help-node-${trigger ? "unstyled" : "styled"} ${
        inverted ? "inverted" : ""
      }`}
    >
      <Popup
        trigger={
          trigger ? (
            trigger
          ) : (
            <span>
              <i className={`far ${iconClass || "fa-question-circle"}`} />
            </span>
          )
        }
        content={content}
        size={size || "tiny"}
        offset={offset || [-12, 0]}
        position={position || "top left"}
      />
    </div>
  );
};

export default HelpNode;
