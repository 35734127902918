import { config } from "../config";
import Enum from "../helpers/enums";

class Match {
  // static functions as state is immutable

  static fromRoundStart(startMatch, is3KO, fullGame, fullMatchAccounts) {
    var newMatch = new Match();

    newMatch.id = startMatch.id;
    newMatch.endReason = null;
    newMatch.randomResult = false;
    newMatch.isThreePlayerKnockout = is3KO;
    newMatch.noOutcome = false;
    newMatch.completed = false;
    newMatch.games = [fullGame];
    newMatch.matchAccounts = fullMatchAccounts;
    newMatch.moves = [];
    newMatch.roundNumber = startMatch.roundNumber;
    newMatch.roundTitle = startMatch.roundTitle;    

    newMatch.vsAi = newMatch.matchAccounts.some((ma) => ma.accountId === config.aiAccountMember.accountId);

    return newMatch;
  }

  static fromTournamentInit(match, account) {
    if (!match) return null;

    var newMatch = new Match();

    newMatch.completed = match.completed;

    if (match.advancers)
      newMatch.advancers = [...match.advancers];

    newMatch.endReason = match.endReason;
    newMatch.games = match.games;
    newMatch.id = match.id;
    newMatch.isThreePlayerKnockout = match.isThreePlayerKnockout;
    newMatch.matchAccounts = match.matchAccounts;
    newMatch.noOutcome = match.noOutcome;
    newMatch.randomResult = match.randomResult;
    newMatch.moves = match.moves;
    newMatch.roundTitle = match.roundTitle;    

    newMatch.iAdvanced =
      newMatch.completed &&
      newMatch.advancers?.some((a) => a.accountId === account.id);
      console.log(newMatch.iAdvanced);

    newMatch.vsAi = match.matchAccounts.some((ma) => ma.accountId === config.aiAccountMember.accountId);

    return newMatch;
  }

  static patchMatchFinishedEvent(
    prevMatch,
    data,
    account,
    stakeIsPositive,
    roundNumber
  ) {
    let patchedMatch = Object.assign(new Match(), {
      ...prevMatch,
      completed: true,
      endReason: data.endReason,
      randomResult: data.randomResult,
      noOutcome: data.noOutcome,
      matchAccounts: JSON.parse(JSON.stringify(data.matchAccounts)),
      roundNumber: roundNumber,
    });

    if (patchedMatch.matchAccounts) {
      for (let i = 0; i < patchedMatch.matchAccounts.length; i++) {
        if (account && patchedMatch.matchAccounts[i].accountId === account.id) {
          patchedMatch.iAdvanced =
            (stakeIsPositive &&
              patchedMatch.matchAccounts[i].result === Enum.ResultType.WIN) ||
            (!stakeIsPositive &&
              patchedMatch.matchAccounts[i].result === Enum.ResultType.LOSS);
        }
      }
    }

    return patchedMatch;
  }

  static patchMatchScoreUpdateEvent(prevMatch, data) {
    let patchedMatch = Object.assign(new Match(), {
      ...prevMatch,
    });

    patchedMatch.matchAccounts.forEach((ma) => {
      ma.score = data.matchAccountScores.find(
        (s) => s.accountId === ma.accountId
      )?.score;
    });

    return patchedMatch;
  }

  static patchGameStartedEvent(prevMatch, newGame, newMoves) {
    return Object.assign(new Match(), {
      ...prevMatch,
      games: [newGame, ...prevMatch.games],
      moves: prevMatch.moves ? [...prevMatch.moves, ...newMoves] : newMoves,
    });
  }

  static patchMatchIMovedEvent(prevMatch, newGame) {
    let gameIdx = prevMatch.games.findIndex((g) => g.id === newGame.id);

    if (gameIdx === -1) return prevMatch;

    const patchedGames = [...prevMatch.games];
    patchedGames[gameIdx] = newGame;

    let patchedMatch = Object.assign(new Match(), {
      ...prevMatch,
      games: patchedGames,
    });

    return patchedMatch;
  }
}

export default Match;
