import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";

import Countdown from "react-countdown";

import AuthContext from "../../contexts/AuthContext";
import { humanReadableRepeatStr } from "../../utilities";
import Avatar from "../Avatar/Avatar";
import Icon from "../Icon/Icon";

import Plural from "../Plural/Plural";

const DecisionsRepeatingItem = ({ tournament, open, onViewTournament }) => {
  const [repeatStr, setRepeatStr] = useState("");
  const { authState } = useContext(AuthContext);

  useEffect(() => {
    const tzNameLong = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzName = moment.tz(tzNameLong).zoneAbbr();

    setRepeatStr(humanReadableRepeatStr(tournament, false, false, tzName));
  }, [tournament]);

  return (
    <div
      className={`decisions-repeating-item ${open ? "open" : ""}`}
      onClick={(e) => onViewTournament(e, tournament.id)}
    >
      <div>
        <div className="creator">
          <Avatar user={tournament.creatorId}></Avatar>{" "}
          {tournament.creatorId === authState?.account?.id && (
            <span>Your&nbsp;</span>
          )}
          {tournament.creatorId !== authState?.account?.id && (
            <>
              <div className="ellipsis-overflow">
                {tournament.creatorUserName}
              </div>
              <span>'s&nbsp;</span>
            </>
          )}
          <span>game</span>
        </div>
        <span className="end-time">
          Next:{" "}
          <nobr>
            <Countdown
              key={tournament.startTime}
              date={tournament.startTime}
              renderer={({ days, hours, minutes, seconds }) => (
                <>
                  {days > 0 && <>{days}d </>}
                  {hours > 0 && <>{hours}h </>}
                  {minutes > 0 && <>{minutes}m </>}
                  {seconds}s
                </>
              )}
            />
          </nobr>
        </span>
      </div>
      <div>
        <span className="stake ellipsis-overflow">{tournament.stake}</span>
        <span className="repeating-schedule">
          <Icon className="far fa-calendar-days"></Icon> {repeatStr}
        </span>
      </div>
      <div>
        <span className="participants">
          {tournament.participants === 1 && (
            <>
              <b className="text-blue">Waiting for players</b>
            </>
          )}
          {tournament.participants > 1 && (
            <span>
              {tournament.participants}{" "}
              <Plural base="player" value={tournament.participants}></Plural>
              {tournament.participants < tournament.winnersToDraw && (
                <small className="text-red">
                  &nbsp;&nbsp;(needs {tournament.winnersToDraw})
                </small>
              )}
            </span>
          )}
        </span>
        {tournament.amParticipating && (
          <span className="participating am-participating">
            <Icon className="fas fa-check"></Icon> Playing
          </span>
        )}
        {!tournament.amParticipating && (
          <span className="participating not-participating">
            <Icon className="fas fa-user-plus"></Icon> Join now
          </span>
        )}
      </div>
    </div>
  );
};

export default DecisionsRepeatingItem;
