import React from "react";

import Enum from "../../../../helpers/enums";

const BoardOverlayMove = ({ move, onClick }) => {
  return (
    <div className="board-overlay-ms-move" onClick={onClick}>
      {move === Enum.MoveType.FORFEIT && <span>F</span>}
      {move === Enum.MoveType.ROCK && (
        <img src={`/images/game/1.png`} alt="Rock" />
      )}
      {move === Enum.MoveType.PAPER && (
        <img src={`/images/game/2.png`} alt="Paper" />
      )}
      {move === Enum.MoveType.SCISSORS && (
        <img src={`/images/game/3.png`} alt="Scissors" />
      )}
    </div>
  );
};

export default BoardOverlayMove;
