import React from "react";

import TextButton from "../TextButton/TextButton";

import "./NotifyBar.scss";

const NotifyBar = ({ options, stacked, children }) => {
  return (
    <div className={`notify-bar ${stacked ? "notify-stacked" : ""}`}>
      <div className="notify-bar-text">{children}</div>
      {options && options.length > 0 && (
        <div className="notify-bar-buttons">
          {options.map((option) => (
            <TextButton key={option.text} onClick={option.onClick}>
              {option.text}
            </TextButton>
          ))}
        </div>
      )}
    </div>
  );
};

export default NotifyBar;
