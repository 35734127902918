import React, { useMemo } from "react";

import { Label } from "semantic-ui-react";

import { reverseFirstX } from "../../../utilities";
import Avatar from "../../Avatar/Avatar";
import Icon from "../../Icon/Icon";

import "./GroupMembersAvatarStack.scss";

const GroupMembersAvatarStack = ({
  group,
  onClick,
  displayNewMemberTag,
  userSlideOutOpen,
}) => {
  const reverseMembers = useMemo(() => {
    return reverseFirstX(group?.groupAccounts, 3);
  }, [group?.groupAccounts]);

  return (
    <div className="group-members-avatar-stack" onClick={onClick}>
      <div className="group-members-avatar-stack-container">
        <div className={`nav-chevron ${userSlideOutOpen ? "flipped" : ""}`}>
          <Icon className="far fa-chevron-right"></Icon>
        </div>
        {group?.groupAccounts?.length > 3 && (
          <div className="group-member-element">
            <div className="group-members-avatar-stack-more">
              <Icon className="fas fa-ellipsis"></Icon>
            </div>
          </div>
        )}
        {reverseMembers &&
          reverseMembers.map((member) => (
            <div className="group-member-element" key={member.id}>
              <Avatar user={member.accountId} />
            </div>
          ))}
        {displayNewMemberTag && (
          <div className="new-group-member-tag">
            <Label color="blue" pointing="right">
              Member&nbsp;joined!
            </Label>
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupMembersAvatarStack;
