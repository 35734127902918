import moment from "moment";
import React, { useEffect, useState } from "react";

import Timer from "../../components/DecidingInterface/InfoPanel/Timer/Timer";
import Icon from "../../components/Icon/Icon";
import withAuth from "../../components/withAuth";

import "./GamesPage.scss";

const GamesPage = ({ groups, history }) => {
  const [countdownTo, setCountdownTo] = useState(null);

  const onTournament = () => {
    history.push("/g/rkotourn");
  };

  const onSolo = () => {
    history.push("/g/solo");
  };

  const onFriends = () => {
    history.push("/groups");
  };

  useEffect(() => {
    if (
      !groups ||
      !groups.data ||
      !groups.data.length ||
      !groups.data[0] ||
      !groups.data[0].promo
    )
      return;

    const cd = moment(groups.data[0].activeDecisionStartTime);

    setCountdownTo(cd);
  }, [groups]);

  return (
    <>
      <div className="subpage subpage-games">
        <div id="subpage-content">
          <div className="inner-content">
            <div className="games-container">
              <div className="gamelinks-main">
                <div
                  className="gamelink gamelink-tournament"
                  onClick={onTournament}
                >
                  <div className="gamelink-bg"></div>
                  <div className="gamelink-content">
                    <div className="gamelink-icon">
                      <Icon className="fal fa-code-fork fa-rotate-90"></Icon>
                    </div>
                    <div className="gamelink-title">Tournament</div>
                    <div className="gamelink-subtitle">
                      Test your skill against thousands across the globe for a
                      chance to win weekly prizes
                    </div>
                    <div className="promo-countdown">
                      <div className="promo-timer">
                        {countdownTo && (
                          <Timer
                            endTime={countdownTo}
                            labelled={true}
                            desc={"until next game"}
                            large={true}
                          ></Timer>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gamelinks-others">
                <div className="gamelink gamelink-solo" onClick={onSolo}>
                  <div className="gamelink-bg"></div>
                  <div className="gamelink-content">
                    <div className="gamelink-icon">
                      <Icon className="fal fa-fw fa-user-robot"></Icon>
                    </div>
                    <div className="gamelink-title">Solo</div>
                    <div className="gamelink-subtitle">
                      Play solo vs RKObot and practice your skills
                    </div>
                  </div>
                </div>
                <div className="gamelink gamelink-friends" onClick={onFriends}>
                  <div className="gamelink-bg"></div>
                  <div className="gamelink-content">
                    <div className="gamelink-icon">
                      <Icon className="fal fa-fw fa-users"></Icon>
                    </div>
                    <div className="gamelink-title">Friends</div>
                    <div className="gamelink-subtitle">
                      Play tournaments with friends
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAuth(GamesPage);
