import React from "react";

import { minutesToTimeString } from "../../utilities";
import Icon from "../Icon/Icon";

import "./TournamentRulesSummary.scss";

const TournamentRulesSummary = ({
  firstToGames,
  stakeIsPositive,
  gameLengthMinutes,
  winnersChosen,
  promo,
}) => (
  <div className="progress-tournament-rules">
    {firstToGames && (
      <div>
        <Icon className="far fa-trophy"></Icon> First to {firstToGames}{" "}
        {stakeIsPositive === false ? "safe" : "wins"}
        {promo ? " advances to next round" : ""}
      </div>
    )}
    {gameLengthMinutes && (
      <div>
        <Icon className="far fa-clock"></Icon>
        {minutesToTimeString(gameLengthMinutes, !promo, !promo)} to make a move
      </div>
    )}
    {winnersChosen && !promo && (
      <div>
        <Icon className="far fa-user-check"></Icon> Choosing {winnersChosen}{" "}
        player{winnersChosen > 1 ? "s" : ""}
      </div>
    )}
  </div>
);

export default TournamentRulesSummary;
