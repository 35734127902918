import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";

import { Api } from "../../helpers/api";

const InviteHandler = ({ inviteCode }) => {
  const history = useHistory();

  const [error, setError] = useState(null);

  useEffect(() => {
    if (!inviteCode || !history) return;

    Api.joinGroup(inviteCode)
      .then((res) => {
        console.log(res);

        if (res.status === 201) {
          history.push(`/g/${res.data.slug}`);
        } else if (res.status === 409) {
          history.push(`/g/${res.data.slug}`);
        } else {
          history.push(`/groups/?joinError=${encodeURIComponent(res.data)}`);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(err);
      });
  }, [history, inviteCode]);

  return (
    <Dimmer inverted active>
      <Loader inverted active content={error || "Joining group..."} />
    </Dimmer>
  );
};

export default InviteHandler;
