import React, { useContext, useMemo } from "react";
import { Button, Label, Progress } from "semantic-ui-react";

import Avatar from "../../../../../components/Avatar/Avatar";
import Timer from "../../../../../components/DecidingInterface/InfoPanel/Timer/Timer";
import EnableNotificationsPromptButton from "../../../../../components/EnableNotificationsPrompt/EnableNotificationsPromptButton";
import Icon from "../../../../../components/Icon/Icon";
import JoyrideButton from "../../../../../components/JoyrideButton/JoyrideButton";
import { config } from "../../../../../config";
import AuthContext from "../../../../../contexts/AuthContext";
import Enum from "../../../../../helpers/enums";

function GroupLowerRpsTournament({
  group,
  currentTournament,
  currentRound,
  currentMatch,
  currentGame,
  handleMakeMoveClick,
  handleModalUserManageOpen,
  handleJoyrideTourOpen,
  handleModalAlertOpen,
}) {
  const { authState } = useContext(AuthContext);

  const myMatchAccount = useMemo(() => {
    if (!currentMatch) return null;

    return currentMatch?.matchAccounts?.find(
      (ma) => ma.accountId === authState?.account?.id,
    );
  }, [currentMatch, authState]);

  const opponentMatchAccount = useMemo(() => {
    if (!currentMatch) return null;

    return currentMatch?.matchAccounts?.find(
      (ma) => ma.accountId !== authState?.account?.id,
    );
  }, [currentMatch, authState]);

  const myMove = useMemo(() => {
    if (!currentGame) return null;

    return currentGame.moves?.find(
      (m) => m.accountId === authState?.account?.id,
    );
  }, [authState, currentGame]);

  const semiFinalResultDecidesTournament = useMemo(() => {
    return (
      currentRound.playersCount === 3 &&
      !currentMatch.vsAi &&
      !currentTournament.myMatch.completed &&
      currentRound.playersRemaining === 2
    );
  }, [currentRound, currentMatch, currentTournament]);

  // const opponentMove = useMemo(() => {
  //   if (!currentGame) return null;

  //   return currentGame.moves?.find(
  //     (m) => m.accountId !== authState?.account?.id
  //   );
  // }, [authState, currentGame]);

  // const amCreator = useMemo(() => {
  //   if (!currentTournament || !authState || !authState.account) return false;
  //   return currentTournament.creatorId === authState.account.id;
  // }, [currentTournament, authState]);

  return (
    <>
      <div className="group-lower">
        <div className="decision-info decision-info-tournament">
          <div className="tournament-info">
            {currentTournament && (
              <>
                {(!currentTournament.myMatch ||
                  (currentTournament.myMatch.completed &&
                    !currentTournament.myMatch.iAdvanced)) && (
                  <>
                    <div className="new-group-instructions">
                      {!currentTournament.amParticipating && (
                        <>
                          Game in progress
                          <br />
                          <br />
                          You are not playing in this tournament
                        </>
                      )}
                      {currentTournament.amParticipating && (
                        <>
                          {currentTournament.stakeIsPositive && (
                            <>
                              Better luck next time!
                              <br />
                              <br />
                              You were knocked out of the tournament, but who
                              will be chosen?
                            </>
                          )}
                          {!currentTournament.stakeIsPositive && (
                            <>
                              You have no more matches
                              <br />
                              <br />
                              You are safe from the penalty, but who will be
                              chosen?
                            </>
                          )}
                          <EnableNotificationsPromptButton
                            promptJsx={
                              <>
                                <Icon className="far fa-bell"></Icon> Want to be
                                notified of the result?
                                <br />
                                Tap here to set up
                              </>
                            }
                            enabledJsx={
                              <>
                                <Icon className="far fa-bell"></Icon> Your
                                notifications are enabled
                                <br />
                                so we'll notify you of the result
                              </>
                            }
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
                {currentTournament.myMatch &&
                  (!currentTournament.myMatch.completed ||
                    currentTournament.myMatch.iAdvanced) && (
                    <>
                      <div className="match-status">
                        {currentTournament.myMatch.completed &&
                          currentTournament.myMatch.iAdvanced && (
                            <>
                              <div className="match-status-copy">
                                <div className="match-completed">
                                  <div className="match-completed-heading">
                                    {currentTournament.stakeIsPositive && (
                                      <>You advance to the next round!</>
                                    )}
                                    {!currentTournament.stakeIsPositive && (
                                      <>
                                        You move one round closer
                                        <br />
                                        to the penalty
                                      </>
                                    )}
                                  </div>
                                </div>
                                <span className="match-whats-next">
                                  <div className="match-whats-next-1">
                                    {currentTournament.stakeIsPositive && (
                                      <>
                                        <span>You won the match</span>
                                      </>
                                    )}
                                    {!currentTournament.stakeIsPositive && (
                                      <>
                                        <span>You lost the match</span>
                                      </>
                                    )}
                                  </div>
                                </span>
                              </div>
                            </>
                          )}
                      </div>

                      {currentRound &&
                        currentTournament.startingPlayers > 2 && (
                          <div className="tournament-round">
                            <div className="tournament-round-title">
                              {currentRound.titleSingle}
                            </div>
                            {currentTournament.myMatch.completed &&
                              currentTournament.myMatch.iAdvanced && (
                                <Progress
                                  value={currentRound.matchesComplete}
                                  total={currentRound.matchesCount}
                                  indicating
                                  progress="ratio"
                                  size="small"
                                >
                                  In progress{" "}
                                  <Icon className="far fa-circle-notch fa-spin"></Icon>
                                </Progress>
                              )}
                          </div>
                        )}

                      {currentTournament.myMatch.completed &&
                        currentTournament.myMatch.iAdvanced &&
                        currentRound.matchesCount -
                          currentRound.matchesComplete >
                          0 && (
                          <div className="match-whats-next">
                            <div className="match-whats-next-2">
                              <span>
                                Wait for {currentRound.titleSingle} to finish
                              </span>
                              <EnableNotificationsPromptButton />
                            </div>
                          </div>
                        )}

                      {currentRound &&
                        currentTournament.startingPlayers <= 2 && (
                          <div className="tournament-round">
                            <div className="tournament-round-title">
                              Your match
                            </div>
                          </div>
                        )}

                      <div
                        className={`my-match my-match-${
                          currentTournament.stakeIsPositive ? "positive" : (
                            "negative"
                          )
                        }`}
                      >
                        {!currentTournament.myMatch.completed && (
                          <div className="match-avatars">
                            <div
                              className={`match-avatar ${
                                myMatchAccount.result === Enum.ResultType.WIN ?
                                  "match-avatar-winner"
                                : ""
                              }  ${
                                myMatchAccount.result === Enum.ResultType.LOSS ?
                                  "match-avatar-loser"
                                : ""
                              }`}
                              onClick={() =>
                                handleModalUserManageOpen(
                                  authState?.account?.id,
                                )
                              }
                            >
                              <Avatar user={authState?.account?.id}></Avatar>
                              <div className="match-account-username">You</div>
                            </div>
                            {currentTournament.firstToGames >= 2 && (
                              <div className="match-account-score">
                                {myMatchAccount.score}
                              </div>
                            )}
                            <div className="match-vs">vs</div>
                            {currentTournament.firstToGames >= 2 &&
                              opponentMatchAccount && (
                                <div className="match-account-score">
                                  {opponentMatchAccount.score}
                                </div>
                              )}
                            <div
                              className={`match-avatar match-avatar-right ${
                                (
                                  opponentMatchAccount?.result ===
                                  Enum.ResultType.WIN
                                ) ?
                                  "match-avatar-winner"
                                : ""
                              }  ${
                                (
                                  opponentMatchAccount?.result ===
                                  Enum.ResultType.LOSS
                                ) ?
                                  "match-avatar-loser"
                                : ""
                              }`}
                              onClick={() =>
                                handleModalUserManageOpen(
                                  opponentMatchAccount?.accountId,
                                )
                              }
                            >
                              {opponentMatchAccount && (
                                <>
                                  <Avatar
                                    user={opponentMatchAccount.accountId}
                                  ></Avatar>
                                  <div className="match-account-username">
                                    {opponentMatchAccount.accountUserName}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}

                        {!currentTournament.myMatch.completed &&
                          currentGame && (
                            <>
                              {currentTournament.startingPlayers > 2 &&
                                !currentRound.isFinalRound &&
                                !semiFinalResultDecidesTournament && (
                                  <div className="match-advancer">
                                    {currentTournament.firstToGames === 1 && (
                                      <>
                                        {currentTournament.stakeIsPositive &&
                                          "Winner advances"}
                                        {!currentTournament.stakeIsPositive &&
                                          "Winner safe from penalty"}
                                      </>
                                    )}
                                    {currentTournament.firstToGames >= 2 && (
                                      <>
                                        {currentTournament.stakeIsPositive &&
                                          `Win ${currentTournament.firstToGames} moves to advance`}
                                        {!currentTournament.stakeIsPositive &&
                                          `Win ${currentTournament.firstToGames} moves to be safe from penalty`}
                                      </>
                                    )}
                                  </div>
                                )}
                              {(currentTournament.startingPlayers === 2 ||
                                currentRound.isFinalRound ||
                                semiFinalResultDecidesTournament) && (
                                <div className="match-advancer">
                                  {currentTournament.firstToGames === 1 && (
                                    <>
                                      {currentTournament.stakeIsPositive &&
                                        "Winner receives the prize!"}
                                      {!currentTournament.stakeIsPositive &&
                                        "Loser receives the penalty!"}
                                    </>
                                  )}
                                  {currentTournament.firstToGames >= 2 && (
                                    <>
                                      {currentTournament.stakeIsPositive &&
                                        `Win ${currentTournament.firstToGames} moves to receive the prize!`}
                                      {!currentTournament.stakeIsPositive &&
                                        `Win ${currentTournament.firstToGames} moves to be safe from penalty`}
                                    </>
                                  )}
                                  {semiFinalResultDecidesTournament && (
                                    <>
                                      <br />
                                      <div
                                        className="provisional-result"
                                        onClick={() => {
                                          handleModalAlertOpen(
                                            "Result decides tournament",
                                            currentTournament.stakeIsPositive ?
                                              config.messages
                                                .semiFinalResultDecidesTournament
                                                .positive
                                            : config.messages
                                                .semiFinalResultDecidesTournament
                                                .negative,
                                          );
                                        }}
                                      >
                                        <Label color="orange" size="small">
                                          Deciding match{" "}
                                          <Icon className="far fa-question-circle"></Icon>
                                        </Label>
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                            </>
                          )}
                      </div>

                      {!currentTournament.myMatch.completed && (
                        <>
                          {currentGame && (
                            <>
                              <Timer
                                endTime={currentGame.endTime}
                                labelled={true}
                                large={true}
                                desc={
                                  myMove ?
                                    "for opponent to move"
                                  : "to make move"
                                }
                              ></Timer>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
              </>
            )}
          </div>
        </div>

        {!group.promo &&
          currentTournament.myMatch &&
          !currentTournament.myMatch.completed && (
            <JoyrideButton onClick={handleJoyrideTourOpen}></JoyrideButton>
          )}

        {currentTournament.myMatch && !currentTournament.myMatch.completed && (
          <div className="action-panel">
            <Button
              icon
              size="big"
              primary
              className="decide-now"
              onClick={handleMakeMoveClick}
            >
              {myMove && <span>See game</span>}
              {!myMove && <span>Make move</span>}
              <Icon className="far fa-chevron-right"></Icon>
            </Button>
          </div>
        )}
      </div>
    </>
  );
}

export default GroupLowerRpsTournament;
