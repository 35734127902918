import React, { useContext, useMemo } from "react";

import AuthContext from "../../contexts/AuthContext";
import Enum from "../../helpers/enums";
import Avatar from "../Avatar/Avatar";

const BracketHistory = ({
  matchAccounts,
  tournament,
  side,
  handleUserClick,
}) => {
  const { authState } = useContext(AuthContext);

  const playerL = matchAccounts[0];
  const playerR = matchAccounts[1];

  const advanceResult = useMemo(() => {
    return tournament.stakeIsPositive ?
        Enum.ResultType.WIN
      : Enum.ResultType.LOSS;
  }, [tournament?.stakeIsPositive]);

  return (
    <div
      className={`br-match br-history br-resolved ${
        tournament.stakeIsPositive ?
          "br-history-positive"
        : "br-history-negative"
      } br-history-${side}`}
    >
      <div
        className={`br-player br-player-left ${
          playerL.result === advanceResult ? "br-player-adv" : "br-player-ko"
        } ${
          tournament.stakeIsPositive ? "br-player-positive" : (
            "br-player-negative"
          )
        }`}
      >
        <div onClick={() => handleUserClick(playerL.accountId)}>
          <Avatar user={playerL.accountId}></Avatar>
          <span className="br-username ellipsis-overflow">
            {playerL.accountId === authState.account.id ?
              "You"
            : playerL.accountUserName}
          </span>
        </div>
      </div>
      <div
        className={`br-player br-player-right ${
          playerR.result === advanceResult ? "br-player-adv" : "br-player-ko"
        } ${
          tournament.stakeIsPositive ? "br-player-positive" : (
            "br-player-negative"
          )
        }`}
      >
        <div onClick={() => handleUserClick(playerR.accountId)}>
          <Avatar user={playerR.accountId}></Avatar>
          <span className="br-username ellipsis-overflow">
            {playerR.accountId === authState.account.id ?
              "You"
            : playerR.accountUserName}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BracketHistory;
