import React from "react";

import GroupMembers from "../../../components/GroupMembers/GroupMembers";
import Enum from "../../../helpers/enums";

function UserSlideout({
  groupLoading,
  group,
  currentTournament,
  onUserSlideoutOpen,
  handleModalInviteOpen,
  handleTouchStart,
  handleTouchEnd,
  handleTouchMove,
  tournamentStatusText,
  tournamentStatusIcon,
}) {
  return (
    <div
      className="user-slideout-container"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    >
      {!groupLoading && (
        <div className="decision-participants">
          <GroupMembers
            group={group}
            currentTournament={currentTournament}
            onInvite={handleModalInviteOpen}
            onGroupUsersClick={onUserSlideoutOpen}
            tournamentStatusText={tournamentStatusText}
            tournamentStatusIcon={tournamentStatusIcon}
            showMatchGrouping={
              currentTournament?.state === Enum.TournamentState.ACTIVE
            }
            participating={
              currentTournament?.state === Enum.TournamentState.ACTIVE ?
                true
              : undefined
            }
          ></GroupMembers>
        </div>
      )}
    </div>
  );
}

export default UserSlideout;
