import React, { useEffect, useState } from "react";

import Brackets from "../../../components/Brackets/Brackets";
import Icon from "../../../components/Icon/Icon";
import Plural from "../../../components/Plural/Plural";
import TextButton from "../../../components/TextButton/TextButton";

function TournamentSlideout({
  group,
  tournament,
  round,
  handleTouchStart,
  handleTouchEnd,
  handleTouchMove,
  handleUserClick,
  handleModalRulesOpen,
}) {
  const [remaining, setRemaining] = useState(0);
  const [matchesLeft, setMatchesLeft] = useState(0);
  const [processedRounds, setProcessedRounds] = useState([]);

  const processMembers = () => {
    setRemaining(
      group?.groupAccounts.filter((x) => x?.isCurrentlyPlaying).length,
    );
  };

  const processMatchesLeft = () => {
    setMatchesLeft(round.matchesCount - round.matchesComplete);
  };

  useEffect(processMembers, [group?.groupAccounts]);
  useEffect(processMatchesLeft, [round?.matchesCount, round?.matchesComplete]);
  useEffect(() => {
    if (tournament?.rounds) {
      const rounds = [...tournament.rounds].reverse();
      setProcessedRounds(rounds);
    }
  }, [tournament?.rounds]);

  return (
    <div
      className="tournament-slideout-container"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    >
      {tournament && (
        <div>
          <div className="ts-header">
            <h1>Rock Paper Scissors</h1>
          </div>

          <div className="ts-body">
            <h5>{round.title}</h5>
            <div className="ts-infos">
              <div className="ts-info">
                <div className="ts-info-num">{tournament.startingPlayers}</div>
                <div className="ts-info-text">
                  players
                  <br />
                  started
                </div>
              </div>
              {!!matchesLeft && (
                <div className="ts-info">
                  <div className="ts-info-num">{round.matchesComplete}</div>
                  <div className="ts-info-text">
                    of {round.matchesCount}{" "}
                    <Plural
                      value={round.matchesCount}
                      base="match"
                      plural="matches"
                    ></Plural>
                    <br />
                    complete
                  </div>
                </div>
              )}
              {!!remaining && (
                <div className="ts-info">
                  <div className="ts-info-num">{remaining}</div>
                  <div className="ts-info-text">
                    players
                    <br />
                    remain
                  </div>
                </div>
              )}
            </div>
            {(tournament.myMatch || tournament.myHistoricalMatch) && round && (
              <div className="ts-brackets">
                <h5>My match</h5>

                <div>
                  <Brackets
                    tournament={tournament}
                    round={round}
                    match={
                      tournament.myMatch ?
                        tournament.myMatch
                      : tournament.myHistoricalMatch
                    }
                    handleUserClick={handleUserClick}
                  ></Brackets>
                </div>
              </div>
            )}
          </div>

          <div className="ts-rounds">
            <table className="ts-round-table">
              <thead>
                <tr>
                  <th>Round</th>
                  <th>Status</th>
                  <th>Players</th>
                </tr>
              </thead>
              <tbody>
                {processedRounds?.map((round) => {
                  let rowClass = "";
                  switch (round.state) {
                    case 0:
                      rowClass = "planned-round";
                      break;
                    case 1:
                      rowClass = "active-round";
                      break;
                    case 3:
                      rowClass = "complete-round";
                      break;
                    case 4:
                      rowClass = "skipped-round";
                      break;
                    default:
                      break;
                  }
                  return (
                    <tr key={round.id} className={rowClass}>
                      <td className="tst-round-num">{round.roundNumber}</td>
                      <td className="tst-round-status">
                        {round.state === 0 && (
                          <>
                            <Icon className="far fa-fw fa-forward"></Icon>
                            Planned
                          </>
                        )}
                        {round.state === 1 && (
                          <>
                            <Icon className="far fa-fw fa-spin fa-circle-notch"></Icon>
                            Active
                          </>
                        )}
                        {round.state === 3 && (
                          <>
                            <Icon className="far fa-fw fa-check-circle"></Icon>
                            Complete
                          </>
                        )}
                        {round.state === 4 && (
                          <>
                            <Icon className="far fa-fw fa-times"></Icon>
                            Skipped
                          </>
                        )}
                      </td>
                      <td>{round.state !== 4 && round.playersCount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="ts-footer">
            <TextButton onClick={handleModalRulesOpen}>
              Show tournament rules
            </TextButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default TournamentSlideout;
