import React, { useContext, useEffect, useMemo, useState } from "react";

import Moment from "react-moment";

import AuthContext from "../../contexts/AuthContext";
import Enum from "../../helpers/enums";
import Avatar from "../Avatar/Avatar";
import Icon from "../Icon/Icon";

import Plural from "../Plural/Plural";

const DecisionHistoryItem = ({ tournament, onOpen }) => {
  const [wasChosen, setWasChosen] = useState(false);
  const { authState } = useContext(AuthContext);

  useEffect(() => {
    setWasChosen(
      tournament?.theChosenOnes?.findIndex(
        (x) => x.id === authState?.account?.id,
      ) > -1,
    );
  }, [authState?.account?.id, tournament?.theChosenOnes]);

  const handleOpen = () => {
    onOpen(tournament.id);
  };

  const creator = useMemo(() => {
    return tournament.creatorId === authState?.account?.id ?
        "Your"
      : tournament.creatorUserName + "'s";
  }, [
    authState?.account?.id,
    tournament.creatorId,
    tournament.creatorUserName,
  ]);

  return (
    <div
      className={`decision-history-item ${wasChosen ? "was-chosen" : ""}`}
      onClick={handleOpen}
    >
      <div>
        <div className="creator">
          <Avatar user={tournament.creatorId}></Avatar>{" "}
          {tournament.startMode === Enum.TournamentStartMode.QUICK && (
            <>{creator} game</>
          )}
          {tournament.startMode === Enum.TournamentStartMode.REPEATING && (
            <>{creator} repeating game</>
          )}
        </div>
        <span className="end-time">
          <Moment
            fromNow
            date={tournament.startTime}
            withTitle
            titleFormat="D MMM YYYY HH:mm:ss"
          />
        </span>
      </div>
      <div className="wrap">
        <span className="stake ellipsis-overflow">
          {tournament.gameMode === Enum.TournamentGameMode.TOURNAMENTRPS && (
            <>
              {tournament.stakeIsPositive ?
                <span className="text-green">
                  <Icon className="fas fa-thumbs-up"></Icon>&nbsp;&nbsp;
                </span>
              : <span className="text-red">
                  <Icon className="fas fa-thumbs-down"></Icon>&nbsp;&nbsp;
                </span>
              }
            </>
          )}
          {tournament.stake}
        </span>
        <span className="chosen-one">
          {wasChosen && tournament.state !== Enum.TournamentState.CANCELLED && (
            <b>
              {tournament.gameMode === Enum.TournamentGameMode.RANDOM && (
                <span className="text-blue">
                  You{" "}
                  {tournament.theChosenOnes.length > 1 ?
                    <>
                      and {tournament.theChosenOnes.length - 1}{" "}
                      <Plural
                        value={tournament.theChosenOnes.length - 1}
                        base="other"
                      ></Plural>
                    </>
                  : "were"}{" "}
                  chosen!
                </span>
              )}
              {tournament.gameMode ===
                Enum.TournamentGameMode.TOURNAMENTRPS && (
                <span
                  className={`text-${
                    tournament.stakeIsPositive ? "green" : "red"
                  }`}
                >
                  You were chosen!
                </span>
              )}
            </b>
          )}
          {!wasChosen &&
            tournament.state !== Enum.TournamentState.CANCELLED &&
            !!tournament.theChosenOnes && (
              <>
                <b>Chosen: </b>
                {tournament.theChosenOnes.length === 1 && (
                  <>
                    <Avatar user={tournament.theChosenOnes[0].id} />{" "}
                    {tournament.theChosenOnes[0].userName}
                  </>
                )}
                {tournament.theChosenOnes.length > 1 && (
                  <>{tournament.theChosenOnes.length} players</>
                )}
              </>
            )}
          {(tournament.state === Enum.TournamentState.CANCELLED ||
            !tournament.theChosenOnes) && <b>Game cancelled</b>}
        </span>
      </div>
      {tournament.theChosenOnes && !!tournament.theChosenOnes.length && (
        <div>
          <span className="participants">
            {tournament.startingPlayers}{" "}
            <Plural base="player" value={tournament.startingPlayers}></Plural>
          </span>
          {tournament.amParticipating && (
            <span className="participating am-participating">
              <Icon className="fas fa-check"></Icon> You played
            </span>
          )}
          {!tournament.amParticipating && (
            <span className="participating not-participating">
              <Icon className="fas fa-times"></Icon> You didn't play
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default DecisionHistoryItem;
