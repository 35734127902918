export const config = {
  aiAccountMember: {
    accountId: 1,
    accountUserName: "RKObot",
  },

  authentication: {
    snapchat: {
      clientId: "d3140a62-10b0-4f35-83df-5dd36aafee7d",
    },
  },

  board: {
    textConfig: {
      message: {
        fontFamily: "Lato",
        fontSize: 20,
        fontWeight: "bold",
        fill: "#333",
        align: "center",
        dropShadow: false,
      },
      messageSm: {
        fontFamily: "Lato",
        fontSize: 17,
        fontWeight: "bold",
        fill: "#333",
        align: "center",
        dropShadow: false,
      },
      lockedIn: {
        fontFamily: "Lato",
        fontSize: 14,
        fontWeight: "bold",
        fill: "#333",
        align: "center",
        dropShadow: false,
      },
      hiddenMove: {
        fontFamily: "Lato",
        fontSize: 48,
        fontWeight: "bold",
        fill: "#666",
        align: "center",
        dropShadow: false,
      },
      dragHere: {
        fontFamily: "Lato",
        fontSize: 13,
        fontWeight: "normal",
        fill: "#888",
        align: "center",
        dropShadow: false,
      },
      soloAnonResult: {
        fontFamily: "Lato",
        fontSize: 20,
        fontWeight: "bold",
        fill: "#333",
        align: "center",
        dropShadow: false,
      },
    },
    overlay: {
      time: 10000,
    },
    toast: {
      time: 5000,
    },
  },

  domains: {
    development: {
      auth: "https://auth.randomko.localhost",
      api: "https://api.randomko.localhost/api/v1",
      hub: "https://api.randomko.localhost/hub",
      web: "https://www.randomko.localhost",
    },
    production: {
      auth: "https://auth.randomko.app",
      api: "https://api.randomko.app/api/v1",
      hub: "https://api.randomko.app/hub",
      web: "https://randomko.app",
    },
    getDomain(domain) {
      // eslint-disable-next-line no-undef
      return this[process.env.NODE_ENV][domain];
    },
  },

  firebase: {
    init: {
      apiKey: "AIzaSyB94AO82ynUUSVMWywg5Ehu9WLuv3ANow8",
      authDomain: "auth.randomko.app",
      projectId: "randomko-app",
      storageBucket: "randomko-app.appspot.com",
      messagingSenderId: "411698865089",
      appId: "1:411698865089:web:112d8b4039565b33c40540",
    },
    vapidKey:
      "BB92phBih9g3p79EliIoDVm7rqw_6964hfHOF2tfVF27lwzZO8EKr1h7w_DVADKiH4-kGoErf8IUhZzRHksbQkk",
    retries: {
      max: 3,
      delay: 1000,
    },
  },

  installPrompt: {
    promptReappearTime: 24 * 60 * 60 * 1000, // 24 hours in milliseconds
    dismissalsBeforeHiding: 5, // permanently hide after this many dismissals
  },

  messages: {
    aiIn3PersonRound: {
      positive:
        "The other match in this 3-player round is being played against RKObot, a bot that plays in rounds with an odd number of players. It never advances to the next round. If it knocks out its human opponent, you will win the tournament and the prize!",
      negative:
        "The other match in this 3-player round is being played against RKObot, a bot that plays in rounds with an odd number of players. It never advances to the next round. If it loses to its human opponent, you will lose the tournament and receive the penalty!",
    },
    semiFinalResultDecidesTournament: {
      positive:
        "RKObot has knocked out the 3rd player. The tournament is down to just you and your opponent. Win to receive the prize!",
      negative:
        "The 3rd player won against RKObot and is safe from the penalty. The tournament is down to just you and your opponent. Lose and you'll be chosen for the penalty!",
    },
    chosenBecauseRkoBot: {
      positive:
        "RKObot knocked out one of the 3 remaining players, so the winner of the other match received the prize!",
      negative:
        "RKObot lost to one of the 3 remaining players, so the loser of the other match received the penalty!",
    },
    chosenBecauseForfeits: {
      positive:
        "Due to other player(s) forfeiting, this player received the prize!",
      negative:
        "Due to other player(s) forfeiting, this player received the penalty!",
    },
  },

  validation: {
    __COMMENT: "Check appsettings.js in back-end too for these limits",
    maxLength: {
      stake: 30,
      groupName: 25,
    },
    winnersToDraw: {
      min: 1,
      max: 16,
    },
    instantRps: {
      maxPlayers: {
        min: 2,
        max: 16,
      },
    },
    tournamentRps: {
      maxPlayers: {
        min: 2,
        max: 16,
        default: 16,
      },
      firstToGames: {
        min: 1,
        max: 10,
        default: 2,
      },
      matchMaxGames: {
        min: 1,
        max: 10,
        default: 5,
      },
      gameLengthMinutes: {
        min: 1,
        max: 720,
        default: 10,
      },
    },
    soloVsBot: {
      firstToGames: {
        min: 1,
        max: 5,
        default: 1,
      },
    },
  },

  strings: {
    decision: "game",
    group: "group",
  },

  rps: {
    enabled: true,
    moveIcons: [null, "rock", "paper", "scissors"],
    moveNames: [null, "Rock", "Paper", "Scissors"],
    resultClass: ["draw", "win", "loss"],
  },

  formatting: {
    nextRoundBegins: {
      sameDay: "[today at] h:mm a",
      nextDay: "[tomorrow at] h:mm a",
      nextWeek: "dddd [at] h:mm a",
      lastDay: "[yesterday]",
      lastWeek: "[last] dddd",
      sameElse: "DD/MM/YYYY [at] h:mm a",
    },
  },

  eventMessages: {
    "move-made-2p-winner": {
      message: "__s1__ won a move against __s2__.",
      reversed: "__s1__ lost a move against __s2__.",
    },
    "move-made-2p-draw": {
      message: "__s1__ drew a move against __s2__.",
    },
    "move-made-3p-draw": {
      message: "__s1__, __s2__ and __s3__ drew a move.",
    },
    "forfeit-self": {
      message: "__s1__ forfeited.",
    },
    "forfeit-forced": {
      message: "__s1__ __w_was_were__ forced to forfeit by __s2__.",
      reversed: "__s1__ forced __s2__ to forfeit.",
    },
    "retire-self": {
      message: "__s1__ retired.",
    },
    "retire-forced": {
      message: "__s1__ __w_was_were__ forced to retire by __s2__.",
      reversed: "__s1__ forced __s2__ to retire.",
    },
    "match-won-2p-nfr-positive": {
      message:
        "__s1__ won a match against __s2__. __s1__ __w_advances_advance__ to the next round.",
      reversed:
        "__s1__ lost a match against __s2__. __s1__ __w_is_are__ knocked out of the running for the prize.",
    },
    "match-won-2p-nfr-negative": {
      message:
        "__s1__ won a match against __s2__. __s1__ __w_is_are__ safe from the penalty.",
      reversed:
        "__s1__ lost a match against __s2__. __s1__ __w_advances_advance__ to the next round.",
    },
    "match-won-2p-final": {
      message: "__s1__ won a match against __s2__.",
      reversed: "__s1__ lost a match against __s2__.",
    },
    "match-won-3p-positive-1winner": {
      message: "__s1__ won a match against __s2__ and __s3__.",
      reversed: "__s1__ and __s2__ lost a match against __s3__.",
      reverseForBoth: true,
    },
    "match-won-3p-positive-2winners": {
      message:
        "__s1__ and __s2__ won a match against __s3__. __w_They_You__ both move forward to the final round.",
      reversed:
        "__s1__ lost a match against __s2__ and __s3__. __s1__ __w_is_are__ knocked out of the running for the prize.",
      reverseForLast: true,
    },
    "match-won-3p-negative-1winner": {
      message:
        "__s1__ won a match against __s2__ and __s3__. __s1__ __w_is_are__ safe from the penalty.",
      reversed:
        "__s1__ and __s2__ lost a match against __s3__. __w_They_You__ both move forward to the final round.",
      reverseForBoth: true,
    },
    "match-won-3p-negative-2winners": {
      message: "__s1__ and __s2__ won a match against __s3__.",
      reversed: "__s1__ lost a match against __s2__ and __s3__.",
      reverseForLast: true,
    },
    "tournament-expired": {
      message:
        "The time limit for this __t_decision__ was reached before it could be resolved.",
    },
    "tournament-closed": {
      message:
        "__s1__ ended the __t_decision__ early before it could be resolved.",
    },
    "tournament-finished-positive": {
      message:
        "A __t_decision__ has been played! __s1__ __w_wins_win__ the prize: __v1__.",
    },
    "tournament-finished-negative": {
      message:
        "A __t_decision__ has been played! __s1__ __w_suffers_suffer__ the penalty: __v1__.",
    },
    "tournament-started-positive": {
      message: "The __t_decision__ has started. The prize: __v1__.",
    },
    "tournament-started-negative": {
      message: "The __t_decision__ has started. The penalty: __v1__.",
    },
    "tournament-preparing-positive": {
      message:
        "__s1__ __w_is_are__ preparing a new __t_decision__. The prize: __v1__.",
    },
    "tournament-preparing-negative": {
      message:
        "__s1__ __w_is_are__ preparing a new __t_decision__. The penalty: __v1__.",
    },
    "tournament-joined": {
      message: "__s1__ joined the upcoming __t_decision__.",
    },
    "tournament-left": {
      message: "__s1__ withdrew from the upcoming __t_decision__.",
    },
    "member-joined": {
      message: "__s1__ joined the __t_group__.",
    },
    "member-left": {
      message: "__s1__ left the __t_group__.",
    },
    "member-kicked": {
      message: "__s1__ __w_was_were__ removed from the __t_group__ by __s2__.",
      reversed: "__s1__ removed __s2__ from the __t_group__.",
    },
    "member-promoted": {
      message: "__s1__ __w_is_are__ now a __t_group__ assistant.",
    },
    "member-demoted": {
      message: "__s1__ __w_is_are__ no longer a __t_group__ assistant.",
    },
    "group-named": {
      message: "Group named __v1__.",
    },
    "group-renamed": {
      message: "Group renamed __v1__.",
    },
    "group-made-public": {
      message:
        "The __t_group__ has been made public. Only the __t_group__ boss and assistants can chat now.",
    },
    "round-started": {
      message: "Round __v1__ has started. __v2__ players remain.",
    },
  },

  eventSpecs: {
    "page-load": {},
    "move-made": {},
    "forfeited-by-player": {},
    "forfeited-by-host": {},
    "retired-by-player": {},
    "retired-by-host": {},
    "game-finished": { delay: 2000 },
    "game-started": {},
    "game-new-expiry": {},
    "match-finished": {
      delay: 100,
    },
    "match-started": {},
    "round-finished": {
      delay: 100,
    },
    "round-progressed": {},
    "round-started": {
      delay: 100,
    },
    "tournament-closed": {
      delay: 100,
    },
    "tournament-finished": {
      delay: 2000,
    },
    "tournament-preparing": {},
    "tournament-repeating-rolled-forward": {},
    "tournament-rescheduled": {},
    "tournament-started": {},
    "tournament-joined": {},
    "tournament-left": {},
    "account-joined": {},
    "account-left": {},
    "account-promoted": {},
    "account-demoted": {},
    "account-username-changed": {},
    "group-name-changed": {},
    "group-closed": {},
    "group-made-public": {},
  },

  stakeSuggestedCount: 5,
  stakes: [
    {
      stake: "Wash the dishes 🧼",
    },
    {
      stake: "Eat the last slice of pizza 🍕",
    },
    {
      stake: "Make tea 🫖",
    },
    {
      stake: "Do the laundry 👕",
    },
    {
      stake: "Take the bins out 🗑️",
    },
    {
      stake: "Pick tonight's movie 🎥",
    },
    {
      stake: "Who has to drive 🚗",
    },
    {
      stake: "Change the nappy 👶",
    },
    {
      stake: "Cook dinner 🍲",
    },
    {
      stake: "Get coffee ☕",
    },
    {
      stake: "Take the dog for a walk 🐕",
    },
    {
      stake: "Put kids to bed 🛌",
    },
    {
      stake: "Water the garden 🌱",
    },
    {
      stake: "Pick up lunch 🥡",
    },
    {
      stake: "Choose a restaurant 🍴",
    },
    {
      stake: "Choose a holiday destination ✈️",
    },
  ],
};
