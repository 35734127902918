import React from "react";
import { Grid, List } from "semantic-ui-react";

import GroupMember from "./GroupMember/GroupMember";

const GroupMembersList = ({
  list,
  group,
  currentTournament,
  myDraftTournament,
  onMemberClick,
  menuActiveId,
  chosenOnes,
  hideParticipating,
  showMatchGrouping,
  size,
  showVsAi,
}) => {
  return (
    <Grid
      className={`group-members-list-list${
        chosenOnes === true ? " chosen-ones"
        : chosenOnes === false ? " non-chosen-ones"
        : ""
      } ${size === "small" ? "group-members-list-list-small" : ""} ${
        showMatchGrouping ? "show-match-grouping" : ""
      }`}
    >
      <Grid.Column>
        <List relaxed verticalAlign="middle">
          {list.map((member, idx) => (
            <React.Fragment
              key={member ? member.id + "_" + member.accountId : idx}
            >
              {member && (
                <GroupMember
                  id={member.id}
                  group={group}
                  currentTournament={currentTournament}
                  myDraftTournament={myDraftTournament}
                  member={member}
                  tournamentAccountsLookup={
                    currentTournament?.tournamentAccountsLookup
                  }
                  onMemberClick={onMemberClick}
                  isMenuActive={member.id === menuActiveId}
                  chosenOnes={chosenOnes}
                  useNumbering={list.length > 1 && chosenOnes}
                  order={idx}
                  hideParticipating={hideParticipating}
                  showVsAi={showVsAi}
                />
              )}
              {!member && (
                <GroupMember
                  deleted={true}
                  order={idx}
                  useNumbering={list.length > 1 && chosenOnes}
                />
              )}
            </React.Fragment>
          ))}
        </List>
      </Grid.Column>
    </Grid>
  );
};

export default GroupMembersList;
