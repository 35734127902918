import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Header } from "semantic-ui-react";

import Icon from "../../components/Icon/Icon";
import TextButton from "../../components/TextButton/TextButton";

import "./StaticPage.scss";

const AboutPage = () => {
  const history = useHistory();

  return (
    <div className="page page-static" id="page-about">
      <div className="inner-content">
        <div>
          <Header as="h1">What is randomKO?</Header>

          <p>The easy way to make group decisions</p>

          <p>
            Join friends for random picks, 1v1 challenges, or rock paper
            scissors tournaments, all in one place
          </p>

          <p className="about-small">
            While we're in beta,{" "}
            <TextButton
              flush
              onClick={() => {
                window.location.href =
                  "mailto:feedback@randomko.app?subject=randomKO beta feedback";
              }}
            >
              we'd love to hear your feedback
            </TextButton>
            <br />
            on what you'd like to see on randomKO
          </p>
        </div>
        <div className="action-panel">
          <Button
            icon
            size="big"
            primary
            onClick={() => history.push("/g/new")}
          >
            <span>
              <Icon className="fas fa-plus"></Icon> New group
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
