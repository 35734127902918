import React, { createContext, useContext, useState, useEffect } from "react";

const UpdatePromptContext = createContext();

export function useUpdatePrompt() {
  return useContext(UpdatePromptContext);
}

export function UpdatePromptProvider({ children }) {
  const [hasUpdate, setHasUpdate] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  useEffect(() => {
    navigator.serviceWorker?.getRegistration().then((registration) => {
      if (registration) {
        registration.addEventListener("updatefound", function () {
          const newWorker = registration.installing;

          newWorker.addEventListener("statechange", function () {
            if (
              newWorker.state === "installed" &&
              navigator.serviceWorker.controller
            ) {
              setHasUpdate(true);
              setWaitingWorker(newWorker);
            }
          });
        });         

        // Forces SW to notice a new version of the app is available and prompt user to install it
        // version.txt is generated when building/publishing the app with VS
        fetch('/version.txt', { cache: 'no-store' })
          .then(response => response.text())
          .then(version => {
            console.log('App version:', version);
            // This fetch is primarily to force the service worker to check for updates.
            // If there's an update, your service worker logic should handle it (e.g., show a notification to the user).
          })
          .catch(error => {
            console.error('Error fetching version:', error);
          });
      }
    });
  }, []);

  const onUpdateClick = () => {
    if (waitingWorker) {
      waitingWorker.postMessage({ type: "SKIP_WAITING" });
      setHasUpdate(false);
      window.location.reload();
    }
  };

  const onDismissClick = () => {
    setHasUpdate(false);
  };

  const value = {
    hasUpdate,
    onUpdateClick,
    onDismissClick,
  };

  return (
    <UpdatePromptContext.Provider value={value}>
      {children}
    </UpdatePromptContext.Provider>
  );
}
