import React, { useMemo } from "react";
import { Modal, TransitionablePortal } from "semantic-ui-react";

import Enum from "../../../helpers/enums";
import Icon from "../../Icon/Icon";
import TournamentRulesSummary from "../../TournamentRulesSummary/TournamentRulesSummary";
import RkoModal from "../RkoModal/RkoModal";

import "./ModalStakeInfo.scss";

const ModalStakeInfo = ({ tournament, open, close }) => {
  const handleClose = () => {
    document.body.classList.remove("modal-fade-in");
    close();
  };

  const stakeClass = useMemo(() => {
    if (tournament?.gameMode === Enum.TournamentGameMode.RANDOM)
      return "text-blue";

    if (tournament?.gameMode === Enum.TournamentGameMode.TOURNAMENTRPS)
      return tournament.stakeIsPositive ? "text-green" : "text-red";

    return "";
  }, [tournament?.gameMode, tournament?.stakeIsPositive]);

  const gameMode = useMemo(() => {
    if (tournament?.gameMode === Enum.TournamentGameMode.RANDOM)
      return <>for</>;

    if (tournament?.gameMode === Enum.TournamentGameMode.TOURNAMENTRPS)
      return (
        <>
          <br />
          rock paper scissors for
        </>
      );

    return <></>;
  }, [tournament?.gameMode]);

  return (
    <TransitionablePortal
      open={open}
      onOpen={() =>
        setTimeout(() => document.body.classList.add("modal-fade-in"), 0)
      }
      transition={{ animation: "fade down", duration: 300 }}
    >
      <RkoModal
        size="mini"
        centered={false}
        open={true}
        onClose={handleClose}
        className="modal-stake-info modal-half-height"
      >
        <Modal.Content>
          <Modal.Description>
            <p>
              {tournament.state === Enum.TournamentState.REPEATING && (
                <>Playing {gameMode}</>
              )}
              {tournament.state === Enum.TournamentState.PREPARE && (
                <>Playing {gameMode}</>
              )}
              {tournament.state === Enum.TournamentState.ACTIVE && (
                <>Playing {gameMode}</>
              )}
              {tournament.state === Enum.TournamentState.COMPLETE && (
                <>Played {gameMode}</>
              )}
              {tournament.state === Enum.TournamentState.CANCELLED && (
                <>Playing {gameMode}</>
              )}
            </p>
            <p className={`stake ${stakeClass}`}>{tournament.stake}</p>
            {tournament.gameMode === Enum.TournamentGameMode.TOURNAMENTRPS && (
              <>
                {tournament.stakeIsPositive && (
                  <p className="stake-sip text-green">
                    <Icon className="fas fa-fw fa-thumbs-up"></Icon> Prize
                  </p>
                )}
                {!tournament.stakeIsPositive && (
                  <p className="stake-sip text-red">
                    <Icon className="fas fa-fw fa-thumbs-down"></Icon>
                    {""}
                    Penalty
                  </p>
                )}
                <TournamentRulesSummary
                  firstToGames={tournament.firstToGames}
                  stakeIsPositive={tournament.stakeIsPositive}
                  gameLengthMinutes={tournament.gameLengthMinutes}
                ></TournamentRulesSummary>
              </>
            )}
            {tournament.gameMode !== Enum.TournamentGameMode.TOURNAMENTRPS && (
              <>
                <TournamentRulesSummary
                  winnersChosen={tournament.winnersToDraw}
                ></TournamentRulesSummary>
              </>
            )}
            <p>
              {(tournament.state === Enum.TournamentState.REPEATING ||
                tournament.state === Enum.TournamentState.PREPARE ||
                tournament.state === Enum.TournamentState.ACTIVE) && (
                <>
                  <b>Good luck!</b>
                </>
              )}
            </p>
          </Modal.Description>
        </Modal.Content>
      </RkoModal>
    </TransitionablePortal>
  );
};

export default ModalStakeInfo;
