import React, { useContext, useState } from "react";

import { BrowserView, MobileView } from "react-device-detect";
import { Button } from "semantic-ui-react";

import AuthContext from "../../contexts/AuthContext";
import { useInstallPrompt } from "../../contexts/InstallPromptContext";
import Icon from "../Icon/Icon";
import ModalIosAddToHomeScreen from "../Modal/ModalIosAddToHomeScreen/ModalIosAddToHomeScreen";
import NotifyBar from "../NotifyBar/NotifyBar";

import "./EnableNotificationsPrompt.scss";

const EnableNotificationsPrompt = ({
  onModalUnblockNotificationsOpen,
  notificationsClicked,
  setNotificationsClicked,
}) => {
  if (setNotificationsClicked === undefined) setNotificationsClicked = () => {};

  const { showIosInstall } = useInstallPrompt();
  const { authState, handleAskNotificationPermission } =
    useContext(AuthContext);

  const [modalIosAddToHomeScreenOpen, setModalIosAddToHomeScreenOpen] =
    useState(false);
  const [modalIosAddToHomeScreenKey, setModalIosAddToHomeScreenKey] = useState(
    new Date(),
  );

  const handleEnableNotifications = () => {
    handleAskNotificationPermission();
    setNotificationsClicked(true);
  };

  const handleModalIosAddToHomeScreenClose = () => {
    setModalIosAddToHomeScreenOpen(false);
  };

  const handleModalIosAddToHomeScreenOpen = () => {
    setModalIosAddToHomeScreenOpen(true);
    setModalIosAddToHomeScreenKey(new Date());
  };

  const handleIosInstallApp = () => {
    handleModalIosAddToHomeScreenOpen();
  };

  return (
    <>
      {showIosInstall && (
        <>
          <div className="join-wrapper">
            <Button
              onClick={handleIosInstallApp}
              size="large"
              color="blue"
              disabled={authState.notifyEnabled}
              className="shiny"
            >
              <span>Add to home screen</span>
            </Button>
          </div>

          <ModalIosAddToHomeScreen
            key={modalIosAddToHomeScreenKey}
            open={modalIosAddToHomeScreenOpen}
            close={handleModalIosAddToHomeScreenClose}
          />
        </>
      )}
      {!showIosInstall && (
        <>
          {(notificationsClicked || !authState.notifyEnabled) && (
            <>
              {!authState.notifyEnabled && (
                <div className="join-wrapper">
                  <Button
                    onClick={handleEnableNotifications}
                    size="large"
                    color="blue"
                    disabled={authState.notifyEnabled}
                    className="shiny"
                  >
                    <span>
                      <Icon className="far fa-bell"></Icon> Enable notifications
                    </span>
                  </Button>
                </div>
              )}

              {(authState.notifyRequesting || authState.notifyLoading) && (
                <>
                  {authState.notifyLoading && (
                    <p className="notification-permissions">
                      <Icon className="far fa-spin fa-spinner-third"></Icon>{" "}
                      Enabling notifications...
                    </p>
                  )}
                  {!authState.notifyLoading && (
                    <>
                      <MobileView>
                        <p className="notification-permissions">
                          Tap "Allow" in the popup
                        </p>
                      </MobileView>
                      <BrowserView>
                        <p className="notification-permissions">
                          Click "Allow" in the popup at the top of your screen
                        </p>
                      </BrowserView>
                    </>
                  )}
                </>
              )}

              {notificationsClicked &&
                !authState.notifyRequesting &&
                !authState.notifyLoading &&
                !authState.notifyEnabled && (
                  <p className="notification-permissions">
                    <Icon className="far fa-question-circle"></Icon> Something
                    went wrong when enabling notifications
                  </p>
                )}

              {authState.notifyEnabled && (
                <p className="notification-permissions success">
                  <Icon className="far fa-check"></Icon>&nbsp;&nbsp;Thanks for
                  enabling notifications
                </p>
              )}

              {!authState.notifyEnabled && authState.n10nDebugMsg && (
                <p className="notification-permissions text-red">
                  {authState.n10nDebugMsg}
                </p>
              )}
            </>
          )}

          {notificationsClicked &&
            authState.notifyDenied &&
            !authState.notifyEnabled && (
              <NotifyBar stacked={true}>
                {!authState.notifyRetrying && (
                  <span>Browser notifications are not allowed.</span>
                )}
                {authState.notifyRetrying && (
                  <span>
                    <Icon className="far fa-spin fa-spinner-third"></Icon>{" "}
                    Checking notifications have been unblocked...
                  </span>
                )}
                <Button
                  size="tiny"
                  basic
                  onClick={onModalUnblockNotificationsOpen}
                >
                  Remove this block to continue
                </Button>
              </NotifyBar>
            )}
        </>
      )}
    </>
  );
};

export default EnableNotificationsPrompt;
