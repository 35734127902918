import React from "react";
import { Button } from "semantic-ui-react";

//import { Api } from "../../helpers/api";
import Icon from "../Icon/Icon";

import "./DevTools.scss";

const DevTools = ({ tournamentId, hub /*, hubStateListeners*/ }) => {
  // const handleResetTournament = (toSignUps, config) => {
  //   Api.a01HandleResetTournament(tournamentId, toSignUps, config).then(() => {
  //     window.location.reload();
  //   });
  // };

  // const skipToFinalMovesDue = () => {
  //   if (finalMovesLoading) return;
  //   setFinalMovesLoading(true);

  //   Api.a01HandleSkipToFinalMovesDue(tournamentId).then(() => {
  //     window.location.reload();
  //   });
  // };

  // const endRound = () => {
  //   if (endRoundLoading) return;
  //   setEndRoundLoading(true);

  //   Api.a01HandleEndRound(tournamentId).then(() => {
  //     window.location.reload();
  //   });
  // };

  // const startRound = () => {
  //   if (startRoundLoading) return;
  //   setStartRoundLoading(true);

  //   Api.a01HandleStartRound(tournamentId).then(() => {
  //     window.location.reload();
  //   });
  // };

  // const putMeInFinal = () => {
  //   if (putMeInFinalLoading) return;
  //   setPutMeInFinalLoading(true);

  //   Api.a01PutMeInFinal(tournamentId).then(() => {
  //     window.location.reload();
  //   });
  // };

  const handleSchedulePromoTournament = () => {
    hub.invoke("ADMIN_SchedulePromoTournament", tournamentId);
  };

  return (
    <div id="dev-tools">
      <div>
        {/* <Form size="tiny" style={{ marginBottom: "20px", textAlign: "left" }}>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Field>
                  <label>Players</label>
                  <Input
                    type="text"
                    placeholder="256"
                    value={players}
                    onChange={(e) => setPlayers(e.target.value)}
                  ></Input>
                </Form.Field>
                <Form.Field>
                  <label>
                    Min to entries close{" "}
                    <HelpNode content="How long until entries close, in minutes"></HelpNode>{" "}
                  </label>
                  <Input
                    type="text"
                    placeholder="1"
                    value={entriesClose}
                    onChange={(e) => setEntriesClose(e.target.value)}
                  ></Input>
                </Form.Field>
                <Form.Field>
                  <label>
                    Min to start{" "}
                    <HelpNode content="How long until tournament starts, in minutes"></HelpNode>{" "}
                  </label>
                  <Input
                    type="text"
                    placeholder="2"
                    value={tournStart}
                    onChange={(e) => setTournStart(e.target.value)}
                  ></Input>
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>
                    Move response min{" "}
                    <HelpNode content="How long players have to make their responding move, in minutes"></HelpNode>{" "}
                  </label>
                  <Input
                    type="text"
                    placeholder="120"
                    value={moveResponse}
                    onChange={(e) => setMoveResponse(e.target.value)}
                  ></Input>
                </Form.Field>
                <Form.Field>
                  <label>
                    Round start min{" "}
                    <HelpNode content="The time of day each round starts daily, in minutes. e.g. 10am is 10 hours = 600 minutes"></HelpNode>{" "}
                  </label>
                  <Input
                    type="text"
                    placeholder="600"
                    value={roundStart}
                    onChange={(e) => setRoundStart(e.target.value)}
                  ></Input>
                </Form.Field>
                <Form.Field>
                  <label>
                    Round end min{" "}
                    <HelpNode content="The time of day each round ends daily, in minutes. e.g. 10pm is 22 hours = 1320 minutes"></HelpNode>{" "}
                  </label>
                  <Input
                    type="text"
                    placeholder="1320"
                    value={roundEnd}
                    onChange={(e) => setRoundEnd(e.target.value)}
                  ></Input>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form> */}

        <Button
          size="small"
          color="blue"
          onClick={() => handleSchedulePromoTournament(tournamentId)}
        >
          <Icon className="fas fa-rotate-left"></Icon> Kick off promo tournament
          scheduling
        </Button>

        {/*
        <Button
          size="small"
          color="blue"
          onClick={() => handleResetTournament(true, true)}
        >
          <Icon className="fas fa-rotate-left"></Icon> 10 min before entries
          close
        </Button>
  */}
        {/* <Button
          size="small"
          color="blue"
          onClick={() =>
            handleResetTournament(true, {
              players,
              entriesClose,
              tournStart,
              moveResponse,
              roundStart,
              roundEnd,
              simulate
            })
          }
        >
          <Icon className="fas fa-rotate-left"></Icon> Sign up page
        </Button>

        <Button
          size="small"
          color="blue"
          onClick={() =>
            handleResetTournament(false, {
              players,
              entriesClose,
              tournStart,
              moveResponse,
              roundStart,
              roundEnd,
              simulate
            })
          }
        >
          <Icon className="fas fa-rotate-left"></Icon> Tournament just started
        </Button>

        <Button size="small" color="blue" onClick={skipToFinalMovesDue}>
          {!finalMovesLoading && (
            <span>
              <Icon className="fas fa-forward"></Icon> Skip to final moves due
            </span>
          )}
          {finalMovesLoading && (
            <span>
              <Icon className="fas fa-spinner fa-spin"></Icon> Please wait...
            </span>
          )}
        </Button>

        <Button size="small" color="blue" onClick={endRound}>
          {!endRoundLoading && (
            <span>
              <Icon className="fas fa-forward"></Icon> End current round
            </span>
          )}
          {endRoundLoading && (
            <span>
              <Icon className="fas fa-spinner fa-spin"></Icon> Please wait...
            </span>
          )}
        </Button>

        <Button size="small" color="blue" onClick={startRound}>
          {!startRoundLoading && (
            <span>
              <Icon className="fas fa-forward"></Icon> Start new round
            </span>
          )}
          {startRoundLoading && (
            <span>
              <Icon className="fas fa-spinner fa-spin"></Icon> Please wait...
            </span>
          )}
        </Button>

        <Button size="small" color="blue" onClick={putMeInFinal}>
          {!putMeInFinalLoading && (
            <span>
              <Icon className="fas fa-forward"></Icon> Put me in final
            </span>
          )}
          {putMeInFinalLoading && (
            <span>
              <Icon className="fas fa-spinner fa-spin"></Icon> Please wait...
            </span>
          )}
        </Button>

        <Button
          size="small"
          color="blue"
          onClick={() =>
            hub.invoke("DEBUG_SendStartNotification", tournamentId)
          }
        >
          <Icon className="fas fa-paper-plane-top"></Icon> Send Start PN
        </Button> */}
      </div>
    </div>
  );
};

export default DevTools;
