import { Tween, Easing } from "@tweenjs/tween.js";
import * as PIXI from "pixi.js";

import {
  clamp,
  distance,
  tweenToTargetPosition,
  tweenToTargetScale,
} from "../../../utilities";

class MoveToken extends PIXI.Sprite {
  constructor(board, type, idx, isOpponent, existingMove, is3KO, is3rd) {
    const tokenTex = PIXI.Texture.from(`/images/game/${type}.png`);
    super(tokenTex);

    this.board = board;
    this.isOpponent = isOpponent;
    this.type = type;
    this.idx = idx;
    this.is3KO = is3KO;
    this.is3rd = is3rd;

    this.showDragHelp = false;
    this.lockedIn = false;
    this.waveTimer = null;

    // Set up the Sprite itself
    this.interactive = !existingMove && !isOpponent;
    this.buttonMode = !existingMove && !isOpponent;

    this.slot = board.getComponent(isOpponent, is3rd, "slot");
    this.history = board.getComponent(isOpponent, is3rd, "history");

    this.initialTransform(existingMove);

    // If the actor, handle click events
    if (!isOpponent) {
      this.on("pointerdown", this.onDragStart)
        .on("pointerup", this.onDragEnd)
        .on("pointerupoutside", this.onDragEnd)
        .on("pointermove", this.onDragMove)
        .on("mouseover", this.onMouseOver)
        .on("mouseout", this.onMouseOut);
    } else this.alpha = 0.6;

    board.app.stage.addChild(this);

    this.disable = this.disable.bind(this);
    this.fight = this.fight.bind(this);
    this.postFight = this.postFight.bind(this);
    this.enterBoard = this.enterBoard.bind(this);
    this.wave = this.wave.bind(this);
    this.startWaveTimer = this.startWaveTimer.bind(this);
    this.cancelWaveTimer = this.cancelWaveTimer.bind(this);

    return this;
  }

  newGame(existingMove) {
    //console.log("resetting move token");

    if (this.isOpponent) this.alpha = 0.6;
    else this.enable();

    this.initialTransform(existingMove);
    this.enterBoard(true, this.idx * 200);
  }

  initialTransform(existingMove) {
    this.lockedIn = false;

    this.anchor.set(0.5);

    this.targetScale = { xy: 0.38 };
    this.scale.set(this.targetScale.xy);

    if (!!existingMove && existingMove.moveType === this.type) {
      this.x = this.targetX = this.slot.x;
      this.y = this.targetY = this.slot.y; // + (this.isOpponent ? 3 : -3);
      this.lockedIn = true;
    } else {
      let center =
        (this.board.appWidth / (this.is3KO ? 4 : 2)) * (this.is3rd ? 3 : 1);
      this.x = this.targetX = center + (this.idx - 1) * (this.is3KO ? 75 : 100);
      this.y =
        this.targetY =
        this.defaultY =
          this.board.sideY(this.isOpponent, -100);

      this.tweenPosition = { x: this.x, y: this.y };
      this.tweenScale = { xy: this.targetScale.xy };
    }
  }

  tick() {
    if (this.dragging) return;

    if (this.tweenPosition) {
      this.x = this.tweenPosition.x;
      this.y = this.tweenPosition.y;
    }

    if (this.tweenScale) {
      this.scale.set(this.tweenScale.xy);
    }
  }

  disable() {
    this.interactive = false;
    this.buttonMode = false;
  }

  enable() {
    this.interactive = true;
    this.buttonMode = true;
  }

  hide() {
    this.alpha = 0;
  }

  wave() {
    if (!this.interactive) return;

    this.defaultY = this.y;
    this.targetY = this.defaultY - 20;

    this.waveTween = new Tween(this.tweenPosition)
      .to({ x: this.targetX, y: this.targetY }, 400)
      .easing(Easing.Cubic.InOut)
      .repeat(1)
      .yoyo(true);

    this.waveTween.start();

    this.startWaveTimer();
  }

  startWaveTimer(timeBuffer = 0) {
    this.cancelWaveTimer();
    this.waveTimer = setTimeout(this.wave, 5000 + timeBuffer);
  }

  cancelWaveTimer() {
    if (this.waveTimer) clearTimeout(this.waveTimer);
  }

  onDragStart(event) {
    this.board.cancelWaves();
    this.targetY = this.defaultY;
    this.data = event.data;
    this.dragging = true;

    var clickPosition = this.data.getLocalPosition(this.parent);
    this.dragOffset = {
      x: this.position.x - clickPosition.x,
      y: this.position.y - clickPosition.y,
    };

    console.log('on drag start');
    this.targetScale.xy = 0.38;
    tweenToTargetScale(this, Easing.Quadratic.Out, 100);
  }

  onDragEnd() {
    if (!this.dragging) return;
    this.dragging = false;

    if (!this.showDragHelp)
    {   
      // Check if close to the original position. If so, show message to drag, not click
      if (distance(this.position, { x: this.targetX, y: this.targetY }) < 40) {
        this.slot.showHelper();
        this.showDragHelp = true;
        this.slot.toggleHelperText(true);
        this.slot.dragHereText.alpha = 0;
      }
    }

    // Check if close to the slot. If so, tween and lock it there instead.
    if (distance(this.position, this.slot.position) < 70) {
      this.targetX = this.slot.x;
      this.targetY = this.slot.y;
      this.lockedIn = true;
      this.board.handleMoveLockedIn(this.type);
    }

    tweenToTargetPosition(this);
    this.board.startWaves();

    // set the interaction data to null
    this.data = null;
  }

  onDragMove() {
    if (!!this && this.dragging) {
      const newPosition = this.data.getLocalPosition(this.parent);

      this.x = clamp(
        newPosition.x + this.dragOffset.x,
        this.width / 2,
        this.board.appWidth - this.width / 2
      );
      this.y = clamp(
        newPosition.y + this.dragOffset.y,
        this.height / 2,
        this.board.appHeight - this.height / 2
      );
    }
  }

  onMouseOver() {
    this.board.cancelWaves();
    this.targetScale.xy = 0.45;
    tweenToTargetScale(this, Easing.Quadratic.Out, 100);
  }

  onMouseOut() {
    this.board.startWaves();
    this.targetScale.xy = 0.38;
    tweenToTargetScale(this, Easing.Quadratic.Out, 100);
  }

  enterBoard(animate, delay) {
    if (this.lockedIn) return;

    this.targetY = this.isOpponent
      ? (this.board.appHeight / 2) - this.board.smB(165, 190)
      : (this.board.appHeight / 2) + this.board.smB(165, 190)

    this.defaultY = this.targetY;

    if (animate) {
      tweenToTargetPosition(
        this,
        Easing.Back.InOut,
        700,
        this.startWaveTimer,
        delay
      );
    } else this.y = this.targetY;
  }

  moveToSlot() {
    this.targetX = this.slot.x + this.slot.center;
    this.targetY = this.slot.y;
    this.alpha = 1;
    this.targetScale.xy = 0.38;

    tweenToTargetPosition(this);
    tweenToTargetScale(this);
  }

  moveToHistory() {
    this.targetX = this.slot.x - 150;
    this.targetY = this.history.y;
    this.targetScale.xy = 0.11;

    tweenToTargetPosition(this);
    tweenToTargetScale(this);
  }

  fight(result, onComplete) {
    var component = this;

    if (this.is3KO) this.targetX = this.targetX + (this.is3rd ? -15 : 15);
    this.targetY = this.targetY + (this.isOpponent ? 15 : -15);

    tweenToTargetPosition(this, Easing.Back.InOut, 500, () => {
      component.postFight(result, onComplete);
    });
  }

  postFight(result, onComplete) {
    this.targetX = this.slot.x + this.slot.center;
    this.targetY = this.slot.y;
    this.targetScale.xy =
      result === "win" ? 0.3 : result === "lose" ? 0.2 : this.targetScale.xy;

    tweenToTargetPosition(this, Easing.Quadratic.Out, 500, onComplete);
    tweenToTargetScale(this);
  }
}

export default MoveToken;
