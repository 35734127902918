import firebase from "firebase/app";
import "firebase/messaging";
import React from "react";
import ReactDOM from "react-dom";
import Moment from "react-moment";

import App from "./components/App/App";
import { config } from "./config";
//import * as reactPrecacheSW from "./service-worker-cwa";

import "./semantic/dist/semantic.min.css";
import "./styles/global.scss";
import AuthProvider from "./providers/AuthProvider";

// Uninstall old service worker
navigator.serviceWorker.getRegistrations().then(function (registrations) {
  registrations.forEach(function (registration) {
    // Check if the service worker script URL ends with 'firebase-messaging-sw.js'
    if (
      registration.active &&
      registration.active.scriptURL.endsWith("service-worker.js")
    ) {
      registration.unregister();
    }
  });
});

// Allows all countdown timers to use a pooled timer (saves memory)
Moment.startPooledTimer();

// Initialise firebase
firebase.initializeApp(config.firebase.init);

// Render the React app itself
ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  document.getElementById("root"),
);

// The default SW in React, precaches the site
// This is intended for sites that can operate offline, which RKO cannot, that is why it is turned off
// service-worker-cwa was generated automatically when the react app was first created
// our service worker can be found in /firebase-messaging-sw.js
//reactPrecacheSW.unregister();

function setDomainForAssets() {
  // eslint-disable-next-line no-undef
  window.AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;

  // Set the correct CSS and JS paths
  const authFont = document.getElementById("authFont");
  const authCss = document.getElementById("authCss");
  const authJs = document.getElementById("authJs");

  if (authFont) {
    authFont.href = `//${window.AUTH_DOMAIN}/font/sso.woff2`;
  }

  if (authCss) {
    authCss.href = `//${window.AUTH_DOMAIN}/css/auth.min.css`;
  }

  if (authJs) {
    authJs.src = `//${window.AUTH_DOMAIN}/js/auth.min.js`;
  }
}

if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", setDomainForAssets);
} else {
  setDomainForAssets();
}
