import React, { useContext } from "react";
import { Confirm, TransitionablePortal } from "semantic-ui-react";

import "./ConfirmBox.scss";
import ModalMountContext from "../../contexts/ModalMountContext";

const ConfirmBox = (props) => {
  const modalMountRef = useContext(ModalMountContext);

  return (
    <TransitionablePortal
      open={props.open}
      onOpen={() =>
        setTimeout(() => document.body.classList.add("modal-fade-in"), 10)
      }
      onClose={() => document.body.classList.remove("modal-fade-in")}
      transition={{ animation: "fade down", duration: 300 }}
    >
      <Confirm
        className="confirm-box"
        size={props.size || "tiny"}
        mountNode={
          modalMountRef && modalMountRef.current
            ? modalMountRef.current
            : undefined
        }
        {...props}
      />
    </TransitionablePortal>
  );
};

export default ConfirmBox;
