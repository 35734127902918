import React from "react";

import "./Icon.scss";

const Icon = ({
  embedded,
  color,
  className,
  wrapperClass = "",
  title,
  onClick,
  mask,
}) => {
  return (
    <span
      className={`rps-icon ${wrapperClass} ${embedded ? "icon" : ""}${
        color ? ` ${color}` : ""
      }`}
      onClick={onClick ? onClick : () => {}}
      data-fa-mask={mask}
    >
      <i className={className} title={title} />
    </span>
  );
};

export default Icon;
