import React, { useContext, useMemo, useState } from "react";
import { Button } from "semantic-ui-react";
import _ from "underscore";

import GroupMembersHeading from "./GroupMembersHeading";
import GroupMembersList from "./GroupMembersList";

import AuthContext from "../../contexts/AuthContext";
import Enum from "../../helpers/enums";
import Icon from "../Icon/Icon";
import ModalUserManage from "../Modal/ModalUserManage/ModalUserManage";
import Plural from "../Plural/Plural";

import "./GroupMembers.scss";

const GroupMembers = ({
  group,
  currentTournament,
  myDraftTournament,
  onInvite,
  participating,
  hideParticipating,
  showMatchGrouping,
  tournamentStatusText,
  tournamentStatusIcon,
}) => {
  const { authState } = useContext(AuthContext);

  const [mainMembers, setMainMembers] = useState([]);
  const [knockedOutMembers, setKnockedOutMembers] = useState([]);
  const [notPlayingMembers, setNotPlayingMembers] = useState([]);

  const [menuActiveId, setMenuActiveId] = useState(null);
  const [noun, setNoun] = useState("member");

  const [modalUserManageOpen, setModalUserManageOpen] = useState(false);
  const [modalUserManageKey, setModalUserManageKey] = useState(
    `user-manage-${new Date()}`,
  );
  const [modalUserManageId, setModalUserManageId] = useState(null);
  const [modalUserManageObj, setModalUserManageObj] = useState(null);

  const handleModalUserManageOpen = (user) => {
    setModalUserManageKey(`group-name-${new Date()}`);
    setModalUserManageId(user.accountId);
    setModalUserManageObj(user);
    setModalUserManageOpen(true);
  };

  const handleModalUserManageClose = () => {
    setModalUserManageOpen(false);
  };

  const processMembers = () => {
    console.log("processing group members list array", participating);

    let mainMembersTemp = [];
    let notPlayingMembersTemp = [];
    let knockedOutMembersTemp = [];

    group.groupAccounts.forEach((m) => {
      let ta =
        currentTournament?.tournamentAccountsLookup ?
          _.find(
            currentTournament.tournamentAccountsLookup,
            (_ta) => Math.abs(_ta) === m.accountId,
          )
        : undefined;

      // push if list is NOT showing only participating members, OR if it is, push if the member is participating and is part of a current matchup
      if (
        !participating ||
        (ta !== undefined &&
          ta > 0 &&
          (m.listMatchPosition ||
            currentTournament?.state === Enum.TournamentState.REPEATING))
      ) {
        mainMembersTemp.push({
          ...m,
          isParticipating: ta === undefined ? null : ta > 0,
        });
      }

      if (currentTournament?.state === Enum.TournamentState.ACTIVE) {
        if (!ta || ta < 0) {
          notPlayingMembersTemp.push({
            ...m,
            isParticipating: false,
          });
        }

        if (!!ta && ta > 0 && !m.isCurrentlyPlaying && !m.listMatchPosition) {
          knockedOutMembersTemp.push(m);
        }
      }
    });

    setMainMembers(mainMembersTemp);

    setNoun(
      (
        currentTournament?.state === Enum.TournamentState.ACTIVE ||
          currentTournament?.state === Enum.TournamentState.REPEATING
      ) ?
        "player"
      : "member",
    );

    if (currentTournament?.state === Enum.TournamentState.ACTIVE) {
      setNotPlayingMembers(notPlayingMembersTemp);
      setKnockedOutMembers(knockedOutMembersTemp);
    } else {
      setNotPlayingMembers([]);
      setKnockedOutMembers([]);
    }
  };

  const handleMemberClick = (id, member) => {
    setMenuActiveId((prevState) => {
      return prevState === id ? null : id;
    });

    handleModalUserManageOpen(member);
  };

  useMemo(processMembers, [
    group.groupAccounts,
    currentTournament?.state,
    currentTournament?.tournamentAccountsLookup,
    participating,
  ]);

  const playingText = useMemo(() => {
    if (!mainMembers) return "";

    const playing = mainMembers.filter((m) => m.isParticipating).length;

    return (
      mainMembers.length === playing ?
        mainMembers.length === 2 ?
          "both playing"
        : "everyone playing"
      : `${playing} playing`
    );
  }, [mainMembers]);

  return (
    <div className="group-members">
      {group && (
        <div className="group-members-list">
          <GroupMembersHeading
            currentTournament={currentTournament}
            tournamentStatusText={tournamentStatusText}
            tournamentStatusIcon={tournamentStatusIcon}
          >
            {(
              mainMembers.length === 1 &&
              mainMembers[0].accountId === authState?.account?.id
            ) ?
              `You are the only ${noun}`
            : <>
                {mainMembers.length}{" "}
                <Plural base={noun} value={mainMembers.length} />
              </>
            }
            {mainMembers.length > 1 &&
              currentTournament?.state === Enum.TournamentState.PREPARE && (
                <span className="sub-heading"> ({playingText})</span>
              )}
            {currentTournament?.state === Enum.TournamentState.ACTIVE &&
              mainMembers.length > 2 && (
                <span className="sub-heading">
                  {" "}
                  ({mainMembers.filter((m) => m.isCurrentlyPlaying).length}{" "}
                  remaining)
                </span>
              )}
          </GroupMembersHeading>

          <GroupMembersList
            list={mainMembers}
            group={group}
            currentTournament={currentTournament}
            myDraftTournament={myDraftTournament}
            onMemberClick={handleMemberClick}
            menuActiveId={menuActiveId}
            hideParticipating={hideParticipating}
            showMatchGrouping={showMatchGrouping}
            showVsAi={currentTournament?.state === Enum.TournamentState.ACTIVE}
          />

          {knockedOutMembers && knockedOutMembers.length > 0 && (
            <>
              {" "}
              <GroupMembersHeading>
                {knockedOutMembers.length}{" "}
                <Plural base="player" value={knockedOutMembers.length} />{" "}
                {currentTournament?.stakeIsPositive ? "knocked out" : "safe"}
              </GroupMembersHeading>
              <GroupMembersList
                list={knockedOutMembers}
                group={group}
                currentTournament={currentTournament}
                myDraftTournament={myDraftTournament}
                onMemberClick={handleMemberClick}
                menuActiveId={menuActiveId}
                hideParticipating={true}
                showMatchGrouping={false}
              />
            </>
          )}

          {notPlayingMembers && notPlayingMembers.length > 0 && (
            <>
              <GroupMembersHeading>
                {notPlayingMembers.length}{" "}
                <Plural base="member" value={notPlayingMembers.length} /> not
                playing
              </GroupMembersHeading>

              <GroupMembersList
                list={notPlayingMembers}
                group={group}
                currentTournament={currentTournament}
                myDraftTournament={myDraftTournament}
                onMemberClick={handleMemberClick}
                menuActiveId={menuActiveId}
                hideParticipating={true}
                showMatchGrouping={false}
              />
            </>
          )}

          <div className="group-members-buttons">
            {group.amBoss && onInvite && (
              <div className="group-members-button">
                <Button
                  className="invite-button only-icon"
                  circular
                  basic
                  size="tiny"
                  onClick={onInvite}
                  flush="true"
                >
                  <Icon className="fas fa-fw fa-user-plus"></Icon>
                </Button>
                <div className="button-description">Invite</div>{" "}
              </div>
            )}
          </div>
        </div>
      )}

      <ModalUserManage
        key={modalUserManageKey}
        open={modalUserManageOpen}
        close={handleModalUserManageClose}
        group={group}
        userId={modalUserManageId}
        user={modalUserManageObj}
        currentTournament={currentTournament}
      />
    </div>
  );
};

export default GroupMembers;
