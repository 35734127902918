import React, { useContext, useMemo } from "react";
import { Button } from "semantic-ui-react";

import Avatar from "../../../../../components/Avatar/Avatar";
import ConfirmBox from "../../../../../components/ConfirmBox/ConfirmBox";
import Timer from "../../../../../components/DecidingInterface/InfoPanel/Timer/Timer";
import Icon from "../../../../../components/Icon/Icon";
import JoyrideButton from "../../../../../components/JoyrideButton/JoyrideButton";
import Plural from "../../../../../components/Plural/Plural";
import RandomGameDrawTransition from "../../../../../components/RandomGameDrawTransition/RandomGameDrawTransition";
import TournamentRulesSummary from "../../../../../components/TournamentRulesSummary/TournamentRulesSummary";
import { config } from "../../../../../config";
import AuthContext from "../../../../../contexts/AuthContext";
import Enum from "../../../../../helpers/enums";

function GroupLowerQuick({
  group,
  currentTournament,
  tournamentStarting,
  handleModalInviteOpen,
  handleJoinTournament,
  handleLeaveTournament,
  handleJoinInstantRpsTournament,
  handleStartClick,
  confirmStartOpen,
  handleStartCancel,
  handleStartConfirm,
  handleModalUserManageOpen,
  handleRandomGameDrawTransitionEnd,
  randomGameDrawTransitionShow,
  handleJoyrideTourOpen,
}) {
  const { authState } = useContext(AuthContext);

  // const moveSet = useMemo(() => {
  //   if (!currentTournament || !authState || !authState.account) return "";
  //   if (currentTournament.myInstantRpsMoveSet)
  //     return currentTournament.myInstantRpsMoveSet;
  //   if (
  //     currentTournament.creatorId === authState.account.id &&
  //     tempCreatorMoveSet
  //   )
  //     return tempCreatorMoveSet;
  //   return "";
  // }, [tempCreatorMoveSet, currentTournament, authState]);

  const gameNoun = useMemo(() => {
    if (!currentTournament) return "";
    if (group.promo) return "Tournament";
    return (
        currentTournament.gameMode === Enum.TournamentGameMode.TOURNAMENTRPS
      ) ?
        "Game"
      : "Game";
  }, [group, currentTournament]);

  const gameNounL = useMemo(() => {
    if (!currentTournament) return "";
    if (group.promo) return "tournament";
    return (
        currentTournament.gameMode === Enum.TournamentGameMode.TOURNAMENTRPS
      ) ?
        "game"
      : "game";
  }, [group, currentTournament]);

  const amCreator = useMemo(() => {
    if (!currentTournament || !authState || !authState.account) return false;
    return currentTournament.creatorId === authState.account.id;
  }, [currentTournament, authState]);

  return (
    <>
      <div className="group-lower">
        {randomGameDrawTransitionShow && (
          <RandomGameDrawTransition
            initialCount={3}
            secondSpeed={750}
            onCountdownEnd={handleRandomGameDrawTransitionEnd}
          ></RandomGameDrawTransition>
        )}

        {tournamentStarting && (
          <div className="tournament-starting">
            <Icon className="fal fa-fw fa-spin fa-spinner-third"></Icon>
            <h5>Starting {gameNounL}</h5>
          </div>
        )}

        {!randomGameDrawTransitionShow && !tournamentStarting && (
          <>
            <div className="decision-info">
              <div>
                {!group.promo && (
                  <h5
                    onClick={() =>
                      handleModalUserManageOpen(currentTournament.creatorId)
                    }
                  >
                    <Avatar user={currentTournament.creatorId}></Avatar>
                    {amCreator ?
                      "Your"
                    : currentTournament.creatorUserName + "'s"}{" "}
                    {gameNounL} <span>starting</span>
                    {currentTournament.gameMode ===
                      Enum.TournamentGameMode.RANDOM && (
                      <span className="player-count">
                        {currentTournament.participants}{" "}
                        <Plural
                          value={currentTournament.participants}
                          base="player"
                        />
                      </span>
                    )}
                    {currentTournament.gameMode ===
                      Enum.TournamentGameMode.TOURNAMENTRPS && (
                      <span className="player-count">
                        {currentTournament.participants}{" "}
                        {currentTournament.startingPlayers !==
                          config.validation.tournamentRps.maxPlayers.max && (
                          <>
                            <span>
                              of {currentTournament.startingPlayers} players
                            </span>
                            {currentTournament.participants > 2 && (
                              <span>(tournament)</span>
                            )}
                          </>
                        )}
                        {currentTournament.startingPlayers ===
                          config.validation.tournamentRps.maxPlayers.max && (
                          <>
                            {currentTournament.participants > 2 && (
                              <span>player tournament</span>
                            )}
                            {currentTournament.participants <= 2 && (
                              <Plural
                                value={currentTournament.participants}
                                base="player"
                              />
                            )}
                          </>
                        )}
                      </span>
                    )}
                  </h5>
                )}

                {group.promo && (
                  <>
                    <div className="promo-player-count">
                      <h5>
                        {currentTournament.startingPlayers -
                          currentTournament.participants}{" "}
                        <Plural
                          base="spot"
                          value={
                            currentTournament.startingPlayers -
                            currentTournament.participants
                          }
                        />{" "}
                        left
                        {currentTournament.participants > 0 && (
                          <span className="player-count">
                            {currentTournament.participants} of{" "}
                            {currentTournament.startingPlayers} players joined
                          </span>
                        )}
                      </h5>
                    </div>
                    <h5>Tournament starts in</h5>
                  </>
                )}

                <div className="timer-wrapper">
                  <Timer
                    endTime={currentTournament.startTime}
                    labelled={true}
                    large={true}
                    showNotificationBell={true}
                  ></Timer>
                </div>

                {currentTournament.rescheduleCount > 0 && (
                  <div className="decision-rescheduled">
                    {gameNoun} rescheduled{" "}
                    {currentTournament.rescheduleCount > 1 && (
                      <>
                        (&times;
                        {currentTournament.rescheduleCount})
                      </>
                    )}
                  </div>
                )}

                {currentTournament.gameMode ===
                  Enum.TournamentGameMode.TOURNAMENTRPS && (
                  <TournamentRulesSummary
                    firstToGames={currentTournament.firstToGames}
                    stakeIsPositive={currentTournament.stakeIsPositive}
                    gameLengthMinutes={currentTournament.gameLengthMinutes}
                    promo={group.promo}
                  ></TournamentRulesSummary>
                )}

                {currentTournament.gameMode ===
                  Enum.TournamentGameMode.RANDOM && (
                  <>
                    <TournamentRulesSummary
                      winnersChosen={currentTournament.winnersToDraw}
                    ></TournamentRulesSummary>
                  </>
                )}

                {!group.promo &&
                  !amCreator &&
                  currentTournament.amParticipating === true && (
                    <div className="decision-joined">
                      Joined {gameNounL}
                      {!amCreator && (
                        <>
                          ,{" "}
                          {(currentTournament.gameMode ===
                            Enum.TournamentGameMode.TOURNAMENTRPS &&
                            "wait for your match") ||
                            "wait for result"}
                        </>
                      )}
                    </div>
                  )}

                {group.promo && currentTournament.amParticipating === true && (
                  <div className="decision-joined">
                    <b>You've joined!</b>
                    <br />
                    <br />
                    Your first match will start soon.
                  </div>
                )}

                {amCreator &&
                  ((currentTournament.winnersToDraw === 1 &&
                    currentTournament.participants >= 2) ||
                    (currentTournament.winnersToDraw > 1 &&
                      currentTournament.participants >=
                        currentTournament.winnersToDraw)) &&
                  group.playerCount ===
                    currentTournament.tournamentAccountsLookup.length && (
                    <div className="decision-joined ready-to-play">
                      Ready to start
                    </div>
                  )}
                {amCreator &&
                  ((currentTournament.winnersToDraw === 1 &&
                    currentTournament.participants < 2) ||
                    (currentTournament.winnersToDraw > 1 &&
                      currentTournament.participants <
                        currentTournament.winnersToDraw)) &&
                  group.playerCount > 1 &&
                  group.playerCount ===
                    currentTournament.tournamentAccountsLookup.length && (
                    <div className="decision-joined not-enough-players">
                      Not enough players to start {gameNounL}
                    </div>
                  )}

                {amCreator &&
                  group.playerCount > 1 &&
                  currentTournament.participants <= 1 &&
                  group.playerCount !==
                    currentTournament.tournamentAccountsLookup.length && (
                    <div className="decision-joined waiting-players">
                      Members invited. Wait for them to join.
                    </div>
                  )}

                {amCreator && group.playerCount === 1 && (
                  <div className="decision-joined waiting-players">
                    Wait for members to join
                  </div>
                )}
              </div>
            </div>

            {!group.promo && amCreator && (
              <JoyrideButton onClick={handleJoyrideTourOpen}></JoyrideButton>
            )}

            <div className="action-panel">
              {group.amBoss &&
                (group.finishedGameCount === 0 ||
                  group.groupAccounts.length === 1) && (
                  <Button
                    icon
                    size="big"
                    primary
                    disabled={tournamentStarting}
                    className="decide-now"
                    onClick={handleModalInviteOpen}
                  >
                    Invite new players
                  </Button>
                )}

              {(currentTournament.amParticipating === null ||
                currentTournament.amParticipating === false) &&
                !amCreator && (
                  <>
                    <Button
                      icon
                      size="big"
                      primary
                      onClick={() => {
                        (
                          currentTournament.gameMode ===
                          Enum.TournamentGameMode.INSTANTRPS
                        ) ?
                          handleJoinInstantRpsTournament()
                        : handleJoinTournament(
                            currentTournament.id,
                            authState?.account?.id,
                          );
                      }}
                      disabled={
                        tournamentStarting ||
                        (currentTournament.gameMode ===
                          Enum.TournamentGameMode.INSTANTRPS &&
                          currentTournament.participants ===
                            currentTournament.startingPlayers)
                      }
                    >
                      <span>Join this {gameNounL}</span>
                    </Button>
                  </>
                )}

              {((currentTournament.amParticipating === null && !group.promo) ||
                currentTournament.amParticipating === true) &&
                !amCreator && (
                  <Button
                    icon
                    basic
                    primary
                    onClick={() => {
                      handleLeaveTournament(
                        currentTournament.id,
                        authState?.account?.id,
                      );
                    }}
                    disabled={tournamentStarting}
                  >
                    {currentTournament.amParticipating === null && (
                      <span>Decline</span>
                    )}
                    {currentTournament.amParticipating === true && (
                      <span>Withdraw from {gameNounL}</span>
                    )}
                  </Button>
                )}

              {(group.amBoss || group.amAssistant || amCreator) && (
                <>
                  <Button
                    icon
                    primary
                    /*basic={
                  group.playerCount === 1 ||
                  group.finishedGameCount === 0 ||
                  group.playerCount !==
                    currentTournament.tournamentAccountsLookup.length
                }*/
                    size="big"
                    onClick={handleStartClick}
                    className="decide-now"
                  >
                    <span>Play now</span>
                    <Icon className="far fa-play"></Icon>
                  </Button>
                  <ConfirmBox
                    content={
                      `Play ${gameNounL} now with ${currentTournament.participants} current players?` +
                      (!amCreator ?
                        " This game was set up by another group member."
                      : "")
                    }
                    cancelButton="No, wait"
                    confirmButton="Yes, play it"
                    open={confirmStartOpen}
                    onCancel={handleStartCancel}
                    onConfirm={handleStartConfirm}
                  ></ConfirmBox>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default GroupLowerQuick;
