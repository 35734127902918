import React, { useContext, useMemo, useState } from "react";
import Moment from "react-moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Modal, TransitionablePortal, Label, Button } from "semantic-ui-react";

import { config } from "../../../config";
import AuthContext from "../../../contexts/AuthContext";
import { Api } from "../../../helpers/api";
import { rpsApi } from "../../../helpers/api-config";
import Enum from "../../../helpers/enums";
import Avatar from "../../Avatar/Avatar";
import ConfirmBox from "../../ConfirmBox/ConfirmBox";
import GroupMemberBadges from "../../GroupMembers/GroupMember/GroupMemberBadges/GroupMemberBadges";
import GroupMemberProviders from "../../GroupMembers/GroupMember/GroupMemberProviders";
import Icon from "../../Icon/Icon";
import Plural from "../../Plural/Plural";
import TextButton from "../../TextButton/TextButton";
import ModalGroupAlert from "../ModalGroupAlert/ModalGroupAlert";
import RkoModal from "../RkoModal/RkoModal";

import "./ModalUserManage.scss";

const ModalUserManage = ({
  group,
  open,
  close,
  userId,
  user,
  currentTournament,
  hideParticipating,
}) => {
  const history = useHistory();

  const [confirmRemoval, setConfirmRemoval] = useState(false);
  const [confirmBan, setConfirmBan] = useState(false);
  const [confirmChallenge, setConfirmChallenge] = useState(false);

  const [loading, setLoading] = useState(false);
  const [challengeCheckLoading, setChallengeCheckLoading] = useState(false);

  const [modalGroupAlertHeader, setModalGroupAlertHeader] = useState("");
  const [modalGroupAlertContent, setModalGroupAlertContent] = useState("");
  const [modalGroupAlertOpen, setModalGroupAlertOpen] = useState(false);
  const [modalGroupAlertKey, setModalGroupAlertKey] = useState(new Date());

  const { authState } = useContext(AuthContext);

  const handleModalGroupAlertClose = () => {
    setModalGroupAlertOpen(false);
  };

  const handleModalGroupAlertOpen = (header, content) => {
    setModalGroupAlertKey(new Date());
    setModalGroupAlertOpen(true);
    setModalGroupAlertHeader(header);
    setModalGroupAlertContent(content);
  };

  const handleClose = () => {
    document.body.classList.remove("modal-fade-in");
    close();
  };

  const handleRemove = () => {
    setConfirmRemoval(true);
  };

  const removeMember = (ban) => {
    setLoading(true);

    rpsApi
      .delete(
        `/groupaccount/${group.id}/?accountId=${userId}${
          ban ? "&ban=true" : ""
        }`,
      )
      .then((response) => {
        if (response.status !== 200) {
          handleModalGroupAlertOpen("Error removing from group", response.data);
        }

        setConfirmRemoval(false);
        handleClose();
      });
  };

  const handleRemoveConfirm = (ban) => {
    removeMember(ban);
  };

  const handleRemoveCancel = () => {
    setConfirmRemoval(false);
  };

  // const handleBan = (e) => {
  //   e.stopPropagation();
  //   //setConfirmBan(true);
  // };

  const handleBanCancel = () => {
    setConfirmBan(false);
  };

  const handleChallenge = () => {
    setConfirmChallenge(true);
  };

  const handleChallengeCancel = () => {
    setConfirmChallenge(false);
  };

  const handleChallengeConfirm = () => {
    setLoading(true);
    Api.challengeAccount(userId).then((res) => {
      if (res.status === 200 || res.status === 201) {
        history.push(`/g/${res.data}`);
        handleClose();
      }
    });
  };

  const handleChallengeCheck = () => {
    setChallengeCheckLoading(true);
    Api.challengeAccount(userId, true).then((res) => {
      if (res.status === 200 || res.status === 204) {
        if (res.data) history.push(`/g/${res.data}`);
        else {
          setChallengeCheckLoading(false);
          handleChallenge();
        }
      }
    });
  };

  const isAi = useMemo(() => {
    return userId === config.aiAccountMember.accountId;
  }, [userId]);

  const isMe = useMemo(() => {
    return userId === authState?.account?.id;
  }, [userId, authState]);

  return (
    <>
      <TransitionablePortal
        open={open}
        onOpen={() =>
          setTimeout(() => document.body.classList.add("modal-fade-in"), 0)
        }
        transition={{ animation: "fade down", duration: 300 }}
      >
        <RkoModal
          size="small"
          centered={false}
          open={true}
          onClose={handleClose}
          className="modal-user-manage"
        >
          {user && (
            <>
              <Modal.Content>
                <Modal.Description>
                  <div className="modal-avatar-breakout">
                    <div className="modal-avatar-breakout-inner">
                      <Avatar user={user.accountId} size="tiny"></Avatar>
                    </div>
                  </div>
                  <div className="group-member-username">
                    {isMe && (
                      <Label color="blue" horizontal>
                        YOU
                      </Label>
                    )}
                    {user.accountUserName}
                    <GroupMemberBadges
                      member={user}
                      hideParticipating={true}
                      hideYou={true}
                    ></GroupMemberBadges>
                    {user.accountTagline && (
                      <div className="group-member-tagline">
                        <Icon className="far fa-message-lines"></Icon>
                        <div>{user.accountTagline}</div>
                      </div>
                    )}
                    {!isAi &&
                      !hideParticipating &&
                      currentTournament != null &&
                      currentTournament.state !==
                        Enum.TournamentState.REPEATING && (
                        <span
                          className={`group-member-participating ${
                            user.isParticipating === true ? "is-participating"
                            : user.isParticipating === false ?
                              "not-participating"
                            : ""
                          }`}
                        >
                          {user.isParticipating === true && (
                            <>
                              <Icon className="fas fa-fw fa-check"></Icon>{" "}
                              Playing this game
                            </>
                          )}
                          {user.isParticipating === false && (
                            <>
                              <Icon className="fas fa-fw fa-times"></Icon> Not
                              playing this game
                            </>
                          )}
                          {user.isParticipating === null && (
                            <>
                              <Icon className="fas fa-fw fa-user-plus"></Icon>{" "}
                              Invited to play game
                            </>
                          )}
                        </span>
                      )}
                  </div>

                  {isAi && (
                    <div className="group-member-dates">
                      <b>{user.accountUserName}</b> is a bot that plays in
                      rounds with an odd number of players. It never advances to
                      the next round,
                      {!currentTournament &&
                        " but it can still knock you out of the running for the prize if you lose, or keep you safe from the penalty if you win."}
                      {currentTournament &&
                        currentTournament.stakeIsPositive &&
                        " but it can still knock you out of the running for the prize if you lose."}
                      {currentTournament &&
                        !currentTournament.stakeIsPositive &&
                        " but it can still keep you safe from the penalty if you win."}
                    </div>
                  )}

                  {!isAi && (
                    <>
                      {user.notMember && (
                        <div className="group-member-dates">
                          This user is no longer a member of this group
                        </div>
                      )}
                      {!user.notMember && (
                        <div className="group-member-dates">
                          <b className="ellipsis-overflow">{group.name}</b>{" "}
                          group member for{" "}
                          <Moment
                            fromNow
                            date={user.joinedTime}
                            withTitle
                            titleFormat="D MMM YYYY HH:mm:ss"
                            ago="false"
                          />
                        </div>
                      )}
                      {user.repeatingGameCount > 0 && (
                        <div className="group-member-dates">
                          <Icon className="fas fa-fw fa-calendar-days"></Icon>
                          &nbsp; Playing in {
                            user.repeatingGameCount
                          } repeating{" "}
                          <Plural value={user.repeatingGameCount} base="game" />
                        </div>
                      )}
                      <GroupMemberProviders
                        member={{ accountProviders: user.accountProviders }}
                      ></GroupMemberProviders>

                      {loading && (
                        <div className="user-loader">
                          <Icon className="fal fa-fw fa-2x fa-circle-notch fa-spin"></Icon>
                        </div>
                      )}

                      {!loading && (
                        <>
                          {!isMe && !isAi && group.playerCount > 2 && (
                            <>
                              <div className="challenge-btn">
                                <Button
                                  primary
                                  onClick={handleChallengeCheck}
                                  disabled={challengeCheckLoading}
                                >
                                  {challengeCheckLoading && (
                                    <Icon className="fas fa-fw fa-spin fa-circle-notch"></Icon>
                                  )}
                                  {!challengeCheckLoading && (
                                    <Icon className="fas fa-fw fa-scissors"></Icon>
                                  )}{" "}
                                  Challenge {user.accountUserName}
                                </Button>
                              </div>
                              <ConfirmBox
                                open={confirmChallenge}
                                header={`Challenge ${user.accountUserName}?`}
                                content={
                                  <div className="content">
                                    Challenging {user.accountUserName} will
                                    create a new group with just the two of you
                                  </div>
                                }
                                cancelButton="Cancel"
                                confirmButton="Continue"
                                onConfirm={() => handleChallengeConfirm(true)}
                                onCancel={handleChallengeCancel}
                              />
                            </>
                          )}

                          {!isMe &&
                            !isAi &&
                            group.amBoss &&
                            !user.notMember &&
                            !user.isParticipating && (
                              <div className="group-member-control group-member-options">
                                <div className="group-member-button-group">
                                  <TextButton onClick={(e) => handleRemove(e)}>
                                    <Icon className="far fa-user-minus"></Icon>{" "}
                                    Remove member
                                  </TextButton>
                                </div>

                                <ConfirmBox
                                  open={confirmRemoval}
                                  header={`Remove ${user.accountUserName}?`}
                                  content={
                                    <div className="content">
                                      Are you sure you want to kick{" "}
                                      <b>{user.accountUserName}</b> from the
                                      group?
                                      {currentTournament?.creatorId ===
                                        userId && (
                                        <span>
                                          {" "}
                                          Their current game will be cancelled.
                                        </span>
                                      )}
                                    </div>
                                  }
                                  cancelButton="Cancel"
                                  confirmButton="Remove"
                                  onConfirm={() => handleRemoveConfirm(false)}
                                  onCancel={handleRemoveCancel}
                                />

                                <ConfirmBox
                                  open={confirmBan}
                                  header={`Ban ${user.accountUserName}?`}
                                  content={
                                    <div className="content">
                                      Are you sure you want to ban{" "}
                                      <b>{user.accountUserName}</b> from the
                                      group? They will never be able to rejoin.
                                      {currentTournament?.creatorId ===
                                        userId && (
                                        <span>
                                          {" "}
                                          Their current game will be cancelled.
                                        </span>
                                      )}
                                      <br />
                                      <br />
                                      <b>This action cannot be undone</b>
                                    </div>
                                  }
                                  cancelButton="Cancel"
                                  confirmButton="Ban"
                                  onConfirm={() => handleRemoveConfirm(true)}
                                  onCancel={handleBanCancel}
                                />
                              </div>
                            )}
                        </>
                      )}
                    </>
                  )}
                </Modal.Description>
              </Modal.Content>
            </>
          )}
        </RkoModal>
      </TransitionablePortal>

      <ModalGroupAlert
        key={"group-alert-" + modalGroupAlertKey}
        open={modalGroupAlertOpen}
        close={handleModalGroupAlertClose}
        header={modalGroupAlertHeader}
        content={modalGroupAlertContent}
      />
    </>
  );
};

export default ModalUserManage;
