import React, { useState } from "react";
import "./ToggleSwitch.scss";

function ToggleSwitch({
  leftText,
  rightText,
  leftColor,
  rightColor,
  value,
  onToggle,
}) {
  const [isToggled, setToggled] = useState(
    value === true ? "right" : value === false ? "left" : "neutral"
  );

  const handleToggle = (value) => {
    setToggled(value);
    onToggle(value === "right" ? true : value === "left" ? false : null);
  };

  const colors = {
    left: leftColor,
    neutral: "#999",
    right: rightColor,
  };

  const activeColor = colors[isToggled];
  //const activeText = isToggled ? rightText : leftText;

  return (
    <div className="toggle-container">
      <div
        className={`toggle-text tt-left ${
          isToggled === "left" ? "active" : ""
        }`}
        style={{
          fontWeight: isToggled === "left" ? "bold" : "normal",
          color: isToggled === "right" ? "#666" : leftColor,
        }}
        onClick={() => handleToggle("left")}
      >
        {leftText}
      </div>
      <div
        className="toggle-switch"
        style={{ borderColor: activeColor, backgroundColor: activeColor }}
        onClick={() => handleToggle(isToggled === "right" ? "left" : "right")}
      >
        <div className="toggle-bg">
          <div className={`toggle ${isToggled}`}></div>
        </div>
      </div>
      <div
        className={`toggle-text tt-right ${
          isToggled === "right" ? "active" : ""
        }`}
        style={{
          fontWeight: isToggled === "right" ? "bold" : "normal",
          color: isToggled === "left" ? "#666" : rightColor,
        }}
        onClick={() => handleToggle("right")}
      >
        {rightText}
      </div>
    </div>
  );
}

export default ToggleSwitch;
