import moment from 'moment';

import { config } from '../config';

class Game {
  static fromRoundStart(startMatch, startTime, gameAccounts, withAi) {
    var newGame = new Game();

    newGame.id = startMatch.games[0].id;
    newGame.matchId = startMatch.id;
    newGame.startTime = startTime;
    newGame.endTime = moment(startMatch.games[0].endTime);
    newGame.endReason = null;
    newGame.isTotalForfeit = false;
    newGame.isDraw = false;
    newGame.completed = false;
    newGame.moves = [];
    newGame.gameAccounts = gameAccounts;
    newGame.winners = [];
    newGame.losers = [];
    newGame.withAi = withAi;

    // Add the AI's move if they're in this game
    if (withAi)
      newGame.moves.push({
        id: 0,
        time: new Date(),
        endReason: 0,
        gameId: newGame.id,
        matchId: newGame.matchId,
        accountId: config.aiAccountMember.accountId,
        accountUserName: config.aiAccountMember.accountUserName,
      });

    return newGame;
  }
  static fromTournamentInit(game) {
    if (!game) return null;

    var newGame = new Game();

    newGame.completed = game.completed;
    newGame.endReason = game.endReason;
    newGame.endTime = moment(game.endTime);
    newGame.gameAccounts = game.gameAccounts;
    newGame.id = game.id;
    newGame.isDraw = game.isDraw;
    newGame.isTotalForfeit = game.isTotalForfeit;
    newGame.losers = game.losers;
    newGame.matchId = game.matchId;
    newGame.moves = game.moves;
    newGame.startTime = game.startTime;
    newGame.winners = game.winners;

    return newGame;
  }

  static patchGameFinishedEvent(prevGame, data) {
    let patchedGame = Object.assign(new Game(), {
      ...prevGame,
      endTime: moment(data.endTime),
      endReason: data.endReason,
      isTotalForfeit: data.isTotalForfeit,
      moves: data.moves,
    });

    for (let i = 0; i < patchedGame.gameAccounts.length; i++) {
      let dataGameAccount = data.gameAccounts.find(
        (ga) => ga.accountId === patchedGame.gameAccounts[i].accountId
      );

      patchedGame.gameAccounts[i].result = dataGameAccount.result;
    }

    return patchedGame;
  }

  static patchGameNewExpiryEvent(prevGame, data) {
    let patchedGame = Object.assign(new Game(), {
      ...prevGame,
      endTime: moment(data.endTime),
    });

    return patchedGame;
  }

  static patchGameIMovedEvent(prevGame, data) {
    let patchedGame = Object.assign(new Game(), {
      ...prevGame,
      moves: [
        ...prevGame.moves,
        {
          id: data.id,
          accountId: data.accountId,
          moveType: data.moveType
        },
      ],
    });

    return patchedGame;
  }

  static patchGameNewMoveEvent(prevGame, data) {
    let patchedGame = Object.assign(new Game(), {
      ...prevGame,
      moves: [
        ...prevGame.moves,
        {
          id: data.id,
          accountId: data.accountId
        },
      ],
    });

    return patchedGame;
  }

  static nextGame(game, gameStartedDto) {
    var newGame = new Game();

    newGame.id = gameStartedDto.id;
    newGame.matchId = game.matchId;
    newGame.startTime = new Date();
    newGame.endTime = moment(gameStartedDto.endTime);
    newGame.endReason = null;
    newGame.isTotalForfeit = false;
    newGame.isDraw = false;
    newGame.completed = false;
    newGame.moves = [];
    newGame.gameAccounts = game.gameAccounts.map((ga) => {
      return {
        gameId: ga.gameId,
        result: null,
        accountId: ga.accountId,
        accountUserName: ga.accountUserName,
      };
    });
    newGame.winners = [];
    newGame.losers = [];
    newGame.withAi = game.withAi;

    // Add the AI's move if they're in this game
    if (newGame.withAi)
      newGame.moves.push({
        id: 0,
        time: new Date(),
        endReason: 0,
        gameId: newGame.id,
        matchId: newGame.matchId,
        accountId: config.aiAccountMember.accountId,
        accountUserName: config.aiAccountMember.accountUserName,
      });

    return newGame;
  }
}

export default Game;
