import FontFaceObserver from "fontfaceobserver";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "semantic-ui-react";

import Board from "../../components/DecidingInterface/Board/Board";
import Icon from "../../components/Icon/Icon";
import Game from "../../models/Game";
import Match from "../../models/Match";

import "./SoloAnonPage.scss";

const SoloAnonPage = () => {
  const history = useHistory();

  const [currentGameId, setCurrentGameId] = useState(-9999);
  const [fontLoaded, setFontLoaded] = useState(false);
  const [showPlayAgain, setShowPlayAgain] = useState(false);

  const group = {
    currentTournamentId: -9999,
    canDelete: false,
    inviteCode: null,
    amBoss: true,
    amAssistant: true,
    amSpectator: true,
    groupAccounts: [
      {
        id: "-9999#You",
        groupId: -9999,
        accountId: -9999,
        role: 147,
        lastOnline: "2024-01-01T00:00:00.0000000+00:00",
        isCurrentlyPlaying: false,
        matchComplete: false,
        hasMoved: false,
        chosenLastTournament: null,
        accountUserName: "You",
        accountTagline: null,
        accountCreatedTime: "2024-01-01T00:00:00.0000000+00:00",
        joinedTime: "2024-01-01T00:00:00.0000000+00:00",
        participating: null,
        repeatingGameCount: 0,
        isBoss: true,
        isAssistant: true,
        isPlayer: true,
        metaAccount: "account/-9999",
      },
      {
        id: "-10000#RKObot",
        groupId: -9999,
        accountId: 1,
        role: 3,
        lastOnline: null,
        isCurrentlyPlaying: false,
        matchComplete: false,
        hasMoved: false,
        chosenLastTournament: null,
        accountUserName: "RKObot",
        accountTagline: null,
        accountCreatedTime: "2024-01-01T00:00:00.0000000+00:00",
        joinedTime: "2024-01-01T00:00:00.0000000+00:00",
        participating: null,
        repeatingGameCount: 0,
        isBoss: false,
        isAssistant: false,
        isPlayer: true,
        metaAccount: "account/1",
      },
    ],
    repeatingTournaments: [],
    id: -9999,
    name: "_solo_anon",
    slug: "_solo_anon",
    closed: false,
    public: false,
    groupAccountsCount: 2,
    playerCount: 2,
    leaderCount: 1,
    repeatingGameCount: 0,
    promo: false,
    maxMembers: null,
    amPlayer: true,
    amBanned: false,
    unseenRepeatingCount: 0,
    finishedGameCount: 0,
    soloAccountId: -9999,
    isSolo: true,
    isSoloAnon: true,
  };

  const [currentTournament, setCurrentTournament] = useState({
    _full: "/tournament/-9999",
    id: -9999,
    groupId: -9999,
    state: 2,
    startTime: "2024-01-01T00:00:00.0000000+00:00",
    endTime: null,
    endReason: null,
    stake: "",
    stakeIsPositive: true,
    firstToGames: 0,
    matchMaxGames: 0,
    gameLengthMinutes: 0,
    gameMode: 2,
    startMode: 0,
    prepareLengthMinutes: null,
    startingPlayers: 2,
    winnersToDraw: 1,
    creatorId: -9999,
    creatorUserName: "You",
    amParticipating: true,
    rescheduleCount: 0,
    repeatParentId: null,
    repeatEvery: null,
    repeatPeriod: null,
    repeatDayOfMonth: null,
    repeatDaysOfWeek: null,
    maxRoundsRequired: null,
    myInstantRpsMoveSet: null,
    prizes: null,
    theChosenOnes: null,
    completed: false,
  });

  const [currentGame, setCurrentGame] = useState({
    matchId: -9999,
    moves: [],
    gameAccounts: [
      {
        gameId: currentGameId,
        accountId: 1,
        accountUserName: null,
        result: null,
      },
      {
        gameId: currentGameId,
        accountId: -9999,
        accountUserName: null,
        result: null,
      },
    ],
    winners: null,
    losers: null,
    id: currentGameId,
    startTime: "2024-01-01T00:00:00.0000000+00:00",
    endTime: "2099-01-01T00:00:00.0000000+00:00",
    endReason: null,
    isTotalForfeit: false,
    isDraw: true,
    completed: false,
  });

  // const _roundMatch = {
  //   roundNumber: 1,
  //   roundTitle: "Final",
  //   prevRoundId: null,
  //   prevRoundNumber: null,
  //   prevRoundTitle: null,
  //   nextRoundNumber: null,
  //   nextRoundTitle: null,
  //   historicalMatchAccounts: null,
  //   roundId: -9999,
  //   games: [{ ...currentGame }],
  //   moves: [],
  //   matchAccounts: [
  //     {
  //       id: -9999,
  //       matchId: -9999,
  //       score: 0,
  //       result: null,
  //       accountId: 1,
  //       accountUserName: "RKObot",
  //     },
  //     {
  //       id: -10000,
  //       matchId: -9999,
  //       score: 0,
  //       result: null,
  //       accountId: -9999,
  //       accountUserName: "You",
  //     },
  //   ],
  //   advancers: [],
  //   leavers: [],
  //   id: -9999,
  //   endReason: null,
  //   randomResult: false,
  //   isThreePlayerKnockout: false,
  //   noOutcome: true,
  //   completed: false,
  // };

  const _freshMatch = {
    completed: false,
    advancers: [],
    endReason: null,
    games: [
      {
        ...currentGame,
      },
    ],
    id: -9999,
    isThreePlayerKnockout: false,
    matchAccounts: [
      {
        id: -10000,
        matchId: -9999,
        score: 0,
        result: null,
        accountId: 1,
        accountUserName: "RKObot",
      },
      {
        id: -9999,
        matchId: -9999,
        score: 0,
        result: null,
        accountId: -9999,
        accountUserName: "You",
      },
    ],
    noOutcome: true,
    randomResult: false,
    moves: [],
    roundTitle: "Final",
    iAdvanced: false,
    vsAi: true,
  };

  const [currentMatch, setCurrentMatch] = useState({
    ..._freshMatch,
    key: new Date(),
  });

  const [currentRound, setCurrentRound] = useState({
    matches: [{ currentMatch }],
    id: -9999,
    tournamentId: -9999,
    roundNumber: 1,
    endTime: null,
    nextRoundBegins: null,
    state: 1,
    isFinalRound: true,
    matchesComplete: 0,
    matchesCount: 1,
    advancersCount: 0,
    playersCount: 2,
    playersRemaining: 2,
  });

  const [actor] = useState({ accountId: -9999 });

  const [opponents] = useState([
    {
      id: -10000,
      matchId: -9999,
      score: 0,
      result: null,
      accountId: 1,
      accountUserName: "RKObot",
    },
  ]);

  const [actorMove, setActorMove] = useState(null);
  const [opponentMoves, setOpponentMoves] = useState([]);
  const [rpsEventCurrent, setRpsEventCurrent] = useState({});

  const moveHistory = useMemo(() => {
    const reversed = [...(currentMatch?.moves || [])].reverse();

    return reversed.reduce((acc, move) => {
      if (!acc[move.accountId]) acc[move.accountId] = [];

      if (acc[move.accountId].length < 3) acc[move.accountId].push(move);

      return acc;
    }, {});
  }, [currentMatch?.moves]);

  useEffect(() => {
    if (!opponents || !currentGame || !currentGame.moves) setOpponentMoves([]);
    else
      setOpponentMoves(
        opponents.map((o) =>
          currentGame.moves.find((m) => m.accountId === o.accountId),
        ),
      );
  }, [opponents, currentGame]);

  const handleMove = (moveType) => {
    setActorMove({ moveType });

    const youMoveId = currentGameId * 2;
    const botMoveId = currentGameId * 2 - 1;

    // Your move
    const newGame = Game.patchGameIMovedEvent(currentGame, {
      id: youMoveId,
      accountId: -9999,
      moveType: moveType,
    });
    setCurrentGame(newGame);

    const newMatch = Match.patchMatchIMovedEvent(currentMatch, newGame);
    setCurrentMatch(newMatch);

    setCurrentTournament((prevTournament) => {
      return {
        ...prevTournament,
        myMatch: {
          ...prevTournament.myMatch,
          ...newMatch,
        },
      };
    });

    setRpsEventCurrent({
      eventType: 100,
      subject: {
        id: youMoveId,
        moveType: null,
        time: "2024-01-01T00:00:00.0000000+00:00",
        endReason: 0,
        gameId: currentGameId,
        matchId: -9999,
        accountId: -9999,
      },
      typeSlug: "move-made",
    });

    // Generate bot move and results
    var youMove = moveType;
    var botMove = (new Date().getMilliseconds() % 3) + 1;
    var youResult =
      youMove === botMove ? 0
      : (
        (youMove === 1 && botMove === 2) ||
        (youMove === 2 && botMove === 3) ||
        (youMove === 3 && botMove === 1)
      ) ?
        2
      : 1;

    var botResult = youResult === 0 ? 0 : 3 - youResult;

    // Bot move made
    setTimeout(() => {
      setRpsEventCurrent({
        eventType: 100,
        subject: {
          id: botMoveId,
          moveType: botMove,
          time: "2024-01-01T00:00:00.0000000+00:00",
          endReason: 0,
          gameId: currentGameId,
          matchId: -9999,
          accountId: 1,
        },
        typeSlug: "move-made",
      });
    }, 1000);

    // Game finished
    setTimeout(() => {
      setRpsEventCurrent({
        eventType: 200,
        matchId: -9999,
        actor: {
          id: "1#RKObot",
          groupId: -9999,
          accountId: 1,
          role: 3,
          lastOnline: null,
          isCurrentlyPlaying: true,
          matchComplete: false,
          hasMoved: false,
          chosenLastTournament: false,
          accountUserName: "RKObot",
          accountTagline: null,
          accountCreatedTime: "2024-01-01T00:00:00.0000000+00:00",
          joinedTime: null,
          participating: true,
          repeatingGameCount: 0,
          isBoss: false,
          isAssistant: false,
          isPlayer: true,
          metaAccount: "account/1",
        },
        target: null,
        subject: {
          id: -9999,
          endTime: "2024-01-01T00:00:00.0000000+00:00",
          endReason: 0,
          isTotalForfeit: false,
          gameAccounts: [
            {
              accountId: 1,
              accountUserName: "RKObot",
              result: botResult,
            },
            {
              accountId: -9999,
              accountUserName: "You",
              result: youResult,
            },
          ],
          matchAccountScores: [
            {
              accountId: 1,
              score: botResult === 1 ? 1 : 0,
            },
            {
              accountId: -9999,
              score: youResult === 1 ? 1 : 0,
            },
          ],
          moves: [
            {
              id: youMoveId,
              moveType: youMove,
              time: "2024-01-01T00:00:00.0000000+00:00",
              endReason: 0,
              accountId: -9999,
            },
            {
              id: botMoveId,
              moveType: botMove,
              time: "2024-01-01T00:00:00.0000000+00:00",
              endReason: 0,
              accountId: 1,
            },
          ],
        },
        typeSlug: "game-finished",
        actorIsTarget: false,
      });

      setShowPlayAgain(true);
    }, 1100);

    /*
    if (youResult === 0) {
      setTimeout(() => {
        const newGame = {
          matchId: -9999,
          moves: [],
          gameAccounts: [
            {
              gameId: currentGameId - 1,
              accountId: 1,
              accountUserName: null,
              result: null,
            },
            {
              gameId: currentGameId - 1,
              accountId: -9999,
              accountUserName: null,
              result: null,
            },
          ],
          winners: null,
          losers: null,
          id: currentGameId - 1,
          startTime: "2024-01-01T00:00:00.0000000+00:00",
          endTime: "2099-01-01T00:00:00.0000000+00:00",
          endReason: null,
          isTotalForfeit: false,
          isDraw: true,
          completed: false,
        };

        var newMatch = { ...currentMatch };
        const newMoves = [
          {
            accountId: 1,
            id: botMoveId,
            moveType: botMove,
            result: botResult,
          },
          {
            accountId: -9999,
            id: youMoveId,
            moveType: youMove,
            result: youResult,
          },
        ];
        newMatch = Match.patchGameStartedEvent(newMatch, newGame, newMoves);

        setCurrentGame(newGame);
        setCurrentMatch(newMatch);
        setCurrentGameId((prevGameId) => prevGameId - 1);
      }, 3100);
    } else {
      //
    }*/
  };

  const getNextRound = () => {};
  const handleModalUserManageOpen = () => {};

  const onPlayAgain = () => {
    const newGame = {
      matchId: -9999,
      moves: [],
      gameAccounts: [
        {
          gameId: currentGameId - 1,
          accountId: 1,
          accountUserName: null,
          result: null,
        },
        {
          gameId: currentGameId - 1,
          accountId: -9999,
          accountUserName: null,
          result: null,
        },
      ],
      winners: null,
      losers: null,
      id: currentGameId - 1,
      startTime: "2024-01-01T00:00:00.0000000+00:00",
      endTime: "2099-01-01T00:00:00.0000000+00:00",
      endReason: null,
      isTotalForfeit: false,
      isDraw: true,
      completed: false,
    };

    const _newMatch = { ..._freshMatch, key: new Date() };

    setCurrentGame(newGame);
    setCurrentMatch(_newMatch);
    setCurrentRound((prevRound) => {
      return {
        ...prevRound,
        matches: [_newMatch],
      };
    });
    setCurrentGameId((prevGameId) => prevGameId - 1);
    setShowPlayAgain(false);
  };

  useEffect(() => {
    const font = new FontFaceObserver("Lato", {
      weight: 400,
      style: "normal",
    });

    font
      .load()
      .then(() => {
        setFontLoaded(true);
      })
      .catch(() => {
        console.error("Font failed to load");
      });
  }, []);

  return (
    <>
      <div id="main">
        <div id="page-logged-in" className="page page-solo-anon">
          <div id="page-content">
            <div
              id="subpage-create"
              className="subpage subpage-group slideout-not-dragging"
            >
              <div id="subpage-content">
                <div className="inner-content">
                  <div className="deciding-interface">
                    <div className="deciding-game-zone">
                      {fontLoaded && (
                        <Board
                          key={currentGameId}
                          group={group}
                          tournament={currentTournament}
                          round={currentRound}
                          match={currentMatch}
                          game={currentGame}
                          moveHistory={moveHistory}
                          actor={actor}
                          opponents={opponents}
                          actorMove={actorMove}
                          opponentMoves={opponentMoves}
                          rpsEventCurrent={rpsEventCurrent}
                          onMove={handleMove}
                          getNextRound={getNextRound}
                          handleModalUserManageOpen={handleModalUserManageOpen}
                        />
                      )}
                      <div className="action-panel">
                        <Button
                          icon
                          size="big"
                          primary
                          className="decide-now"
                          onClick={onPlayAgain}
                          style={{
                            visibility: showPlayAgain ? "visible" : "hidden",
                          }}
                        >
                          <Icon className="fas fa-sync"></Icon> Play again
                        </Button>
                        <Button
                          icon
                          className="decide-now"
                          onClick={() => history.push("/")}
                        >
                          <Icon className="fas fa-user-plus"></Icon> Sign up for
                          more RPS games!
                        </Button>
                      </div>
                    </div>
                    {/* <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        background: "rgba(0,0,0,0.5)",
                      }}
                    >
                      <pre>{JSON.stringify(opponentMoves, "", 2)}</pre>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoloAnonPage;
