import React, { useState, useEffect, useContext } from "react";

import {
  Button,
  Form,
  Modal,
  Popup,
  TransitionablePortal,
} from "semantic-ui-react";

import AuthContext from "../../../contexts/AuthContext";
import { Api } from "../../../helpers/api";
import { rpsApi } from "../../../helpers/api-config";
import { usePrevious } from "../../../hooks";
import Avatar from "../../Avatar/Avatar";
import Icon from "../../Icon/Icon";
import RkoModal from "../RkoModal/RkoModal";

import "./ModalPostRegistration.scss";

const ModalPostRegistration = ({ open, close, onChangeUserName }) => {
  const [userNameCheckTimer, setUserNameCheckTimer] = useState(null);
  const [userName, setUserName] = useState("");
  const [userNameAvailable, setUserNameAvailable] = useState(true);
  const [userNameCheckLoading, setUserNameCheckLoading] = useState(false);
  const [userNameStatus, setUserNameStatus] = useState("");
  const [userNameStatusPopup, setUserNameStatusPopup] = useState(false);

  const { authState } = useContext(AuthContext);

  const prevOpen = usePrevious(open);
  const prevUserName = usePrevious(userName);

  const handleClose = () => {
    document.body.classList.remove("modal-fade-in");
    close();
  };

  const handleUserNameChange = (value) => {
    if (userNameCheckTimer) clearInterval(userNameCheckTimer);

    setUserNameAvailable(false);
    setUserNameCheckLoading(true);
    setUserNameStatus("");
    setUserName(value);
  };

  const getUserNameCheckIcon = () => {
    var iconData =
      userNameCheckLoading ? { className: "fas fa-spinner-third fa-spin" }
      : userNameAvailable ? { className: "fas fa-check", color: "green" }
      : { className: "fas fa-times", color: "red" };

    return (
      <Icon
        key={`username-check-icon-${iconData.className}`}
        embedded
        {...iconData}
      />
    );
  };

  const handleSave = () => {
    if (userName === authState?.account?.userName) {
      Api.updateUser({ tagline: "" });
      handleClose();
      return;
    }

    onChangeUserName(userName, () => {
      handleClose();
    });
  };

  useEffect(() => {
    if (!prevUserName || !userName || prevUserName === userName) return;

    if (userName.length < 3 || userName.length > 16) {
      setUserNameAvailable(false);
      setUserNameCheckLoading(false);
      setUserNameStatus(
        `Username must be ${
          userName.length < 3 ? "at least 3" : "no more than 16"
        } characters.`,
      );
    } else {
      setUserNameCheckTimer(
        setTimeout(() => {
          rpsApi.post(`/account/username`, { userName }).then((result) => {
            setUserNameAvailable(result.data.available);
            setUserNameCheckLoading(false);
            setUserNameStatus(
              result.data.available ?
                "" //This username is available."
              : result.data.error || "An error occurred, please try again",
            );
          });
        }, 700),
      );
    }
  }, [prevUserName, userName]);

  useEffect(() => {
    //if (!open || prevOpen || !account) return;
    setUserName(authState?.account?.userName);
  }, [authState?.account?.userName, open, prevOpen]);

  useEffect(() => {
    setUserNameStatusPopup(!!userNameStatus);
  }, [userNameStatus]);

  useEffect(() => {
    return () => {
      if (userNameCheckTimer) clearTimeout(userNameCheckTimer);
    };
  }, [userNameCheckTimer]);

  return (
    <TransitionablePortal
      open={open}
      onOpen={() =>
        setTimeout(() => document.body.classList.add("modal-fade-in"), 0)
      }
      transition={{ animation: "fade down", duration: 300 }}
    >
      <RkoModal
        size="tiny"
        centered={false}
        open={true}
        onClose={handleClose}
        className="modal-post-registration"
        closeOnDimmerClick={false}
      >
        {authState?.account && (
          <>
            <Modal.Content>
              <Modal.Description>
                <div className="modal-avatar-breakout">
                  <div className="modal-avatar-breakout-inner">
                    <Avatar user={authState?.account.id} size="tiny"></Avatar>
                  </div>
                </div>

                <h1>Thanks for joining!</h1>

                <h4>Before you play, set your public username</h4>

                <Form>
                  <Form.Field>
                    <Popup
                      content={userNameStatus}
                      position="bottom left"
                      open={
                        !userNameCheckLoading && userNameStatusPopup && open
                      }
                      size="small"
                      trigger={
                        <Form.Input
                          fluid
                          icon={getUserNameCheckIcon()}
                          iconPosition="left"
                          placeholder="Username"
                          type="text"
                          name="userName"
                          value={userName}
                          onChange={(e) => {
                            var value = e.target.value;
                            handleUserNameChange(value);
                          }}
                        />
                      }
                    />
                  </Form.Field>
                </Form>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <div
                className="action-panel-btn-wrap"
                onClick={() => {
                  if (userNameAvailable) {
                    handleSave();
                  }
                }}
              >
                <Button
                  primary
                  icon={<Icon embedded className="fas fa-chevron-right" />}
                  labelPosition="right"
                  content="Next"
                  onClick={handleSave}
                  disabled={!userNameAvailable}
                />
              </div>
            </Modal.Actions>
          </>
        )}
      </RkoModal>
    </TransitionablePortal>
  );
};

export default ModalPostRegistration;
