import React, { useState } from "react";
import { Button } from "semantic-ui-react";

import Icon from "../../../../../components/Icon/Icon";
import JoyrideButton from "../../../../../components/JoyrideButton/JoyrideButton";
import ModalDecisionOptions from "../../../../../components/Modal/ModalDecisionOptions/ModalDecisionOptions";
import TournamentConfig from "../../../../../components/TournamentConfig/TournamentConfig";

function RepeatingViewConfigure({
  group,
  viewingRepeatingId,
  handleConfigCancel,
  handleConfigChange,
  handlePrepareClick,
  handleCancelClick,
  newRepeatingDecisionValid,
  handleModalAlertOpen,
  handleJoyrideTourOpen,
}) {
  const [modalDecisionOptionsOpen, setModalDecisionOptionsOpen] =
    useState(false);
  const [modalDecisionOptionsKey, setModalDecisionOptionsKey] = useState(
    new Date().getTime(),
  );

  const handleSetUpRepeatingGameClick = () => {
    handleModalDecisionOptionsOpen();
  };

  const handleModalDecisionOptionsOpen = () => {
    setModalDecisionOptionsOpen(true);
    setModalDecisionOptionsKey(new Date().getTime());
  };

  const handleModalDecisionOptionsClose = () => {
    setModalDecisionOptionsOpen(false);
  };

  return (
    <div className="repeating-config-wrapper">
      {viewingRepeatingId && (
        <div className="repeating-config-cancel" onClick={handleCancelClick}>
          <Icon className="far fa-trash-alt"></Icon>
        </div>
      )}
      <TournamentConfig
        tournament={group.myDraftTournament}
        onConfigChange={handleConfigChange}
        isRepeating={true}
        isEditing={!!viewingRepeatingId}
      />

      {!viewingRepeatingId && <JoyrideButton onClick={handleJoyrideTourOpen} />}

      <div className="action-panel">
        <Button
          icon
          size="big"
          primary
          onClick={() => {
            if (viewingRepeatingId) handlePrepareClick();
            else handleSetUpRepeatingGameClick();
          }}
          disabled={!newRepeatingDecisionValid()}
          id="joyride-tour-repeating-btn"
        >
          {viewingRepeatingId && <>Update</>}
          {!viewingRepeatingId && <>Set up game</>}
          <Icon className="far fa-chevron-right"></Icon>
        </Button>
        {!!viewingRepeatingId && (
          <Button icon basic onClick={handleConfigCancel}>
            <Icon className="far fa-chevron-left"></Icon>
            &nbsp; Back
          </Button>
        )}
      </div>

      <ModalDecisionOptions
        key={"decision-options-" + modalDecisionOptionsKey}
        open={modalDecisionOptionsOpen}
        close={handleModalDecisionOptionsClose}
        group={group}
        tournament={group.myDraftTournament}
        onTournamentPrepare={handlePrepareClick}
        handleModalAlertOpen={handleModalAlertOpen}
      />
    </div>
  );
}

export default RepeatingViewConfigure;
